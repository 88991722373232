/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React, { useState } from "react";
import { MarketTypeMap, TransactionTypeMap } from "../../../store/reports/reportSelectors";
import Modal from "../../Modal/Modal";
import CustomTable from "./CustomTable";
import { useAppSelector } from "../../../store/storeHooks";

interface AccountStatementTableProps {
  records: any;
}

const AccountStatementTable: React.FC<AccountStatementTableProps> = (props) => {
  const { records } = props;
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { langData } = useAppSelector((state) => state.cms);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const headings = [
    "date",
    "transaction",
    "event",
    "market_type",
    "credit_debit",
    "close_balance",
    "transaction_id",
  ];

  return (
    <>
      <table className="w-full border-collapse mt-3">
        <thead>
          <tr className="bg-neutral-200 text-neutral-500 ">
            {headings.map((heading, index) => (
              <th
                key={index}
                className={`px-2 text-start py-1 border-r font-[500] text-[14px] leading-[16.2px] ${
                  index < headings.length - 1 ? "border-[#757575]" : ""
                }`}
              >
                {langData?.[heading]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((row, index) => (
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {moment(row.transactionTime).format("DD/MM/YYYY")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {TransactionTypeMap[row?.transactionType]}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.eventName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {MarketTypeMap[row.marketType]}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.amount}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.balanceAfter}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.transactionId}
              </td>
              <td
                onClick={() => {
                  setIsModalOpen(true);
                  setSelectedRecord(row);
                  setShowTransactionDetailsModal(true);
                }}
                className=" cursor-pointer px-6 py-4 whitespace-nowrap text-sm"
              >
                {">"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {records && records.length === 0 && (
        <div className=" h-12 flex justify-center items-center">{langData?.['records_not_found_txt']}</div>
      )}
       <Modal
        isOpen={isModalOpen}
        title={langData?.['account_statement']}
        onClose={closeModal}
      >
        <CustomTable
          row={selectedRecord}
        />
      </Modal>
    </>
  );
};

export default AccountStatementTable;
