/**
 * This code was generated by Builder.io.
 */
import React from "react";
import ProfileForm from "./Form/ProfileForm";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../store/storeHooks";

interface ProfileSectionProps {
  profileDetails: any;
  setProfileDetails: Function;
  updateUserDetails: Function;
  resetDetails: Function;
  updateNewPassword: Function;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  setOldPassword: Function;
  setNewPassword: Function;
  setConfirmPassword: Function;
  resetPassword: Function;
}

const MyComponent: React.FC<ProfileSectionProps> = (props) => {
  const {
    profileDetails,
    resetDetails,
    updateUserDetails,
    setProfileDetails,
    updateNewPassword,
    oldPassword,
    newPassword,
    confirmPassword,
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
    resetPassword,
  } = props;
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <main className="flex flex-col mx-auto w-full bg-white max-w-[480px]">
      <NavLink to="/">
        <div className="flex gap-1 self-start mt-3 ml-3 text-[14px] font-medium text-center whitespace-nowrap text-stone-900">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d42cf57120fbc12be212f17f3a73d903583610a72bf71aa1d40b6aa0f1ea254f?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c"
            alt=""
            className="shrink-0 my-auto w-1.5 aspect-[0.6]"
          />
          <div>{langData?.['back']}</div>
        </div>
      </NavLink>
      <ProfileForm
        profileDetails={profileDetails}
        setProfileDetails={setProfileDetails}
        updateUserDetails={updateUserDetails}
        resetDetails={resetDetails}
        oldPassword={oldPassword}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        updateNewPassword={updateNewPassword}
        setOldPassword={setOldPassword}
        setNewPassword={setNewPassword}
        setConfirmPassword={setConfirmPassword}
        resetPassword={resetPassword}
      />
    </main>
  );
};

export default MyComponent;
