// multiMarketUtils.js

export const checkIncludeMultiMarket = (marketData, marketId, eventId) => {
    let marketInclue = marketData.filter((itm) => itm.marketId === marketId);
    return marketInclue.length ? true : false;
  };
  
  export const addToMultiMarket = (competitionId, eventId, marketId, providerId, sportId) => {
    const username = sessionStorage.getItem('username') ?? '';
   
    if (marketId && username) {
      let data = [];
      const localData = localStorage.getItem(`multiMarket_${username}`) ?? '';
      if (localData) data = JSON.parse(atob(localData));
      const marketInclue = data?.filter((itm) => itm.marketId === marketId);
      if (marketInclue?.length === 0) {
        data.push({
          competitionId,
          eventId,
          marketId,
          providerId,
          sportId,
        });
        localStorage.setItem(`multiMarket_${username}`, btoa(JSON.stringify(data)));
      }
    }
  };
  
  export const removeToMultiMarket = (eventId, marketId) => {
    const username = sessionStorage.getItem('username') ?? '';
    if (username && marketId) {
      let data = [];
      const localData = localStorage.getItem(`multiMarket_${username}`) ?? '';
      if (localData) data = JSON.parse(atob(localData));
      const index = data?.findIndex((itm) => itm.eventId === eventId && itm.marketId === marketId);
      if (index > -1) {
        data.splice(index, 1);
        localStorage.setItem(`multiMarket_${username}`, btoa(JSON.stringify(data)));
      }
    }
  };
  