/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import GameCard from "./GameCard";
import Modal from "../../Modal/Modal";
import purple from "../../../assets/purple_bg.png";
import { categories } from "../../../store/catalog/catalogSelectors";
import search from "../../../assets/icons/Search.svg";
import DropDown from "../../Mobile/DropDown";
import { useAppSelector } from "../../../store/storeHooks";

interface GamegridProps {
  games: any;
  providerList: any;
  categoryList: any;
  selectedProvider: string;
  searchTerm: string;
  setSearchTerm: Function;
  setSelectedProvider: Function;
  selectedGameType: string;
  setSelectedGameType: Function;
  handleGameClick: Function;
  title: string;
}

const Gamegrid: React.FC<GamegridProps> = (props) => {
  const {
    title,
    games,
    selectedProvider,
    selectedGameType,
    setSelectedGameType,
    setSelectedProvider,
    searchTerm,
    providerList,
    categoryList,
    setSearchTerm,
    handleGameClick,
  } = props;
  const { langData } = useAppSelector((state) => state.cms);

  const handleSelectedGame = (e) => {
    setSelectedGameType(e.value);
  }

  const handleSelectedProvider = (e) => {
    setSelectedProvider(e.value);
  }

  return (
    <div className="flex flex-col max-md:w-full">
      <div className="flex flex-col pb-6 py-px mt-3 w-full bg-white rounded-lg border overflow-hidden border-solid border-neutral-200 max-md:mt-6 max-md:max-w-full">
        <div className="flex h-9 bg-stone-900 w-full">
          <div className=" bg-primary h-9 py-2 pr-4 pl-2 text-[14px] text-white font-medium">
            {title}
          </div>
          <div className=" skew-x-[-25deg] bg-primary -translate-x-[8px] w-4 h-9"></div>
          <div className=" skew-x-[-25deg] bg-primary w-1 h-9 -translate-x-1"></div>
          <div className=" skew-x-[-25deg] bg-primary w-1 h-9"></div>
        </div>
        <div className="flex gap-5 justify-between pr-5 mt-3 w-full text-[14px] max-md:flex-wrap max-md:max-w-full">
          <div className="flex flex-col justify-center text-neutral-500 ml-2">
            <div className="flex flex-col justify-center py-2 w-full border border-solid bg-zinc-100 border-neutral-200 rounded-[10000px]">
              <div className="flex gap-2 pl-2">
                <img
                  loading="lazy"
                  src={search}
                  className="shrink-0 self-start w-3.5 aspect-square"
                  alt=""
                />
                <input
                  type="text"
                  placeholder={langData?.['search_games']}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="bg-transparent border-none outline-none"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-3 font-medium max-md:flex-wrap">
            <div className="flex gap-2">
              <div className="my-auto text-right capitalize text-neutral-500">
                {langData?.['select_providers']}
              </div>
              <div className="w-[200px]">
                <DropDown options={providerList} selectedOption={selectedProvider === "All" ? { value: "-1", label: "All" } : selectedProvider} onChange={handleSelectedProvider} />
              </div>
            </div>
            {title !== "Virtual Casino" && (
              <div className="flex flex-col justify-center">
                <div className="flex gap-2">
                  <div className="my-auto capitalize text-neutral-500">
                    {langData?.['select_games']}
                  </div>
                  <div className="w-[200px]">
                    <DropDown options={categoryList} selectedOption={selectedGameType === "All" ? { value: "-1", label: "All" } : selectedGameType} onChange={handleSelectedGame} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-2 mt-3 text-[14px] px-2 font-semibold text-black max-md:mr-2">
          {games.map((game) => (
            <GameCard
              handleGameClick={handleGameClick}
              key={game.id}
              game={game}
            />
          ))}
        </div>
        {games.length === 0 && (
          <div className="text-center font-normal text-[14px]">
            {langData?.['no_games_to_display']}
          </div>
        )}
      </div>
    </div>
  );
};

export default Gamegrid;
