/**
 * This code was generated by Builder.io.
 */
import React from "react";
import {
  Filters,
  TransactionFilters,
  transactionPageStatusFilter,
  turnOverReptxTypes,
} from "../../../store/reports/reportSelectors";
import moment from "moment";
import DropDown from "../DropDown";
import ReactDatePicker from "../ReactDatePicker";
import { useAppSelector } from "../../../store/storeHooks";
interface DatePickerProps {
  onDateChange: Function;
  filters: Filters;
  setFilters: Function;
  TransactionTypes: any;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  filters,
  setFilters,
  TransactionTypes,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  const handleFromDateChange = (date) => {
    onDateChange(date, filters.endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(filters.startDate, date);
  };

  const handleTransactionType = (event) => {
    setFilters({ ...filters, transactionType: event.value });
  };

  const handleTransactionStatus = (event) => {
    setFilters({ ...filters, transactionStatus: event.value });
  };

  return (
    <div className="flex w-[100%] gap-[12px] self-end text-[14px] flex-col whitespace-nowrap  ">
      <div className="flex">
        <div className="w-[50%] gap-2.5 ml-2">
          <div className="grow my-auto capitalize text-neutral-500">
            {langData?.['transaction_type']}
          </div>
          <DropDown
            options={TransactionTypes}
            selectedOption={
              filters?.transactionType === "ALL"
                ? { value: "ALL", label: "All" }
                : filters?.transactionType
            }
            onChange={handleTransactionType}
          />
        </div>
        <div className="w-[50%] gap-2.5 ml-2">
          <div className="grow my-auto capitalize text-neutral-500">
            {langData?.['transaction_status']}
          </div>
          <div>
            <DropDown
              options={transactionPageStatusFilter}
              selectedOption={
                filters?.transactionStatus === "ALL"
                  ? { value: "ALL", label: "All" }
                  : filters?.transactionStatus
              }
              onChange={handleTransactionStatus}
            />
          </div>
        </div>
      </div>
      <div className="flex w-[100%] justify-between gap-[12px] ">
        <div className="flex flex-col w-[50%]  ">
          <div className="grow my-auto ml-1 capitalize text-neutral-500">From</div>
          <ReactDatePicker date={filters.startDate} setDate={handleFromDateChange} />
        </div>
        <div className="flex flex-col w-[50%] ">
          <div className="grow my-auto ml-1 capitalize text-neutral-500">To</div>
          <ReactDatePicker date={filters.endDate} setDate={handleToDateChange} />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
