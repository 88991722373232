import React, { useEffect, useRef, useState } from "react";
import {
  MatchOddsDTO,
  MatchOddsRunnerDTO,
} from "../../../models/common/MatchOddsDTO";
import { EventDTO } from "../../../models/common/EventDTO";
import moment from "moment";
import { ExchangePriceDTO } from "../../../models/SPEventDTO";
import { PlaceBetRequest } from "../../../models/BsData";
import { cFactor } from "../../../store/reports/reportSelectors";
import { UserBet } from "../../../models/UserBet";
import { SelectedObj } from "../../../models/SportsState";
import SVLS_API from "../../../api/api-services/svls-api";
import { RootState } from "../../../store";
import { addExchangeBet } from "../../../store/betSlip/betSlipThunk";
import { connect } from "react-redux";
import MarketBtn from "../MarketComponents/MarketBtn/MarketBtn";
import purple from "../../../assets/purple_bg.png";
import {
  addToMultiMarket,
  checkIncludeMultiMarket,
  removeToMultiMarket,
} from "../../../store/multiMarket/multiMarketUtils";
import { useMarketLocalState } from "../../../hooks/storageHook";
import { useNavigate } from "react-router-dom";
import RemoveMultiPinDarkGreen from "../../../assets/icons/remove_multi_pin_dark_green.svg";
import RemoveMultiPinDarkViolet from "../../../assets/icons/remove_multi_pin_dark_violet.svg";
import RemoveMultiPin from "../../../assets/icons/removemultimarket.svg";
import { ReactComponent as MultiPin } from "../../../assets/icons/multipin.svg";
import {
  getAllMarketsByEvent,
  getSecondaryMatchOddsByEvent,
} from "../../../store/catalog/catalogSelectors";
import { formatTime, ThousandFormatter } from "../../../utils/commonUtils";
import { isMobile } from "react-device-detect";
import Betslip from "../Betslip/Betslip";
import { CiCircleAlert } from "react-icons/ci";
import Modal from "./shared/ModalComponents";
import RulesPopUp from "./shared/RulesPopup";
import { useAppSelector } from "../../../store/storeHooks";

export type CashoutInfo = {
  selectionId: string;
  betType: any;
  oddValue: number;
  stakeAmount: number;
};

interface MatchOddsProps {
  eventData: EventDTO;
  bets: PlaceBetRequest[];
  openBets: UserBet[];
  addExchangeBet: (data: PlaceBetRequest) => void;
  selectedEventType: SelectedObj;
  secondaryMatchOdds: MatchOddsDTO[];
  getFormattedMinLimit: (num: number) => string;
  getFormattedMaxLimit: (num: number) => string;
  loggedIn?: boolean;
  isMultiMarket?: boolean;
  exposureMap: any;
  fetchEvent: (
    sportId: string,
    competitionId: string,
    eventId: string,
    marketTime: string
  ) => void;
  setBetStartTime?: Function;
  setAddNewBet?: Function;
}

const multiPinsMap = {
  purple: RemoveMultiPin,
  darkvoilet: RemoveMultiPinDarkViolet,
  darkgreen: RemoveMultiPinDarkGreen,
};

const MatchOdds: React.FC<MatchOddsProps> = (props) => {
  const {
    eventData,
    bets,
    openBets,
    addExchangeBet,
    selectedEventType,
    secondaryMatchOdds,
    loggedIn,
    isMultiMarket,
    exposureMap,
    setAddNewBet,
    setBetStartTime,
  } = props;

  let [matchOddsData, setMatchOddsData] = useState<MatchOddsDTO>();
  const disabledStatus = ["suspended", "closed", "suspended-manually"];
  const [multiMarketData, setMultiMarketData] = useMarketLocalState();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<string>("");
  const [open, setOpen] = useState<String[]>([]);
  const [openPopup, setIsOpenPopup] = useState<boolean>(false);
  const { langData } = useAppSelector((state) => state.cms);

  useEffect(() => {
    if (eventData !== null) {
      setMatchOddsData(eventData.matchOdds);
    }
  }, [eventData]);

  const getOpenBetsPL = (
    marketId: string,
    marketName: string,
    runner: MatchOddsRunnerDTO
  ) => {
    let returns = null;

    if (exposureMap && exposureMap?.[`${marketId}:${marketName}`]) {
      for (let rn of exposureMap[`${marketId}:${marketName}`]) {
        if (rn.runnerId === runner.runnerId) {
          return rn?.userRisk / cFactor;
        }
      }
    }
  };

  const getOpenBetsPLInArray = (
    marketId: string,
    marketName: string,
    runner: MatchOddsRunnerDTO
  ) => {
    let pl = getOpenBetsPL(marketId, marketName, runner);
    return pl ? [pl] : [];
  };

  const getTotalPL = (
    marketId: string,
    marketName: string,
    runner: MatchOddsRunnerDTO
  ) => {
    let returns = null;
    const mBetslipBets = bets.filter(
      (b) =>
        // (b.marketName === 'Match Odds' || b.marketName === 'Goal Markets') &&
        b.marketId === marketId && b.amount && b.amount > 0
    );

    if (mBetslipBets.length > 0) {
      returns = getOpenBetsPL(marketId, marketName, runner);
      for (let bet of mBetslipBets) {
        const plVal = bet.oddValue * bet.amount - bet.amount;
        if (bet.betType === "BACK") {
          if (bet.outcomeId === runner.runnerId) {
            returns ? (returns += plVal) : (returns = plVal);
          } else {
            returns ? (returns -= bet.amount) : (returns = 0 - bet.amount);
          }
        } else if (bet.betType === "LAY") {
          if (bet.outcomeId !== runner.runnerId) {
            returns ? (returns += bet.amount) : (returns = bet.amount);
          } else {
            returns ? (returns -= plVal) : (returns = 0 - plVal);
          }
        }
      }
    }
    return [returns];
  };

  const getMarketStatus = (marketTime: Date) => {
    let duration = moment.duration(moment(marketTime).diff(moment()));
    return duration.asMinutes() < 10 && duration.asMinutes() > 0
      ? "OPEN"
      : "SUSPENDED";
  };

  const handleMultiMarket = (
    competitionId,
    eventId,
    marketId,
    providerName,
    sportId,
    isAdd = true
  ) => {
    if (loggedIn) {
      if (isAdd) {
        addToMultiMarket(
          competitionId,
          eventId,
          marketId,
          providerName,
          sportId
        );
        marketId &&
          setMultiMarketData((prevState) => {
            return [
              ...prevState,
              {
                competitionId,
                eventId,
                marketId,
                providerName,
                sportId,
              },
            ];
          });
      } else {
        removeToMultiMarket(eventId, marketId);
        marketId &&
          setMultiMarketData((prevState) => {
            let data = [...prevState];
            const index = data?.findIndex(
              (itm) => itm.eventId === eventId && itm.marketId === marketId
            );
            index > -1 && data.splice(index, 1);
            return [...data];
          });
      }
    } else {
      navigate("/login");
    }
  };

  const CashOut = async (matchOddsData: MatchOddsDTO) => {
    try {
      const response = await SVLS_API.get(
        `/order/v2/orders/sports/${eventData.sportId}/events/${eventData.eventId}/markets/${matchOddsData.marketId}/cash-out-bet:probability`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt_token"),
          },
        }
      );

      if (response.status === 200) {
        const betDetails: CashoutInfo = response.data;

        // Add bet to betslip
        addExchangeBet({
          sportId: eventData.sportId,
          seriesId: eventData.competitionId,
          seriesName: eventData.competitionName,
          eventId: eventData.eventId,
          eventName: eventData.eventName,
          eventDate: eventData.openDate,
          marketId: matchOddsData.marketId,
          marketName: matchOddsData.marketName,
          marketType: "MO",
          outcomeId: betDetails?.selectionId,
          betType: betDetails?.betType === 0 ? "BACK" : "LAY",
          oddValue: +betDetails?.oddValue.toFixed(2),
          // initialOddValue: +betDetails.oddValue.toFixed(2),
          amount: +(betDetails?.stakeAmount / cFactor).toFixed(2),
          outcomeDesc: getOutcomeId(
            betDetails?.selectionId,
            matchOddsData.runners
          ),
          sessionPrice: -1,
          srEventId: eventData.eventId,
          srSeriesId: eventData.competitionId,
          srSportId: eventData.sportId,
          oddLimt: matchOddsData?.marketLimits?.maxOdd.toString(),
          mcategory: "ALL",
        });
      }
    } catch (err) {
      console.log("Something went wrong");
    }
  };

  const getOutcomeId = (selectionId: string, runners: MatchOddsRunnerDTO[]) => {
    for (let mo of runners) {
      if (mo.runnerId === selectionId) {
        return mo.runnerName;
      }
    }
  };

  const isHaveCashOut = (matchOddsData: MatchOddsDTO) => {
    if (matchOddsData?.runners?.length !== 2) {
      return false;
    }

    if (openBets?.length === 0) {
      return false;
    }

    // Get the market specific bets
    const op = openBets?.filter((bet: UserBet) => {
      return bet.marketId === matchOddsData.marketId;
    });

    return op?.length > 0 ? true : false;
  };

  return (
    <>
      {matchOddsData?.runners?.length > 0 &&
        matchOddsData.status.toLowerCase() !== "closed" ? (
        !isMultiMarket ||
          checkIncludeMultiMarket(
            multiMarketData,
            matchOddsData.marketId,
            eventData.eventId
          ) ? (
          <>
            <div className="w-full h-9 bg-[#000000] rounded-tl-lg rounded-tr-lg mt-1.5 relative">
              <div className=" flex">
                <div className=" flex h-9 bg-primary rounded-tl-lg py-2 pr-4 pl-2 text-[14px] text-white font-medium">
                  {!checkIncludeMultiMarket(
                    multiMarketData,
                    matchOddsData.marketId,
                    eventData?.eventId
                  ) ? (
                    <span title={langData?.['add_to_multi_markets_txt']}>
                      <MultiPin
                        // color="primary"
                        className="multi-add-icon cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          multiMarketData?.length < 10 &&
                            handleMultiMarket(
                              eventData?.competitionId,
                              eventData?.eventId,
                              matchOddsData.marketId,
                              eventData?.providerName,
                              eventData?.sportId,
                              true
                            );
                        }}
                      />
                    </span>
                  ) : (
                    <span title={langData?.['remove_from_multi_markets_txt']}>
                      <MultiPin
                        className="multi-remove-icon cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMultiMarket(
                            eventData?.competitionId,
                            eventData?.eventId,
                            matchOddsData.marketId,
                            eventData?.providerName,
                            eventData?.sportId,
                            false
                          );
                        }}
                      />
                    </span>
                  )}{" "}
                  <span className=" ml-2">{langData?.['match_odds']}</span>
                  {isHaveCashOut(matchOddsData) ? (
                    <div className="h-[28px]">
                      <button
                        color="primary"
                        className="h-[28px] ml-2 bg-[#b98eb1] text-[#fff] cursor-pointer px-2 flex items-center"
                        disabled={
                          disabledStatus.includes(
                            matchOddsData.status.toLowerCase()
                          ) ||
                          matchOddsData.suspend === true ||
                          !(
                            `${matchOddsData?.marketId}:${matchOddsData?.marketName}` in
                            exposureMap
                          )
                        }
                        onClick={() => CashOut(matchOddsData)}
                      >
                        {langData?.['cashout']}
                      </button>
                    </div>
                  ) : null}
                  <span className="text-[11px] flex items-center absolute right-3 font-normal text-[#fff]">
                  <div className="cursor-pointer ">
                          <CiCircleAlert className="text-[20px]" onClick={() => setIsOpenPopup(true)} />
                        </div>
                    {langData?.['min']}:
                    {matchOddsData?.marketLimits
                      ? ThousandFormatter(
                        matchOddsData?.marketLimits?.minStake / cFactor
                      )
                      : 100}{" "}
                    {langData?.['max']}:
                    {matchOddsData?.marketLimits
                      ? ThousandFormatter(
                        matchOddsData?.marketLimits?.maxStake / cFactor
                      )
                      : 5000}
                  </span>
                </div>
                <div className=" skew-x-[-25deg] bg-primary h-9 -translate-x-[9px] w-5"></div>
                <div className=" skew-x-[-25deg] bg-primary h-9 w-1 -translate-x-1"></div>
                <div className=" skew-x-[-25deg] bg-primary h-9 w-1"></div>
              </div>
            </div>
            <div className="flex flex-col px overflow-x-scroll bg-white  border border-solid border-neutral-200 max-md:max-w-full">
              <table className="w-full text-[14px] font-medium text-stone-900">
                <thead>
                  <tr className=" py-2 text-[14px] font-medium whitespace-nowrap h-[32px] bg-neutral-200 text-neutral-500 ">
                    <th className=" w-[70%] text-left pl-3">{langData?.['markets']}</th>
                    <th className="  ">{langData?.['back']}</th>
                    <th className=" ">{langData?.['lay']}</th>
                  </tr>
                </thead>
                <tbody>
                  {matchOddsData?.status &&
                    matchOddsData?.runners
                      .filter(
                        (runner, idx) =>
                          runner?.status.toLowerCase() !== "loser"
                      )
                      .map((runner, index) => (
                        <MatchOddsRow
                          minStake={
                            matchOddsData?.marketLimits
                              ? matchOddsData?.marketLimits?.minStake / cFactor
                              : 0
                          }
                          maxStake={
                            matchOddsData?.marketLimits
                              ? matchOddsData?.marketLimits?.maxStake / cFactor
                              : 0
                          }
                          eventData={eventData}
                          marketName="Match Odds"
                          // marketLimits={marketLimits}
                          matchOddsData={matchOddsData}
                          runner={runner}
                          getOpenBetsPL={getOpenBetsPLInArray}
                          getTotalPL={getTotalPL}
                          disabledStatus={disabledStatus}
                          addExchangeBet={addExchangeBet}
                          bets={bets}
                          selectedRow={selectedRow}
                          setSelectedRow={setSelectedRow}
                          open={open}
                          setOpen={setOpen}
                          index={index}
                        />
                      ))}
                </tbody>
              </table>
            </div>
          </>
        ) : null
      ) : null}
      {/* Secondary Match Odds */}
      {matchOddsData?.status &&
        secondaryMatchOdds?.map((moData, idx) => (
          <>
            {moData.status?.toLowerCase() !== "closed" ? (
              <>
                {!isMultiMarket ||
                  checkIncludeMultiMarket(
                    multiMarketData,
                    moData.marketId,
                    eventData.eventId
                  ) ? (
                  <>
                    <div className="w-full bg-[#000000] rounded-tl-lg rounded-tr-lg mt-1.5 relative">
                      <div className=" flex">
                        <div className=" flex bg-primary rounded-tl-lg py-2 pr-4 pl-2 text-[14px] text-white font-medium">
                          {!checkIncludeMultiMarket(
                            multiMarketData,
                            moData.marketId,
                            eventData?.eventId
                          ) ? (
                            <span title={langData?.['add_to_multi_markets_txt']}>
                              <MultiPin
                                // color="primary"
                                className="multi-add-icon cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  multiMarketData?.length < 10 &&
                                    handleMultiMarket(
                                      eventData?.competitionId,
                                      eventData?.eventId,
                                      moData.marketId,
                                      eventData?.providerName,
                                      eventData?.sportId,
                                      true
                                    );
                                }}
                              />
                            </span>
                          ) : (
                            <span title={langData?.['remove_from_multi_markets_txt']}>
                              <MultiPin
                                className="multi-remove-icon cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMultiMarket(
                                    eventData?.competitionId,
                                    eventData?.eventId,
                                    moData.marketId,
                                    eventData?.providerName,
                                    eventData?.sportId,
                                    false
                                  );
                                }}
                              />
                            </span>
                          )}
                          <span className="ml-2">{moData?.marketName}</span>
                          {["7", "4339"].includes(selectedEventType?.id) ? (
                            <span className="market-start-time">
                              {formatTime(moData?.marketTime)}
                            </span>
                          ) : null}

                          {["7", "4339"].includes(selectedEventType?.id) ? (
                            <span
                              className={
                                getMarketStatus(moData.marketTime) === "OPEN"
                                  ? " bg-[green] web-view"
                                  : " bg-[red] web-view"
                              }
                            >
                              {" "}
                              {getMarketStatus(moData.marketTime)}
                            </span>
                          ) : null}

                          <span className="text-[11px] flex items-center absolute right-3 font-normal text-[#fff]">
                          <div className="cursor-pointer ">
                          <CiCircleAlert className="text-[20px]" onClick={() => setIsOpenPopup(true)} />
                        </div>
                            {langData?.['min']}:
                            {moData?.marketLimits
                              ? ThousandFormatter(
                                moData?.marketLimits?.minStake / cFactor
                              )
                              : 100}{" "}
                            {langData?.['max']}:
                            {moData?.marketLimits
                              ? ThousandFormatter(
                                moData?.marketLimits?.maxStake / cFactor
                              )
                              : 5000}
                          </span>
                        </div>
                        <div className=" skew-x-[-25deg] bg-primary -translate-x-[9px] w-5"></div>
                        <div className=" skew-x-[-25deg] bg-primary w-1 -translate-x-1"></div>
                        <div className=" skew-x-[-25deg] bg-primary w-1"></div>
                      </div>
                    </div>
                    <div className="flex flex-col px overflow-x-scroll bg-white border border-solid border-neutral-200 max-md:max-w-full">
                      <table className="w-full text-[14px] font-medium text-stone-900">
                        <thead>
                          <tr className=" py-2 text-[14px] font-medium whitespace-nowrap h-[32px] bg-neutral-200 text-neutral-500 ">
                            <th className=" w-[70%] text-left pl-3 ">
                              {langData?.['market']}
                              {isHaveCashOut(moData) ? (
                                <div className="h-[28px]">
                                  <button
                                    color="primary"
                                    className="h-[28px] ml-2 bg-[#b98eb1] text-[#fff] cursor-pointer px-2 flex items-center"
                                    disabled={
                                      disabledStatus.includes(
                                        moData.status.toLowerCase()
                                      ) ||
                                      moData.suspend === true ||
                                      !(
                                        `${moData?.marketId}:${moData?.marketName}` in
                                        exposureMap
                                      )
                                    }
                                    onClick={() => CashOut(moData)}
                                  >
                                    {langData?.['cashout']}
                                  </button>
                                </div>
                              ) : null}
                            </th>
                            <th className="  ">{langData?.['back']}</th>
                            <th className=" ">{langData?.['lay']}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {idx === 0 &&
                            ["7", "4339"].includes(selectedEventType?.id) ? (
                            <label
                              className={
                                getMarketStatus(moData.marketTime) === "OPEN"
                                  ? "profit mob-view"
                                  : "loss mob-view"
                              }
                            >
                              {" "}
                              {getMarketStatus(moData.marketTime)}
                            </label>
                          ) : null}
                          {moData?.runners?.length > 0 &&
                            moData?.runners
                              .filter(
                                (runner, idx) =>
                                  runner?.status?.toLowerCase() !== "loser"
                              )
                              .map((runner, index) => (
                                <MatchOddsRow
                                  minStake={
                                    matchOddsData?.marketLimits
                                      ? matchOddsData?.marketLimits?.minStake /
                                      cFactor
                                      : 0
                                  }
                                  maxStake={
                                    matchOddsData?.marketLimits
                                      ? matchOddsData?.marketLimits?.maxStake /
                                      cFactor
                                      : 0
                                  }
                                  eventData={eventData}
                                  marketName="Match Odds"
                                  // marketLimits={marketLimits}
                                  matchOddsData={matchOddsData}
                                  runner={runner}
                                  getOpenBetsPL={getOpenBetsPLInArray}
                                  getTotalPL={getTotalPL}
                                  disabledStatus={disabledStatus}
                                  addExchangeBet={addExchangeBet}
                                  bets={bets}
                                  selectedRow={selectedRow}
                                  setSelectedRow={setSelectedRow}
                                  open={open}
                                  setOpen={setOpen}
                                  index={index}
                                />
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </>
        ))}
        {openPopup && (
        <Modal
          onClose={() => setIsOpenPopup(false)}
          isOpen={openPopup}
          title={langData?.['rules']}
        >
          <RulesPopUp />
        </Modal>
      )}
    </>
  );
};

interface MatchOddsRowProps {
  minStake: any;
  maxStake: any;
  eventData: EventDTO;
  marketName: string;
  matchOddsData: MatchOddsDTO;
  runner: MatchOddsRunnerDTO;
  // marketLimits: any;
  getOpenBetsPL: (
    marketId: string,
    marketName: string,
    runner: MatchOddsRunnerDTO,
    type: string
  ) => number[];
  getTotalPL: (
    marketId: string,
    marketName: string,
    runner: MatchOddsRunnerDTO
  ) => number[];
  disabledStatus: string[];
  addExchangeBet: (data: PlaceBetRequest) => void;
  bets: PlaceBetRequest[];
  selectedRow: string;
  setSelectedRow: (data) => void;
  open?: String[];
  setOpen?: (data) => void;
  index?: number;
}

const MatchOddsRow: React.FC<MatchOddsRowProps> = (props) => {
  const {
    eventData,
    marketName,
    // marketLimits,
    matchOddsData,
    runner,
    getOpenBetsPL,
    getTotalPL,
    disabledStatus,
    addExchangeBet,
    minStake,
    maxStake,
    bets,
    selectedRow,
    setSelectedRow,
  } = props;

  const isOddDisable = (
    eventData: EventDTO,
    status: string,
    suspend: boolean,
    betType: string,
    odd: number,
    marketTime?: Date
  ) => {
    if (disabledStatus.includes(status.toLowerCase()) || suspend === true)
      return true;

    if (matchOddsData?.marketLimits?.maxOdd < odd) return true;

    // BBL CUP
    if (matchOddsData?.marketName.toLowerCase().includes("winner"))
      return false;

    if (["7", "4339"].includes(eventData?.sportId)) {
      if (betType === "lay") return true;

      let duration = moment.duration(moment(marketTime).diff(moment()));
      return duration.asMinutes() < 10 && duration.asMinutes() > 0
        ? false
        : true;
    }

    return eventData?.status === "IN_PLAY" ? false : true;
  };
  const betslipRef = useRef(null);

  useEffect(() => {
    if (bets?.length > 0 && betslipRef.current) {
      betslipRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'center', // Scroll so the element is in the center of the view
      });
    }
  }, [bets]);

  return (
    <>
      <tr className=" justify-between pr-6 mt-2 w-full pl-2 h-[32px] border-b border-[#e0e0e0] ">
        <td className="my-auto text-[14px] font-medium text-stone-900 px-2 flex-[6] ">
          <div>
            {runner?.runnerName}
            {getOpenBetsPL(
              matchOddsData.marketId,
              matchOddsData.marketName,
              runner,
              "array"
            ).map((ret) =>
              ret !== null ? (
                <span
                  className={ret >= 0 ? " text-[#1b891b] " : " text-[#ff3a3a] "}
                >
                  {ret > 0
                    ? "+" + Number(ret).toFixed(2)
                    : Number(ret).toFixed(2)}
                </span>
              ) : null
            )}
          </div>
          {getTotalPL(
            matchOddsData.marketId,
            matchOddsData.marketName,
            runner
          ).map((ret) =>
            ret !== null ? (
              <div className="profit-loss-box">
                <span
                  className={ret >= 0 ? " text-[#1b891b] " : " text-[#ff3a3a] "}
                >
                  {ret > 0
                    ? "+" + Number(ret).toFixed(2)
                    : Number(ret).toFixed(2)}
                </span>
              </div>
            ) : null
          )}
        </td>
        <td className="">
          <div className="flex gap-[1px] h-[24px] w-[168px] cursor-pointer m-[5px] web-view flex-row-reverse rounded overflow-hidden ">
            {runner?.backPrices?.length > 0
              ? runner?.backPrices?.map((odds, idx) => (
                <MarketBtn
                  mainValue={odds?.price}
                  subValue={odds.size}
                  showSubValueinKformat={true}
                  oddType="back-odd"
                  valueType="matchOdds"
                  oddsSet={[
                    runner.backPrices[0] ? runner.backPrices[0]?.price : 0,
                    runner.backPrices[1] ? runner.backPrices[1]?.price : 0,
                    runner.backPrices[2] ? runner.backPrices[2]?.price : 0,
                  ]}
                  key={idx}
                  marketType="mo"
                  disable={isOddDisable(
                    eventData,
                    matchOddsData.status.toLowerCase(),
                    matchOddsData.suspend,
                    "back",
                    odds.price,
                    matchOddsData.marketTime
                  )}
                  onClick={() => {
                    if (
                      moment(eventData?.openDate).diff(moment(), "hour") <=
                      24 ||
                      eventData?.status === "IN_PLAY" ||
                      disabledStatus.includes(
                        matchOddsData.status.toLowerCase()
                      ) ||
                      matchOddsData.suspend === true ||
                      !["7", "4339"].includes(eventData?.sportId)
                    ) {
                      setSelectedRow(runner.runnerId + marketName + "MO");
                      addExchangeBet({
                        sportId: eventData?.sportId,
                        seriesId: eventData?.competitionId,
                        seriesName: eventData?.competitionName,
                        eventId: eventData?.eventId,
                        eventName: eventData?.eventName,
                        eventDate: eventData?.openDate,
                        marketId: matchOddsData.marketId,
                        marketName: marketName,
                        marketType: "MO",
                        outcomeId: runner.runnerId,
                        outcomeDesc: runner.runnerName,
                        betType: "BACK",
                        amount: 0,
                        oddValue: odds?.price,
                        oddSize: odds.size,
                        sessionPrice: -1,
                        srEventId: eventData?.eventId,
                        srSeriesId: eventData?.competitionId,
                        srSportId: eventData?.sportId,
                        minStake: minStake,
                        maxStake: maxStake,
                        oddLimt:
                          matchOddsData?.marketLimits?.maxOdd.toString(),
                        mcategory: "ALL",
                      });
                    }
                  }}
                />
              ))
              : null}
          </div>
          <div className="flex h-[24px] w-fit rounded overflow-hidden cursor-pointer m-[5px] mob-view ">
            <MarketBtn
              mainValue={runner.backPrices[0]?.price}
              subValue={runner.backPrices[0]?.size}
              showSubValueinKformat={true}
              oddType="back-odd"
              marketType="mo"
              valueType="matchOdds"
              disable={isOddDisable(
                eventData,
                matchOddsData.status.toLowerCase(),
                matchOddsData.suspend,
                "back",
                runner.backPrices[0]?.price,
                matchOddsData.marketTime
              )}
              onClick={() => {
                if (
                  moment(eventData?.openDate).diff(moment(), "hour") <= 24 ||
                  eventData?.status === "IN_PLAY" ||
                  disabledStatus.includes(matchOddsData.status.toLowerCase()) ||
                  matchOddsData.suspend === true ||
                  !["7", "4339"].includes(eventData?.sportId)
                ) {
                  setSelectedRow(runner.runnerId + marketName + "MO");
                  addExchangeBet({
                    sportId: eventData?.sportId,
                    seriesId: eventData?.competitionId,
                    seriesName: eventData?.competitionName,
                    eventId: eventData?.eventId,
                    eventName: eventData?.eventName,
                    eventDate: eventData?.openDate,
                    marketId: matchOddsData.marketId,
                    marketName: marketName,
                    marketType: "MO",
                    outcomeId: runner.runnerId,
                    outcomeDesc: runner.runnerName,
                    betType: "BACK",
                    amount: 0,
                    oddValue: runner.backPrices[0].price,
                    oddSize: runner.backPrices[0].size,
                    sessionPrice: -1,
                    srEventId: eventData?.eventId,
                    srSeriesId: eventData?.competitionId,
                    srSportId: eventData?.sportId,
                    minStake: minStake,
                    maxStake: maxStake,
                    oddLimt: matchOddsData?.marketLimits?.maxOdd.toString(),
                    mcategory: "ALL",
                  });
                }
              }}
            />
          </div>
        </td>
        <td className="">
          <div className="flex gap-[1px] h-[24px] w-[168px] rounded overflow-hidden cursor-pointer m-[5px] web-view ">
            {runner?.layPrices?.length > 0 ? (
              runner?.layPrices.map((odds, idx) => (
                <MarketBtn
                  mainValue={
                    ["7", "4339"].includes(eventData?.sportId) ? 0 : odds?.price
                  }
                  subValue={odds.size}
                  showSubValueinKformat={true}
                  oddType="lay-odd"
                  valueType="matchOdds"
                  oddsSet={[
                    runner.layPrices[0] ? runner.layPrices[0]?.price : 0,
                    runner.layPrices[1] ? runner.layPrices[1]?.price : 0,
                    runner.layPrices[2] ? runner.layPrices[2]?.price : 0,
                  ]}
                  key={idx}
                  marketType='mo'
                  disable={isOddDisable(
                    eventData,
                    matchOddsData.status.toLowerCase(),
                    matchOddsData.suspend,
                    "lay",
                    odds.price
                  )}
                  onClick={() => {
                    if (
                      moment(eventData?.openDate).diff(moment(), "hour") <=
                      24 ||
                      eventData?.status === "IN_PLAY" ||
                      disabledStatus.includes(
                        matchOddsData.status.toLowerCase()
                      ) ||
                      matchOddsData.suspend === true ||
                      !["7", "4339"].includes(eventData?.sportId)
                    ) {
                      setSelectedRow(runner.runnerId + marketName + "MO");
                      addExchangeBet({
                        sportId: eventData?.sportId,
                        seriesId: eventData?.competitionId,
                        seriesName: eventData?.competitionName,
                        eventId: eventData?.eventId,
                        eventName: eventData?.eventName,
                        eventDate: eventData?.openDate,
                        marketId: matchOddsData.marketId,
                        marketName: marketName,
                        marketType: "MO",
                        outcomeId: runner.runnerId,
                        outcomeDesc: runner.runnerName,
                        betType: "LAY",
                        amount: 0,
                        oddValue: odds?.price,
                        oddSize: odds.size,
                        sessionPrice: -1,
                        srEventId: eventData?.eventId,
                        srSeriesId: eventData?.competitionId,
                        srSportId: eventData?.sportId,
                        minStake: minStake,
                        maxStake: maxStake,
                        oddLimt: matchOddsData?.marketLimits?.maxOdd.toString(),
                        mcategory: "ALL",
                      });
                    }
                  }}
                />
              ))
            ) : (
              <></>
            )}
          </div>

          <div className="flex h-[24px] w-fit cursor-pointer m-[5px] mob-view ">
            <MarketBtn
              mainValue={
                ["7", "4339"].includes(eventData?.sportId)
                  ? 0
                  : runner.layPrices[0]?.price
              }
              subValue={runner.layPrices[0]?.size}
              showSubValueinKformat={true}
              oddType="lay-odd"
              marketType="mo"
              valueType="matchOdds"
              disable={isOddDisable(
                eventData,
                matchOddsData?.status?.toLowerCase(),
                matchOddsData.suspend,
                "lay",
                runner?.layPrices[0]?.price
              )}
              onClick={() => {
                if (
                  moment(eventData?.openDate).diff(moment(), "hour") <= 24 ||
                  eventData?.status === "IN_PLAY" ||
                  disabledStatus.includes(matchOddsData.status.toLowerCase()) ||
                  matchOddsData.suspend === true ||
                  !["7", "4339"].includes(eventData?.sportId)
                ) {
                  setSelectedRow(runner.runnerId + marketName + "MO");
                  addExchangeBet({
                    sportId: eventData?.sportId,
                    seriesId: eventData?.competitionId,
                    seriesName: eventData?.competitionName,
                    eventId: eventData?.eventId,
                    eventName: eventData?.eventName,
                    eventDate: eventData?.openDate,
                    marketId: matchOddsData.marketId,
                    marketName: marketName,
                    marketType: "MO",
                    outcomeId: runner.runnerId,
                    outcomeDesc: runner.runnerName,
                    betType: "LAY",
                    amount: 0,
                    oddValue: runner.layPrices[0]?.price,
                    oddSize: runner.layPrices[0]?.size,
                    sessionPrice: -1,
                    srEventId: eventData?.eventId,
                    srSeriesId: eventData?.competitionId,
                    srSportId: eventData?.sportId,
                    minStake: minStake,
                    maxStake: maxStake,
                    oddLimt: matchOddsData?.marketLimits?.maxOdd.toString(),
                    mcategory: "ALL",
                  });
                }
              }}
            />
          </div>
        </td>
      </tr>
      {bets?.length > 0 &&
        bets?.[0]?.marketName === marketName &&
        bets?.[0]?.marketId === matchOddsData.marketId &&
        bets?.[0]?.outcomeId === runner.runnerId &&
        isMobile ? (
        <tr className="inline-betslip">
          <td colSpan={3}>
            <div ref={betslipRef} className="betslip-container">
              {" "}
              <Betslip eventData={eventData} />{" "}
            </div>
          </td>
        </tr>
      ) : null}
      
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const eventType = state.catalog.selectedEventType;
  const competition = state.catalog.selectedCompetition;
  const event = state.catalog.selectedEvent;
  return {
    // eventData: getAllMarketsByEvent(
    //   state.catalog.events,
    //   eventType.id,
    //   competition.id,
    //   event.id
    // ),
    // secondaryMatchOdds: getSecondaryMatchOddsByEvent(
    //   state.catalog.secondaryMatchOddsMap,
    //   event.id
    // ),
    selectedEventType: eventType,
    bets: state.betSlip.bets,
    openBets: state.betSlip.openBets,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    addExchangeBet: (data: PlaceBetRequest) => dispatch(addExchangeBet(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchOdds);
