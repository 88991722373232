import React, { useCallback, useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { toast } from "react-toastify";
import SVLS_API from "../../api/api-services/svls-api";
import EventAggreagtionReport from "./EventAggregationReport";
// import DatePicker from '../../components/DatePicker/DatePicker';
import { CommissionReportRecord } from "../../models/CommissionReport";
import SportAggreagtionReport from "./SportAggregationReport";
import CompetitionAggreagtionReport from "./CompetitionAggregationReport";
import MarketAggreagtionReport from "./MarketAggregationReport";
import DatePicker from "./DatePicker";
import { IoIosNavigate, IoMdNavigate } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa6";
import { FiLoader } from "react-icons/fi";
import Loader from "../Loader/Loader";
import { isMobile } from "react-device-detect";
import ReactDatePicker from "../Mobile/ReactDatePicker";
import { useAppSelector } from "../../store/storeHooks";

const UpLineReport: React.FC = () => {
  const [groupBy, setGroupBy] = useState<
    "SPORT" | "COMPETITION" | "EVENT" | "MARKET"
  >("SPORT");
  const [nRec, setNRec] = useState<number>(40);
  const [page, setPage] = useState<number>(1);
  const [startDate, setStartDate] = useState<Moment>(
    moment().subtract(6, "days")
  );
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<CommissionReportRecord[]>([]);
  const [sportId, setSportId] = useState<string | null>(null);
  const [competitionId, setCompetitionId] = useState<string | null>(null);
  const [eventId, setEventId] = useState<string | null>(null);
  const { langData } = useAppSelector((state) => state.cms);

  // Breadcrumb variables
  const [sportName, setSportName] = useState<string | null>(null);
  const [competitionName, setCompetitionName] = useState<string | null>(null);
  const [eventName, setEventName] = useState<string | null>(null);

  const getRequestParams = useCallback(() => {
    let params: any = {
      groupBy: "SPORT",
      nRec: nRec,
      page: page,
      startDate: startDate.startOf("day").toISOString(),
      endDate: endDate.endOf("day").toISOString(),
    };
    if (sportId !== null) {
      params["sportId"] = sportId;
      params["groupBy"] = "COMPETITION";
    } else {
      return params;
    }
    if (competitionId !== null) {
      params["competitionId"] = competitionId;
      params["groupBy"] = "EVENT";
    } else {
      return params;
    }
    if (eventId !== null) {
      params["eventId"] = eventId;
      params["groupBy"] = "MARKET";
    }
    return params;
  }, [nRec, page, startDate, endDate, sportId, competitionId, eventId]);

  const fetchRecords = useCallback(async () => {
    setLoading(true);
    try {
      const response = await SVLS_API.get("/reports/v2/commission/", {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
        params: getRequestParams(),
      });
      if (response.status === 200) {
        setRecords(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
      setLoading(false);
    }
  }, [getRequestParams]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <>
      <div
        className={
          "relative h-[31px] capitalize bg-black text-white rounded-t-lg mx-2 max-lg:mt-3 "
        }
      >
        <div className="flex flex-col justify-center items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
          <div className="flex ">
            <div className=" bg-primary h-9 w-full py-2 pr-4 pl-2 text-[14px] text-white">
              {langData?.['upline_report']}
            </div>
            <div className=" skew-x-[-25deg] h-9 bg-primary -translate-x-[8px] w-10"></div>
            <div className="absolute skew-x-[-25deg] bg-primary w-1 h-9 left-[150px] -translate-x-1"></div>
            <div className="absolute skew-x-[-25deg] bg-primary w-1 left-[155px] h-9"></div>
          </div>
        </div>
      </div>
      <div className=" flex-col lg:flex-row px-2">
        {isMobile ? (
          <div className="flex gap-3 self-center mt-[12px] text-[14px] font-medium whitespace-nowrap max-w-[406px]">
            <div className="flex flex-col flex-1 ">
              <label htmlFor="fromDate" className=" ml-1 text-neutral-500">
                {langData?.['from']}
              </label>
              <DatePicker
                  selectedDate={startDate}
                  onDateChange={setStartDate}
                  maxDate={endDate}
                />
            </div>
            <div className="flex flex-col flex-1  ">
              <label htmlFor="toDate" className=" ml-1 text-neutral-500">
                {langData?.['to']}
              </label>
              <DatePicker
                  selectedDate={endDate}
                  onDateChange={setEndDate}
                  minDate={startDate}
                />
            </div>
          </div>
        ) : (
          <div className=" px-4 py-2 bg-white border border-gray-300">
            <div className="space-y-4 flex gap-4 items-center justify-end ">
              <div className="flex items-center gap-2">
                <div className="text-[14px] font-medium text-gray-600">
                  {langData?.['from']}
                </div>
                <DatePicker
                  selectedDate={startDate}
                  onDateChange={setStartDate}
                  maxDate={endDate}
                />
              </div>
              <div className="!mt-0 flex items-center gap-2">
                <div className="text-[14px] font-medium text-gray-600">{langData?.['to']}</div>
                <DatePicker
                  selectedDate={endDate}
                  onDateChange={setEndDate}
                  minDate={startDate}
                />
              </div>
            </div>
          </div>
        )}
        <div className="w-full ">
          <div className="flex items-center justify-between mb-2">
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => {
                if (groupBy !== "SPORT") {
                  setGroupBy("SPORT");
                  setEventId(null);
                  setCompetitionId(null);
                  setSportId(null);
                }
              }}
            >
              {/* <UplineIcon className="w-6 h-6" /> */}
            </div>
          </div>

          {/* Breadcrumbs Section */}
          {groupBy !== "SPORT" && (
            <div className="flex items-center space-x-2 text-sm mb-2">
              {groupBy === "MARKET" || groupBy === "EVENT" ? (
                <div
                  className="text-[#4da8f1] underline capitalize text-[14px] cursor-pointer"
                  onClick={() => {
                    setGroupBy("COMPETITION");
                    setEventId(null);
                    setCompetitionId(null);
                  }}
                >
                  {sportName}
                </div>
              ) : null}
              {groupBy !== "COMPETITION" && competitionId !== null ? (
                <>
                  <FaAngleRight className="w-4 h-4" />
                  <div
                    className="text-[#4da8f1] underline capitalize text-[14px] cursor-pointer"
                    onClick={() => {
                      setGroupBy("EVENT");
                      setEventId(null);
                    }}
                  >
                    {competitionName}
                  </div>
                </>
              ) : null}
              {groupBy !== "EVENT" && eventId !== null ? (
                <>
                  <FaAngleRight className="w-4 h-4" />
                  <div className="text-gray-500 text-[14px]">{eventName}</div>
                </>
              ) : null}
            </div>
          )}

          {/* Reports table */}
          <div className="bg-white rounded-lg shadow-md relative">
            {loading ? (
              <div className="text-center py-4">
                <Loader />
              </div>
            ) : (
              <>
                {groupBy === "SPORT" && records.length > 0 && (
                  <SportAggreagtionReport
                    setSportDetails={(record: CommissionReportRecord) => {
                      setGroupBy("COMPETITION");
                      setSportId(record.sportId);
                      setSportName(record.sportName);
                    }}
                    records={records}
                  />
                )}
                {groupBy === "COMPETITION" && (
                  <CompetitionAggreagtionReport
                    setCompetitionDetails={(record: CommissionReportRecord) => {
                      setGroupBy("EVENT");
                      setCompetitionId(record.competitionId);
                      setCompetitionName(record.competitionName);
                    }}
                    records={records}
                  />
                )}
                {groupBy === "EVENT" && (
                  <EventAggreagtionReport
                    setEventDetails={(record: CommissionReportRecord) => {
                      setGroupBy("MARKET");
                      setEventId(record.eventId);
                      setEventName(record.eventName);
                    }}
                    records={records}
                  />
                )}
                {groupBy === "MARKET" && (
                  <MarketAggreagtionReport records={records} />
                )}
                {records.length === 0 && (
                  <div className="text-center py-4 text-gray-500">
                    {langData?.['records_not_found_txt']}
                  </div>
                )}
              </>
            )}
          </div>

          {/* Pagination Section */}
          <div className="flex justify-between mt-4">
            {page > 1 && !loading && (
              <button
                onClick={() => setPage(page - 1)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
              >
                {langData?.['prev']}({page - 1})
              </button>
            )}
            {records.length === nRec && !loading && (
              <button
                onClick={() => setPage(page + 1)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 ml-auto"
              >
                {langData?.['next']}({page + 1})
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpLineReport;
