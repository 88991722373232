/**
 * This code was generated by Builder.io.
 */
import React, { useEffect } from "react";
import { useAppSelector } from "../../../../store/storeHooks";

interface ProfileInfoProps {
  profileDetails: any;
  setProfileDetails: Function;
  updateUserDetails: Function;
  resetDetails: Function;
}

const ProfileInfo: React.FC<ProfileInfoProps> = (props) => {
  const { profileDetails, resetDetails, updateUserDetails, setProfileDetails } =
    props;
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex flex-col w-full max-md:ml-0 rounded-md overflow-hidden max-md:w-full">
      <div className="flex flex-col pb-5 mt-3 w-full text-[14px] font-medium bg-white rounded-md overflow-hidden border border-solid border-neutral-200">
        <div className="flex bg-stone-900 w-full">
          <div className=" bg-primary min-w-[170px] py-2 pr-4 pl-2 text-[14px] text-white font-medium">
            {langData?.['my_profile']}
          </div>
          <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
          <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
          <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
        </div>
        <form className="flex flex-col w-full">
          <div className="flex w-full gap-3 px-2 mt-3.5 text-stone-900 max-md:flex-wrap"></div>
          <div className="flex w-full px-2 gap-3 mt-5 text-stone-900 max-md:flex-wrap">
            <div className="flex w-full flex-col py-0.5 ">
              <label htmlFor="userName" className="">
                {langData?.['username']}
              </label>
              <input
                id="userName"
                type="text"
                disabled
                value={profileDetails.username}
                className="px-3 py-2 h-[44px] w-full mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200  "
                placeholder={langData?.['enter_username_txt']}
              />
            </div>
            <div className="flex w-full flex-col py-0.5 ">
              <label htmlFor="userName" className="">
                {langData?.['full_name']}
              </label>
              <input
                id="fullName"
                type="text"
                value={profileDetails.fullName}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    fullName: e.target.value,
                  })
                }
                className="px-3 py-2 h-[44px] w-full mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200  "
                placeholder={langData?.['enter_full_name']}
              />
            </div>
          </div>
          <div className="flex w-full gap-3 px-2 mt-5 text-stone-900 max-md:flex-wrap">
            <div className="flex w-full flex-col py-0.5 ">
              <label htmlFor="email" className="">
                {langData?.['email_address']}
              </label>
              <input
                id="email"
                type="email"
                value={profileDetails.emailId}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    emailId: e.target.value,
                  })
                }
                className="px-3 py-2 h-[44px] mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200  "
                placeholder={langData?.['enter_email']}
              />
            </div>
            <div className="flex w-full flex-col py-0.5 max-md:max-w-full">
              <label htmlFor="phoneNumber" className="max-md:max-w-full">
                {langData?.['phone_number']}
              </label>
              <input
                id="phoneNumber"
                type="tel"
                value={profileDetails.phoneNumber}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    phoneNumber: e.target.value,
                  })
                }
                className="px-3 py-2 h-[44px] w-full mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200  max-md:max-w-full"
                placeholder={langData?.['enter_phone_number_txt']}
              />
            </div>
          </div>
          <div className="flex w-full gap-3 px-2 mt-5 whitespace-nowrap text-stone-900 max-md:flex-wrap">
            <div className="flex w-full flex-col py-0.5 whitespace-nowrap max-md:max-w-full">
              <label htmlFor="address" className="max-md:max-w-full">
                {langData?.['address']}
              </label>
              <input
                id="address"
                type="text"
                value={profileDetails.address}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    address: e.target.value,
                  })
                }
                className="px-3 py-2 h-[44px] w-full mt-3 rounded-md border border-solid bg-zinc-100 border-neutral-200  max-md:max-w-full"
                placeholder={langData?.['enter_address']}
              />
            </div>
            <div className="flex w-full flex-col py-0.5 max-md:max-w-full">
              <label htmlFor="city" className="max-md:max-w-full">
                {langData?.['City']}
              </label>
              <input
                id="city"
                type="text"
                value={profileDetails.city}
                onChange={(e) =>
                  setProfileDetails({ ...profileDetails, city: e.target.value })
                }
                className="px-3 py-2 h-[44px] mt-2.5 rounded-md border border-solid bg-zinc-100 border-neutral-200  max-md:max-w-full"
                placeholder={langData?.['enter_city']}
              />
            </div>
          </div>
          <div className="flex w-[50%] gap-3 px-2 mt-5 whitespace-nowrap text-stone-900 max-md:flex-wrap">
            <div className="flex w-full flex-col py-0.5 max-md:max-w-full">
              <label htmlFor="pincode" className="max-md:max-w-full">
                {langData?.['pincode']}
              </label>
              <input
                id="pincode"
                type="text"
                value={profileDetails.pinCode}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    pinCode: e.target.value,
                  })
                }
                className="px-3 py-2 h-[44px] mt-3 rounded-md border border-solid bg-zinc-100 border-neutral-200  max-md:max-w-full"
                placeholder={langData?.['enter_pincode']}
              />
            </div>
          </div>
          <div className="flex gap-3 self-end mt-10 max-w-full text-sm text-center whitespace-nowrap w-[412px]">
            <button
              onClick={() => resetDetails()}
              type="reset"
              className="px-16 w-[192px] py-2.5 text-black rounded-md border border-fuchsia-700 border-solid max-md:px-5"
            >
              {langData?.['reset']}
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                updateUserDetails(profileDetails);
              }}
              type="submit"
              className="px-16 w-[192px] py-2.5 text-white bg-fuchsia-900 rounded-md max-md:px-5"
            >
              {langData?.['save']}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileInfo;
