import React from "react";
import Flag from "../../../assets/AuthScreens/India.webp"
import ArrowDown from "../../../assets/AuthScreens/arrowDown.webp"
import { useAppSelector } from "../../../store/storeHooks";

interface StepOneProps {
  otp: number;
  otpSent: boolean;
  phoneNumber: string;
  agreedToTerms: boolean;
  setOtp: Function;
  onPhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTermsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StepOne: React.FC<StepOneProps> = ({
  otp,
  otpSent,
  phoneNumber,
  agreedToTerms,
  setOtp,
  onPhoneChange,
  onTermsChange,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <>
      <div className="mt-6">
        {/* <label htmlFor="phoneNumber" className="self-start text-neutral-500">
          Phone Number
        </label> */}
        <div className="flex gap-2.5  mt-3 text-neutral-500 text-opacity-60">
          <div className="w-[80px] p-[2px] h-[44px] flex items-center rounded-md border border-solid border-bg-grey">
            <img
            src={Flag}
            />
            <div>+91</div>
            <img
            src={ArrowDown}
            
            />
          </div>
          <input
            type="tel"
            id="phoneNumber"
            className="px-3 py-4  w-full h-[44px] bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60"
            placeholder={langData?.['enter_phone_number_txt']}
            value={phoneNumber}
            onChange={onPhoneChange}
          />
        </div>
      </div>
      {otpSent && (
        <div className="mt-6">
          <label htmlFor="phoneNumber" className="self-start text-neutral-500">
            {langData?.['otp']}
          </label>
          <div className="flex gap-2.5 mt-3 text-neutral-500 text-opacity-60">
            <input
              type="tel"
              id="otp"
              className="px-3 py-4 mt-3 w-full bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60"
              placeholder={langData?.['enter_otp_txt']}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
        </div>
      )}

      <div className="flex gap-2.5 self-start mt-5 text-stone-900">
        <input
          type="checkbox"
          id="termsAgreement"
          className="flex shrink-0 w-8 h-8 bg-white rounded-lg"
          checked={agreedToTerms}
          onChange={onTermsChange}
        />
        <label htmlFor="termsAgreement" className="flex-auto my-auto">
          {langData?.['terms_and_conditions_txt']}
        </label>
      </div>
    </>
  );
};

export default StepOne;
