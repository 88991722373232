import { AddAccountFormType, WithdrawFormType, FormDataObjType, AddUpiAccountFormType } from "./types";

export const withdrawFormArr = [
  {
    key: "amount",
    langKey: "amount",
    label: "Amount",
    type: "number",
    placeholder: "enter_amount",
  },
  {
    key: "notes",
    langKey: "notes",
    label: "Notes",
    type: "text",
    placeholder: "enter_notes",
  },
];

export const addAccountFormArr: FormDataObjType[] = [
  {
    key: "accountNumber",
    langKey: "account_no",
    label: "Account Number",
    type: "text",
    placeholder: "enter_account_no"
  },
  {
    key: "accountHolderName",
    langKey: "account_holder_name",
    label: "Account Holder Name",
    type: "text",
    placeholder: "enter_acc_holder_name"
  },
  {
    key: "displayName",
    langKey: "display_name",
    label: "Display Name",
    type: "text",
    placeholder: "enter_display_name",
  },
  {
    key: "bankName",
    langKey: "bank_name",
    label: "Bank Name",
    type: "text",
    placeholder: "enter_bank_name",
  },
  {
    key: "branchName",
    langKey: "branch_name",
    label: "Branch Name",
    type: "text",
    placeholder: "enter_branch_name",
  },
  {
    key: "ifscCode",
    langKey: "ifsc_code",
    label: "IFSC Code",
    type: "text",
    placeholder: "enter_ifsc_code",
  },
];

export const addUpiFormArr: FormDataObjType[] = [
  {
    key: "upiId",
    label: "UPI ID",
    langKey: "upi_id",
    type: "text",
    placeholder: "enter_upi_id",
  },

  {
    key: "accountHolderName",
    label: "Account Holder Name",
    langKey: "account_holder_name",
    type: "text",
    placeholder: "enter_acc_holder_name",
  },
  {
    key: "displayName",
    label: "Display Name",
    langKey: "display_name",
    type: "text",
    placeholder: "enter_display_name",
  },
];

export const createInitialDataObjFromFormArr = (
  formDataArr: FormDataObjType[]
) => {
  const initialState = {};

  for (let fieldInfo of formDataArr) {
    initialState[fieldInfo.key] = "";
  }

  return initialState;
};

export const getAddAccountFromInitialState = (): AddAccountFormType =>
  createInitialDataObjFromFormArr(addAccountFormArr) as AddAccountFormType;

export const getInitialWithdrawForm = () =>
  createInitialDataObjFromFormArr(withdrawFormArr) as WithdrawFormType;

export const getAddUpiAccountInitialForm = () =>
  createInitialDataObjFromFormArr(addUpiFormArr) as AddUpiAccountFormType;
