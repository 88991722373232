/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React from "react";
import { transactionTypesMap } from "../../../../store/reports/reportSelectors";
import { useAppSelector } from "../../../../store/storeHooks";

interface TurnoverHistoryTableProps {
  records: any;
}

const TurnoverHistoryTable: React.FC<TurnoverHistoryTableProps> = (props) => {
  const { records } = props;
  const headings = [
    // "Event Date",
    // "Transaction Type",
    // "Event Name",
    // "Market",
    // "Market Type",
    // "Amount",
    "Turnover Balance",
  ];
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <>
     <div className="flex flex-col justify-center px-3 py-px mt-1.5 w-full text-[14px] font-medium rounded-md bg-neutral-200 text-neutral-500">
      <div className="overflow-x-scroll text-[14px]">
        <div className="flex gap-5 justify-between w-full">
          <div className="my-auto">{langData?.['event_date']}</div>
          <div className="flex gap-4 whitespace-nowrap">
            <div className="flex gap-2">
              <div className="shrink-0 w-px border border-solid border-neutral-500 h-full" />
              <div className="my-auto text-wrap">{langData?.['event_name']}</div>
            </div>
            <div className="flex gap-2">
              <div className="shrink-0 w-px border border-solid border-neutral-500 h-full" />
              <div className="my-auto text-wrap">{langData?.['transaction_type']}</div>
            </div>
            <div className="flex gap-2">
              <div className="shrink-0 w-px border border-solid border-neutral-500 h-full" />
              <div className="my-auto">{langData?.['market']}</div>
            </div>
          </div>
        </div>
        {records?.map((row, index) => (
          <div
            key={index}
            className="flex flex-col items-center py-0.5 mt-1.5 w-full bg-white border-2 border-sky-200 border-solid"
          >
            <div className="flex gap-5 justify-between w-full max-w-[379px]">
              <div className="flex flex-col self-start mt-3 pl-[10px]">
                <div className="text-sm font-medium text-stone-900">
                {moment(row.event_date).format("DD/MM/YYYY")}
                </div>
              </div>
              <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                <div className="my-auto text-wrap">  {transactionTypesMap[row.transaction_type]}</div>
              </div>
              <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                <div className="my-auto text-wrap">{row.event_name}</div>
              </div>
              {/* <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                <div className="my-auto text-wrap"> {row.market_name}</div>
              </div> */}
            </div>
            <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
            <div className="flex">
            <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
              <div className="flex flex-col my-auto">
                <div className="flex gap-1">
                  <div className="font-medium text-neutral-500">{langData?.['market_type']}:</div>
                  <div className="text-black">{row.market_type}</div>
                </div>
              </div>
              <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
            </div>
            
            <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
              <div className="flex flex-col my-auto">
                <div className="flex gap-1">
                  <div className="font-medium text-neutral-500">{langData?.['amount']}:</div>
                  <div className="text-black">{row.amount}</div>
                </div>
                <div className="flex  my-auto">
                <div className="flex gap-1">
                  <div className="font-medium text-neutral-500">{langData?.['turnover_balance']} :</div>
                  <div className="text-black">{row.turnover_balance}</div>
                </div>
              </div>
              </div>
            </div>
           
            </div>
          </div>
        ))}
        {records && records.length === 0 && (
          <div className=" text-center">{langData?.['records_not_found_txt']}</div>
        )}
      </div>
      </div>
    </>
  );
};

export default TurnoverHistoryTable;
