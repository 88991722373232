/**
 * This code was generated by Builder.io.
 */
import React from "react";
import TransactionFilter from "./TransactionFilter";
import TransactionList from "./TransactionList";
import purple from "../../../../assets/purple_bg.png"
import { useAppSelector } from "../../../../store/storeHooks";
interface MyTransactionProps {
  filters:any;
  setFilters:any;
  records: any;
  fetchTransactionRequest: Function;
  handleDateChange:Function;
  TransactionTypes: any;
}


const MyTransaction: React.FC<MyTransactionProps> = (props) => {
  const { handleDateChange, TransactionTypes, filters, setFilters, records } = props;
  const { langData } = useAppSelector((state) => state.cms);
  return (
    <div className="flex overflow-hidden flex-col mx-auto w-full bg-zinc-100 max-w-[480px] transition ease-in-out duration-[2000ms]">
      <main className="flex flex-col pt-px mt-[12px] w-full bg-white ">
      <div className="flex flex-col mt-2 justify-center items-start text-[14px] font-medium text-white rounded-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
      <div className=" bg-stone-900 flex w-full h-8 text-white text-[14px] font-semibold rounded-t-md">
        <div className="flex flex-col justify-center w-[70%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
          <div className="flex min-w-[170px]">
            <div className=" bg-primary w-full py-[0.4rem] pr-4 pl-2">
              {langData?.['my_transactions']}
            </div>
            <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
          </div>
        </div>

      </div>
      </div>
        <TransactionFilter TransactionTypes={TransactionTypes} handleDateChange={handleDateChange} filters={filters} setFilters={setFilters} />
        <TransactionList records={records} />
      </main>
    </div>
  );
};

export default MyTransaction;
