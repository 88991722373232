import moment from "moment";
import { CURRENCY_TYPE_FACTOR } from "../../constants/CurrencyTypeFactor";
import { Currency } from "../../models/Currency";
import { getCurrencyTypeFromToken } from "../../utils/commonUtils";
import store, { RootState } from '../index';

const langData = (store.getState() as RootState).cms.langData;
export interface PLRecordMktLvl {
  id: string;
  betPlacedTime: Date;
  betType?: string;
  eventId?: string;
  eventName?: string;
  marketId?: string;
  marketName?: string;
  marketType?: string;
  oddValue: number;
  outcomeDesc: string;
  sessionRuns: string;
  stakeAmount: number;
  username: string;
  payOutAmount: number;
  outcomeResult?: string;
  commission?: number;
}

export type Filters = {
  startDate: any;
  endDate: any;
  selectedGame: string;
  status: string;
  pageToken: string[];
  sport: string;
  transaction: Number;
  bettingCurrency: string;
  selectedMarket: any;
  transactionType: string;
  transactionStatus: string;
};

export const defaultFilters: Filters = {
  selectedGame: "",
  status: "",
  startDate: moment().subtract(7, "d"),
  endDate: moment(),
  pageToken: [],
  sport: "",
  transaction: -1,
  bettingCurrency: Currency.Pts,
  selectedMarket: [],
  transactionType: "",
  transactionStatus: "",
};

export interface PLStatement {
  username: string;
  categoryType: string;
  gamesLost: number;
  gamesWon: number;
  profit: number;
  eventId: string;
  eventName: string;
  betPlacedTime: Date;
  payOutDate: Date;
  sportId: string;
  seriesId: string;
  seriesName: string;
  marketId: string;
  marketName: string;
  commission: number;
}

export const pageSize = 25;
export const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

export const MarketTypeByEnumMap: { [key: string]: string } = {
  MATCH_ODDS: "Match odds",
  BOOKMAKER: "Bookmaker",
  FANCY: "Fancy",
  PREMIUM: "Premium",
  CASINO: "Casino",
  BINARY: "Binary",
  INDIAN_CASINO: "Indian Casino",
};

export const turnOverReptxTypes = [
  { value: "ALL", label: "all" },
  { value: "BET_PLACEMENT", label: "bet_placement" },
  { value: "BET_SETTLEMENT", label: "bet_settlement" },
  { value: "BINARY_BET_ROLLBACK", label: "binary_bet_rollback" },
  { value: "BINARY_BET_SETTLEMENT", label: "binary_bet_settlement" },
  { value: "BINARY_BET_VOID", label: "binary_bet_void" },
  { value: "CASINO_BET_PLACEMENT", label: "casino_bet_placement" },
  { value: "CASINO_BET_ROLLBACK", label: "casino_bet_rollback" },
  { value: "CASINO_BET_SETTLEMENT", label: "casino_bet_settlement" },
  { value: "GAP_BET_ROLLBACK", label: "gap_bet_rollback" },
  { value: "GAP_BET_SETTLEMENT", label: "gap_bet_settlement" },
  { value: "ROLLBACK_BET_SETTLEMENT", label: "rollback_bet_settlement" },
  {
    value: "ROLLBACK_VOID_BET_SETTLEMENT",
    label: "rollback_void_bet_settlement",
  },
  {
    value: "SPORT_BOOK_BET_ROLLBACK",
    label: "sport_book_bet_rollback",
  },
  {
    value: "SPORT_BOOK_BET_SETTLEMENT",
    label: "sport_book_bet_settlement",
  },
  {
    value: "VOID_BET_SETTLEMENT",
    label: "void_bet_settlement",
  },
];

export const OutcomeDescMap = {
  "0": "Lost",
  "1": "Win",
  "2": "Unsettled",
  "11": "Unsettled",
  "3": "Rolled Back",
  "4": "Void",
  "5": "Lapsed",
  "6": "Cancelled",
};

export const MarketTypeMap: { [key: string]: string } = {
  "0": "Match odds",
  "1": "Book maker",
  "2": "Fancy",
  "3": "Premium Market",
  "4": "Casino",
  "5": "Binary",
  "6": "Indian Casino",
};

export const TransactionMap = {
  IN_PROGRESS: "In Progress",
  INITIATED: "Initiated",
  APPROVAL_PENDING: "Approval Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  SUCCEEDED: "Succeeded",
  FAILED: "Failed",
  CANCELLED: "Cancelled",
};

export function getSportNameByIdMap(eventType: string) {
  return SportNameByIdMap[eventType];
}

export const SportNameByIdMap: { [key: string]: string } = {
  "1": "soccer",
  "2": "tennis",
  "4": "cricket",
  "7522": "basketball",
  "7511": "baseball",
  "4339": "greyhoundracing",
  "7": "horseracing",
};

export function getOutcomeDescByEnumName(eventType: string) {
  return OutcomeDescByEnumMap[eventType];
}

export const OutcomeDescByEnumMap = {
  Lost: "Lost",
  Won: "Win",
  Open: "Unsettled",
  "11": "Unsettled",
  RolledBack: "Rolled Back",
  Voided: "Void",
  Lapse: "Lapsed",
  Cancelled: "Cancelled",
};

export const transactionPageStatusFilter = [
  { value: "ALL", label: "all" },
  { value: "APPROVAL_PENDING", label: "approval_pending" },
  { value: "IN_PROGRESS", label: "in_progress" },
  { value: "INITIATED", label: "initiated" },
  { value: "APPROVED", label: "approved" },
  { value: "REJECTED", label: "rejected" },
  { value: "SUCCEEDED", label: "succeeded" },
  { value: "FAILED", label: "failed" },
];

export const TransactionFilters = [
  { value: "-1", label: "all" },
  { value: "0", label: "deposit" },
  { value: "1", label: "withdraw" },
  { value: "2", label: "settlement_deposit" },
  { value: "3", label: "settlement_withdraw" },
  { value: "27", label: "bet_settlement" },
  { value: "6-10-21-28-38-42", label: "rollback" },
  { value: "7-29", label: "voided" },
  { value: "45", label: "bonus_redeemed" },
  { value: "46", label: "bonus_rollback" },
  { value: "47", label: "refund" },
];

export const TransactionTypeMap = {
  "0": "Deposit",
  "1": "Withdraw",
  "2": "Settlement Deposit",
  "3": "Settlement Withdraw",
  "4": "Bet Placement",
  "5": "Bet Settlement",
  "6": "Rollback Bet Settlement",
  "7": "Void Bet Settlement",
  "8": "Casino Bet Placement",
  "9": "Casino Bet Settlement",
  "10": "Casino Bet Rollback",
  "11": "Win Commission",
  "12": "Win Commission Reversal",
  "13": "Indian Casino Bet Placement",
  "14": "Indian Casino Bet Settlement",
  "15": "Commission Claim",
  "16": "Commission Deposit",
  "17": "Commission Rollback",
  "18": "Commission Void",
  "19": "Casino Bet Placement",
  "20": "Casino Bet Settlement",
  "21": "Casino Rollback",
  "22": "-",
  "23": "Binary Bet Placement",
  "24": "Binary Bet Settlement",
  "25": "Binary Bet Rollback",
  "26": "Bet Placement",
  "27": "Bet Settlement",
  "28": "Bet Rollback",
  "29": "Bet Void",
  "30": "Bet Cancelled",
  "31": "Bet Expired",
  "32": "Bet Lapsed",
  "33": "Bet Deleted",
  "34": "Risk Update",
  "35": "Binary Bet Void",
  "36": "Sport Book Bet Placement",
  "37": "Sport Book Bet Settlement",
  "38": "sport Book Bet Rollback",
  "39": "Rolling Commission",
  "40": "Win Commission Update",
  "41": "Win Commission Update",
  "42": "Rollback Void Bet Settlement",
  "44": "Commission Claim",
  "45": "Bonus Redeemed",
  "46": "Bonus Rollback",
  "47": "Refund",
  "48": "Affiliate Commission Redeemed",
};

export const transactionTypesMap = {
  BET_PLACEMENT: "Bet Placement",
  BET_SETTLEMENT: "Bet Settlement",
  BINARY_BET_PLACEMENT: "Binary Bet Placement",
  BINARY_BET_ROLLBACK: "Binary Bet Rollback",
  BINARY_BET_SETTLEMENT: "Binary Bet Settlement",
  BINARY_BET_VOID: "Binary Bet Void",
  BONUS_REDEEMED: "Bonus Redeemed",
  BONUS_ROLLED_BACK: "Bonus Rolled Back",
  CASINO_BET_PLACEMENT: "Casino Bet Placement",
  CASINO_BET_ROLLBACK: "Casino Bet Rollback",
  CASINO_BET_SETTLEMENT: "Casino Bet Settlement",
  CLAIM_INCENTIVE: "Claim Incentive",
  COMMISSION_CLAIM: "Commission Claim",
  COMMISSION_DEPOSIT: "Commission Deposit",
  COMMISSION_ROLLBACK: "Commission Rollback",
  COMMISSION_VOID: "Commission Void",
  DEPOSIT: "Deposit",
  GAP_BET_PLACEMENT: "Gap Bet Placement",
  GAP_BET_ROLLBACK: "Gap Bet Rollback",
  GAP_BET_SETTLEMENT: "Gap Bet Settlement",
  INCENTIVE_TRANSFER: "Incentive Transfer",
  INDIAN_CASINO_BET_PLACEMENT: "Indian Casino Bet Placement",
  INDIAN_CASINO_BET_SETTLEMENT: "Indian Casino Bet Settlement",
  ROLLBACK_BET_SETTLEMENT: "Rollback Bet Settlement",
  ROLLBACK_VOID_BET_SETTLEMENT: "Rollback Void Bet Settlement",
  ROLLBACK_WIN_COMMISSION: "Rollback Win Commission",
  ROLLING_COMMISSION: "Rolling Commission",
  ROLLING_COMMISSION_ROLLBACK: "Rolling Commission Rollback",
  SAP_BET_CANCELLED: "SAP Bet Cancelled",
  SAP_BET_DELETED: "SAP Bet Deleted",
  SAP_BET_EXPIRED: "SAP Bet Expired",
  SAP_BET_LAPSED: "SAP Bet Lapsed",
  SAP_BET_PLACEMENT: "SAP Bet Placement",
  SAP_BET_ROLLBACK: "SAP Bet Rollback",
  SAP_BET_SETTLEMENT: "SAP Bet Settlement",
  SAP_BET_VOID: "SAP Bet Void",
  SAP_UPDATE_RISK: "SAP Update Risk",
  SETTLEMENT_DEPOSIT: "Settlement Deposit",
  SETTLEMENT_WITHDRAW: "Settlement Withdraw",
  SPORT_BOOK_BET_PLACEMENT: "Sport Book Bet Placement",
  SPORT_BOOK_BET_ROLLBACK: "Sport Book Bet Rollback",
  SPORT_BOOK_BET_SETTLEMENT: "Sport Book Bet Settlement",
  UN_KNOWN_TYPE: "Unknown Type",
  VOID_BET_SETTLEMENT: "Void Bet Settlement",
  WIN_COMMISSION: "Win Commission",
  WIN_COMMISSION_UPDATE: "Win Commission Update",
  WITHDRAW: "Withdraw",
};
