import React, { useMemo, useState } from "react";
import EventCategory from "./EventCategory";
import EventItem from "./EventItem";
import { EventDTO } from "../../../models/common/EventDTO";
import LiveVirtualFilter from "../../../common/LiveVirtualFilter/LiveVirtualFilter";

type InplayEventsObj = {
  sportId: string;
  sportName: string;
  sportSlug: string;
  events: EventDTO[];
};

type EventlistProp = {
  inplayEvents: InplayEventsObj[];
};

interface FilterState {
  isLive: boolean;
  isVirtual: boolean;
}

interface FiltersState {
  [index: number]: FilterState;
}

const EventList: React.FC<EventlistProp> = ({ inplayEvents }) => {
  const [inPlayLiveVirtualFilters, setInPlayLiveVirtualFilters] = useState<FiltersState>({});

  const handleInPlayFilterChange = (index: number, newIsLive: boolean, newIsVirtual: boolean) => {
    setInPlayLiveVirtualFilters((prevFilters) => ({
      ...prevFilters,
      [index]: {
        isLive: newIsLive,
        isVirtual: newIsVirtual,
      },
    }));
  };

  const filteredEvents = useMemo(() => {
    return inplayEvents.map((iEvent, index) => {
      const { isLive, isVirtual } = inPlayLiveVirtualFilters[index] || {
        isLive: false,
        isVirtual: false,
      };

      const filterLiveEvents = iEvent.events?.filter(
        (event) => event.status.toLowerCase() === "in_play" && (!event.catId || event?.catId !== "SR VIRTUAL")
      ) || [];

      const filterVirtualEvents = iEvent.events?.filter((event) => event.catId && event.catId === "SR VIRTUAL") || [];

      if (isLive && isVirtual) {
        return [...filterLiveEvents, ...filterVirtualEvents];
      } else if (isLive) {
        return filterLiveEvents;
      } else if (isVirtual) {
        return filterVirtualEvents;
      } else {
        return iEvent.events;
      }
    });
  }, [inPlayLiveVirtualFilters, inplayEvents]);

  return (
    <div className="flex flex-col py-px mt-1.5 w-full bg-white border border-solid border-neutral-200 max-md:max-w-full">
      {inplayEvents.map((iEvent, index) => (
        <React.Fragment key={index}>
          <LiveVirtualFilter
            isLive={inPlayLiveVirtualFilters[index]?.isLive || false}
            isVirtual={inPlayLiveVirtualFilters[index]?.isVirtual || false}
            onFilterChange={(newIsLive: boolean, newIsVirtual: boolean) => {
              handleInPlayFilterChange(index, newIsLive, newIsVirtual);
            }}
          />
          <div className="mb-3">
            <EventCategory name={iEvent?.sportName} />
            {filteredEvents[index]?.map((match, eventIndex) => (
              <React.Fragment key={eventIndex}>
                <EventItem match={match} iEvent={iEvent} />
                {eventIndex < iEvent.events.length - 1 && (
                  <div className="mt-1.5 w-full border border-solid border-neutral-200 min-h-[1px] max-md:max-w-full" />
                )}
              </React.Fragment>
            ))}
            {index < inplayEvents.length - 1 && (
              <div className="mt-1.5 w-full border border-solid border-neutral-200 min-h-[1px] max-md:max-w-full" />
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default EventList;
