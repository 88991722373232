/**
 * This code was generated by Builder.io.
 */
import React, { useEffect } from "react";
import DropDown from "../DropDown";

interface SelectDropdownProps {
  label: string;
  options: string[];
  selectedValue: string;
  setSelectedValue: Function;
}

const SelectDropdown: React.FC<SelectDropdownProps> = (props) => {
  const { label, options, selectedValue, setSelectedValue } = props;

  const handleSelectedValue = (e) => {
    setSelectedValue(e.value);
  }

  return (
    <div className="flex flex-col flex-1 ">
      <label
        htmlFor={`${label.toLowerCase()}Select`}
        className="text-neutral-500"
      >
        {label}
      </label>
      <DropDown options={options} selectedOption={selectedValue === "All" ? { value: "-1", label: "All" } : selectedValue} onChange={handleSelectedValue} />
    </div>
  );
};

export default SelectDropdown;
