import React,{useState} from "react";
import { Filters } from "../../../../store/reports/reportSelectors";
import { useAppSelector } from "../../../../store/storeHooks";

interface GameTabsProps {
  filters: Filters;
  setFilters: Function;
  sports:any;
}

const GameTabs: React.FC<GameTabsProps> = (props) => {
  const {sports,filters,setFilters}=props;
  const { langData } = useAppSelector((state) => state.cms);
  const [selectedGame, setSelectedGame] = useState("All");
  const handleChange=(game)=>{
    setSelectedGame(game.name);
    setFilters({ ...filters, sport: game.value });
  }
  return (
    <div className="flex scrollbar-hide flex-col justify-center mt-4 w-full text-[14px] font-medium text-center whitespace-nowrap bg-white border border-solid border-neutral-200 text-neutral-500">
      <div className="flex pr-20">
        {sports.map((game) => (
          <div
            key={game.name}
            onClick={()=>handleChange(game)}
            className={`px-6 py-2.5 cursor-pointer ${
              selectedGame === game.name
                ? "bg-neutral-500 text-white"
                : " text-black"
            }`}
          >
            {langData?.[game.langKey]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameTabs;
