/**
 * This code was generated by Builder.io.
 */
import React from "react";
import ReactDatePicker from "../ReactDatePicker";
import { useAppSelector } from "../../../store/storeHooks";

interface DatePickerProps {
  onDateChange: Function;
  startDate: Date;
  endDate: Date;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  startDate,
  endDate,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFromDate = new Date(event.target.value);
    onDateChange(newFromDate, endDate);
  };

  const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newToDate = new Date(event.target.value);
    onDateChange(startDate, newToDate);
  };

  return (
    <div className="flex gap-3 self-center mt-[12px] text-[14px] font-medium whitespace-nowrap max-w-[406px]">
      <div className="flex flex-col flex-1 ">
        <label htmlFor="fromDate" className=" ml-1 text-neutral-500">
          {langData?.['from']}
        </label>
        <ReactDatePicker date={startDate} setDate={handleFromDateChange} />
      </div>
      <div className="flex flex-col flex-1  ">
        <label htmlFor="toDate" className=" ml-1 text-neutral-500">
          {langData?.['to']}
        </label>
        <ReactDatePicker date={endDate} setDate={handleToDateChange} />
      </div>
    </div>
  );
};

export default DatePicker;
