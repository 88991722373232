import React from "react";
import {
  BankDepositAccountDetailsFormType,
  DEPOSIT_PAYMENT_TYPES,
  DepositReqUpiResType,
} from "./utils/types";
import InputField from "./InputComponents/InputField";
import ScanMeComponent from "./DepositDesktop/ScanMeComponent/ScanMeComponent";
import { PaymentOptionTypes } from "./utils/deposit.utils";
import { useAppSelector } from "../../../../store/storeHooks";

interface DepositUpiDetailsCompProps {
  upiDepositPaymentDetails: DepositReqUpiResType;
  upiReferenceId: string;
  bankDepositAccountDetailsForm: BankDepositAccountDetailsFormType;
  handleReferenceIdChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  bankAccountDetailsSelectedPaymentOpt: (opt: any) => void; // TODO: Add proper type
  handleBankDetailsFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DepositUpiDetailsComp: React.FC<DepositUpiDetailsCompProps> = ({
  upiDepositPaymentDetails,
  handleReferenceIdChange,
  upiReferenceId,
  bankDepositAccountDetailsForm,
  bankAccountDetailsSelectedPaymentOpt,
  handleBankDetailsFormChange,
}) => {
  const { langData } = useAppSelector((state) => state.cms);
  const { payment_options } = upiDepositPaymentDetails;
  const {
    payment_method_details: { upi_intent },
  } = payment_options.find(
    (option) => option.payment_method === DEPOSIT_PAYMENT_TYPES.UPI
  );

  return (
    <form>
      <div className="font-semibold">
        <ScanMeComponent title="Scan Me:" qrInfoStr={upi_intent} />

        <div className="mt-4">
          <InputField
            label={langData?.['enter_reference_id']}
            placeholder={langData?.['enter_utr_txt']}
            value={bankDepositAccountDetailsForm.bankTxRefId}
            fieldKey={"bankTxRefId"}
            onChange={handleBankDetailsFormChange}
            type={"text"}
          />

          <div className="flex flex-col min-w-[240px] max-md:mt-2 w-full lg:w-[48%] max-md:max-w-full ml-4 max-md:ml-0">
            <label
              htmlFor={"selectedPaymentOption"}
              className="self-start text-[14px] font-medium text-stone-900"
            >
              {langData?.['select_payment_option']}
            </label>

            <select
              className="gap-2.5 self-stretch px-3 py-3.5 mt-2 rounded-md border border-solid bg-zinc-100 border-neutral-200 min-h-[44px] text-[14px]"
              id="selectedPaymentOption"
              value={bankDepositAccountDetailsForm.selectedPaymentOption}
              onChange={(e) =>
                bankAccountDetailsSelectedPaymentOpt(e.target.value)
              }
            >
              <option value="" disabled>
                {langData?.['select_an_option']}
              </option>
              {PaymentOptionTypes.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DepositUpiDetailsComp;
