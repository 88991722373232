/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo (2).png";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/storeHooks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { getLangData } from "../../../store/cms/cmsThunk";
import { getLangCode } from "../../../utils/localizationUtil";

const MainHeader: React.FC = () => {
  const { loggedIn, balanceSummary } = useAppSelector((state) => state.auth);
  const { langData } = useAppSelector((state) => state.cms);
  const { languages } = useAppSelector((state) => state.common.domainConfig);
  const { domainConfig } = useAppSelector(state => state.common);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [langSelected, setLangSelected] = useState<string>(sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : "en");

  useEffect(() => {
    if(langSelected !== undefined){
        sessionStorage.setItem("lang", langSelected);
        dispatch(getLangData(langSelected));
    }
  }, [langSelected]);

  useEffect(() => {
    console.log("qwerty", domainConfig);
  }, [domainConfig])

  return (
    <div className="flex h-[52px] justify-between items-center px-10 py-2 bg-primary">
      <div className="flex items-center gap-2.5">
        <NavLink to="/">
          <img src={logo} alt="Brand" className="w-[110px]" />
        </NavLink>
      </div>
      <div className="flex gap-1">
      {loggedIn ? (
        <div className="flex gap-3">
         {domainConfig?.payments && <div
            onClick={() => navigate("/deposit")}
            className="flex items-center px-2 cursor-pointer justify-center border border-white text-white font-[500] text-[14px] leading-[16.2px]	rounded-md w-auto h-[36px] bg-[#3C7701] "
          >
            {langData?.["deposit"]}
          </div>}
          {domainConfig?.payments && 
          <div
            onClick={() => navigate("/withdraw")}
            className="flex items-center px-2 cursor-pointer justify-center border border-white font-[500] text-[14px] leading-[16.2px] text-white	rounded-md w-auto h-[36px] bg-[#B40202] "
          >
          {langData?.["withdraw"]}
          </div>}
         { domainConfig?.bonus && <div className="flex  items-center justify-center border border-white text-white font-[500] text-[14px] leading-[16.2px]	rounded-md w-[88px] h-[36px] bg-primary p-1">
            {langData?.["header_bonus_txt"]}: {balanceSummary?.bonus}
          </div>}

          <button className="flex h-[36px] items-center text-[14px] font-medium bg-white text-white border border-white rounded-md overflow-hidden ">
            <div>
              <span className="flex font-[500] text-[14px] leading-[17.34px] w-[80px] text-black px-4 py-3 ">
                {langData?.["header_balance_txt"]}:{" "}
                {balanceSummary?.balance ? balanceSummary?.balance : "0"}
              </span>
            </div>
            <div>
              <span className=" bg-primary font-[500] text-[14px] leading-[16.2px] px-4 py-3 signup-btn-clip ">
                {langData?.["header_exp_txt"]}:{" "}
                {balanceSummary?.exposure ? balanceSummary?.exposure : "0.00"}
              </span>
            </div>
          </button>
        </div>
      ) : (
        <button className="flex h-[36px] items-center text-sm font-medium bg-white text-white border border-white rounded-md overflow-hidden ">
          <NavLink to="/login">
            <span className=" text-[14px] font-medium flex w-[60px] text-black px-4 py-3 ">
              {langData?.["login"]}
            </span>
          </NavLink>
          <NavLink to="/signup">
            <span className=" text-[14px] font-medium signup-btn-clip bg-primary px-4 py-3">
              {langData?.["signup"]}
            </span>
          </NavLink>
        </button>
      )}
      {languages.length > 0 && (
        <div className="relative flex items-center justify-center h-7 p-2 m-1 bg-custom-btn-bg-variant-1 rounded-full group">
          <div className="flex items-center text-white justify-center text-custom-btn-text-variant-1 text-sm font-medium select-none">
            {langSelected?.toUpperCase()}
          </div>
          <div className="hidden group-hover:block absolute top-7 right-0 z-50 max-h-64 w-36 bg-custom-btn-bg-variant-1 overflow-y-scroll rounded-md">
            {languages.map((language: string) => {
              return (
                <div
                  className="p-2 bg-white text-custom-btn-text-variant-1 text-sm font-medium cursor-pointer hover:bg-gray-300"
                  onClick={() => setLangSelected(getLangCode(language)?.toLowerCase())}
                >
                  {language.replace("(", " (")}
                </div>
              );
            })}
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default MainHeader;
