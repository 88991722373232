/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { getOutcomeDescByEnumName, getSportNameByIdMap, MarketTypeByEnumMap } from "../../../../store/reports/reportSelectors";
import moment from "moment";
import { useAppSelector } from "../../../../store/storeHooks";

interface Bet {
  outcomeDesc: string;
  sportId: string;
  marketName: string;
  eventName: string;
  betPlacedTime: string;
  odds: number;
  amount: number;
  winnings: number;
  market: string;
  betOn: string;
  result: string;
  betType: string;
  oddValue: number;
  sessionRuns: number;
  stakeAmount: number;
  marketType: string;
  outcomeResult: string;
}

interface BetListProps {
  bets: Bet[];
}

const BetList: React.FC<BetListProps> = ({ bets }) => {
  const headers = ['Event Name', 'Odds', 'Amount', 'Winnings'];
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex flex-col justify-center px-3 py-px mt-1.5 w-full text-[14px] font-medium rounded-md bg-neutral-200 text-neutral-500">
      <div className=" flex w-full gap-5 ml-2 max-w-[379px]">
        <div className=" w-[35%]">{langData?.['event_name']}</div>
        <div className=" w-[15%]">{langData?.['odds']}</div>
        <div className=" w-[15%]">{langData?.['amount']}</div>
        <div className=" w-[20%]">{langData?.['winnings']}</div>
      </div>
      {bets.map((bet, index) => (
        <div
          key={index}
          className="flex flex-col items-center py-0.5 mt-1.5 w-full bg-white border-2 border-sky-200 border-solid"
        >
          <div className="flex gap-5 justify-between w-full max-w-[379px]">
            <div className="flex flex-col self-start mt-1 w-[35%] pl-[10px]">
              <div className="text-sm font-medium text-stone-900">
                {bet.eventName}
              </div>
              <div className=" text-wrap mt-1 text-[10px]">{moment(bet.betPlacedTime).format("DD-MM-YY, h:mm:ss A")}</div>
            </div>
            <div className="flex gap-2  w-[15%] text-[14px] font-medium text-black whitespace-nowrap">
              <div className="shrink-0 w-px border border-solid border-neutral-200 h-[70px]" />
              <div className="my-auto">
                <span className="mob-fs-13">
                  {bet.oddValue && bet.oddValue !== -1.0
                    ? bet.marketType === "FANCY" || bet.marketType === "BINARY"
                      ? Number(bet.sessionRuns).toFixed(0)
                      : bet.marketType === "BOOKMAKER"
                      ? (bet.oddValue * 100 - 100).toFixed(2)
                      : bet.oddValue.toFixed(2)
                    : "-"}
                </span>
              </div>
            </div>
            <div className="flex gap-2  w-[15%] text-[14px] font-medium text-black whitespace-nowrap">
              <div className="shrink-0 w-px border border-solid border-neutral-200 h-[70px]" />
              <div className="my-auto">{bet.stakeAmount}</div>
            </div>
            <div className="flex gap-2  w-[20%] mr-4 text-[14px] font-semibold text-lime-700 whitespace-nowrap">
              <div className="shrink-0 w-px border border-solid border-neutral-200 h-[70px]" />
              <div className="my-auto">
                {getOutcomeDescByEnumName(bet.outcomeResult.toString())
                  ? getOutcomeDescByEnumName(bet.outcomeResult.toString())
                  : "Unsettled"}
              </div>
            </div>
          </div>
          <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
          <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
            <div className="flex flex-col my-auto">
              <div className="flex gap-1">
                <div className="font-medium text-neutral-500">{langData?.['market']}:</div>
                <div className=" text-[12px] text-black">{bet.marketType === "BOOKMAKER"
                    ? bet.marketName || MarketTypeByEnumMap[bet.marketType]
                    : MarketTypeByEnumMap[bet.marketType]
                    ? MarketTypeByEnumMap[bet.marketType]
                    : "Casino"}
                  <span className="event-name">
                    ({getSportNameByIdMap(bet?.sportId)})
                  </span></div>
              </div>
              <div className="flex gap-1">
                <div className="font-medium text-neutral-500">{langData?.['bet_on']}:</div>
                <div className=" text-[12px] text-black">{bet.marketType === "FANCY"
                    ? bet.marketName +
                      " @ " +
                      Number(bet.oddValue * 100 - 100).toFixed(0)
                    : bet.marketType === "BINARY"
                    ? bet.outcomeDesc +
                      " @ " +
                      Number(bet.sessionRuns).toFixed(0)
                    : bet.outcomeDesc}</div>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="shrink-0 w-px border border-solid border-neutral-200 h-[43px]" />
              <div className="flex flex-col my-auto">
                <div className="flex gap-1 whitespace-nowrap">
                  <div className="font-medium text-neutral-500">{langData?.['results']}:</div>
                  <div className="text-black">{bet.result}</div>
                </div>
                <div className="flex gap-1">
                  <div className="font-medium text-neutral-500">{langData?.['bet_type']}:</div>
                  <div className="font-semibold text-black">{bet.betType}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {bets && bets.length === 0 && <div className=" text-center border-t-[1px] border-neutral-500">{langData?.['bet_not_found_txt']}</div>}
    </div>
  )
};

export default BetList;
