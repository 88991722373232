/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Footer1 from "../../../assets/FooterImage/footerImg1.webp"
import Footer2 from "../../../assets/FooterImage/footerImg2.webp"
import Footer3 from "../../../assets/FooterImage/footerImg3.webp"
import Footer4 from "../../../assets/FooterImage/footerImg4.webp"
import Footer5 from "../../../assets/FooterImage/footerImg5.webp"
import Footer6 from "../../../assets/FooterImage/footerImg6.webp"
import { useAppSelector } from "../../../store/storeHooks";
const footerLinks = [
  {
    name: "Home",
    path: "/home"
  },
  {
    name: "Exchange",
    path: "/exchange_sports/inplay"
  },
  {
    name: "Sportsbook",
    path: "/sportsbook"
  },
  {
    name: "Live Casino",
    path: "/live-casino"
  },
]

const paymentMethods = [
 Footer1,
 Footer2,
 Footer3,
 Footer4,
];

const Footer: React.FC = () => {
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <footer className="flex flex-col justify-center mt-2 w-full bg-white border border-solid border-neutral-200 max-md:max-w-full">
      <div className="flex flex-col pt-6 w-full bg-white max-md:max-w-full">
        <div className="flex gap-2.5 self-center text-xl font-medium text-[#4da8f1]">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/17ec18ba746803a81b890a84840e4a633913918106bdf23db5a13a34fb1511d1?apiKey=ea6881ef1e8b4a70921da55f074c8f7c&&apiKey=ea6881ef1e8b4a70921da55f074c8f7c"
            className="shrink-0 aspect-square w-[26px]"
            alt="Indigo Gaming logo"
          />
          <div>Indigo Gaming</div>
        </div>
        <nav className="flex gap-5 justify-center self-center mt-4 text-[14px] text-center text-stone-900">
          {footerLinks.map((link) => (
            <a
              key={link.name}
              href={link.path}
              className={link.name === "Home" ? "font-semibold" : ""}
            >
              {link.name}
            </a>
          ))}
        </nav>
        <div className="flex mt-4 w-full border border-solid border-neutral-200 min-h-[1px] max-md:max-w-full" />
        <div className="flex  w-1 justify-center	items-center w-[100%]	gap-[0.85rem]  items-start  mt-4 ">
          {paymentMethods.map((src, index) => (
            <img
              key={index}
              loading="lazy"
              src={src}
              className="shrink-0 w-[2.5rem] rounded-md aspect-[1.59] border-neutral-200"
              alt={`Payment method ${index + 1}`}
            />
          ))}
        </div>
        <div className="self-start px-8 py-3 mt-4 text-[14px] text-center border border-solid bg-neutral-200 border-neutral-200 text-stone-900 max-md:px-5 max-md:max-w-full">
          {langData?.['login_disclaimer_txt']}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
