export const langData = {
    "turnover_history": "Turnover History",
    "header_cashable_txt": "Cashable",
    "bet_status": "Bet Status",
    "ftd_amount": "FTD amount",
    "greyhound": "GreyHound",
    "other_menu": "Other Menu",
    "user_not_found_txt": "User not found",
    "password": "Password",
    "bonus_statement": "Bonus Statement",
    "installments": "Installments",
    "no_market_followed_txt": "No Market Followed",
    "no_bet_placed_txt": "There is no bet placed till now.",
    "transaction_id_caps": "TRANSACTION ID",
    "button_variable_saved_txt": "Button Variable Saved Successfully.",
    "create_new_affiliate_txt": "The affiliate is not active. Please create new.",
    "sign_up": "Sign Up",
    "settled": "Settled",
    "bonus_type": "Bonus Type",
    "full_name": "Full name",
    "login_disclaimer_txt": "Disclaimer: Please note that Gambling involves a financial risk and could be addictive over time if not practised within limits. Only 18+ people should use the services and should use it responsibly. Players should be aware of any financial risk and govern themselves accordingly.",
    "rules_and_regulation_copyright_txt": "Rules & Regulations © 2024",
    "deposit": "Deposit ",
    "incorrect_username_password_txt": "Incorrect username or password",
    "header_bonus_txt": "Bonus",
    "create_affiliate": "Create Affiliate",
    "enter_username_txt": "Enter username",
    "recommended_games": "Recommended Games",
    "bet_type": "Bet Type",
    "create_new_campaign": "Create New Campaign",
    "campaign_link": "Campaign Link",
    "join_affiliate_program_txt": "Join our affiliate program",
    "bet_slip": "Bet Slip",
    "account_statement": "Account Statement",
    "pin_code": "Pin code",
    "forgot_password_send_otp_txt": "We’ll send OTP on your registered number associated with username.",
    "view_all": "View All",
    "available_balance_caps_txt": "AVAILABLE BALANCE",
    "instagram_follow_txt": "Follow on Instagram",
    "withdraw_available_commission": "Withdraw Available Commission",
    "result": "Result",
    "my_transactions": "My Transaction",
    "eligible_ftd": "Eligible FTD",
    "min": "MIN",
    "no_open_bets_txt": "No open bets to show",
    "commission_txt": "Comm.",
    "approved_amount": "Approved Amount",
    "market_type": "Market Type",
    "referral_link": "Referral Link",
    "no_multimarket_follwed_txt": "There is currently no multi-market followed.",
    "address": "Address",
    "available_commission": "Available Commission",
    "live_stream": "Live stream",
    "email_address": "Email address",
    "match_odds": "Match Odds",
    "cricket": "Cricket",
    "to": "To",
    "stake_settings": "Stake Settings",
    "bonus_rewarded": "Bonus Rewarded",
    "notes": "Notes",
    "available_caps": "AVAILABLE",
    "close_balance_caps": "CLOSE BALANCE",
    "futsal": "Futsal",
    "add_new": "Add New",
    "lifetime_sign_ups": "Lifetime Sign ups",
    "won": "Won",
    "event_date": "event date",
    "bet_not_found_txt": "No Bets Found",
    "credit_debit": "Credit/Debit",
    "campaign_id": "Campaign ID",
    "sign_in": "Sign in",
    "demo_login": "Demo login",
    "forgot_username_password_txt": "Forgot Username/Password?",
    "username_exists_txt": "Username already exist.",
    "start_time": "Start time",
    "inplay": "Inplay",
    "phone_number": "Phone Number",
    "reset_password_txt": "Reset password",
    "otp_length_verification_txt": "otp must be exactly 6 characters",
    "no_events_display_txt": "No Events to display",
    "turnover_balance": "turnover balance",
    "transaction_status": "Transaction Status",
    "account_not_found_txt": "Don’t have account?",
    "session": "Session",
    "back": "Back",
    "description": "Description",
    "awarded_amount_txt": "Awarded Amt.",
    "lifetime_ftd": "Lifetime First Deposits",
    "change_input_label_settings_txt": "Change your input variable and label setting",
    "records_not_found_txt": "No records available.",
    "turnover": "Turnover",
    "live": "Live",
    "select_games": "Select Games",
    "live_casino_games": "Live Casino Games",
    "no_inplay_events_txt": "No Inplay Events",
    "deposit_turnover": "Deposit Turnover",
    "mobile": "Mobile",
    "no_data_found": "No data found",
    "football": "Football",
    "filters": "Filters",
    "input_value": "Input Value",
    "home": "Home",
    "mini_bookmaker": "Mini Bookmaker",
    "social_media_follow_txt": "Follow on",
    "open_bets": "Open Bets",
    "header_exp_txt": "Exp",
    "upcoming": "UPCOMING ",
    "username": "Username",
    "withdraw": "Withdraw",
    "see_more": "See more",
    "fancy": "Fancy",
    "enter_login_details_txt": "Please enter your login details here.",
    "affiliate_marketing": "Affiliate marketing",
    "horse_race": "Horse race",
    "horse_racing": "Horse Racing",
    "required": "Required",
    "logout": "Logout",
    "balance": "Balance",
    "lost": "Lost",
    "odds": "Odds",
    "confirm_new_password_txt": "Confirm New Password",
    "enter": "enter",
    "select_deposit_method_txt": "Please select deposit method",
    "affiliate_programs": "Affiliate Programs",
    "know_more": "Know More",
    "approval_required_txt": "Approval Req.",
    "password_mismatch_txt": "Password mismatch",
    "otp": "OTP ",
    "transactions_not_found_txt": "You don't have any transactions",
    "enter_otp_txt": "Enter OTP",
    "live_score": "Live score",
    "user_id": "User ID",
    "invalid_email_id_txt": "Invalid email id",
    "binary": "Binary",
    "reset": "Reset ",
    "baseball": "Baseball",
    "volleyball": "Volleyball",
    "button_label": "Button Label",
    "status": "Status",
    "live_casino": "Live Casino",
    "live_match": "LIVE MATCH",
    "cashable_amount": "Cashable Amount",
    "city": "City",
    "password_change_success_txt": "Password changed successfully.",
    "ice_hockey": "Ice Hockey",
    "save": "Save",
    "payment_related_queries": "Payment Related Queries",
    "payment_failed_issues": "Payment Failed Issues",
    "login": "Login",
    "bet_on": "Bet on",
    "header_non_cashable_txt": "Non-Cashable",
    "premium": "Premium",
    "ftd": "FTD",
    "payment_payment_withrawal_txt": "following payment withdrawal information:",
    "event_caps": "EVENT",
    "my_bets": "My Bets",
    "sports_book": "Sportsbook",
    "top_rated_games": "Top Rated Games",
    "exposure_credit": "Exposure Credit",
    "max": "MAX",
    "multimarkets": "Multi Markets",
    "expiry_date": "Expiry Date",
    "data_not_found_txt": "No data found",
    "trending_games": "Trending Games",
    "tennis": "Tennis",
    "eligible_ftd_amount": "Eligible FTD amount",
    "no_history_found_txt": "No history found",
    "table_tennis": "Table Tennis",
    "promotions": "Promotions",
    "betting_profit_and_loss": "Betting Profit & Loss",
    "my_wallet": "My wallet",
    "darts": "Darts",
    "popular_games": "Popular Games",
    "transaction": "Transaction",
    "resend_in_txt": "Resend in",
    "date": "Date",
    "affiliate_program": "Affiliate Program",
    "enter_old_password_txt": "Enter old password",
    "commission_redeemed": "Commission Redeemed",
    "casino": "Casino",
    "refer_friend_txt": "Refer a friend",
    "have_account_txt": "Have an account?",
    "change_password_txt": "Change Password",
    "basketball": "Basketball",
    "awarded_date": "Awarded Date",
    "bookmaker": "Bookmaker",
    "from": "From",
    "funds": "Funds",
    "payment_method": "Payment Method ",
    "enter_confirm_password_txt": "Enter confirm password",
    "all": "All",
    "game_rules": "Game Rules",
    "terms_and_policy": "Terms & Policy",
    "void": "Void",
    "lay": "Lay",
    "premium_cricket": "Premium Cricket",
    "my_bet": "My Bet ",
    "matches": "Matches",
    "send_otp": "Send OTP",
    "transaction_type": "Transaction Type",
    "no_transactions_txt": "You have no transactions",
    "my_profile": "My Profile",
    "market": "Market",
    "event_name": "Event name",
    "telegram_follow_txt": "Follow on Telegram",
    "politics": "politics",
    "redeemed_amount": "Redeemed Amt.",
    "redeemed_amount_short": "R. amount",
    "affiliate_not_active_txt": "Affiliate not active",
    "campaign_name": "Campaign name",
    "footer_trusted_txt": "Trusted by providers around the world",
    "personal_info": "Personal Info ",
    "transaction_time": "Transaction Time",
    "security_and_logout": "Security & Logout",
    "total_commission": "Total Commission",
    "amount": "Amount",
    "last_redeemed_date": "Last Redeemed Date",
    "last_redeemed_date_short": "Last R. Date",
    "sports": "Sports",
    "header_balance_txt": "Bal",
    "settled_time": "Settled time",
    "net_win": "Net Win",
    "winnings": "Winnings",
    "lifetime_ftd_amount": "Lifetime FTD Amount",
    "place_date": "Place Date",
    "signup_username_restriction_txt": "The User Name field must be at least 4 characters.",
    "sign_up_success_txt": "Signed up successfully.",
    "phone_number_exists_txt": "Phone number already exists.",
    "enter_phone_number_required_txt": "Please Enter Phone Number.",
    "enter_valid_otp_txt": "Please Enter Valid OTP.",
    "general_err_txt": "Something went wrong, Please try again.",
    "enter_password": "Enter Password",
    "referral_code": "Referral Code",
    "enter_referral_code": "Enter Referral code",
    "enter_phone_number_txt": "Enter Phone Number",
    "seconds": "sec",
    "numbers_restriction_txt": "Only numbers are allowed",
    "password_update_success_txt": "Password updated successfully",
    "text_copied_txt": "Text Copied!",
    "username_copied_txt": "Username Copied!!",
    "your_username_is_txt": "Your Username is",
    "special_characters_restriction_txt": "Special characters are not allowed",
    "demo_user": "Demo User",
    "customer_support_txt": "24x7 Customer Support",
    "games_access_login_txt": "Access required for gameplay. Please log in to proceed.",
    "notice": "Notice",
    "cancel": "Cancel",
    "connect_to_social_network_txt": "Get connected with us on social networks",
    "notifications": "Notifications",
    "rules_and_regulation_txt": "Rules And Regulations",
    "no_events_txt": "No Events",
    "scorecard": "Scorecard",
    "premium_markets_not_found_txt": "No Premium Markets",
    "markets_not_found_txt": "No Markets Available",
    "login_to_place_bet_txt": "You have to login to place bet(s)",
    "minimum_stake_required_txt": "Minimum stake amount required.",
    "wrong_odds_txt": "Wrong odds",
    "bet_rate_not_accepted_txt": "Bet not accepted. Rate over",
    "minimum_stake_txt": "Total stake should not be less than",
    "maximum_stake_txt": "and should not exceed",
    "invalid_odds_txt": "Invalid odds",
    "cancel_old_bet_failed_txt": "Unable to cancel the old bet.",
    "cancel_bet_failed_txt": "Unable to cancel bet.",
    "bet_placed_txt": "Bet is not placed, Try again",
    "previous_bet_in_progress_txt": "Previous bet in progress. Bet not placed.",
    "odd_value": "Odd Value",
    "all_in_txt": "All IN",
    "clear": "Clear",
    "place_bet_txt": "Place Bet",
    "total_amount_in_pts_txt": "Total Amount (in PTS)",
    "pl_bet_info_txt": "Your profit/loss as per placed bet",
    "match_winner_txt": "Who will win the match ?",
    "match": "Match",
    "innings": "Innings",
    "over": "Over",
    "player": "Player",
    "other": "other",
    "all_capital": "ALL",
    "sessions": "SESSIONS",
    "wp_market": "W/P MARKET",
    "extra_market": "EXTRA MARKET",
    "odd_even": "ODD/EVEN",
    "fancy_markets_not_found_txt": "No Fancy Markets for this event",
    "book": "Book",
    "add_to_multi_markets_txt": "Add To Multi Markets",
    "remove_from_multi_markets_txt": "Remove From Multi Markets",
    "cashout": "Cashout",
    "bookmaker_markets_not_found_txt": "No Bookmaker Markets for this event",
    "match_odds_not_found_txt": "No Match Odds for this event",
    "goal_markets_not_found_txt": "No Goal markets for this event",
    "fav_game_login_txt": "Please login to add favourite game into favourite games",
    "slot_games": "Slot games",
    "andar_bahar": "Andarbahar",
    "teenpatti": "Teenpatti",
    "live_lottery": "Live Lottery",
    "live_poker": "Live Poker",
    "live_lobby": "Live Lobby",
    "crash_game": "Crash Game",
    "live_blackjack": "Live Blackjack",
    "live_baccarat": "Live Baccarat",
    "live_sic_bo": "Live Sic Bo",
    "live_roulette": "Live Roulette",
    "others": "Others",
    "casual_game": "Casual Game",
    "indian_games": "Indian games",
    "dragon_tiger": "Dragon Tiger",
    "virtual_casino": "Virtual Casino",
    "live_dice": "Live Dice",
    "live_ga_shows": "Live Ga. Shows",
    "fish_shooting": "Fish Shooting",
    "live_game_shows": "Live Game Shows",
    "tv_games": "Tv Games",
    "table": "Table",
    "virtual": "Virtual",
    "live_dealer": "Live Dealer",
    "lobby": "Lobby",
    "live_wheel_of_fortune": "Live Wheel of Fortune",
    "live_games": "Live Games",
    "wheel_of_fortune": "Wheel of Fortune",
    "32cards": "32cards",
    "baccarat": "Baccarat",
    "poker": "Poker",
    "blackjack": "Blackjack",
    "lucky7": "Lucky7",
    "roulette": "Roulette",
    "sicbo": "Sicbo",
    "video_slots": "Video Slots",
    "live_popular": "Live Popular",
    "casual_games": "Casual Games",
    "scratch_cards": "Scratch Cards",
    "indian": "Indian",
    "game_info": "Game Info",
    "1_10_point_conversion_casino_txt": "1 Casino Point is equal to 10 Normal Points.",
    "1_10_point_conversion_casino_ex_txt": "Ex: 100 Casino Points = 1000 Points",
    "continue": "Continue",
    "close": "Close",
    "slot": "Slot",
    "lottery": "Lottery",
    "add_success_txt": "Succesfully added",
    "remove_success_txt": "Succesfully removed",
    "remove_from_fav_txt": "remove from favourites",
    "add_to_fav_txt": "add to favourites",
    "bet_failed_prefix": "Bet failed",
    "enter_details_manually_txt": "Please enter details manually!",
    "details_entered_success_txt": "Details entered successfully!",
    "upload_txn_img_txt": "Please Upload Transaction Image",
    "min_deposit_amount_txt": "Deposit Amount cannot be less than 100",
    "min_500_deposit_amount_txt": "Minimum deposit amount is 500",
    "min_500_deposit_amount_err_txt": "Deposit Amount cannot be less than 500",
    "txn_saved_success_txt": "Transaction Saved Successfully!",
    "invalid_mobile_no_txt": "Invalid Mobile Number",
    "utr_entered_success_txt": "UTR entered successfully!",
    "enter_amount": "Enter Amount",
    "enter_deposit_amount_txt": "Enter Amount to be Deposited",
    "enter_mobile_no": "Enter Mobile Number",
    "next": "Next",
    "account_details": "Account Details",
    "account_holder_name": "Account Holder Name",
    "click_to_copy": "Click to Copy",
    "account_holder_number": "Account Holder Number",
    "account_ifsc_code": "Account IFSC Code",
    "bank_name": "Bank Name",
    "upi_id": "UPI ID",
    "enter_utr_txt": "Enter Reference ID/UTR",
    "reverify_for_correctness_txt": "Re-verify for correctness",
    "select_payment_option": "Select Payment Option",
    "select_one": "Select One",
    "uploaded_image": "Uploaded Image",
    "upload_image": "Upload Image",
    "confirm_payment": "Confirm Payment",
    "choose_beneficiary_account": "Choose Beneficiary Account",
    "beneficiary_account_not_found_txt": "No Beneficiary Account Available",
    "enter_payment_details": "Enter Payment Details",
    "enter_deposited_amount": "Enter Deposited Amount",
    "enter_txn_ref_id": "Enter Transaction Reference ID",
    "enter_notes": "Enter Notes",
    "submit": "Submit",
    "deposit_info_txt": "Note : Please allow 30mins for deposit amount to credit, in case of any further delay reach out to customer care.",
    "payment_declined_info_txt": "If Payment declined due To Security Reasons, Please Scan this below Qr Code",
    "providers_not_found_txt": "No Providers Available",
    "select_bonus_type": "Select Bonus type",
    "upi_id_copied_txt": "UPI ID Copied!!",
    "select_crypto_currency": "Select crypto currency",
    "select_currency": "Select Currency",
    "amount_copied_txt": "Amount Copied!!",
    "wallet_address": "Wallet address",
    "wallet_address_copied_txt": "Wallet Address copied!",
    "enter_txn_hash": "Enter Transaction Hash",
    "min_100_withdrawal_amount_txt": "Withdrawal Amount cannot be less than 100",
    "min_withdrawal_amount_txt": "Withdrawal Amount cannot be less than",
    "details_saved_success_txt": "Details Saved Successfully!",
    "beneficiary_account_deleted_success_txt": "Beneficiary account deleted successfully",
    "delete_account": "Delete Account",
    "delete_account_confirm_txt": "Are you sure you want to delete this account? Please confirm.",
    "delete_confirm_txt": "Yes, delete",
    "choose_payment_option": "Choose Payment Option",
    "add_new_account": "Add New Account",
    "upi_method": "UPI Method",
    "bank_transfer": "Bank Transfer",
    "pay_method": "Pay Method",
    "select_payment_options": "Select payment options",
    "enter_withdraw_amount": "Enter Withdraw Amount",
    "enter_bank_details": "Enter Bank Details",
    "account_no": "Account Number",
    "enter_account_no": "Enter Account Number",
    "branch_name": "Branch Name",
    "ifsc_no": "IFSC Number",
    "ifsc_code": "Ifsc Code",
    "enter_details": "Enter Details",
    "display_name": "Display Name",
    "enter_upi_details": "Enter UPI Details",
    "enter_upi_id": "Enter UPI Id",
    "add_account": "Add Account",
    "add": "ADD",
    "my_card": "My Card",
    "withdraw_info_txt": "Note : Please allow 72hrs for withdrawal amount to credit, in case of any further delay reach out to customer care.",
    "my_address": "My Address",
    "add_wallet": "Add Wallet",
    "enter_wallet_address": "Enter Wallet Address",
    "receivable_crypto_amount": "Receivable Crypto Amount",
    "open": "Open",
    "results": "Results",
    "prev": "Prev",
    "change_order": "Change Order",
    "overview": "Overview",
    "commission": "Commission",
    "campaigns": "Campaigns",
    "referred_users": "Referred Users",
    "ftd_report": "FTD Report",
    "create": "Create",
    "campaign_create_success": "Campaign created successfully",
    "commission_percentage": "Commission Percentage",
    "data_not_available_txt": "No data available",
    "username_caps": "USERNAME",
    "registered_caps": "REGISTERED",
    "total_deposit_caps": "TOTAL DEPOSIT",
    "last_deposit_caps": "LAST DEPOSIT",
    "wagered_caps": "WAGERED",
    "commission_caps": "COMMISSION",
    "affliate_referral_txt": "Keep your friends close and referral friends closer! Invite your friends and family to sign up using your personal referral code.",
    "casino_caps": "CASINO",
    "sports_caps": "SPORTS",
    "commission_formula_txt": "(Edge as decimal * Wagered/2) * commission rate",
    "minimum_commission_txt": "Available commission must be greater than 0",
    "withdraw_complete_success": "Withdraw completed successfully",
    "rules": "Rules",
    "withdraw_commission": "Withdraw commission",
    "withdraw_amount": "Withdraw amount",
    "yes": "Yes",
    "no": "No",
    "date_caps": "DATE",
    "withdraw_caps": "WITHDRAW",
    "invite_referral_txt": "Keep your friends close and referral friends closer! Invite your friends and family to sign up using your personal referral code (mentioned below) and get 20% of each and every one of their deposits made thereafter!",
    "affiliate_program_header_txt": "ABOUT PROGRAM",
    "pl_details_txt": "Profit - Loss Details",
    "placed": "Placed",
    "selection": "Selection",
    "bet_id": "Bet ID",
    "type": "Type",
    "back_subtotal": "Back subtotal",
    "lay_subtotal": "Lay subtotal",
    "market_subtotal": "Market subtotal",
    "net_market_total": "NET MARKET TOTAL",
    "event_type": "Event Type",
    "txn_id": "Txn ID",
    "txn_type": "Txn Type",
    "txn_caps": "TRANSACTION",
    "market_type_caps": "MARKET TYPE",
    "credit_debit_caps": "CREDIT/ DEBIT",
    "placed_date": "Placed date",
    "event": "Event",
    "stake": "Stake",
    "returns": "Returns",
    "balance_after": "Balance After",
    "balance_before": "Balance Before",
    "credit_debit_mob_txt": "cre/dbt",
    "txn_mob_txt": "trx",
    "txn_id_mob_txt": "tx id",
    "settlement_deposit": "Settlement Deposit",
    "settlement_withdraw": "Settlement Withdraw",
    "bet_settlement": "Bet Settlement",
    "rollback": "Rollback",
    "voided": "Voided",
    "bonus_redeemed": "Bonus Redeemed",
    "bonus_rollback": "Bonus Rollback",
    "refund": "Refund",
    "no_txns_for_selected_criteria_txt": "You have no transaction for selected criteria",
    "redeemed_date": "Redeemed Date",
    "my_txns": "My Transactions",
    "transaction_id": "Transaction ID",
    "invalid_label_or_amount_txt": "Label or Amount invalid",
    "button_variables_save_success_txt": "Button Variables Saved Successfully",
    "enter_min_value_txt": "Please enter minimum value",
    "invalid_phone_no_txt": "Phone number is not valid",
    "invalid_pin_code_txt": "Pin Code is not valid",
    "details_save_failed_txt": "Unable to save details, something went wrong!",
    "phone_no_update_success_txt": "Phone Number updated successfully.",
    "choose_strong_password_txt": "Please choose a strong password. For more details hover on help icon(?).",
    "please_accept_tc_txt": "Please accept terms and conditions",
    "new_password_err_txt": "New password should not be same as username/old password",
    "enter_new_password_txt": "Enter New Password",
    "accept_tc_txt": "I understand & accept the terms and conditions",
    "set_your_password_txt": "Set your password",
    "user_details": "User Details",
    "first_name": "First Name",
    "last_name": "Last Name",
    "dob": "Date of Birth",
    "change_date": "Change Date",
    "login_required": "Login Required",
    "login_required_txt": "login required to see preferences",
    "pl_statement": "P/L Statement",
    "available_balance": "Available Balance",
    "exposure_credited": "Exposure Credited",
    "reports_menu": "Reports Menu",
    "referral_link_copied_txt": "Referral link copied!",
    "refer_and_earn_txt": "refer & earn",
    "copy_codes_and_share_txt": "Copy following codes and share with your friends and colleagues",
    "signup_code_txt": "Don't wait, start making HUGE PROFITS NOW! Use my special code mLzC7u to Sign-Up & Get Referral Bonus!",
    "tc_header_txt": "TERM & POLICY",
    "inplay_rules": "Inplay Rules",
    "pre_match_rules": "Pre-Match Rules",
    "action": "action",
    "app_security_txt": "Due to some inactivity or security reasons stop your website, please \nclose the developer tool. Thank you for your support",
    "access_redirect_txt": "International Casino is Under Maintenance.",
    "match_schedule": "Match Schedule",
    "inplay_matches_not_found": "There is no inplay matches.",
    "option": "Option",
    "deposit_upi_problem_txt": "Note : UPI deposit is facing problem right now, please wait for few hours.",
    "promotion_info": "Promotion Info",
    "no_display_content_txt": "No display content",
    "update_time": "Update Time",
    "pincode": "Pincode",
    "search_games": "Search games",
    "signup": "Signup",
    "in_play": "In-play",
    "preferences": "Preferences",
    "cashout_confirm_txt": "Do you wish to perform cash out?",
    "age": "Age",
    "abandon": "Abandon",
    "abandoned": "Abandoned",
    "checkbonuses": "Check Bonuses",
    "kabaddi": "kabaddi",
    "no_events_placed": "Currently, there is no event placed in calendar.",
    "bet_placement": "Bet Placement",
    "binary_bet_rollback": "Binary Bet Rollback",
    "binary_bet_settlement": "Binary Bet Settlement",
    "binary_bet_void": "Binary Bet Void",
    "casino_bet_placement": "Casino Bet Placement",
    "casino_bet_rollback": "Casino Bet Rollback",
    "casino_bet_settlement": "Casino Bet Settlement",
    "gap_bet_rollback": "Gap Bet Rollback",
    "gap_bet_settlement": "Gap Bet Settlement",
    "rollback_bet_settlement": "Rollback Bet Settlement",
    "rollback_void_bet_settlement": "Rollback Void Bet Settlement",
    "sport_book_bet_rollback": "Sport Book Bet Rollback",
    "sport_book_bet_settlement": "Sport Book Bet Settlement",
    "void_bet_settlement": "Void Bet Settlement",
    "awarded": "AWARDED",
    "redeemed": "REDEEMED",
    "cancelled": "CANCELLED",
    "in_progress_cap": "IN PROGRESS",
    "approval_pending": "Approval Pending",
    "in_progress": "In Progress",
    "initiated": "Initiated",
    "approved": "Approved",
    "rejected": "Rejected",
    "succeeded": "Succeeded",
    "failed": "Failed",
    "no_pending_bonus": "No pending bonus to claim",
    "claimed": "Claimed",
    "claim_now": "Claim Now",
    "expires_on": "Expires on",
    "casino_lobby": "Casino Lobby",
    "search_events": "search events",
    "disclaimer": "Disclaimer: For faster and more reliable payments, please use the bank transfer option.",
    "whatsapp_deposit": "WHATSAPP DEPOSIT",
    "bonus_sub_header": "Congratulations! You are eligible for the LossBack Bonus.",
    "bonus_time": "You will receive a non-cashable bonus based on your losses from 27th October to 4th November:",
    "cashback_percentage": "For losses between ₹1,000 and ₹10,0000: 10% cashback.",
    "cashback_amount": "For losses exceeding ₹1,00,000: ₹10,000 cashback.",
    "cashable": "Cashable",
    "non_cashable": "Non-cashable",
    "aviator": "Aviator",
    "mines": "Mines",
    "color_games": "Color Games",
    "cricketwar": "Cricketwar",
    "crash": "Crash",
    "slots": "Slots",
    "bingo": "Bingo",
    "hilo": "Hi Lo",
    "monopoly": "Monopoly",
    "deal_or_no_deal": "Deal Or No Deal",
    "rummy": "Rummy",
    "mac88": "Mac88",
    "mac88_virtuals": "Mac88 virtuals",
    "fun_games": "fun games",
    "wingo": "Wingo",
    "smartsoft_gaming": "smartsoft gaming",
    "spribe": "spribe",
    "evolution": "evolution",
    "turbo": "turbo",
    "ezugi": "ezugi",
    "jili_gaming": "jili gaming",
    "ae_sexy": "AE SEXY",
    "live_playtech": "Live playtech",
    "betsoft": "betsoft",
    "gamzix": "gamzix",
    "betgames": "betgames.tv",
    "evoplay_entertainment": "evoplay entertainment",
    "asia_gaming": "asia gaming",
    "winfinity": "winfinity",
    "vivo": "vivo",
    "kingmaker": "kingmaker",
    "royal_gaming": "royal gaming",
    "mma": "MMA",
    "rugby": "Rugby",
    "stay_connected_with_us": "stay connected with us",
    "wallet": "Wallet",
    "daily_report": "Daily Report",
    "enter_amount_inr": "Enter Amount (INR)",
    "bank": "BANK",
    "crypto": "CRYPTO",
    "phone_number_copied": "Phone Number Copied",
    "ifsc": "IFSC",
    "ifsc_code_copied": "IFSC Code Copied",
    "upi": "UPI",
    "account_number_copied_txt": "'Account Number Copied'",
    "currency": "Currency",
    "network_id": "Network id",
    "network_id_copied_txt": "Network ID Copied",
    "blockchain": "BlockChain",
    "block_chain_copied": "Block Chain Copied",
    "payable_crypto_amount": "Payable Crypto Amount",
    "enter_reference_id": "Enter Reference ID/UTR (Re-verify for correctness)",
    "imps": "IMPS",
    "neft": "NEFT",
    "rtgs": "RTGS",
    "completed_match": "Completed Match",
    "toss": "toss",
    "over_market": "over market",
    "over_bookmaker": "over bookmaker",
    "tied_match": "Tied Match",
    "goals": "GOALS",
    "over_under": "OVER/UNDER",
    "no_competitions_available": "No competitions available",
    "personal": "Personal",
    "referral": "Referral",
    "all_sports": "All Sports",
    "select_an_option": "Select an option",
    "previous": "Previous",
    "create_your_account_txt": "Create your account by following these simple steps.",
    "step_1": "Step 01",
    "step_2": "Step 02",
    "already_have_an_account": "Already have account?",
    "terms_and_conditions_txt": "I agree to the all terms & Conditions.",
    "affiliate_not_active": "The affiliate is not active.",
    "please_create_new": "Please create new.",
    "show_affiliates": "Show Affiliates",
    "commission_rate_txt": "(0.03 * Wagered/2) * commission rate",
    "no_data_to_display": "No Data To Display",
    "old_password": "Old Password",
    "new_password": "New Password",
    "confirm_password": "Confirm Password",
    "update_password": "Update Password",
    "save_changes": "Save Changes",
    "profit_loss_details": "Profit & Loss Details",
    "price_label": "Price Label",
    "enter_label": "Enter Label",
    "price_value": "Price Value",
    "vivo_games": "vivo games",
    "playtech": "playtech",
    "sexybcrt": "sexybcrt",
    "mac88_gaming": "Mac88 gaming",
    "bookmaker_rules": "Bookmaker Rules",
    "match_rules": "Match Rules",
    "matched_bet": "Matched Bet",
    "no_real_time_records_found": "No real-time records found",
    "runner_name": "Runner Name",
    "profit_loss": "Profit/Loss",
    "book_list": "Book List",
    "suspended": "Suspended",
    "loading": "Loading",
    "acc_holder_name_copied": "Account Holder Name Copied",
    "enter_amount_verify": "Enter Amount (Re-verify for correctness)",
    "enter_deposit_amount": "Enter Deposit Amount",
    "contact_customer_support": "Note: Please allow 30 mins for the deposit amount to be credited. In case of further delay, contact customer support.",
    "scan_qr": "If payment is declined due to security reasons, please scan the QR code below.",
    "deposit_amount": "Deposit Amount",
    "select_provider": "Select Provider",
    "select_game_type": "Select Game Type",
    "no_games_to_display": "No games to display",
    "my_market": "My Market",
    "expose": "Expose",
    "not_found_txt": "Sorry, not found!",
    "football_fancy": "Football Fancy",
    "casino_payout_restrictions": "Casino Payout Restrictions",
    "virtual_tennis": "Virtual Tennis",
    "election": "Election",
    "khado": "Khado",
    "world_cup": "World Cup",
    "psl": "Pakistan Super League (PSL)",
    "wpl": "Women's Premier League (WPL)",
    "ipl": "IPL",
    "fancy_market": "Fancy Market",
    "lunch_favourite": "Lunch Favorite",
    "big_bash_league": "Big Bash League",
    "enter_acc_holder_name": "Enter Account Holder Name",
    "enter_display_name": "ESlot gamesnter Display Name",
    "enter_bank_name": "Enter Bank Name",
    "enter_branch_name": "Enter Branch Name",
    "enter_ifsc_code": "Enter IFSC Code",
    "select_a_crypto": "Select a crypto",
    "crash_games": "Crash Games",
    "competition_name": "competition name",
    "no_accounts_found": "No accounts found",
    "int_casino": "Int Casino",
    "forgot_password": "Forgot password",
    "turnover_report": "Turnover Report",
    "bet_history": "Bet History",
    "profit_loss_report": "Profit Loss Report",
    "site_protection_txt": "This site is protected by reCAPTCHA and the Google",
    "view_by": "View by",
    "competition": "COMPETITION",
    "time": "TIME",
    "new": "NEW",
    "min_stake": "Min Stake",
    "max_stake": "Max Stake",
    "bet_for": "Bet for",
    "profit": "Profit",
    "place_bet_to_see_it_here": "Place bet to see it here",
    "last_date": "Last Date",
    "close_balance": "Close Balance",
    "change_button_values": "Change Button Values",
    "update": "Update",
    "cricket_match_odds": "CRICKET Match Odds :- In the event of match not being completed or tied all bets will be void.",
    "tennis_match_odds": "TENNIS Match Odds :- If 1st set has been not completed at the time of the retirement or disqualification, then all bets relating to that individual match will be void.",
    "football_match_odds": "FOOTBALL Match Odds :- All bets apply to the relevant full 'regular time' period including stoppage time. Any extra-time and/or penalty shoot-out is not included. For the cancellation of a goal, due to VAR, bets matched between the time of the goal being scored and the time at which the video assistant referee finishes the review will be voided. For the cancellation of a red card, due to VAR, bets matched after the time at which the video assistant referee commences the review will be voided.",
    "football_under_over_goals": "FOOTBALL Under_Over Goals :- In the event of a match starting but not being completed, all bets will be void, unless the specific market outcome is already determined.",
    "advantage_or_disadvantage_for_team_txt": "Due to any reason any team will be getting advantage or disadvantage we are not concerned.",
    "suspend_or_void_rights": "Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at the same time (Punching) and others. Note : only winning bets will be voided.",
    "compare_teams_25_overs": "We will simply compare both teams 25 overs score higher score team will be declared winner in ODI (25 over comparison)",
    "compare_teams_10_overs": "We will simply compare both teams 10 overs higher score team will be declared winner in T20 matches (10 over comparison)",
    "contacted_within_7_days": "Any query about the result or rates should be contacted within 7 days of the specific event, the same will not be considered valid post 7 days from the event.",
    "end_two_teams_with_equal_points": "If two team ends up with equal points, then result will be given based on the official point table",
    "tennis_advance_fancy_market": "Tennis:- Advance fancy market",
    "all_bets_will_be_voided": "If the second set is not completed all bets regarding this market will be voided",
    "market_will_be_settled_as_three_sets": "If a player retires after completion of second set, then the market will be settled as three sets",
    "virtual_cricket": "Virtual Cricket",
    "technical_issues_bookmaker_market_will_be_voided": "At any situation if the video gets interrupted/stopped then the same cannot be continued due to any technical issues bookmaker market will be voided",
    "no_account": "Don't have an account?",
    "privacy_policy": "Privacy Policy",
    "terms_of_service": "Terms of Service",
    "and": "and",
    "gpay": "GPAY",
    "phonepe": "PHONEPE",
    "paytm": "PAYTM",
    "create_campaign": "Create Campaign",
    "create_a_new_campaign": "Fill in the details to create a new campaign",
    "add_affiliate": "Add Affiliate",
    "enter_campaign_name": "Enter campaign name",
    "enter_campaign_id": "Enter campaign ID"
}