/**
 * This code was generated by Builder.io.
 */
import React, { useMemo } from "react";
import { EventDTO } from "../../../models/common/EventDTO";
import { EmptyOddsBlock } from "../../Desktop/Tables/MatchList";
import MarketBtn from "../../Desktop/MarketComponents/MarketBtn/MarketBtn";
import { connect } from "react-redux";
import { SelectedObj } from "../../../models/SportsState";
import {
  setCompetition,
  setEventType,
  setExchEvent,
} from "../../../store/catalog";
import { fetchCompetitions } from "../../../api/catalog";
import { useNavigate } from "react-router-dom";
import { InplayEventsObj } from "../../../views/InplayView/InplayView";
import { useAppSelector } from "../../../store/storeHooks";
import LiveMatch from "../../../assets/LiveMatchIcon.svg";
import EventDateDisplay from "../../../common/EventDateDisplay/EventDateDisplay";

interface EventItemProps {
  match: EventDTO;
  setEventType: Function;
  setCompetition: Function;
  setExchEvent: Function;
  iEvent: InplayEventsObj;

}

const EventItem: React.FC<EventItemProps> = ({
  match,
  setCompetition,
  setEventType,
  setExchEvent,
  iEvent,

}) => {
  const runnerTypes = ["home", "draw", "away"];
  const { loggedIn } = useAppSelector((state) => state.auth);
  const { langData } = useAppSelector((state) => state.cms);


  const getOdds = (eventData: EventDTO, teamType: string) => {
    const team =
      teamType === "home"
        ? eventData.homeTeam
        : teamType === "away"
        ? eventData.awayTeam
        : teamType;

    const teamLowerCase = team.toLowerCase();
    for (let runner of eventData.matchOdds.runners) {
      let runnerLowCase = runner.runnerName.toLowerCase();
      if (
        runnerLowCase === teamLowerCase ||
        runnerLowCase.includes(teamLowerCase)
      ) {
        return [
          {
            type: "back-odd",
            price: runner?.backPrices[0]?.price,
            size: runner.backPrices[0]?.size,
            outcomeId: runner?.runnerId,
            outcomeName: runner.runnerName,
          },
          {
            type: "lay-odd",
            price: runner?.layPrices[0]?.price,
            size: runner?.layPrices[0]?.size,
            outcomeId: runner.runnerId,
            outcomeName: runner.runnerName,
          },
        ];
      }
    }
    return null;
  };

  const navigate = useNavigate();

  const handleEventChange = (iEvent: InplayEventsObj, event: EventDTO) => {
    const competitionSlug = event.competitionName
      ? event.competitionName
          .toLocaleLowerCase()
          .replace(/[^a-z0-9]/g, " ")
          .replace(/ +/g, " ")
          .trim()
          .split(" ")
          .join("-")
      : "league";
    setEventType({
      id: iEvent.sportId,
      name: iEvent.sportName,
      slug: iEvent.sportSlug,
    });
    setCompetition({
      id: event.competitionId,
      name: event.competitionName,
      slug: competitionSlug,
    });
    setExchEvent({
      id: event.eventId,
      name: event.eventName,
      slug: event.eventSlug,
    });
    if (event?.providerName?.toLowerCase() === "sportradar" && !loggedIn) {
      navigate("/login");
    } else if (event?.catId === "SR VIRTUAL") {
      navigate(
        `/exchange_sports/virtuals/${iEvent.sportSlug}/${competitionSlug}/${
          event.eventSlug
        }/${btoa(`${event.sportId}:${event.competitionId}:${event.eventId}`)}`
      );
    } else {
      navigate(
        `/exchange_sports/${iEvent.sportSlug}/${competitionSlug}/${
          event.eventSlug
        }/${btoa(`${event.sportId}:${event.competitionId}:${event.eventId}`)}`
        // {
        //   homeTeam: event?.homeTeam,
        //   awayTeam: event?.awayTeam,
        //   openDate: event?.openDate,
        // }
      );
    }
  };

  return (
    <div
      className="flex flex-col mx-[12px] "
      onClick={() => handleEventChange(iEvent, match)}
    >
      <div className="flex gap-2 mt-2 w-full max-md:flex-wrap max-md:max-w-full">
        <div className="flex w-full gap-3 py-1 pl-px items-center rounded-none text-stone-900">
          {match.status.toLowerCase() === "in_play" ? (
            <div className=" w-[28px] h-[18px] text-[12px] leading-[18px] Switzer font-[600] text-center text-white bg-[#3C7701] rounded-sm">
              LIVE
            </div>
          ) : (
            <div className=" w-[34px] h-[18px] text-[12px] leading-[18px] Switzer font-[600] text-center text-white bg-[#3C7701] rounded-sm">
              UPCOMING
            </div>
          )}
          <div className=" flex justify-between  w-full">
            <div className="flex-auto my-auto text-[14px]text-wrap w-1/2 grow ">
              {match?.homeTeam !== "" && match?.awayTeam !== "" ? (
                <div className="text-[14px] capitalize">
                <div className="flex items-center">
                  <div className="font-[500] max-w-[200px] truncate">
                    {match.homeTeam} vs {match.awayTeam}
                  </div>
                  {/* Green blinking dot */}
                  <div className="ml-2 flex-shrink-0">
                    <span className="inline-block w-2.5 h-2.5 rounded-full bg-green-500 animate-blink"></span>
                  </div>
                </div>
                <div className="text-[12px] text-stone-900 text-center mt-1">
                  <EventDateDisplay openDate={match.openDate} />
                </div>
              </div>
              ) : (
                <div className=" text-[14px] capitalize ">
                  <div className=" font-[500]">{match.eventName}</div>
                  <div className="text-[12px] text-stone-900 text-center">
                    <div className="">
                      <EventDateDisplay openDate={match.openDate} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex self-end justify-end mb-auto flex-1 gap-1.5 w-fit pl-2 ">
              <div>
                <img src={LiveMatch} className="mr-2" />
              </div>
              {match?.enableMatchOdds ? (
                <button className="w-9 h-6 leading-[13.5px] bg-primary rounded text-white text-[14px] font-semibold ">
                  MO
                </button>
              ) : null}
              {match?.enableBookmaker ? (
                <button className="w-9 h-6 leading-[13.5px] bg-primary rounded text-white text-[14px] font-semibold ">
                  BM
                </button>
              ) : null}
              {match?.enableFancy ? (
                <button className="w-[2rem] h-6 leading-[13.5px] bg-primary rounded text-white text-[14px] font-semibold ">
                  F
                </button>
              ) : null}
              {match?.enablePremium ? (
                <button className="w-[2rem] h-6 leading-[13.5px] bg-primary rounded text-white text-[14px] font-semibold ">
                  P
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-1.5 self-end m mt-2  ">
        {runnerTypes?.map((runnerType) => (
          <td>
            <div className="flex w-[110px] cursor-pointer rounded-[3px] overflow-hidden mx-auto">
              {match?.matchOdds ? (
                getOdds(match, runnerType) ? (
                  getOdds(match, runnerType).map((odd) => (
                    <MarketBtn
                      mainValue={odd.price}
                      subValue={odd.size}
                      oddType={odd.type === "back-odd" ? "back-odd" : "lay-odd"}
                      disable={match.matchOdds.status
                        .toLowerCase()
                        .includes("suspended")}
                      valueType="matchOdds"
                      showSubValueinKformat={true}
                      onClick={() => null}
                    />
                  ))
                ) : (
                  <EmptyOddsBlock />
                )
              ) : (
                <EmptyOddsBlock />
              )}
            </div>
          </td>
        ))}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setEventType: (eType: SelectedObj) => dispatch(setEventType(eType)),
    setCompetition: (competition: SelectedObj) =>
      dispatch(setCompetition(competition)),
    setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
  };
};

export default connect(null, mapDispatchToProps)(EventItem);
