/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Vector from "../../../../../assets/AuthScreens/VectorImg.webp"
import { useAppSelector } from "../../../../../store/storeHooks";
const Logo: React.FC = () => {
  const { langData } = useAppSelector((state) => state.cms);
  
  return (
    <div className="flex flex-col items-center relative gap-2 justify-center text-3xl font-semibold tracking-wider whitespace-nowrap">
      <img
        loading="lazy"
        src={Vector}
        className="shrink-0 w-10 rounded-none aspect-square"
        alt={langData?.['indigo_logo']}
      />
      <h1>{langData?.['indigo']}</h1>
    </div>
  );
};

export default Logo;
