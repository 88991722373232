/**
 * This code was generated by Builder.io.
 */
import React from "react";
import ReactDatePicker from "../ReactDatePicker";
import { useAppSelector } from "../../../store/storeHooks";

interface DatePickerProps {
  onDateChange: Function;
  startDate: Date;
  endDate: Date;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  startDate,
  endDate,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  const handleFromDateChange = (date) => {
    onDateChange(date, endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(startDate, date);
  };

  return (
    <div className="flex w-[100%] gap-[12px] self-end  text-[14px] whitespace-nowrap ">
      <div className="flex gap-2.5 flex-col w-[50%]">
        <div className="grow my-auto capitalize text-neutral-500">{langData?.['from']}</div>
        <ReactDatePicker date={startDate} setDate={handleFromDateChange} />
      </div>
      <div className="flex gap-2.5 flex-col w-[48%]">
        <div className="grow my-auto capitalize text-neutral-500">{langData?.['to']}</div>
        <ReactDatePicker date={endDate} setDate={handleToDateChange} />
      </div>
    </div>
  );
};

export default DatePicker;
