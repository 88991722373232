
import React from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useAppSelector } from "../../../../store/storeHooks";

interface BackScreen {
    redirectToHome: Function;
}
const BackScreen: React.FC<BackScreen> = (props) => {
    const { redirectToHome } = props;
  const { langData } = useAppSelector((state) => state.cms);
  return (
        <div
    onClick={() => redirectToHome()}
    className="fixed top-[20px] left-[10px] flex items-center gap-1.5 text-sm font-medium whitespace-nowrap text-white z-10"
>
    <FaArrowLeftLong className="text-white" />
    <h1 className="my-auto font-medium">{langData?.['back']}</h1>
</div>
    );
};

export default BackScreen;
