/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { useAppSelector } from "../../../store/storeHooks";

const MatchWinner: React.FC = () => {
  const { langData } = useAppSelector((state) => state.cms);
  return (
    <div className="flex flex-col pt-px pb-2.5 mt-1.5 bg-white rounded-md border border-solid border-neutral-200 max-md:max-w-full">
      <div className="flex gap-5 rounded-md bg-stone-900 max-md:flex-wrap max-md:max-w-full">
        <div className="flex justify-center items-center">
          <div className="flex relative flex-col justify-center items-start px-3 py-1.5 rounded-none aspect-[9.1] w-[282px] max-md:pr-5">
            <img
              loading="lazy"
              src="http://b.io/ext_76-"
              className="object-cover absolute inset-0 size-full"
              alt=""
            />
            <div className="flex relative gap-1.5">
              <div className="flex justify-center items-center p-1 w-5 h-5 bg-white rounded-[1000px]">
                <img
                  loading="lazy"
                  src="http://b.io/ext_80-"
                  className="w-2.5 aspect-square border-stone-900"
                  alt=""
                />
              </div>
              <div className="my-auto text-[14px] font-medium text-white">
                {langData?.['match_winner_txt']}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-auto my-auto text-[14px] tracking-wide text-right text-white">
          {langData?.['min']}: 100 {langData?.['max']}: 25K
        </div>
      </div>
      <div className="flex gap-5 justify-between mt-2 text-[14px] font-medium text-stone-900 max-md:flex-wrap max-md:max-w-full">
        <div className="my-auto">Gujarat Titans</div>
        <div className="px-5 py-1.5 text-[14px] text-center whitespace-nowrap bg-sky-200 rounded">
          5.09
        </div>
        <div className="px-1 py-0.5 font-semibold tracking-wider text-center text-white uppercase whitespace-nowrap rounded bg-neutral-500">
          VS
        </div>
        <div className="my-auto">Gujarat Titans</div>
        <div className="px-5 py-1.5 text-[14px] text-center whitespace-nowrap bg-sky-200 rounded">
          5.09
        </div>
      </div>
    </div>
  );
};

export default MatchWinner;
