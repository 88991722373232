import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";

interface MarketBtnProps {
    mainValue?: any;
    mainValueClass?: 'price' | 'runs';
    subValue?: number;
    subValueClass?: 'size' | 'odds';
    disable?: boolean;
    oddType:
    | 'back-odd'
    | 'lay-odd'
    | 'odds-yes-cell'
    | 'odds-no-cell'
    | 'premium-odd';
    valueType?:
    | 'matchOdds'
    | 'bookmakerOdds'
    | 'fancyMarketOdds'
    | 'binaryOdds'
    | 'premiumOdds';
    onClick?: () => void;
    oddsSet?: number[];
    showSubValueinKformat?: boolean;
    marketType?: string;
}

const MarketBtn: React.FC<MarketBtnProps> = (props) => {
    const {
        marketType,
        mainValue,
        mainValueClass,
        subValue,
        subValueClass,
        disable,
        oddType,
        valueType,
        onClick,
        oddsSet,
        showSubValueinKformat,
    } = props;
    const [currentValue, setCurrentValue] = useState<number>(null);
    const [classes, setClasses] = useState<string>('exch-odd-button');
    const [timer, setTimer] = useState(null);
    const [currentOdds, setCurrentOdds] = useState<number[]>([]);
    const [volume, setVolume] = useState<number>();
    const subValprecision: number = valueType === 'matchOdds' ? 0 : 0;

    useEffect(() => {
        if (subValue) {
            if (valueType === 'matchOdds') setVolume(subValue * 1000);
            else setVolume(subValue);
        }
    }, [subValue, valueType]);

    const oddValueChanged = () => {
        clearTimeout(timer);

        const chVal = mainValue;
        if (chVal) {
            const cVal = currentValue;
            setCurrentValue(chVal);
            let showAnimation = true;
            if (oddsSet && oddsSet.length > 0) {
                showAnimation = !currentOdds.includes(chVal);
                setCurrentOdds(oddsSet);
            }
            if (showAnimation) {
                if (cVal && chVal !== cVal) {
                    if (!classes.includes('odd-change'))
                        setClasses(classes + ' odd-change');
                    setTimer(
                        setTimeout(
                            () => setClasses(classes.replace(' odd-change', '')),
                            5000
                        )
                    );
                }
            }
        }
    };

    useEffect(() => oddValueChanged(), [mainValue, subValue]);

    const clipClass = () => {
        if (marketType) {
            return 'rounded'
        }
        if (valueType !== "fancyMarketOdds") {
            if (oddType === 'lay-odd') {
                return 'lay-btn-clip ml-[-15px] rounded-tr-md rounded-br-md';
            } else {
                return 'back-btn-clip rounded-tl-md rounded-bl-md';
            }
        } else {
            if (oddType === 'lay-odd') {
                return 'back-btn-clip rounded-tl-md rounded-bl-md';
            } else {
                return 'lay-btn-clip ml-[-15px] rounded-tr-md rounded-br-md';
            }
        }
    }


    const btnBgColor =
        oddType === "lay-odd" || oddType === "odds-no-cell"
            ? "bg-pink1"
            : "bg-sky1";

    return (
        <>
            {mainValue && Number(mainValue) > 0 ? (
                <div
                    className={`${disable ? "disabled" : "cursor-pointer"
                        } min-w-[33.4%] sm:min-w-0 sm:w-full flex rounded-[2px]`}
                >
                    <span className="flex items-center  justify-center w-full p-[1px] md:p-[2px] overflow-hidden">
                        <div
                            className={`${oddType === "lay-odd" ? "bg-[#F9CFCE]" : "bg-[#A5D9FE]"
                                } relative w-full px-1 py-[1px] rounded-sm flex flex-col items-center justify-center bg-bg_BackBtnBg border border-backBtn`}
                            onClick={() => (!disable ? onClick() : {})}
                        >
                            <span
                                id="oddBtnPrice"
                                className={`${oddType === "premium-odd" ? "h-full" : ""
                                    } relative font-bold z-10 transition-all ease-in-out duration-300 flex items-center justify-center w-full text-text_OddValue leading-5 text-sm md:text-[15px]`}
                                // title={Number.isNaN(Number(mainValue)) ? "" : Number(mainValue)}
                            >
                                {Number.isNaN(Number(mainValue)) ? (
                                    <br />
                                ) : valueType === "fancyMarketOdds" ? (
                                    Number(mainValue).toFixed(3)
                                ) : (
                                    Number(mainValue).toFixed(3)
                                )}
                            </span>

                            <span
                                id="oddBtnSize"
                                className="relative z-10 transition-all ease-in-out duration-300 flex items-center justify-center w-full text-[10px] text-text_OddValue leading-3 text-center whitespace-normal font-normal"
                            >
                                <span className="w-max break-all truncate">
                                    {volume
                                        ? showSubValueinKformat && Number(volume > 999)
                                            ? Number(volume / 1000).toFixed(subValprecision) + "K"
                                            : Number(volume).toFixed(subValprecision)
                                        : "0.00K"}
                                </span>
                            </span>
                        </div>
                    </span>
                </div>
            ) : (
                <div className="min-w-[33.4%] sm:min-w-0 sm:w-full rounded-[2px]">
                    <span className="flex items-center justify-center w-full p-[1px] md:p-[2px] overflow-hidden">
                        <div
                            className={`${oddType === "lay-odd" ? "bg-[#f2f2f2]" : "bg-[#E0E0E0]"
                                } relative opacity-50 cursor-not-allowed w-full px-1 py-[1px] rounded-sm flex flex-col items-center justify-center bg-bg_BackBtnBg border border-backBtn`}
                        >
                            <span
                                id="oddBtnPrice"
                                className="relative z-10 transition-all ease-in-out duration-300 flex items-center justify-center w-full text-text_OddValue leading-5 text-sm md:text-[15px] font-semibold"
                            >
                                <span className="">-</span>
                            </span>
                            <span
                                id="oddBtnSize"
                                className="relative z-10 transition-all ease-in-out duration-300 flex items-center justify-center w-full text-[10px] text-text_OddValue leading-3 text-center whitespace-normal font-normal"
                            >
                                <span className="w-max break-all truncate invisible">-</span>
                            </span>
                        </div>
                    </span>
                </div>

            )}
        </>

    );
}
export default MarketBtn;