/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useRef, useState } from "react";
import MatchInfo from "./MatchInfo";
import MatchOdds from "./MatchOdds";
import Bookmaker from "./Bookmaker";
import MatchWinner from "./MatchWinner";
import FancyMarket from "./FancyMarket";
import { BookmakerDTO } from "../../../models/common/BookmakerDTO";
import {
  EventDTOMap,
  SecondaryMarkets,
  SelectedObj,
} from "../../../models/SportsState";
import { RootState } from "../../../store";
import {
  getAllMarketsByEvent,
  getSecondaryMarketsByEvent,
  getSecondaryMatchOddsByEvent,
  getBookmakerMarketsByEvent,
  getPremiumMarkets,
  getLineMarketsByEvent,
} from "../../../store/catalog/catalogSelectors";
import { useNavigate, useParams } from "react-router-dom";
import { SPEventDTO } from "../../../models/SPEventDTO";
import { MatchOddsDTO } from "../../../models/common/MatchOddsDTO";
import { connect, useDispatch } from "react-redux";
import {
  fetchEventMarkets,
  fetchPremiummarketsByEventId,
} from "../../../store/catalog/catalogThunk";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import { EventDTO } from "../../../models/common/EventDTO";
import {
  BFToSRIdMap,
  getCurrencyTypeFromToken,
  getHouseIdFromToken,
  getParentIdFromToken,
  getSapTokenFromToken,
} from "../../../utils/commonUtils";
import SVLS_API from "../../../api/api-services/svls-api";
import { PROVIDER_ID } from "../../../constants/Branding";
import { CURRENCY_TYPE_FACTOR } from "../../../constants/CurrencyTypeFactor";
import { PlaceBetRequest } from "../../../models/BsData";
import { BET_TIMEOUT } from "../../../constants/CommonConstants";
import { betStatus, fetchOpenBets } from "../../../store/betSlip/betSlipThunk";
import moment from "moment";
import {
  setExchEvent,
  updateBookMakerMarkets,
} from "../../../store/catalog/catalogSlice";
import { fetchBalance } from "../../../store/auth/authThunk";
import API_SPORT_FEED from "../../../api/api-services/sportsfeed-api";
import {
  clearBetStatusResponse,
  clearExchangeBets,
  setBettingInprogress,
} from "../../../store/betSlip/betSlipSlice";
import { UserBet } from "../../../models/UserBet";
import purple from "../../../assets/purple_bg.png";
import MarketBtn from "../MarketComponents/MarketBtn/MarketBtn";
import "./ExchAllMarkets.css";
import PremiumMarkets from "./PremiumMarkets";
import { isDesktop, isMobile } from "react-device-detect";
import { showToast } from "../../../store/common/commonSlice";
import { AlertDTO } from "../../../models/Alert";
import {
  subscribeWsForNotifications,
  subscribeWsForNotificationsPerAdmin,
  subscribeWsForNotificationsPerAdminAllMarkets,
  unsubscribePNWsforEvents,
} from "../../../webSocket/pnWebSocket";
import {
  checkStompClientSubscriptions,
  disconnectToWS,
  subscribeSportRadarEventOdds,
  subscribeWsForEventOdds,
  subscribeWsForFancyMarkets,
  subscribeWsForSecondaryMarkets,
  subscribeWsForSecondaryMatchOdds,
  unsubscribeAllWsforEvents,
} from "../../../webSocket/websocket";
import SidePanel from "../MainContent/SidePanel";
import useScript from "../../../hooks/useScript";

type RouteParams = {
  eventType: string;
  competition: string;
  eventId: string;
  eventInfo: string;
};

type StoreProps = {
  fetchEvent: (
    sportId: string,
    competitionId: string,
    eventId: string,
    marketTime: string
  ) => void;
  eventInfoProp?: any;
  showToast: Function;
  premiumMarkets: SPEventDTO;
  eventData: EventDTO;
  secondaryMarkets: SecondaryMarkets;
  secondaryMatchOdds: MatchOddsDTO[];
  loggedIn: boolean;
  fetchPremiummarketsByEventId: Function;
  selectedEvent: SelectedObj;
  selectedEventType: { id: ""; name: ""; slug: "" };
  bmMData: BookmakerDTO[];
  seEventData: SPEventDTO;
  bets: PlaceBetRequest[];
  openBets: UserBet[];
  totalOrders: number;
  fetchOpenBets: (eventId: string, sportId: string) => void;
  setExchEvent: (event: SelectedObj) => void;
  triggerFetchMarkets: number;
  triggerFetchOrders: number;
  triggerBetStatus: number;
  betStatusResponse: any;
  clearExchangeBets: () => void;
  setBettingInprogress: (val: boolean) => void;
  clearBetStatusResponse: () => void;
  topicUrls: any;
  houseId: string;
  parentId: string;
  accountId: string;
  betFairWSConnected: boolean;
  pushNotifWSConnection: boolean;
};

const ExchAllMarkets: React.FC<StoreProps> = (props) => {
  const {
    selectedEvent,
    eventData,
    secondaryMatchOdds,
    secondaryMarkets,
    bets,
    openBets,
    totalOrders,
    loggedIn,
    fetchOpenBets,
    selectedEventType,
    fetchEvent,
    fetchPremiummarketsByEventId,
    setExchEvent,
    seEventData,
    bmMData,
    topicUrls,
    houseId,
    parentId,
    accountId,
    triggerFetchMarkets,
    triggerFetchOrders,
    triggerBetStatus,
    betStatusResponse,
    clearExchangeBets,
    setBettingInprogress,
    clearBetStatusResponse,
    betFairWSConnected,
    pushNotifWSConnection,
    eventInfoProp,
    showToast,
    premiumMarkets,
  } = props;
  const { langData } = useAppSelector((state) => state.cms);

  const [tabVal, setTabVal] = useState(0);
  const [openBetslip, setOpenBetslip] = useState<boolean>(true);
  const [backupStreamUrl, setBackupStreamUrl] = useState<string>(null);
  const [fancyTabVal, setFancyTabVal] = useState(0);
  const [scorecardID, setScorecardID] = useState<string>("");
  const [virtualScorecard, setVirtualScorecard] = useState();
  const [cFactor, setCFactor] = useState<number>(
    CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()]
  );
  const [dialogShow, setDialogShow] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<"fancy" | "premium">("fancy");

  const [exposureMap, setExposureMap] = useState(new Map());
  const [matchOddsBaseUrl, setMatchOddsBaseUrl] = useState<string>("");
  const [matchOddsTopic, setMatchOddsTopic] = useState<string>("");
  const [bookMakerBaseUrl, setBookMakerBaseUrl] = useState<string>("");
  const [bookMakerTopic, setBookMakerTopic] = useState<string>("");
  const [fancyBaseUrl, setFancyBaseUrl] = useState<string>("");
  const [fancyTopic, setFancyTopic] = useState<string>("");
  const [premiumBaseUrl, setPremiumBaseUrl] = useState<string>("");
  const [premiumTopic, setPremiumTopic] = useState<string>("");
  const [enableFetchOrders, setEnableFetchOrders] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<Date>();
  const [fetchOpenOrders, setFetchOpenOrders] = useState<number>(null);
  const [addNewBet, setAddNewBet] = useState<boolean>(true);
  const intervalRef = useRef(null);
  const isFirstRender = useRef(true);
  const isFirstRenderStartTime = useRef(true);
  const isFirstRenderFetchMarkets = useRef(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const routeParams = useParams<RouteParams>();
  const { rgGames, egGames } = useAppSelector(
    (state: RootState) => state.catalog
  );

  const generateScript = (scorecardID: string) => {
    return `(function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
          g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
          )})(window,document,"script", "https://widgets.sir.sportradar.com/8ee45b574e2781d581b0b0a133803906/widgetloader", "SIR", {
              theme: false, // using custom theme
              language: "en"
          });
          SIR("addWidget", ".sr-widget-1", "match.lmtPlus", {layout: "double", scoreboard: "extended", momentum: "extended", collapseTo: "momentum", matchId:${scorecardID}});
              `;
  };

  useEffect(() => {
    const script = document.createElement("script");

    if (scorecardID) {
      script.async = true;
      script.innerHTML = generateScript(scorecardID);

      document.body.appendChild(script);
    }
  }, [scorecardID]);

  useEffect(() => {
    return () => {
      setScorecardID('');
    }
  }, []);

  // score card listener to change the height of iframe to its content
  useEffect(() => {
    window.addEventListener("message", (event) => {
      const element: HTMLIFrameElement = document.getElementById(
        "frame"
      ) as HTMLIFrameElement;

      if (element !== null && element !== undefined) {
        if (event && event.data && event.data.scoreWidgetHeight) {
          element.height = event.data.scoreWidgetHeight + 12;
        }
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      unsubscribeAllWsforEvents();
    };
  }, []);

  useEffect(() => {
    if (!loggedIn) {
      unsubscribeAllWsforEvents();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn && eventData) {
      setCFactor(CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()]);
      if (topicUrls?.matchOddsTopic) {
        updateMatchOddsTopic(
          topicUrls?.matchOddsTopic,
          topicUrls?.matchOddsBaseUrl
        );
        subscribeWsForEventOdds(
          topicUrls?.matchOddsTopic,
          eventData.sportId,
          eventData.competitionId,
          eventData.eventId,
          eventData.matchOdds.marketId,
          eventData.providerName,
          false
        );
      }

      if (
        eventData.sportId === "4" ||
        eventData.sportId === "2" ||
        eventData.sportId === "1"
      ) {
        if (secondaryMarkets?.bookmakers?.length && topicUrls?.bookMakerTopic) {
          updateBookMakerTopic(
            topicUrls?.bookMakerTopic,
            topicUrls?.bookMakerBaseUrl
          );
          for (let itm of secondaryMarkets?.bookmakers) {
            subscribeWsForSecondaryMarkets(
              topicUrls?.bookMakerTopic,
              eventData?.eventId,
              itm.marketId
            );
          }
        }
        if (secondaryMarkets?.fancyMarkets?.length && topicUrls?.fancyTopic) {
          updateFancyTopic(topicUrls?.fancyTopic, topicUrls?.fancyBaseUrl);
          subscribeWsForFancyMarkets(topicUrls?.fancyTopic, eventData?.eventId);
        }
      }

      if (topicUrls?.matchOddsTopic) {
        updateMatchOddsTopic(
          topicUrls?.matchOddsTopic,
          topicUrls?.matchOddsBaseUrl
        );
        for (let mo of secondaryMatchOdds) {
          subscribeWsForSecondaryMatchOdds(
            topicUrls?.matchOddsTopic,
            eventData.eventId,
            mo.marketId,
            eventData.providerName
          );
        }
      }

      if (
        seEventData?.markets?.matchOdds &&
        seEventData?.markets?.matchOdds?.length > 0 &&
        topicUrls?.premiumTopic
      ) {
        updatePremiumTopic(topicUrls?.premiumTopic, topicUrls?.premiumBaseUrl);
        subscribeSportRadarEventOdds(
          topicUrls?.premiumTopic,
          selectedEvent?.id,
          seEventData.eventId
        );
      }
    }
  }, [
    betFairWSConnected,
    selectedEvent,
    loggedIn,
    secondaryMatchOdds,
    secondaryMarkets,
  ]);

  useEffect(() => {
    if (
      eventData?.eventId &&
      eventData?.status === 'IN_PLAY' &&
      scorecardID === '' &&
      eventData?.providerName !== "SportRadar"
    ) {
      loggedIn && getSPEventIdByBetfairId();
    }
  }, [eventData?.eventId]);

  useEffect(() => {
    if (loggedIn && seEventData?.eventId && scorecardID === '') {      
      setScorecardID(seEventData?.eventId?.split(':')[2]);
    }
  }, [seEventData]);

  useEffect(() => {
    console.log('score - ', scorecardID)
  }, [scorecardID])

  const updateMatchOddsTopic = (
    currentTopic: string,
    currentBaseUrl: string
  ) => {
    if (!matchOddsTopic && !matchOddsBaseUrl) {
      setMatchOddsTopic(currentTopic);
      setMatchOddsBaseUrl(currentBaseUrl);
    }
    else if (
      matchOddsTopic !== currentTopic ||
      matchOddsBaseUrl !== currentBaseUrl
    ) {
      disconnectToWS();
      setMatchOddsTopic(currentTopic);
      setMatchOddsBaseUrl(currentBaseUrl);
    }
  };

  const updateBookMakerTopic = (
    currentTopic: string,
    currentBaseUrl: string
  ) => {
    if (
      bookMakerTopic !== currentTopic ||
      bookMakerBaseUrl !== currentBaseUrl
    ) {
      disconnectToWS();
      setBookMakerTopic(currentTopic);
      setBookMakerBaseUrl(currentBaseUrl);
    }
  };

  const updateFancyTopic = (currentTopic: string, currentBaseUrl: string) => {
    if (fancyTopic !== currentTopic || fancyBaseUrl !== currentBaseUrl) {
      disconnectToWS();
      setFancyTopic(currentTopic);
      setFancyBaseUrl(currentBaseUrl);
    }
  };

  const updatePremiumTopic = (currentTopic: string, currentBaseUrl: string) => {
    if (premiumTopic !== currentTopic || premiumBaseUrl !== premiumBaseUrl) {
      disconnectToWS();
      setPremiumTopic(currentTopic);
      setPremiumBaseUrl(currentBaseUrl);
    }
  };

  const getSPEventIdByBetfairId = async () => {
    try {
      await API_SPORT_FEED.post(`/feed/get-sr-event`, {
        eventId: eventData?.eventId,
      }).then((result) => {
        if (result?.data?.status === "RS_OK")
          setScorecardID(
            result?.data?.srEventDetails?.srEventId?.split(":")[2]
          );
        else setScorecardID(null);
      });
    } catch (err) {
      setScorecardID(null);
    }
  };

  useEffect(() => {
    if (secondaryMarkets?.enableFancy) {
      if (["1", "2"].includes(eventData?.sportId)) {
        setActiveTab("premium");
      }
      if (
        !secondaryMarkets?.fancyMarkets ||
        secondaryMarkets?.fancyMarkets?.length === 0
      ) {
        setActiveTab("premium");
      } else {
        setActiveTab("fancy");
      }
    } else {
      setActiveTab("premium");
    }
  }, [secondaryMarkets?.enableFancy]);

  useEffect(() => {
    clearExchangeBets();
    return () => {
      setExchEvent({ id: "", name: "", slug: "" });
    };
  }, []);

  // Get new Premium fancy markets
  useEffect(() => {
    try {
      let paramSId = null;
      let paramCId = null;
      let paramEId = null;
      let paramPName = null;
      if (loggedIn) {
        const [sportId, competitionId, eventId, marketTime] = atob(
          routeParams.eventInfo ? routeParams.eventInfo : ""
        ).split(":");

        if (eventInfoProp) {
          paramSId = eventInfoProp.sportId;
          paramCId = eventInfoProp.competitionId;
          paramEId = eventInfoProp.eventId;
          paramPName = "BetFair";
        } else {
          paramSId = selectedEventType?.id;
          paramCId = competitionId?.includes("_")
            ? competitionId.split("_").join(":")
            : competitionId;
          paramEId = selectedEvent?.id.includes("_")
            ? selectedEvent?.id.split("_").join(":")
            : selectedEvent?.id;
          paramPName =
            selectedEvent?.id.includes("_") || selectedEvent?.id.includes(":")
              ? "SportRadar"
              : "BetFair";
        }
        let refreshInterval = setInterval(() => {
          if (selectedEvent?.id)
            fetchPremiummarketsByEventId(
              paramPName,
              paramCId,
              paramSId,
              paramEId,
              marketTime
            );
        }, 1000 * 30);
        return () => {
          clearInterval(refreshInterval);
        };
      }
    } catch (err) {}
  }, [loggedIn, selectedEvent?.id]);

  useEffect(() => {
    if (loggedIn && enableFetchOrders && selectedEvent?.id) {
      setTimeout(() => {
        let sid = selectedEvent?.id.includes("_")
          ? BFToSRIdMap[selectedEventType?.id]
          : selectedEventType?.id;
        fetchOpenBets(selectedEvent?.id, sid);
        dispatch(fetchBalance());
        fetchOpenBetsRisk(selectedEvent?.id);
      }, 1000);
    }
  }, [
    loggedIn,
    triggerFetchOrders,
    fetchOpenOrders,
    selectedEvent?.id,
    enableFetchOrders,
  ]);

  useEffect(() => {
    try {
      let paramSId = null;
      let paramCId = null;
      let paramEId = null;
      const [sportId, competitionId, eventId, marketTime] = atob(
        routeParams.eventInfo ? routeParams.eventInfo : ""
      ).split(":");
      if (eventInfoProp) {
        paramSId = eventInfoProp.sportId;
        paramCId = eventInfoProp.competitionId;
        paramEId = eventInfoProp.eventId;
      } else {
        paramSId = sportId;
        paramCId = competitionId;
        paramEId = eventId;
      }
      if (loggedIn && !backupStreamUrl && (eventId || eventInfoProp?.eventId)) {
        fetchStreamUrl(paramSId, paramCId, paramEId);
      }
    } catch (err) {}
  }, [loggedIn, routeParams?.eventId]);

  // Fetch market prices from URL encoded param

  useEffect(() => {
    try {
      let paramSId = null;
      let paramCId = null;
      let paramEId = null;
      let paramPName = null;
      const [sportId, competitionId, eventId, marketTime] = atob(
        routeParams.eventInfo ? routeParams.eventInfo : ""
      ).split(":");

      if (eventInfoProp) {
        paramSId = eventInfoProp.sportId;
        paramCId = eventInfoProp.competitionId;
        paramEId = eventInfoProp.eventId;
      } else {
        paramSId = sportId;
        paramCId = competitionId;
        paramEId = eventId;
      }
      if (!eventId.toLowerCase().includes("sr")) {
        fetchEvent(paramSId, paramCId, paramEId, marketTime);
      }
      if (loggedIn && routeParams?.eventId) {
        setEnableFetchOrders(true);

        let sid = paramEId?.includes("_") ? BFToSRIdMap[paramSId] : paramSId;
        fetchOpenBets(paramEId, sid);
        fetchOpenBetsRisk(paramEId);
      }
      if (!eventInfoProp) {
        paramCId = competitionId.includes("_")
          ? competitionId.split("_").join(":")
          : competitionId;
        paramPName = eventId.includes("_") ? "SportRadar" : "BetFair";
        paramEId = eventId.includes("_")
          ? eventId.split("_").join(":")
          : eventId;
      }
      fetchPremiummarketsByEventId(
        paramPName,
        paramCId,
        paramSId,
        paramEId,
        marketTime
      );

      setBettingInprogress(false);
      if (bets[0]?.amount) {
        clearExchangeBets();
      }
      clearBetStatusResponse();
    } catch (err) {}
  }, [routeParams?.eventId]);

  useEffect(() => {
    if (isFirstRenderFetchMarkets.current) {
      isFirstRenderFetchMarkets.current = false;
      return;
    }
    if (eventData !== null) {
      fetchEvent(
        eventData?.sportId,
        eventData?.competitionId,
        eventData?.eventId,
        ""
      );
    }
  }, [triggerFetchMarkets]);

  useEffect(() => {
    if (eventData && eventData.status === "IN_PLAY") {
      setTabVal(0);
    }
    if (totalOrders && !isMobile) {
      setTabVal(1);
    } else {
      setTabVal(0);
    }
  }, [totalOrders]);

  useEffect(() => {
    if (!openBetslip && bets.length > 0) {
      setOpenBetslip(true);
    }
  }, [bets]);

  const fetchStreamUrl = async (
    sportId: string,
    competitionId: string,
    eventId: string
  ) => {
    try {
      const response = await SVLS_API.get(`/catalog/v2/live-stream-links/`, {
        headers: {
          Authorization: sessionStorage.getItem("jwt_token"),
        },
        params: {
          providerId: PROVIDER_ID,
          sportId: sportId,
          competitionId: competitionId,
          eventId: eventId,
        },
      });

      if (response.status === 200 && response.data) {
        setBackupStreamUrl(response.data);
      } else {
        setBackupStreamUrl(null);
      }
    } catch (err) {
      setBackupStreamUrl(null);
    }
  };

  useEffect(() => {
    if (openBets?.length > 0 && !isMobile) setTabVal(1);
  }, [openBets?.length]);

  useEffect(() => {
    if (bets?.length > 0) setTabVal(0);
  }, [bets]);

  useEffect(() => {
    if (loggedIn && eventData?.eventId) {
      unsubscribePNWsforEvents(houseId);
      unsubscribePNWsforEvents(parentId);
      subscribeWsForNotifications(
        false,
        houseId,
        eventData?.sportId,
        eventData?.competitionId,
        eventData?.eventId
      );
      subscribeWsForNotificationsPerAdminAllMarkets(
        false,
        houseId,
        parentId,
        accountId,
        eventData?.eventId
      );
      return () => {
        unsubscribePNWsforEvents(houseId);
        unsubscribePNWsforEvents(parentId + ":" + eventData?.eventId);
        if (!window.location.pathname.includes("==")) {
          subscribeWsForNotificationsPerAdmin(
            false,
            houseId,
            parentId,
            accountId
          );
          subscribeWsForNotifications(false, houseId);
        }
      };
    }
  }, [pushNotifWSConnection, loggedIn, eventData?.eventId]);

  useEffect(() => {
    if (loggedIn) {
      // let refreshInterval = setInterval(() => {
      if (topicUrls?.matchOddsBaseUrl) {
        const baseUrlsPayload = {
          matchOddsBaseUrl: topicUrls?.matchOddsBaseUrl,
          bookMakerAndFancyBaseUrl: topicUrls?.bookMakerBaseUrl,
          premiumBaseUrl: topicUrls?.premiumBaseUrl,
        };
        checkStompClientSubscriptions(baseUrlsPayload);
      }
      // }, 10000);
      // return () => {
      //   clearInterval(refreshInterval);
      // };
    }
  }, []);

  const getFormattedMaxLimit = (max: number) => {
    const num = Number(max / cFactor);
    const fMax = Number(Math.floor(num / 50) * 50);
    return fMax > 999 ? Number(fMax / 1000).toFixed() + "K" : fMax.toFixed();
  };

  const getFormattedMinLimit = (min: number) => {
    const num = Number(min / cFactor);
    const fMin = Number(Math.ceil(num / 10) * 10);
    return fMin > 999 ? Number(fMin / 1000).toFixed() + "K" : fMin.toFixed();
  };

  const fetchOpenBetsRisk = async (eventId: string) => {
    try {
      if (!eventId || !loggedIn) {
        return;
      }

      const response = await SVLS_API.get(
        "/reports/v2/risk-management/user-risk",
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt_token"),
          },
          params: {
            eventId: eventId,
          },
        }
      );
      setExposureMap(response.data.marketExposureMap);
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   dispatch(updateBookMakerMarkets(bmMData));
  // }, [bmMData]);

  useEffect(() => {
    if (betStatusResponse === null) {
      return;
    }
    switch (betStatusResponse.status) {
      case "IN_PROGRESS": {
        showToast({
          type: "success",
          message: betStatusResponse.message,
        });
        break;
      }
      case "SUCCESS": {
        showToast({
          type: "success",
          message: betStatusResponse.message,
        });

        if (addNewBet) {
          setFetchOpenOrders(moment.now());
        }
        dispatch(fetchBalance());
        break;
      }
      case "FAIL": {
        showToast({
          type: "error",
          message: betStatusResponse.message,
        });
        break;
      }
    }

    setBettingInprogress(false);
    if (bets[0]?.amount) {
      clearExchangeBets();
    }
    clearBetStatusResponse();
  }, [betStatusResponse]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    clearInterval(intervalRef.current);
    betStatus();
  }, [triggerBetStatus]);

  useEffect(() => {
    if (isFirstRenderStartTime.current) {
      isFirstRenderStartTime.current = false;
      return;
    }

    intervalRef.current = setInterval(() => {
      betStatus();
      clearInterval(intervalRef.current);
    }, BET_TIMEOUT);

    return () => clearInterval(intervalRef.current);
  }, [startTime]);

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider: string
  ) => {
    if (loggedIn) {
      navigate(
        `/dc/gamev1.1/${gameName.toLowerCase().replace(/\s+/g, "-")}-${btoa(
          gameId.toString()
        )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
      );
    } else {
      setDialogShow(true);
    }
  };
  return (
    <div className="flex gap-2">
      <section className="flex flex-col pb-12 w-[100%] ">
        {/* {!virtualScorecard
              && scorecardID ? (
            <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={scorecardScript}
            />
          ) : null} */}
        {eventData ? (
          <div className="flex flex-col mt-3 max-md:mt-2 max-md:max-w-full">
            <MatchInfo
              awayTeam={eventData?.awayTeam}
              homeTeam={eventData?.homeTeam}
              openDate={eventData?.openDate}
            />

            {loggedIn &&
            eventData?.status === "IN_PLAY" &&
            !["7", "4339"].includes(eventData.sportId) ? (
              <>
                <div className="w-full bg-[#000000] rounded-t-lg mt-1.5">
                  <div className=" flex">
                    <div className=" bg-primary rounded-tl-lg py-2 pr-4 pl-2 text-[14px] text-white font-medium">
                      <span>{langData?.['scorecard']}</span>
                    </div>
                    <div className=" skew-x-[-25deg] bg-primary -translate-x-[8px] w-4"></div>
                    <div className=" skew-x-[-25deg] bg-primary w-1 -translate-x-1"></div>
                    <div className=" skew-x-[-25deg] bg-primary w-1"></div>
                  </div>
                </div>
                <div>
                  <div className="widgets">
                    <div>
                      <div className="sr-widget sr-widget-1"></div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {eventData?.providerName?.toLowerCase() !== "sportradar" ? (
              <MatchOdds
                exposureMap={exposureMap ? exposureMap : null}
                loggedIn={loggedIn}
                getFormattedMinLimit={getFormattedMinLimit}
                getFormattedMaxLimit={getFormattedMaxLimit}
                eventData={eventData}
                fetchEvent={fetchEvent}
                secondaryMatchOdds={secondaryMatchOdds}
                setBetStartTime={(date) => setStartTime(date)}
                setAddNewBet={(val) => setAddNewBet(val)}
              />
            ) : null}

            {secondaryMarkets?.bookmakers?.length > 0 &&
            secondaryMarkets?.bookmakers[0]?.runners?.length > 0 ? (
              <Bookmaker
                loggedIn={loggedIn}
                getFormattedMinLimit={getFormattedMinLimit}
                getFormattedMaxLimit={getFormattedMaxLimit}
                bmData={bmMData}
                eventData={eventData}
                exposureMap={exposureMap ? exposureMap : null}
                fetchEvent={fetchEvent}
                setBetStartTime={(date) => setStartTime(date)}
                setAddNewBet={(val) => setAddNewBet(val)}
              />
            ) : null}

            {premiumMarkets?.providerName?.toLowerCase() !== "sportradar" &&
              premiumMarkets?.markets?.matchOdds.length > 0 &&
              (premiumMarkets?.sportId === "4" ||
                premiumMarkets?.sportId === "sr:sport:21") &&
              premiumMarkets?.markets?.matchOdds?.filter(
                (market) => market.marketId === "340"
              )?.length > 0 && <MatchWinner />}

            {secondaryMarkets?.fancyMarkets?.length > 0 ||
            seEventData?.markets?.matchOdds?.length > 0 ? (
              <div className="flex flex-col justify-center items-start mt-3.5 max-md:mt-2 text-[14px] text-center bg-white rounded border border-solid border-neutral-200 max-md:pr-5 max-md:max-w-full">
                <div className="flex justify-between">
                  <div
                    className={`px-6 py-2.5 font-semibold ${
                      activeTab === "fancy"
                        ? "bg-amber-400 text-stone-900"
                        : "text-neutral-500"
                    } border border-solid border-neutral-200 cursor-pointer`}
                    onClick={() => setActiveTab("fancy")}
                  >
                    {langData?.['fancy_market']}
                  </div>
                  <div
                    className={`px-6 py-2.5 my-auto font-semibold ${
                      activeTab === "premium"
                        ? "bg-amber-400 text-stone-900"
                        : "text-neutral-500"
                    } cursor-pointer`}
                    onClick={() => setActiveTab("premium")}
                  >
                    {langData?.['premium_market']}
                  </div>
                </div>
              </div>
            ) : null}

            {activeTab === "fancy" &&
            secondaryMarkets?.fancyMarkets?.length > 0 ? (
              <FancyMarket
                loggedIn={loggedIn}
                getFormattedMinLimit={getFormattedMinLimit}
                getFormattedMaxLimit={getFormattedMaxLimit}
                exposureMap={exposureMap ? exposureMap : null}
                fetchEvent={fetchEvent}
              />
            ) : null}
            {activeTab === "premium" &&
            seEventData?.markets?.matchOdds?.length > 0 ? (
              <PremiumMarkets
                loggedIn={loggedIn}
                dreamData={eventData}
                getFormattedMinLimit={getFormattedMinLimit}
                getFormattedMaxLimit={getFormattedMaxLimit}
                setBetStartTime={(date) => setStartTime(date)}
                setAddNewBet={(val) => setAddNewBet(val)}
              />
            ) : null}
          </div>
        ) : null}
      </section>
      {isDesktop ? (
        <SidePanel
          setDialogShow={setDialogShow}
          dialogShow={dialogShow}
          handleGameClick={getGameUrl}
          rgGames={rgGames}
          egGames={egGames}
          eventId={eventData?.eventId}
          providerUrl={backupStreamUrl}
          eventData={eventData}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps) => {
  const selectedEvent = state.catalog.selectedEvent;
  const event = state.catalog.selectedEvent;

  return {
    eventData: getAllMarketsByEvent(
      state.catalog.events,
      state.catalog.selectedEventType?.id,
      state.catalog.selectedCompetition?.id,
      selectedEvent?.id
    ),
    secondaryMarkets: getSecondaryMarketsByEvent(
      state.catalog.secondaryMarketsMap,
      selectedEvent?.id
    ),
    secondaryMatchOdds: getSecondaryMatchOddsByEvent(
      state.catalog.secondaryMatchOddsMap,
      selectedEvent?.id
    ),
    bmMData: getBookmakerMarketsByEvent(
      state.catalog.secondaryMarketsMap,
      selectedEvent?.id
    ),

    seEventData: getPremiumMarkets(
      state.catalog.premiumMarketsMap,
      selectedEvent?.id
    ),
    lineMarkets: getLineMarketsByEvent(
      state.catalog.secondaryMatchOddsMap,
      selectedEvent?.id
    ),
    premiumMarkets: getPremiumMarkets(
      state.catalog.premiumMarketsMap,
      event?.id
    ),
    selectedEventType: state.catalog.selectedEventType,
    selectedEvent: selectedEvent,
    // bets: state.exchBetslip.bets,
    openBets: state.betSlip.openBets,
    // totalOrders: state.exchBetslip.totalOrders,
    loggedIn: state.auth.loggedIn,
    // streamUrl: state.common.streamUrl,
    topicUrls: state?.catalog?.topicUrls,
    houseId: getHouseIdFromToken(),
    parentId: getParentIdFromToken(),
    accountId: sessionStorage.getItem("aid"),
    triggerFetchMarkets: state.catalog.triggerFetchMarkets,
    triggerFetchOrders: state.catalog.triggerFetchOrders,
    triggerBetStatus: state.catalog.triggerBetStatus,
    betStatusResponse: state.betSlip.betStatusResponse,
    betFairWSConnected: state.catalog.betFairWSConnected,
    pushNotifWSConnection: state.catalog.pushNotifWSConnection,
    // alert: state.common.alert,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    // clearExchcngeBets: () => dispatch(clearExchcngeBets()),
    fetchOpenBets: (eventId: string, sportId: string) =>
      dispatch(fetchOpenBets({ eventId, sportId })),
    fetchEvent: (
      sportId: string,
      competitionId: string,
      eventId: string,
      marketTime: string
    ) =>
      dispatch(
        fetchEventMarkets({ sportId, competitionId, eventId, marketTime })
      ),
    fetchPremiummarketsByEventId: (
      providerId: string,
      competitionId: string,
      sportId: string,
      eventId: string,
      marketTime: string
    ) =>
      dispatch(
        fetchPremiummarketsByEventId({
          providerId,
          competitionId,
          sportId,
          eventId,
          marketTime,
        })
      ),
    betStatus: () => dispatch(betStatus()),
    clearExchangeBets: () => dispatch(clearExchangeBets()),
    setBettingInprogress: (val: boolean) => dispatch(setBettingInprogress(val)),
    clearBetStatusResponse: () => dispatch(clearBetStatusResponse()),
    setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
    // clearExchangeBets: () => dispatch(clearExchcngeBets()),
    // setBettingInprogress: (val: boolean) => dispatch(setBettingInprogress(val)),
    // clearBetStatusResponse: () => dispatch(clearBetStatusResponse()),
    showToast: (alert: AlertDTO) => dispatch(showToast(alert)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchAllMarkets);
