import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "white", // Tailwind's bg-white
    borderColor: state.isFocused ? "primary" : "lightgray", // Tailwind's border color classes
    boxShadow: "none", // Tailwind's shadow-none
    borderRadius: "0.375rem", // Tailwind's rounded-md
    fontSize: "1rem", // Tailwind's text-base
    // padding: "0.5rem", // Tailwind's p-2
    "&:hover": {
      borderColor: "#4da8f1", // Tailwind's border-purple-500
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.375rem", // Tailwind's rounded-md
    marginTop: "0.25rem", // Tailwind's mt-1
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "primary" : "white", // Tailwind's bg-violet-500 for selected option
    color: state.isSelected ? "white" : "black", // Tailwind's text-white and text-black
    "&:hover": {
      backgroundColor: "lightgray", // Tailwind's bg-gray-200
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black", // Tailwind's text-black
  }),
};

interface DropDownProps {
  options: any;
  selectedOption: any;
  onChange: (e) => void;
}

const DropDown: React.FC<DropDownProps> = (props) => {
  const { options, selectedOption, onChange } = props;

  return (
      <Select
        menuPosition="fixed"
        menuPlacement="auto"
        styles={customStyles}
        className=" w-full"
        defaultValue={selectedOption}
        onChange={onChange}
        options={options}
      />
  );
};

export default DropDown;
