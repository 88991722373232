import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { langData } from "../../constants/defaultlang";

const initialState = {
    langData: langData
};

const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    setLangData: (state, action) => {
      state.langData = action.payload;
    },
  },
});

export const {
  setLangData
} = cmsSlice.actions;

export default cmsSlice.reducer;
