/**
 * This code was generated by Builder.io.
 */
import React from "react";
import MenuItem from "./MenuItem";
import purple from "../../../assets/purple_bg.png"
import { logout } from '../../../store/auth/authThunk';
import store,{AppDispatch} from "../../../store";
import MyBets from "../../../assets/SettingScreenIcon/mybets.webp"
import deposit from "../../../assets/SettingScreenIcon/deposit.webp"
import withdraw from "../../../assets/SettingScreenIcon/withdraw.webp"
import myWallet from "../../../assets/SettingScreenIcon/mywallet.webp"
import bettingPL from "../../../assets/SettingScreenIcon/bettingPL.webp"
import TurnOver from "../../../assets/SettingScreenIcon/turnover.webp"
import bonus from "../../../assets/SettingScreenIcon/bonus.webp"
import myTransaction from "../../../assets/SettingScreenIcon/myTransaction.webp"
import Account from "../../../assets/SettingScreenIcon/account.webp"
import Stake from "../../../assets/SettingScreenIcon/stake.webp"
import myProfile from "../../../assets/SettingScreenIcon/myProfile.webp"
import { useAppSelector } from "../../../store/storeHooks";
interface MenuItemData {
  icon: string;
  text: string;
  langKey: string;
  path:string;
}

const menuItems: MenuItemData[] = [
  {
    icon: MyBets,
    text: "My Bets",
    langKey: 'my_bets',
    path:"/my-bets"
  },
  {
    icon: deposit,
    text: "Deposit",
    langKey: 'deposit',
    path:"/deposit"
  },
  {
    icon: withdraw,
    text: "Withdraw",
    langKey: 'withdraw',
    path:"/withdraw"
  },
  {
    icon: myWallet,
    text: "My Wallet",
    langKey: 'my_wallet',
    path:"/my-wallet"
  },
  {
    icon: bettingPL,
    text: "Betting P/L",
    langKey: 'betting_profit_and_loss',
    path:"/profit-loss"
  },
  {
    icon: TurnOver,
    text: "Turnover History",
    langKey: 'turnover_history',
    path:"/turnover-history"
  },
  {
    icon: myTransaction,
    text: "Commission Report",
    langKey: 'commission_report',
    path:"/commission_report"
  },
  {
    icon: bonus,
    text: "Bonus Statement",
    langKey: 'bonus_statement',
    path:"/bonus-statement"
  },
  {
    icon: myTransaction,
    text: "My Transaction",
    langKey: 'my_transactions',
    path:"/my-transaction"
  },
  {
    icon: Account,
    text: "Account Statement",
    langKey: 'account_statement',
    path:"/account-statement"
  },
  {
    icon: Stake,
    text: "Stake Settings",
    langKey: 'stake_settings',
    path:"/stake-setting"
  },
  {
    icon: myProfile,
    text: "My Profile",
    langKey: 'my_profile',
    path:"/my-profile"
  },
];
const handleLogout=()=>{
const dispatch = store.dispatch as AppDispatch;
    dispatch(logout());
}
const MenuItems: React.FC = () => {
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="px-2">
      <header style={{backgroundImage:`url(${purple})`}} className="flex h-8 text-white pl-2 bg-no-repeat flex-col justify-center items-start rounded-t-md bg-stone-900 max-md:pr-5 mt-[12px] max-md:max-w-full">
        {langData?.['report_menu']}
      </header>
    <section className="flex flex-col mt-[1px] w-full text-sm font-medium text-black">
      {menuItems.map((item, index) => (
        <MenuItem key={index} icon={item.icon} langKey={item.langKey} text={item.text} path={item.path}/>
      ))}
      <div className="mx-[8px]">
      <button onClick={handleLogout} className="self-center py-3 w-[100%] mt-[21px]  text-base font-medium text-white whitespace-nowrap bg-primary rounded-md">
        {langData?.['logout']}
      </button>
      </div>
    </section>
    </div>
  );
};

export default MenuItems;
