import React, { useEffect, useRef, useState } from "react";
import rg7 from "../../../assets/right_side/rg_casino_war.png";
import rg8 from "../../../assets/right_side/rg_cricket_war.png";
import rg9 from "../../../assets/right_side/rg_dtl_vr.png";
import rg10 from "../../../assets/right_side/rg_high_low_vr.png";
import rg11 from "../../../assets/right_side/rg_teen_patti_vr.png";
import rg12 from "../../../assets/right_side/rg_worli_matka.png";
import pr9 from "../../../assets/providers/Group 1000008150.png";
import pr1 from "../../../assets/providers/Group 1000008151.png";
import pr2 from "../../../assets/providers/Group 1000008152.png";
import pr3 from "../../../assets/providers/Group 1000008153.png";
import pr4 from "../../../assets/providers/Group 1000008154.png";
import pr5 from "../../../assets/providers/Group 1000008155.png";
import pr6 from "../../../assets/providers/Group 1000008156.png";
import pr7 from "../../../assets/providers/Group 1000008157.png";
import pr8 from "../../../assets/providers/Group 1000008158.png";
import { RiArrowRightSLine } from "react-icons/ri";
import BetSlip from "../Betslip/Betslip";
import { Game } from "../LiveCasino/types";
import Modal from "../../Modal/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import { connect } from "react-redux";
import { useAppSelector } from "../../../store/storeHooks";
import { isMobile } from "react-device-detect";
import TVIcon from "../../../assets/market_page/tv.svg";
import LiveStream from "../ExchAllMarkets/LiveStream";
import { EventDTO } from "../../../models/common/EventDTO";

const virtualGaming = [rg7, rg8, rg9, rg10, rg11, rg12];
const providers = [pr1, pr2, pr3, pr4, pr5, pr6, pr7, pr8, pr9];

interface SidePanelProps {
  rgGames: Game[];
  egGames: Game[];
  handleGameClick: Function;
  dialogShow: boolean;
  setDialogShow: Function;
  eventId?: string;
  providerUrl?: string;
  eventData?: EventDTO;
}

const SidePanel: React.FC<SidePanelProps> = (props) => {
  const { rgGames, egGames, handleGameClick, dialogShow, setDialogShow, eventId, providerUrl, eventData } = props;
  const navigate = useNavigate();
  let { bets, openBets } = useAppSelector(state => state.betSlip);
  let { loggedIn } = useAppSelector(state => state.auth);
  let { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const betslipRef = useRef(null);
  const { langData } = useAppSelector((state) => state.cms);

  useEffect(() => {
    if (bets?.length > 0 && betslipRef.current) {
      betslipRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'center', // Scroll so the element is in the center of the view
      });
    }
  }, [bets]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=" overflow-y-auto pt-3 min-w-[280px] max-w-[280px] pr-2 sticky top-3 h-fit ">
      {
        loggedIn && pathname?.split('/')?.length >= 6 && eventId ?
          <div className="h-fit mb-4 border border-neutral-200 rounded-lg bg-white overflow-hidden relative">
            <div className=" flex justify-between h-8 text-nowrap">
              <div className="flex w-full">
                <div className=" bg-primary py-2 pr-4 pl-2 text-[14px] text-white font-medium">
                  {langData?.['live_streaming']}
                </div>
                <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
                <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
                <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
              </div>

            </div>
            {
              eventId ?
                <LiveStream eventID={eventId} providerUrl={providerUrl} />
                : (
                  <div className="flex flex-col items-center justify-center h-[100%] absolute inset-0 p-[14px] ">
                    <div className="flex bg-[#e7e6e6] p-[14px] rounded-[50px] ">
                      <img src={TVIcon} height={24} />
                    </div>
                    <div className="text-[14px] text-[#757575] max-w-[145px] text-center   ">
                      {langData?.['no_available_live_stream']}
                    </div>
                  </div>
                )
            }
          </div>
          : null
      }

      {
        loggedIn &&
          !isMobile &&
          eventData?.eventId &&
          (bets?.length > 0 ||
            openBets.length > 0) ? (
              <div ref={betslipRef} className="betslip-container">
          <BetSlip eventData={eventData} />
          </div>
        ) : null
      }

      <div className="mb-4 border rounded-lg border-neutral-200 overflow-hidden bg-white">
        <div className=" flex justify-between h-8 text-nowrap  ">
          <div className="flex w-full">
            <div className=" bg-primary py-2 flex items-center pr-4 pl-2 text-[14px] text-white font-[600]">
              {langData?.['royal_gaming']}
            </div>
            <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
          </div>
          <div
            onClick={() => navigate("/indian-casino")}
            className=" flex items-center cursor-pointer pr-3 text-neutral-500 text-[12px] font-medium h-[32px]"
          >
            {langData?.['see_more']} <RiArrowRightSLine />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 p-2 ">
          {rgGames?.slice(0, 6)?.map((game, index) => (
            <div className=" rounded-[4px]  overflow-hidden">
              <img
                key={index}
                className="w-full h-auto"
                src={game.urlThumb}
                alt={game.gameName}
                onClick={() =>
                  handleGameClick(
                    game.gameId,
                    game.gameName,
                    game.gameCode,
                    game.subProviderName,
                    game.providerName
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mb-4 border border-neutral-200 rounded-lg bg-white overflow-hidden">
        <div className=" flex justify-between h-8 text-nowrap">
          <div className="flex w-full">
            <div className=" bg-primary py-2 pr-4 pl-2 flex items-center text-[14px] text-white font-[600]">
              {langData?.['live_casino']}
            </div>
            <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
          </div>
          <div
            onClick={() => navigate("/live-casino")}
            className=" flex items-center pr-3 cursor-pointer text-neutral-500 text-[12px] font-medium h-[32px]"
          >
            {langData?.['see_more']} <RiArrowRightSLine />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 p-2">
          {egGames?.slice(0, 10)?.map((game, index) => (
            <div className=" rounded-[4px]  overflow-hidden">
              <img
                key={index}
                className="w-full h-auto"
                src={game.urlThumb}
                alt={game.gameName}
                onClick={() =>
                  handleGameClick(
                    game.gameId,
                    game.gameName,
                    game.gameCode,
                    game.subProviderName,
                    game.providerName
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
      {/* <div className=" border border-neutral-200 rounded-lg bg-white">
        <div className=" flex justify-between">
          <div
            style={{ backgroundImage: `url(${purple})` }}
            className="text-[14px] font-semibold mb-2 pl-2 w-[60%] h-[32px] bg-no-repeat leading-[32px] text-white bg-cover"
          >
            Provider List
          </div>
          <div className=" text-neutral-500 text-[14px] font-semibold mt-2 h-[32px]">
            {"See More >"}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 p-2">
          {providers.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Provider ${index + 1}`}
              className="w-full h-auto aspect-[2.44]"
            />
          ))}
        </div>
      </div> */}

      <Modal
        isOpen={isModalOpen}
        title="Game"
        onClose={closeModal}
      >
        {langData?.['games_access_login_txt']}
      </Modal>

    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    bets: state.betSlip.bets
  };
};

export default connect(mapStateToProps, null)(SidePanel);
