import { connect } from "react-redux";
import { CURRENCY_TYPE_FACTOR } from "../../constants/CurrencyTypeFactor";
import { UserBet } from "../../models/UserBet";
import { RootState } from "../../store";
import { getCurrencyTypeFromToken } from "../../utils/commonUtils";
import { useAppSelector } from "../../store/storeHooks";

type OpenBetsProps = {
    openBets: UserBet[];
};

const getDisplayName = (runnerName: string) => {
    let name =
        runnerName.split(':').length > 1
            ? runnerName.split(':')[1]
            : runnerName.split(':')[0];
    if (name.toLowerCase().includes('crudeoil')) return 'CRUDE OIL';
    else if (name.toLowerCase().includes('gold')) return 'GOLD';
    else if (name.toLowerCase().includes('silver')) return 'SILVER';
    else if (name.toLowerCase().includes('banknifty')) return 'BANK NIFTY';
    return name;
};

const OpenBetsTab: React.FC<OpenBetsProps> = (props) => {

    const { openBets } = props;
    const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
    const { langData } = useAppSelector((state) => state.cms);

    const getBetDisplayName = (bet: UserBet) => {
        switch (bet.marketType) {
            // TODO: remove binary
            case 'FANCY':
            case 'BINARY':
                return (
                    (bet.marketType === 'BINARY'
                        ? getDisplayName(bet.outcomeDesc)
                        : bet.marketName) +
                    ' @ ' +
                    Number(bet.oddValue * 100 - 100).toFixed(0)
                );
            case 'BOOKMAKER':
                return `${bet.marketName.toLowerCase().includes('toss') ? 'Toss' : 'BM'
                    } - ${bet.outcomeDesc}`;
            case 'MATCH_ODDS':
                if (bet.marketName === 'Completed Match') {
                    return 'Completed Match - ' + bet.outcomeDesc;
                } else if (bet.marketName === 'Tied Match') {
                    return 'Tied Match - ' + bet.outcomeDesc;
                } else {
                    return 'MO - ' + bet.outcomeDesc;
                }
            default:
                return bet.marketName + ' - ' + bet.outcomeDesc;
        }
    };

    const getBetOddValue = (bet: UserBet) => {
        return bet.marketType === 'FANCY' || bet.marketType === 'BINARY'
            ? Number(bet.sessionRuns).toFixed(0)
            : bet.marketType === 'BOOKMAKER'
                ? Number(bet.oddValue * 100 - 100).toFixed(2)
                : Number(bet.oddValue);
    };

    const getBetStakeAmount = (bet: UserBet) => Number(bet.stakeAmount / cFactor).toFixed(2);

    return (
        openBets?.length > 0 ? (
            <table className=' text-black w-full text-[14px]'>
                <thead >
                    <tr className=' text-[#757575] text-[14px] font-medium h-[30px]'>
                        <th className=' border-r border-[#e0e0e0] text-left pl-2'>{langData?.['market']}</th>
                        <th className=' border-r border-[#e0e0e0] text-left pl-2'>{langData?.['odds']}</th>
                        <th className=' border-r border-[#e0e0e0] text-left pl-2'>{langData?.['amount']}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        openBets.map((bet, idx) => (
                            <tr key={"row" + bet?.betId} className={` h-[30px] ${idx % 2 ? ' bg-[#A5D9FE] bg-opacity-60' : ' bg-[#F9CFCE] bg-opacity-60 '}`}>
                                <td className=' pl-2 border-r border-white' key={"cell" + bet?.betId + idx}>
                                    {getBetDisplayName(bet)}
                                </td>
                                <td className=' pl-2 border-r border-white' key={"cell" + bet?.betId + idx}>
                                    {getBetOddValue(bet)}
                                </td>
                                <td className=' pl-2 border-r border-white' key={"cell" + bet?.betId + idx}>
                                    {getBetStakeAmount(bet)}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        ) : (
            <div className="flex items-center justify-center text-[14px] text-[#757575] h-full pt-7 pb-6 ">
                {langData?.['place_bet_to_see_it_here']}
            </div>
        )
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        openBets: state.betSlip.openBets,
    };
};

export default connect(mapStateToProps, null)(OpenBetsTab);