import React from 'react';
import { CommissionReportRecord } from '../../models/CommissionReport';
import { CommissionNameMap } from '../../constants/Commission';
import { useAppSelector } from '../../store/storeHooks';

type CommisionProps = {
  records: CommissionReportRecord[];
};

const MarketAggreagtionReport: React.FC<CommisionProps> = (props) => {
  const { records } = props;
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="reports-ctn user-commission-ctn">
      <div className="content-ctn light-bg">
        <div className="balance-history-tbl-ctn">
          <div className="tbl-ctn">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-100 border-b text-[14px]">
                  <tr>
                    <th className="text-left px-6 py-2 font-medium text-gray-700">
                      {langData?.['market']}
                    </th>
                    <th className="text-left px-6 py-2 font-medium text-gray-700">
                      {langData?.['commission_type']}
                    </th>
                    <th className="text-left px-6 py-2 font-medium text-gray-700">
                      {langData?.['percentage']}
                    </th>
                    <th className="text-left px-6 py-2 font-medium text-gray-700">
                      {langData?.['commission_points']}
                    </th>
                    <th className="text-left px-6 py-2 font-medium text-gray-700">
                      {langData?.['status']}
                    </th>
                  </tr>
                </thead>
                {records.length > 0 ? (
                  <tbody>
                    {records.map((row, idx) => (
                      <tr key={idx} className="border-b hover:bg-gray-100 text-[11px] font-medium">
                        <td className="px-6 py-2">
                          {row.marketName}
                        </td>
                        <td className="px-6 py-2">
                          {CommissionNameMap[row.method]}
                        </td>
                        <td className="px-6 py-2">
                          {row.percentage?.toPrecision(2)}
                        </td>
                        <td className="px-6 py-2">
                          {row.commission?.toFixed(2)}
                        </td>
                        <td className="px-6 py-2">
                          {row.status}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="text-center py-4 text-gray-500">
                        {langData?.['records_not_found_txt']}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketAggreagtionReport;
