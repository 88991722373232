import React, { useEffect, ReactNode } from 'react';

interface ModalProps {
  isOpen: boolean;
  title?: string;
  content?: ReactNode;
  onClose: () => void;
  children: React.ReactNode;
  modalBg?: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, title, content, onClose, children, modalBg = "bg-white" }) => {
  
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    } else {
      document.removeEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className={`fixed z-[100]   inset-0 bg-black bg-opacity-50 flex items-center justify-center  `}
      onClick={onClose}
    >
      <div
        className={`${modalBg} rounded-lg md:h-[70%] scrollbar-hide  h-[60%]  overflow-y-scroll  shadow-lg w-11/12  mx-auto px-4 py-3 relative`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex  justify-between items-center border-b pb-3">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button onClick={onClose} className="text-gray-900 hover:text-gray-800">
            <svg
              className="w-6 h-6"
              fill="black"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div className="py-4 overflow-y-scroll h-fit">
            {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
