/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import cut_btn from "../../../../assets/cross.png"
import { useAppSelector } from "../../../../store/storeHooks";

interface TransactionList {
  details: any;
  setActive: any;
}
const AccountDetails: React.FC<TransactionList> = (props) => {
  const { details, setActive } = props;
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <>
      <div className="rounded-md  ">
        <div className="w-[100%] flex px-[8px]  justify-between  items-center pt-[2px]">
          <div className="font-semibold">{langData?.['account_statement']}</div>
          <div onClick={() => setActive(false)}><img src={cut_btn} /></div>
        </div>
        <div className="flex flex-col justify-center  py-px mt-1.5 w-full text-[14px] font-medium rounded-md  text-neutral-500">
          <div className="flex gap-5 justify-between w-full bg-neutral-200">
            <div className="my-auto">{langData?.['event_name']}</div>
            <div className="flex gap-4 whitespace-nowrap">
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
                <div className="my-auto">{langData?.['credit_debit']}</div>
              </div>
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
                <div className="my-auto">{langData?.['transaction']}</div>
              </div>
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
                <div className="my-auto">{langData?.['transaction_id']}</div>
              </div>
            </div>
          </div>


          {
            <div
              className="flex flex-col  px-3 items-center py-0.5 mt-1.5 w-full bg-white border-2  border-solid"
            >
              <div className="flex z-10 gap-5 justify-between w-full max-w-[379px]">
                <div className="flex flex-col self-start mt-3 pl-[10px]">
                  <div className="text-sm font-medium text-stone-900">
                    {details?.eventName}
                  </div>
                  <div className="mt-3 text-[14px] text-black">{details.amount}</div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto">{details?.balanceAfter}</div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto">{details?.transactionId}</div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto">{details?.transactionId}</div>
                </div>
              </div>
              <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
              <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
                <div className="flex flex-col my-auto w-[100%]">
                  <div className="flex gap-1 w-[100%] justify-between">
                    <div className="flex flex-col">
                      <div className="font-medium text-neutral-500">{langData?.['bet_id']}:</div>
                      <div className="font-medium text-neutral-500">{langData?.['bet_on']}:</div>
                    </div>
                    <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                    <div className="flex w-[50%] justify-between">
                      <div className="flex flex-col">
                        <div className="font-medium text-neutral-500">{langData?.['results']}:</div>
                        <div className="font-medium text-neutral-500">{langData?.['bet_type']}:</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          }
        </div>
        <div className="w-[100%] flex justify-center mt-1.5">
          <div className="px-3 w-[93%] bg-[#F2F2F2] mx-[3px]">
            <div className="flex justify-between ">
              <div className="px-3 py-1">{langData?.['back_subtotal']}</div>
              <div className="px-3 py-1">1000</div>
            </div>
            <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
            <div className="flex justify-between ">
              <div className="px-3 py-1">{langData?.['lay_subtotal']}</div>
              <div className="px-3 py-1">1000</div>
            </div>
            <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
            <div className="flex justify-between">
              <div className="px-3 py-1">{langData?.['market_subtotal']}</div>
              <div className="px-3 py-1">1000</div>
            </div>
            <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
            <div className="flex justify-between">
              <div className="px-3 py-1">{langData?.['Commission']}</div>
              <div className="px-3 py-1">1000</div>
            </div>
            <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
            <div className="flex justify-between">
              <div className="px-3 py-1 font-semibold">{langData?.['net_market_total']}</div>
              <div className="px-3 py-1 font-semibold">1000</div>
            </div>
          </div>
        </div>
      </div>
      {details && details.length === 0 && (
        <div className=" text-center">{langData?.['records_not_found_txt']}</div>
      )}
    </>
  );
};

export default AccountDetails;
