/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Filters, TransactionFilters, turnOverReptxTypes } from "../../../store/reports/reportSelectors";
import moment from "moment";
import DropDown from "../DropDown";
import ReactDatePicker from "../ReactDatePicker";
import { useAppSelector } from "../../../store/storeHooks";
interface DatePickerProps {
  onDateChange: Function;
  filters: Filters;
  setFilters: Function;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  filters,
  setFilters
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  const handleFromDateChange = (date) => {
    onDateChange(date, filters.endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(filters.startDate, date);
  };

  const handleTransactionType = (event) => {
    setFilters({ ...filters, transactionType: event.value });
  };

  return (
    <div className="flex w-[100%] gap-3 self-end  mt-[12px] text-[14px] flex-col whitespace-nowrap ">
      <div className="flex flex-col ">
        <div className="grow my-auto capitalize text-neutral-500">
          {langData?.['transaction_type']}
        </div>
        <DropDown options={TransactionFilters} selectedOption={filters?.transactionType === "ALL" ? { value: "-1", label: "All" } : filters?.transactionType} onChange={handleTransactionType} />
      </div>
      <div className="flex w-[100%] justify-between gap-[12px]">
      <div className="flex flex-col w-[50%]  ">
        <div className="grow my-auto capitalize text-neutral-500">{langData?.['from']}</div>
        <ReactDatePicker date={filters.startDate} setDate={handleFromDateChange} />
      </div>
      <div className="flex flex-col w-[50%] ">
        <div className="grow my-auto capitalize text-neutral-500">{langData?.['to']}</div>
        <ReactDatePicker date={filters.endDate} setDate={handleToDateChange} />
      </div>
      </div>
    </div>
  );
};

export default DatePicker;
