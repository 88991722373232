/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import { useAppSelector } from "../../../../../store/storeHooks";

// import purple from '../../../../../assets/purple_bg.png'

const Referral: React.FC = () => {
  const [copiedCode, setCopiedCode] = useState<string | null>(null);
  const { langData } = useAppSelector((state) => state.cms);

  const referralCodes = [
    {
      text: langData?.['referral_bonus'],
      link: "https://indigoexch.com",
    }
  ];

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedCode(text);
      setTimeout(() => setCopiedCode(null), 2000);
    });
  };

  return (
    <div className="flex flex-col pb-20 px-1 w-full rounded-md border bg-white border-solid border-neutral-200 ">
      {/* <div className="flex flex-col justify-center items-start text-[14px] font-medium text-white rounded-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
        <div style={{backgroundImage:`url(${purple})`}} className="flex overflow-hidden relative flex-col px-3 py-2 max-w-full h-[32px] w-[30%] bg-no-repeat  max-md:pr-5">
          My Profile
        </div>
      </div> */}
      <div className="mx-7 mt-6 text-base font-semibold text-center text-stone-900 tracking-[3.52px] max-md:mr-2.5 max-md:max-w-full">
        {langData?.['refer_and_earn_txt']}:
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/03da6f93c206a15f35da1d70618eb2db77ece921275009dc7eb2ba9791ffebf7?apiKey=b24a234f6f1a429faa4b53c4011fa200&&apiKey=b24a234f6f1a429faa4b53c4011fa200"
        className="self-center mt-4 max-w-full aspect-[4.17] w-[184px]"
        alt=""
      />
      <div className="self-center mt-4 text-[#828181] text-sm font-medium leading-5 text-center max-md:max-w-full">
        {langData?.['invite_referral_txt']}
      </div>
      <div className="mx-7 mt-4 text-sm font-medium text-center text-stone-900 max-md:mr-2.5 max-md:max-w-full">
        {langData?.['copy_codes_and_share_txt']}:
      </div>
      <div className="justify-between mx-7 mt-4 max-md:mr-2.5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          {referralCodes.map((code, index) => (
            <div
              key={index}
              className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full"
            >
              <div className="flex flex-col grow justify-center text-sm font-medium text-stone-900 max-md:mt-3 max-md:max-w-full">
                <div className="flex flex-col px-4 py-5 rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:max-w-full">
                  <div className="flex gap-5">
                    <div className=" text-[#828181] flex-auto">{code.text}</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/99db1f41e7922506137d7689aa8cd8c72ccea55863d05f927c9488938e0b32bb?apiKey=b24a234f6f1a429faa4b53c4011fa200&&apiKey=b24a234f6f1a429faa4b53c4011fa200"
                      className="shrink-0 self-start aspect-[0.85] fill-stone-900 w-[17px] cursor-pointer"
                      alt="Copy"
                      onClick={() => copyToClipboard(code.text)}
                    />
                  </div>
                  <div className="mt-6">{code.link}</div>
                  {copiedCode === code.text && (
                    <div className="mt-2 text-green-600">{langData?.['copied']}!</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Referral;
