/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { AccountField, AccountFieldProps } from "./AccountField";
import InputField from "../InputComponents/InputField";
import {
  BankDepositAccountDetailsFormType,
  DEPOSIT_PAYMENT_TYPES,
  DepositReqBankResType,
} from "../utils/types";
import { PaymentOptionTypes } from "../utils/deposit.utils";
import { useAppSelector } from "../../../../../store/storeHooks";

interface AccountDetailsProps {
  bankDepositPaymentDetails: DepositReqBankResType;
  bankDepositAccountDetailsForm: BankDepositAccountDetailsFormType;
  handleBankDetailsFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  bankAccountDetailsSelectedPaymentOpt: (opt: any) => void; // TODO: Add proper type
}

export const AccountDetails: React.FC<AccountDetailsProps> = ({
  bankDepositPaymentDetails,
  bankDepositAccountDetailsForm,
  handleBankDetailsFormChange,
  bankAccountDetailsSelectedPaymentOpt,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  const { payment_method_details } =
    bankDepositPaymentDetails.payment_options.find(
      (option) => option.payment_method === DEPOSIT_PAYMENT_TYPES.BANK
    );

  const {
    bank_account_holder: accountHolderName,
    bank_account_number: accountNumber,
    bank_name: bankName,
    ifs_code: accountIFSCCode,
  } = payment_method_details;

  const accountFields: AccountFieldProps[] = [
    { label: "Account Holder Name:", langKey: "account_holder_name", value: accountHolderName },
    { label: "A Number:", langKey: "account_no", value: accountNumber },
    { label: "Account IFSC Code:", langKey: "ifsc_code", value: accountIFSCCode },
    { label: "Bank Name:", langKey: "bank_name", value: bankName },
  ];

  return (
    <section className="flex flex-col font-medium rounded-none w-full text-stone-900">
      <h2 className="self-start text-xs">{langData?.['account_details']}:</h2>
      <div className="flex flex-wrap gap-5 justify-between p-4 mt-2 w-full rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:max-w-full">
        <div className="flex flex-col max-md:max-w-full">
          {accountFields.map((field, index) => (
            <AccountField key={index} {...field} />
          ))}
        </div>
      </div>
      <div className="mt-4 flex max-md:flex-col w-full">
        <InputField
          label={langData?.['enter_reference_id']}
          placeholder={langData?.['enter_utr_txt']}
          value={bankDepositAccountDetailsForm.bankTxRefId}
          fieldKey={"bankTxRefId"}
          onChange={handleBankDetailsFormChange}
          type={"text"}
        />

        <div className="flex flex-col min-w-[240px] max-md:mt-2 w-full lg:w-[48%] max-md:max-w-full ml-4 max-md:ml-0">
          <label
            htmlFor={"selectedPaymentOption"}
            className="self-start text-[14px] font-medium text-stone-900"
          >
            {langData?.['select_payment_option']}
          </label>

          <select
            className="gap-2.5 self-stretch px-3 py-3.5 mt-2 rounded-md border border-solid bg-zinc-100 border-neutral-200 min-h-[44px] text-[14px]"
            id="selectedPaymentOption"
            value={bankDepositAccountDetailsForm.selectedPaymentOption}
            onChange={(e) =>
              bankAccountDetailsSelectedPaymentOpt(e.target.value)
            }
          >
            <option value="" disabled>
              {langData?.['select_an_option']}
            </option>
            {PaymentOptionTypes.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    </section>
  );
};
