/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Filters, transactionPageStatusFilter } from "../../../store/reports/reportSelectors";
import DropDown from "../../Mobile/DropDown";
import ReactDatePicker from "../../Mobile/ReactDatePicker";
import { useAppSelector } from "../../../store/storeHooks";

interface DatePickerProps {
  onDateChange: Function;
  filters: Filters;
  setFilters: Function;
  TransactionTypes: any;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  filters,
  setFilters,
  TransactionTypes
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  const options = transactionPageStatusFilter.map((opt) => ({
    ...opt,
    label: langData?.[opt.label],
  }));

  const handleFromDateChange = (date) => {
    onDateChange(date, filters.endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(filters.startDate, date);
  };

  const handleTransactionType = (event) => {
    setFilters({ ...filters, transactionType: event.value });
  };

  const handleTransactionStatus = (event) => {
    setFilters({ ...filters, transactionStatus: event.value });
  };

  return (
    <div className="flex gap-3 self-end pl-20 mt-3.5 text-[14px] max-md:pl-5 max-md:mr-2.5">
      <div className="flex gap-2.5">
        <div className="grow my-auto capitalize text-neutral-500">
          {langData?.['transaction_type']}
        </div>
        <div className="w-[200px] ">
        <DropDown
          options={TransactionTypes}
          selectedOption={
            filters?.transactionType === "ALL"
              ? { value: "ALL", label: "All" }
              : filters?.transactionType
          }
          onChange={handleTransactionType}
        />
        </div>
      </div>
      <div className="flex gap-2.5 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">
          {langData?.['transaction_status']}
        </div>
        <div className="w-[200px]">
        <DropDown
          options={options}
          selectedOption={
            filters?.transactionStatus === "ALL"
              ? { value: "ALL", label: "All" }
              : filters?.transactionStatus
          }
          onChange={handleTransactionStatus}
        />
        </div>
      </div>
      <div className="flex gap-2.5 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">{langData?.['from']}</div>
        <div className="flex mt-1 justify-center align-middle">
          <ReactDatePicker date={filters.startDate} setDate={handleFromDateChange} />
        </div>
      </div>
      <div className="flex gap-2.5 mr-2 ml-2">
        <div className="grow my-auto capitalize text-neutral-500">{langData?.['to']}</div>
        <div className="flex mt-1 justify-center align-middle">
          <ReactDatePicker date={filters.endDate} setDate={handleToDateChange} />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
