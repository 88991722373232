/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React, { useEffect } from "react";
import ReactDatePicker from "../../../Mobile/ReactDatePicker";
import { isMobile } from "react-device-detect";
import { useAppSelector } from "../../../../store/storeHooks";

interface DatePickerProps {
  onDateChange: Function;
  startDate: Date;
  endDate: Date;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  startDate,
  endDate,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  const handleFromDateChange = (date) => {
    onDateChange(date, endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(startDate, date);
  };

  return (
    <>
      {isMobile ? (
        <div className="flex gap-3 self-center mt-[12px] text-[14px] font-medium whitespace-nowrap max-w-[406px]">
          <div className="flex flex-col flex-1 ">
            <label htmlFor="fromDate" className=" ml-1 text-neutral-500">
              {langData?.['from']}
            </label>
            <ReactDatePicker date={startDate} setDate={handleFromDateChange} />
          </div>
          <div className="flex flex-col flex-1  ">
            <label htmlFor="toDate" className=" ml-1 text-neutral-500">
              {langData?.['to']}
            </label>
            <ReactDatePicker date={endDate} setDate={handleToDateChange} />
          </div>
        </div>
      ) : (
        <div className="flex gap-3 self-end pl-20 mt-3.5 text-[14px] whitespace-nowrap max-md:flex-wrap max-md:pl-5 max-md:mr-2.5">
          <div className="flex">
            <div className="grow my-auto capitalize text-neutral-500">{langData?.['from']}</div>
            <div className="flex mt-1 ml-1 justify-center align-middle">
              <ReactDatePicker
                date={startDate}
                setDate={handleFromDateChange}
              />
            </div>
          </div>
          <div className="flex">
            <div className="grow my-auto capitalize text-neutral-500">{langData?.['to']}</div>
            <div className="flex mt-1 ml-1 justify-center align-middle">
              <ReactDatePicker date={endDate} setDate={handleToDateChange} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DatePicker;
