/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { maskAccountNumber } from "../../../utils/commonUtils";
import { PaymentMethodObjType } from "../../../store/withdraw/withdrawSlice";
import { useAppSelector } from "../../../store/storeHooks";

interface PaymentOptionsProps {
  onSavedAccountCardClick: (account: PaymentMethodObjType) => void;
  paymentMethodList: PaymentMethodObjType[];
  onAddNewAccountPress: () => void;
  onAccountDeleteClick: (account: PaymentMethodObjType) => void;
}

const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  paymentMethodList,
  onSavedAccountCardClick,
  onAddNewAccountPress,
  onAccountDeleteClick,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <section>
      <div className="mt-3 w-full h-40">
        <div className="flex gap-5 max-md:flex-col w-full h-full">
          {paymentMethodList && (
            <div
              style={{ msOverflowStyle: "none", scrollbarWidth: "none" }}
              className="flex gap-5 max-md:flex-col max-w-[800px] overflow-scroll h-full"
            >
              {paymentMethodList.length > 0 &&
                paymentMethodList.map((currentPaymentMethod) => {
                  const {
                    displayName,
                    id,
                    paymentMethod,
                    paymentMethodDetails: {
                      accountHolderName,
                      accountNumber,
                      bankName,
                      branchName,
                      ifscCode,
                      upiId,
                    },
                  } = currentPaymentMethod;
                  return (
                    <div
                      key={id}
                      className="flex flex-col w-[280px] max-md:ml-0 max-md:w-full cursor-pointer"
                      onClick={() =>
                        onSavedAccountCardClick(currentPaymentMethod)
                      }
                    >
                      <div className="flex flex-col justify-center text-sm font-semibold text-stone-900 max-md:mt-3">
                        <div className="flex flex-col justify-center bg-purple-200 rounded-xl">
                          <div className="flex overflow-hidden relative flex-col items-end px-4 py-5 w-full aspect-[1.78]">
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1b844481649020af97c217c7b9f8a05e5502c6cd7a9851769185ddf4fc7a7160?apiKey=b24a234f6f1a429faa4b53c4011fa200&&apiKey=b24a234f6f1a429faa4b53c4011fa200"
                              className="object-cover absolute inset-0 size-full"
                              alt=""
                            />
                            <div className="flex relative gap-5 w-64 max-w-full whitespace-nowrap tracking-[3.36px]">
                              <div className="flex-auto my-auto">
                                {displayName}
                              </div>
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onAccountDeleteClick(currentPaymentMethod); // TODO: Move all these inline functions to parent component (Keep these common for both mobile and web)
                                }}
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ee56629a8aaba396ec19c688f86c64a7ef9e0617e346768ae1229716f4c0aa94?apiKey=b24a234f6f1a429faa4b53c4011fa200&&apiKey=b24a234f6f1a429faa4b53c4011fa200"
                                className="shrink-0 w-6 aspect-square"
                                alt=""
                              />
                            </div>
                            <div className="relative self-stretch mt-10 text-2xl tracking-[8.36px]">
                              {maskAccountNumber(accountNumber)}
                            </div>
                            <div className="relative font-medium max-md:mt-10">
                              {accountHolderName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}

          <div className="flex flex-col ml-5 w-60 h-full mr-2">
            <div className="flex flex-col grow justify-center font-medium text-stone-900 max-md:mt-3 h-full">
              <div
                onClick={onAddNewAccountPress}
                className="cursor-pointer flex justify-center items-center rounded-xl border border-dashed border-neutral-200 h-full"
              >
                <div className="flex flex-col items-center">
                  <div className="px-4 py-1 text-3xl whitespace-nowrap bg-zinc-100 h-[51px] rounded-[1000px] w-[51px]">
                    +
                  </div>
                  <div className="mt-3.5 text-sm">{langData?.['add_new_account']}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentOptions;
