/**
 * This code was generated by Builder.io.
 */
import React from "react";

import telegram from '../../../assets/telegram.svg'
import whatsapp from '../../../assets/whatsapp.svg'
import linkedin from '../../../assets/linkedin.svg'
import { useAppSelector } from "../../../store/storeHooks";

interface SocialLink {
  icon: string;
  text: string;
  enable:boolean
}

const SocialLinks: React.FC = () => {
  const { langData } = useAppSelector((state) => state.cms);
  const { domainConfig } = useAppSelector(state => state.common);
  const socialLinks: SocialLink[] = [
    { icon: telegram, text: "telegram" ,enable:domainConfig?.telegram},
    { icon: whatsapp, text: "whats_app",enable:domainConfig?.whatsapp },
    { icon: linkedin, text: "linked_in",enable:true },
  ];

  return (
    <>
      <div className="mt-6 text-[14px] font-semibold tracking-widest text-neutral-500">
        {langData?.['follow_us_on']}
      </div>
      {socialLinks?.filter(link => link.enable) 
      .map((link, index, filteredLinks) => (
        <React.Fragment key={index}>
          <div
            className={`flex font-[600] items-center gap-3.5 mt-3 text-[14px] whitespace-nowrap`}
          >
            <img src={link.icon} alt="" className="w-5 h-5" />
            <span>{langData?.[link.text]}</span>
          </div>
          {index < filteredLinks.length - 1 && (
            <div className="mt-1.5 h-px bg-zinc-100" />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default SocialLinks;
