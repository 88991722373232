/**
 * This code was generated by Builder.io.
 */
import React, { useCallback, useEffect, useRef, useState } from "react";

import moment from "moment";
import { connect } from "react-redux";
import API from "../../../api";
import { CURRENCY_TYPE_FACTOR } from "../../../constants/CurrencyTypeFactor";
import { PlaceBetRequest } from "../../../models/BsData";
import { EventDTO } from "../../../models/common/EventDTO";
import { FancyMarketDTO } from "../../../models/common/FancyMarketDTO";
import { UserBet } from "../../../models/UserBet";
import { RootState } from "../../../store";
import { addExchangeBet } from "../../../store/betSlip/betSlipThunk";
import { CiCircleAlert } from "react-icons/ci";
import {
  getAllMarketsByEvent,
  getFancyMarketsByEvent,
  isFancyMarketSuspended,
} from "../../../store/catalog/catalogSelectors";
import {
  getCurrencyTypeFromToken,
  ThousandFormatter,
} from "../../../utils/commonUtils";
import MarketBtn from "../MarketComponents/MarketBtn/MarketBtn";
import { OddsInfoMsg } from "./Bookmaker";
import { IoIosArrowUp } from "react-icons/io";
import Modal from "../../Modal/Modal";
import FancyBookView from "./FancyBookView";
import { isMobile } from "react-device-detect";
import Betslip from "../Betslip/Betslip";
import { useAppSelector } from "../../../store/storeHooks";

interface MarketData {
  team: string;
  book: string;
  odds: string;
  min: number;
  max: number;
};

interface FMTableProps {
  eventData: EventDTO;
  fmData: FancyMarketDTO[];
  openBets: UserBet[];
  commissionEnabled: boolean;
  addExchangeBet: (data: PlaceBetRequest) => void;
  loggedIn: boolean;
  getFormattedMinLimit: (num: number) => string;
  getFormattedMaxLimit: (num: number) => string;
  bets: PlaceBetRequest[];
  exposureMap: any;
  fancySuspended: boolean;
  fetchEvent: (
    sportId: string,
    competitionId: string,
    eventId: string,
    marketTime: string
  ) => void;
};

const FancyMarket: React.FC<FMTableProps> = (props) => {
  const {
    eventData,
    fmData,
    bets,
    openBets,
    commissionEnabled,
    addExchangeBet,
    loggedIn,
    exposureMap,
    fancySuspended,
    fetchEvent
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { langData } = useAppSelector((state) => state.cms);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const disabledStatus = ['suspended', 'suspended-manually', 'ball_running'];
  const [showBooksModal, setShowBooksModal] = useState<boolean>(false);
  const [fancyBookOutcomeId, setFancyBookOutcomeId] = useState<string>();
  const [fancyBookOutcomeName, setFancyBookOutcomeName] = useState<string>();
  const [fancyCategories, setFancyCategories] = useState<Set<string>>(new Set());
  const fancyCategoriesOrder = [
    { fancyCategory: "All", label: "ALL" },
    { fancyCategory: "OVERS", label: "SESSIONS" },
    { fancyCategory: "BATSMAN", label: "W/P MARKET" },
    { fancyCategory: "SINGLE_OVER", label: "ONLY OVER" },
    { fancyCategory: "BALL_BY_BALL_SESSION", label: "BALL BY BALL" },
    { fancyCategory: "ODD_EVEN", label: "ODD/EVEN" },
    { fancyCategory: "THREE_SELECTIONS", label: "XTRA MARKET" },
  ]
  const [tabVal, setTabVal] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<string>('');
  const [infoDilalog, setInfoDialog] = useState<OddsInfoMsg>({
    launch: false,
    oddsType: null,
    eventTypeID: null,
  });
  // todo : remove all market limits usage and commented code if limits is working fine
  const [marketLimits, setMarketLimits] = useState<any>({});
  const [filteredFancyMarketsData, setFilteredFancyMarketsData] = useState<FancyMarketDTO[]>([]);

  const fetchBetLimits = async (marketId: string, localMCategory: string) => {
    try {
      const payload = {
        competitionId: eventData.competitionId,
        eventId: eventData.eventId,
        marketId: marketId,
        marketType: 'FANCY',
        outcomeDesc: 'fancy',
        sessionId: marketId,
        sportId: eventData.sportId,
        mcategory: localMCategory,
      };

      let response;
      if (sessionStorage.getItem('jwt_token')) {
        response = await API.post('/bs/fetch-market-limits', payload, {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        });
      } else {
        response = await API.post('/bs/fetch-market-limits', payload);
      }
      if (response.status === 200 && response.data.success) {

        let limits = marketLimits;

        limits[marketId ? marketId : eventData.marketId] = {
          minStake: response.data.limits.minStake,
          maxStake: response.data.limits.maxStake,
          maxOdd: response.data.limits.maxOdd,
        };

        setMarketLimits(marketLimits => {
          return {
            ...marketLimits,
            ...limits
          }
        });

      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let localFancyCategories = new Set<string>();
    for (const fm of fmData) {
      if (fm?.marketId && !fm?.marketLimits && !marketLimits[fm?.marketId]) {
        //setting a default value and the calling the fetch bet limits 
        //so that fetch market limits is not multiple times for a single market
        marketLimits[fm?.marketId] = {
          minStake: 100,
          maxStake: 100,
          maxOdd: 4
        }
        fetchBetLimits(fm?.marketId, fm?.category);
      }
      localFancyCategories.add(fm.category);
    }
    fmData.map((fm) => localFancyCategories.add(fm.category))
    setFancyCategories(localFancyCategories);
  }, [fmData])

  const handletabs = async (localFancyCategory: string) => {
    setFilteredFancyMarketsData(localFancyCategory === "All" ? fmData : fmData.filter(fm => { return fm.category === localFancyCategory }));
  }

  useEffect(() => {
    handletabs('All')
  }, []);

  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const getFancyMarketsByGroup = (category: string) => {
    return fmData
      .filter((fm) => fm.category === category)
      .sort((a, b) => {
        if (a?.sort - b?.sort != 0) {
          return a?.sort - b?.sort;
        }
        const aDesc = a.marketName;
        const bDesc = b.marketName;
        if (aDesc > bDesc) return 1;
        else if (aDesc < bDesc) return -1;
        return 0;
      });
  };

  return (
    <div className="flex flex-col py-px mt-2 rounded-md border-solid border-neutral-200 max-md:max-w-full">
      <div className="flex h-[30px] flex-col bg-white">
        <div className="flex flex-row w-full border-b border-gray-300">
          {fancyCategories.size > 0 ? (
            <span
              className={`py-2 border border-neutral-200 px-4 text-[12px] leading-[14.88px] font-semibold cursor-pointer ${tabVal === 0
                ? "sel-tab bg-[#86198F] text-white"
                : "ind-tab bg-gray-100 text-gray-700"
                }`}
              onClick={() => {
                handletabs("All");
                setTabVal(0);
              }}
            >
              {langData?.['all']}
            </span>
          ) : null}
          {fancyCategoriesOrder.map((fc, index) => {
            return fancyCategories.has(fc.fancyCategory) ? (
              <>
              <span
                key={index}
                className={`py-2 border border-neutral-200 px-4 text-[12px] max-md:text-[11px] max-md:leading-3 max-md:px-2 text-nowrap leading-[14.88px] font-semibold cursor-pointer ${tabVal === index + 1
                  ? "sel-tab bg-[#86198F] text-white"
                  : "ind-tab bg-gray-100 text-gray-700"
                  }`}
                onClick={() => {
                  setTabVal(index + 1);
                  handletabs(fc.fancyCategory);
                }}
              >
                {fc.label}
              </span>
              {/* <div><CiCircleAlert /></div> */}
              </>
            ) : null;
          })}
        </div>
      </div>

      <table className="mt-2 bg-white">
        <tbody className=" px-3 text-[14px]">
          {filteredFancyMarketsData && filteredFancyMarketsData.length > 0 ? (
            <>
              {fancyCategoriesOrder.map((group) => (
                <>
                  {filteredFancyMarketsData.filter((fm) => fm.category === group.fancyCategory && fancyCategories.has(group.fancyCategory)
                  ).length > 0 ? (
                    <>
                        <FancyHeaderRow groupName={group.label} />
                      {getFancyMarketsByGroup(group.fancyCategory).map(
                        (fMarket, index) => (
                          <FancyMarketRow
                            eventData={eventData}
                            fMarket={fMarket}
                            index={index}
                            cFactor={cFactor}
                            loggedIn={loggedIn}
                            openBets={openBets}
                            disabledStatus={disabledStatus}
                            addExchangeBet={addExchangeBet}
                            setShowBooksModal={() => {
                              setFancyBookOutcomeId(fMarket.marketId);
                              setFancyBookOutcomeName(fMarket.marketName);
                              openModal();
                            }}
                            outcomeOpenBets={openBets.filter(
                              (b) =>
                                b.marketType === 2 &&
                                b.outcomeId === fMarket.marketId
                            )}
                            exposureMap={exposureMap}
                            bets={bets}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                            // fetchBetLimits={(mId, mcategory) => fetchBetLimits(mId, mcategory)}
                            minStake={
                              fMarket?.marketLimits?.minStake
                                ? fMarket?.marketLimits?.minStake
                                : marketLimits[fMarket?.marketId]?.minStake
                            }
                            maxStake={
                              fMarket?.marketLimits?.maxStake
                                ? fMarket?.marketLimits?.maxStake
                                : marketLimits[fMarket?.marketId]?.maxStake
                            }
                            oddLimit={
                              fMarket?.marketLimits?.maxOdd?.toString()
                                ? fMarket?.marketLimits?.maxOdd?.toString()
                                : marketLimits[fMarket?.marketId]?.maxOdd
                            }
                            commissionEnabled={commissionEnabled}
                            fancySuspended={fancySuspended}
                            openModal={openModal}
                          />
                        )
                      )}
                      </>
                  ) : null}
                </>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={12}>
                <div className="fm-table-msg-text">
                  {langData?.['fancy_markets_not_found_txt']}
                </div>
              </td>
            </tr>
          )}

          {/* // {activeTab === "fancy" &&
          //   fancyMarketData.map((data, index) => (
          //     <React.Fragment key={index}>{renderFancyMarketRow(data)}</React.Fragment>
          //   ))} */}
        </tbody>
      </table>

      {/* {activeTab === "premium" &&
        premiumMarketData.map((data, index) => (
          <React.Fragment key={index}>
            {renderPremiumMarketRow(data)}
          </React.Fragment>
        ))} */}
      <Modal
        isOpen={isModalOpen}
        title={langData?.['book_list']}
        // content={<p>This is the content of the modal.</p>}
        onClose={closeModal}
      >
        <FancyBookView fancyBookOutcomeId={fancyBookOutcomeId} exposureMap={exposureMap &&
          exposureMap && exposureMap[
          `${fancyBookOutcomeId}:${fancyBookOutcomeName}`
          ] ? exposureMap[
        `${fancyBookOutcomeId}:${fancyBookOutcomeName}`] : {}} />
      </Modal>
    </div>
  );
};

type FancyHeaderRowProps = {
  groupName: string;
  className?: string;
};

const FancyHeaderRow: React.FC<FancyHeaderRowProps> = (props) => {
  const { groupName, className } = props;

  const tableFields = [
    {
      key: "groupName",
      Label: groupName,
      className: " py-2 px-3 market-name-cell-head",
      align: "left",
    },
    {
      key: "odds-no",
      Label: "",
      className: " py-2 px-3 odds-cell-head book-btn-cell",
      align: "center",
    },
    {
      key: "odds-no",
      Label: "No",
      className: " py-2 px-3 odds-cell-head odds-no-cell",
      align: "center",
    },
    {
      key: "odds-yes",
      Label: "Yes",
      className: " py-2 px-3 odds-cell-head odds-yes-cell",
      align: "center",
    },
    // {
    //   key: "limits",
    //   Label: "",
    //   className: " py-2 px-3 odds-cell-head limits-cell",
    //   align: "center",
    // },
  ];

  return (
    <tr className=" text-[14px] font-medium bg-black text-white justify-between">
      {tableFields.map((tF, index) => (
        <td
          key={tF.key + index}
          align={tF.align === "left" ? "left" : "center"}
          className={tF.className + " relative "}
        >
          {
            tF.key === "groupName"? (
            <div className={tF.key.toLowerCase() + "-cell capitalize "}>
              <div className={`absolute left-0 top-0 bottom-0 bg-primary h-9 ${isMobile ? 'w-[50%]' : 'w-[25%]'}`}></div>
              <div className={`absolute ${isMobile ? 'left-[46%] skew-x-[-20deg] w-[15px]' : 'left-[22%] skew-x-[-27deg] w-[40px]'} top-0  bg-primary h-9 `}></div>
              <div className={`absolute ${isMobile ? 'left-[52%] skew-x-[5deg]' : 'left-[27.5%]  skew-x-[-5deg]'} top-0 header-slanting-line bg-primary h-9 w-[20px]`}></div>
              <div className={`absolute ${isMobile ? 'left-[56.5%] skew-x-[5deg]' : 'left-[28.5%]  skew-x-[-5deg]'} top-0 header-slanting-line bg-primary h-9 w-[20px]`}></div>
              {/* <div className="absolute left-[48%] top-0 header-slanting-line bg-primary h-full w-[10px]"></div> */}
              <span className=" relative z-10 ">{tF.Label?.toLowerCase()}</span>
            </div>
          ) : null}
        </td>
      ))}
    </tr>
  );
};

interface FancyRowProps {
  eventData: EventDTO;
  fMarket: FancyMarketDTO;
  index: number;
  cFactor: number;
  loggedIn: boolean;
  disabledStatus: string[];
  addExchangeBet: (data: PlaceBetRequest) => void;
  setShowBooksModal: () => void;
  outcomeOpenBets: UserBet[];
  bets: PlaceBetRequest[];
  selectedRow: string;
  setSelectedRow: (data) => void;
  openBets: UserBet[];
  exposureMap: any;
  // fetchBetLimits: (mId, mcategory) => void;
  maxStake: number;
  minStake: number;
  oddLimit: string;
  commissionEnabled: boolean;
  fancySuspended: boolean;
  openModal: Function;
}

const FancyMarketRow: React.FC<FancyRowProps> = (props) => {
  const {
    eventData,
    fMarket,
    index,
    cFactor,
    loggedIn,
    disabledStatus,
    addExchangeBet,
    setShowBooksModal,
    outcomeOpenBets,
    bets,
    selectedRow,
    setSelectedRow,
    openBets,
    exposureMap,
    // fetchBetLimits,
    minStake,
    maxStake,
    oddLimit,
    commissionEnabled,
    fancySuspended,
    openModal
  } = props;

  const isFancySuspended = (fMarketSuspended: boolean) => {
    return fancySuspended === true ? true : fMarketSuspended;
  };
  const betslipRef = useRef(null);
  const { langData } = useAppSelector((state) => state.cms);

  useEffect(() => {
    if (bets?.length > 0 && betslipRef.current) {
      betslipRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'center', // Scroll so the element is in the center of the view
      });
    }
  }, [bets]);

  return (
    <>
     {bets?.length > 0 && bets?.[0]?.marketName === fMarket?.marketName &&
      bets?.[0]?.marketId === fMarket?.marketId &&
      isMobile ? (
      <tr className=" inline-betslip ">
        <td colSpan={12}>
        <div ref={betslipRef} className="betslip-container">
          {" "}
          <Betslip eventData={eventData} />{" "}
          </div>
        </td>
      </tr>
    ) : null}
    <tr className=" text-[14px] relative w-full h-[41px] border-b border-[#e0e0e0] align-middle">
      <td className="w-[65%] max-md:w-[50%] font-medium text-stone-900 pl-3">
        {fMarket?.marketName}
      </td>
      <td className=" w-[10%] font-semibold text-white">
        <div className="flex justify-end">
        <button
          onClick={() => {
            if (exposureMap &&
              exposureMap[
              `${fMarket.marketId}:${fMarket.marketName}`
              ]) setShowBooksModal();
          }}
          className=" text-center w-[57px] max-md:w-fit max-md:px-[3px] max-md:h-fit  leading-[24px] h-[24px] whitespace-nowrap bg-fuchsia-800 rounded cursor-pointer"
          disabled={!(exposureMap &&
            exposureMap[
            `${fMarket.marketId}:${fMarket.marketName}`
            ])}>
          {langData?.['book']}
        </button>
        </div>
        
      </td>
      <td className=" w-[15%] border-r text-center relative ">
        <div className="flex m-[5px] rounded overflow-hidden justify-end ">
          <MarketBtn
            mainValue={fMarket.layPrice}
            mainValueClass="runs"
            subValue={fMarket.laySize}
            subValueClass="odds"
            oddType="lay-odd"
            valueType="fancyMarketOdds"
            disable={
              disabledStatus.includes(fMarket.status.toLowerCase()) ||
              isFancySuspended(fMarket.suspend) === true ||
              moment(eventData.openDate).diff(moment(), "minutes") > 60 * 24 * 2
            }
            onClick={() => {
              if (
                !disabledStatus.includes(fMarket.status.toLowerCase()) ||
                isFancySuspended(fMarket.suspend) === true
              ) {
                setSelectedRow(fMarket.marketName + "FM");
                addExchangeBet({
                  sportId: eventData.sportId,
                  seriesId: eventData.competitionId,
                  seriesName: eventData.competitionName,
                  eventId: eventData.eventId,
                  eventName: eventData.eventName,
                  eventDate: eventData.openDate,
                  marketId: fMarket.marketId,
                  marketName: fMarket.marketName,
                  marketType: "FANCY",
                  outcomeId: fMarket.marketId,
                  outcomeDesc: "@ " + fMarket.layPrice,
                  betType: "LAY",
                  amount: 0,
                  oddValue: fMarket.laySize,
                  sessionPrice: fMarket.layPrice,
                  oddLimt: oddLimit,
                  minStake: minStake,
                  maxStake: maxStake,
                  mcategory: fMarket.category,
                });
              }
            }}
          />
          <div className=" w-[1px] bg-white h-full"></div>
          <MarketBtn
            mainValue={fMarket.backPrice}
            mainValueClass="runs"
            subValue={fMarket.backSize}
            subValueClass="odds"
            oddType="back-odd"
            valueType="fancyMarketOdds"
            disable={
              disabledStatus.includes(fMarket.status.toLowerCase()) ||
              isFancySuspended(fMarket.suspend) === true ||
              moment(eventData.openDate).diff(moment(), 'minutes') > 60 * 24 * 2
            }
            onClick={() => {
              if (!disabledStatus.includes(fMarket.status.toLowerCase()) ||
                isFancySuspended(fMarket.suspend) === true) {
                setSelectedRow(fMarket.marketName + 'FM');
                addExchangeBet({
                  sportId: eventData.sportId,
                  seriesId: eventData.competitionId,
                  seriesName: eventData.competitionName,
                  eventId: eventData.eventId,
                  eventName: eventData.eventName,
                  eventDate: eventData.openDate,
                  marketId: fMarket.marketId,
                  marketName: fMarket.marketName,
                  marketType: 'FANCY',
                  outcomeId: fMarket.marketId,
                  outcomeDesc: '@ ' + fMarket.backPrice,
                  betType: 'BACK',
                  amount: 0,
                  oddValue: fMarket.backSize,
                  sessionPrice: fMarket.backPrice,
                  oddLimt: oddLimit,
                  minStake: minStake,
                  maxStake: maxStake,
                  mcategory: fMarket.category,
                });
              }
            }}
          />
        </div>
        {disabledStatus.includes(fMarket.status.toLowerCase()) || 
        isFancySuspended(fMarket.suspend) === true ? (
        <div className=" absolute suspended-market-info text-center right-2 text-[12px] text-nowrap font-semibold top-3 text-[#c22222] " key={'row-' + index + 'cell-5'}>
          {fMarket.status.toLowerCase().includes('suspended') || isFancySuspended(fMarket.suspend) === true
            ? 'SUSPENDED'
            : fMarket.status.replace('_', ' ')}
        </div>
      ) : null}
      </td>
      <td className=" w-[10%] max-md:w-[15%] text-neutral-500 pr-3 text-[10px] text-center ">
        <div>
          {langData?.['min']}:{" "}
          {minStake
            ? ThousandFormatter(minStake ? minStake / cFactor : 100)
            : 0}
        </div>
        <div>
          {langData?.['max']}:{" "}
          {maxStake
            ? maxStake % 1000 === 0
              ? ThousandFormatter(maxStake ? maxStake / cFactor : 100)
              : maxStake
                ? maxStake / cFactor
                : 100
            : 0}
        </div>
      </td>
    </tr>
   
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const eventType = state.catalog.selectedEventType;
  const competition = state.catalog.selectedCompetition;
  const event = state.catalog.selectedEvent;
  return {
    eventData: getAllMarketsByEvent(
      state.catalog.events,
      eventType.id,
      competition.id,
      event.id
    ),
    fmData: getFancyMarketsByEvent(state.catalog.secondaryMarketsMap, event.id),
    fancySuspended: isFancyMarketSuspended(
      state.catalog.secondaryMarketsMap,
      event.id
    ),
    bets: state.betSlip.bets,
    openBets: state.betSlip.openBets,
    commissionEnabled: state.common.commissionEnabled,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    addExchangeBet: (data: PlaceBetRequest) => dispatch(addExchangeBet(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FancyMarket);
