/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import BonusStatementTable from "./BonusStatementTable";

import purple from '../../../assets/purple_bg.png'
import { RootState } from "../../../store";
import { connect } from "react-redux";
import { defaultFilters, Filters } from "../../../store/reports/reportSelectors";
import { fetchRecords, getBonusData } from "../../../store/reports/reportsThunk";
import { Currency } from "../../../models/Currency";
import moment from "moment";
import { useAppSelector } from "../../../store/storeHooks";

interface BonusStatementProps {
  records: any;
  getBonusData: any;
  filters: any;
  handleDateChange: Function;
  setFilters: any;
}

const BonusStatement: React.FC<BonusStatementProps> = (props) => {
  const { records, getBonusData, handleDateChange, filters } = props;
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex flex-col bg-zinc-100">
      <div className="w-full max-md:max-w-full ">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-full">
            <div className="flex flex-col py-px w-full text-[14px] font-medium bg-white rounded-lg overflow-hidden border border-solid border-neutral-200 max-md:mt-6">
            <div  className="flex bg-stone-900 w-full">
                  <div className=" bg-primary py-2 pr-4 pl-2 text-[14px] text-white font-medium">
                    {langData?.['bonus_statement']}
                  </div>
                  <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
                  <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
                  <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
              </div>
              <DatePicker onDateChange={handleDateChange} startDate={filters.startDate} endDate={filters.endDate} />
              <BonusStatementTable records={records} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusStatement;
// const mapStateToProps = (state: RootState) => {
//   return {
//     records: state.reports.records,
//   };
// };

// const mapDispatchToProps = (dispatch: Function) => {
//   return {
//     getBonusData: (event: any) => dispatch(getBonusData(event)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(BonusStatement);