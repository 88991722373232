/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import MatchSection from "./MatchSection";
import Footer from "./Footer";
import { PlaceBetRequest } from "../../../models/BsData";
import { UserBet } from "../../../models/UserBet";
import { checkStompClientSubscriptions, connectToWS, disconnectToWS, isConnected, subscribeWsForEventOdds, subscribeWsForSecondaryMarkets, subscribeWsForSecondaryMatchOdds, unsubscribeAllWsforEvents } from "../../../webSocket/websocket";
import { getCurrencyTypeFromToken } from "../../../utils/commonUtils";
import { CURRENCY_TYPE_FACTOR } from "../../../constants/CurrencyTypeFactor";
import MatchOdds from "../../Desktop/ExchAllMarkets/MatchOdds";
import { fetchEventMarkets } from "../../../store/catalog/catalogThunk";
import { connect } from "react-redux";
import Bookmaker from "../../Desktop/ExchAllMarkets/Bookmaker";

interface MultiMarketProps {
  key: string;
  loggedIn: boolean;
  eventData: any;
  secondaryMatchOdds: any;
  secondaryMarkets: any;
  bmMData: any;
  eventId: string;
  isMultiMarket: boolean;
  exposureMap: any;
  topicUrls: any;
  fetchEvent: (
    sportId: string,
    competitionId: string,
    eventId: string,
    marketTime: string
  ) => void;
};

const MultiMarket: React.FC<MultiMarketProps> = (props) => {
  const {
    loggedIn,
    eventData,
    secondaryMatchOdds,
    secondaryMarkets,
    eventId,
    bmMData,
    isMultiMarket,
    exposureMap,
    key,
    topicUrls,
    fetchEvent
  } = props;

  const [cFactor, setCFactor] = useState<number>(
    CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()]
  );
  const [showBooksModal, setShowBooksModal] = useState<boolean>(false);
  const [matchOddsBaseUrl, setMatchOddsBaseUrl] = useState<string>('');
  const [matchOddsTopic, setMatchOddsTopic] = useState<string>('');
  const [bookMakerBaseUrl, setBookMakerBaseUrl] = useState<string>('');
  const [bookMakerTopic, setBookMakerTopic] = useState<string>('');

  const updateMatchOddsTopic = (currentTopic, currentBaseUrl) => {
    if (matchOddsTopic !== currentTopic || matchOddsBaseUrl !== currentBaseUrl) {
      disconnectToWS();
      setMatchOddsTopic(currentTopic);
      setMatchOddsBaseUrl(currentBaseUrl);
    }
  }

  const updateBookMakerTopic = (currentTopic, currentBaseUrl) => {
    if (bookMakerTopic !== currentTopic || bookMakerBaseUrl !== currentBaseUrl) {
      disconnectToWS();
      setBookMakerTopic(currentTopic);
      setBookMakerBaseUrl(currentBaseUrl);
    }
  }

  useEffect(() => {
    unsubscribeAllWsforEvents();
  }, []);

  // Websocket handler
  useEffect(() => {
    if (loggedIn && topicUrls?.matchOddsBaseUrl) {
      if (isConnected()) {
        disconnectToWS();
      }
      const baseUrlsPayload = {
        matchOddsBaseUrl: topicUrls?.matchOddsBaseUrl,
        bookMakerAndFancyBaseUrl: topicUrls?.bookMakerBaseUrl,
        premiumBaseUrl: topicUrls?.premiumBaseUrl
      }
      connectToWS(baseUrlsPayload);

      return () => {
        if (isConnected()) {
          disconnectToWS();
        }
      };
    }
  }, [loggedIn, topicUrls]);

  useEffect(() => {
    if (loggedIn && eventData && topicUrls?.matchOddsTopic) {
      setCFactor(CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()]);
      updateMatchOddsTopic(topicUrls?.matchOddsTopic, topicUrls?.matchOddsBaseUrl);
      subscribeWsForEventOdds(
        topicUrls?.matchOddsTopic,
        eventData.sportId,
        eventData.competitionId,
        eventData.eventId,
        eventData.matchOdds.marketId,
        eventData.providerName,
        true
      );
    }
    if (secondaryMarkets?.bookmakers?.length && topicUrls?.bookMakerTopic) {
      updateBookMakerTopic(topicUrls?.bookMakerTopic, topicUrls?.bookMakerBaseUrl);
      for (let itm of secondaryMarkets?.bookmakers) {
        subscribeWsForSecondaryMarkets(
          topicUrls?.bookMakerTopic,
          eventData?.eventId,
          itm.marketId,
          eventData.sportId,
          eventData.competitionId,
          true
        );
      }
    }
    if (topicUrls?.matchOddsTopic) {
      updateMatchOddsTopic(topicUrls?.matchOddsTopic, topicUrls?.matchOddsBaseUrl);
      for (let mo of secondaryMatchOdds) {
        subscribeWsForSecondaryMatchOdds(
          topicUrls?.matchOddsTopic,
          eventData.eventId,
          mo.marketId,
          eventData.providerName,
          eventData.sportId,
          eventData.competitionId,
          true
        );
      }
    }
  }, [
    isConnected(),
    loggedIn,
    secondaryMatchOdds,
    secondaryMarkets,
    eventData?.eventId,
  ]);

  useEffect(() => {
    if (loggedIn) {
      let refreshInterval = setInterval(() => {
        if (topicUrls?.matchOddsBaseUrl) {
          const baseUrlsPayload = {
            matchOddsBaseUrl: topicUrls?.matchOddsBaseUrl,
            bookMakerAndFancyBaseUrl: topicUrls?.bookMakerBaseUrl,
            premiumBaseUrl: topicUrls?.premiumBaseUrl
          }
          checkStompClientSubscriptions(baseUrlsPayload);
        }
      }, 10000);
      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, []);

  const getFormattedMaxLimit = (max: number) => {
    const num = Number(max / cFactor);
    const fMax = Number(Math.floor(num / 50) * 50);
    return fMax > 999 ? Number(fMax / 1000).toFixed() + 'K' : fMax.toFixed();
  };

  const getFormattedMinLimit = (min: number) => {
    const num = Number(min / cFactor);
    const fMin = Number(Math.ceil(num / 10) * 10);
    return fMin > 999 ? Number(fMin / 1000).toFixed() + 'K' : fMin.toFixed();
  };
  return (
    <div className="flex flex-col mx-auto w-full bg-zinc-100">
      {/* <MatchSection title="Gujarat Titans vs Rajasthan Royals" /> */}
      <MatchOdds
        exposureMap={exposureMap ? exposureMap : null}
        loggedIn={loggedIn}
        getFormattedMinLimit={getFormattedMinLimit}
        getFormattedMaxLimit={getFormattedMaxLimit}
        eventData={eventData}
        secondaryMatchOdds={secondaryMatchOdds}
        isMultiMarket={isMultiMarket}
        fetchEvent={fetchEvent}
      />
      {secondaryMarkets?.bookmakers?.length > 0 &&
        secondaryMarkets?.bookmakers[0].runners.length > 0 ? (
        <Bookmaker
          exposureMap={exposureMap ? exposureMap : null}
          loggedIn={loggedIn}
          getFormattedMinLimit={getFormattedMinLimit}
          getFormattedMaxLimit={getFormattedMaxLimit}
          bmData={bmMData}
          eventData={eventData}
          isMultiMarket={isMultiMarket}
          fetchEvent={fetchEvent}
        />
      ) : null
      }
      {/* <Footer /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    // clearExchcngeBets: () => dispatch(clearExchcngeBets()),
    fetchEvent: (
      sportId: string,
      competitionId: string,
      eventId: string,
      marketTime: string
    ) => dispatch(fetchEventMarkets({ sportId, competitionId, eventId, marketTime }))
  };
};

export default connect(null, mapDispatchToProps)(MultiMarket);
