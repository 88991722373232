/**
 * This code was generated by Builder.io.
 */
import React, { useEffect } from "react";
import TopBanner from "./TopBanner";
import MainHeader from "./MainHeader";
import Navigation from "./Navigation";
import { useAppSelector } from "../../../store/storeHooks";

interface HeaderProps {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const Header: React.FC<HeaderProps> = ({ selectedTab, setSelectedTab }) => {
  const { loggedIn } = useAppSelector(state => state.auth);
  
  return (
    <header className="sticky top-0 z-50 bg-white shadow-md">
      {
        loggedIn ?
          <TopBanner />
        : null
      }
      <MainHeader />
      <Navigation selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
    </header>
  );
};

export default Header;
