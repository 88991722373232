/**
 * This code was generated by Builder.io.
 */

import { useAppSelector } from "../../../store/storeHooks";
import { PAYMENT_METHODS } from "../MyProfile/ProfileTabs/utils/types";
import { AddAccountFormType, AddUpiAccountFormType } from "./utils/types";
import { addAccountFormArr, addUpiFormArr } from "./utils/Withdraw.utils";

interface AddAccountFormProps {
  handleAddAccountFormChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  resetForms: () => void;
  onSave: (e: React.FormEvent<HTMLFormElement>) => void;
  isSaveBtnDisabled: boolean;
  addAccountFormData: AddAccountFormType;
  selectedPaymentMethod: PAYMENT_METHODS;
  handleAddUpiAccountFormChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  addUpiAccountFormData: AddUpiAccountFormType;
}

const AddAccountForm: React.FC<AddAccountFormProps> = ({
  handleAddAccountFormChange,
  resetForms,
  isSaveBtnDisabled,
  onSave,
  addAccountFormData,
  selectedPaymentMethod,
  addUpiAccountFormData,
  handleAddUpiAccountFormChange,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <form onSubmit={onSave}>
      <h2 className="mt-4 text-[14px] font-medium capitalize text-neutral-500 max-md:max-w-full">
        {langData?.['enter_account_details']}:
      </h2>

      <div className="flex flex-col mt-3 text-[14px] font-medium text-stone-900 max-md:max-w-full">
        {!(selectedPaymentMethod === PAYMENT_METHODS.UPI) ? (
          <div className="flex gap-3 flex-wrap">
            {addAccountFormArr.map((field) => {
              const { key, label, type, langKey, placeholder } = field;

              return (
                <div
                  key={key}
                  className="flex flex-col py-0.5 max-md:max-w-full"
                >
                  <label htmlFor="amount" className="max-md:max-w-full">
                    {langData?.[langKey]}
                  </label>
                  <input
                    value={addAccountFormData[key]}
                    type={type}
                    id={key}
                    placeholder={langData?.[placeholder]}
                    onChange={handleAddAccountFormChange}
                    className="px-3 py-2 mt-3 rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pr-5 max-md:max-w-full"
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex gap-3 flex-wrap">
            {addUpiFormArr.map((field) => {
              const { key, label, type, langKey, placeholder } = field;

              return (
                <div
                  key={key}
                  className="flex flex-col py-0.5 max-md:max-w-full"
                >
                  <label htmlFor="amount" className="max-md:max-w-full">
                    {langData?.[langKey]}
                  </label>
                  <input
                    value={addUpiAccountFormData[key]}
                    type={type}
                    id={key}
                    placeholder={langData?.[placeholder]}
                    onChange={handleAddUpiAccountFormChange}
                    className="px-3 py-2 mt-3 rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pr-5 max-md:max-w-full"
                  />
                </div>
              );
            })}
          </div>
        )}

        <div className="shrink-0 mt-5 h-px border border-solid bg-neutral-200 border-neutral-200 max-md:max-w-full" />
      </div>

      <div className="flex gap-3 self-end mt-5 max-w-full text-sm font-medium text-center whitespace-nowrap w-[405px] max-md:mr-1.5">
        <button
          onClick={resetForms}
          type="reset"
          className="px-16 py-2.5 text-black rounded-md border border-white border-solid max-md:px-5"
        >
          {langData?.['reset']}
        </button>
        <button
          disabled={isSaveBtnDisabled}
          type="submit"
          className={`px-16 py-2.5 text-white ${
            isSaveBtnDisabled ? "bg-fuchsia-400" : "bg-primary"
          } rounded-md max-md:px-5`}
        >
          {langData?.['save']}
        </button>
      </div>
    </form>
  );
};

export default AddAccountForm;
