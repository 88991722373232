/**
 * This code was generated by Builder.io.
 */
import React from "react";
import TransactionFilter from "./TransactionFilter";
import TransactionList from "./TransactionList";
import purple from "../../../../assets/purple_bg.png"
import { useAppSelector } from "../../../../store/storeHooks";
interface AccountStatement {
  records:any;
  getBonusData:any;
  filters:any;
  handleDateChange:Function;
  setFilters:any;
}


const AccountStatement: React.FC<AccountStatement> = (props) => {
  const { handleDateChange, filters, setFilters, records } = props;
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex overflow-hidden flex-col mx-auto w-full bg-zinc-100 max-w-[480px] transition ease-in-out duration-[2000ms]">
      <main className="flex flex-col pt-px mt-3 w-full bg-white ">
        <div className="bg-[black]">
        <div className=" bg-stone-900 flex w-full h-8 text-white text-[14px] font-semibold rounded-t-md">
        <div className="flex flex-col justify-center w-[50%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
          <div className="flex min-w-[170px]">
            <div className=" bg-primary w-full h-8 py-2 pr-4 pl-2">
              {langData?.['bonus_statement']}
            </div>
            <div className=" skew-x-[-25deg] bg-primary h-8 -translate-x-[7px] w-4"></div>
            <div className=" skew-x-[-25deg] bg-primary h-8 w-1 -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-primary h-8 w-1"></div>
          </div>
        </div>

      </div>
        </div>
        <TransactionFilter handleDateChange={handleDateChange} filters={filters} setFilters={setFilters} />
        <TransactionList records={records} />
      </main>
    </div>
  );
};

export default AccountStatement;
