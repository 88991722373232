/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React from "react";
import { useAppSelector } from "../../../../store/storeHooks";

interface BonusStatementTableProps {
  records: any;
}

const BonusStatementTable: React.FC<BonusStatementTableProps> = (props) => {
  const { records } = props;
  const { langData } = useAppSelector((state) => state.cms);

  const headings = [
    // "Bonus Type",
    // "Approval Req.",
    // "Awarded Date",
    // "Awarded Amt.",
    // "Turnover",
    // "Installments",
    // "Redeemed Amt.",
    // "Status",
    "Last Date",
  ];

  return (
    <>
      <div className="flex flex-col justify-center px-3 py-px mt-1.5 w-full text-[14px] font-medium rounded-md bg-neutral-200 text-neutral-500">
        <div className=" text-[14px]">
          <div className="flex gap-2 justify-between">
            <div className="my-auto">{langData?.['bonus_type']}</div>
            <div className="flex gap-4 whitespace-nowrap">
              <div className="flex gap-1">
                <div className="shrink-0 border border-solid border-neutral-500 h-full" />
                <div className="my-auto text-wrap">{langData?.['approval_required_txt']}</div>
              </div>
              <div className="flex gap-1">
                <div className="shrink-0 border border-solid border-neutral-500 h-full" />
                <div className="my-auto text-wrap">{langData?.['awarded_date']}</div>
              </div>
              <div className="flex gap-1">
                <div className="shrink-0 border border-solid border-neutral-500 h-full" />
                <div className="my-auto text-wrap">{langData?.['awarded_amount_txt']}</div>
              </div>
            </div>
          </div>
          {records?.map((row, index) => (
            <div
              key={index}
              className="flex flex-col items-center py-0.5 mt-1.5 w-full bg-white border-2 border-sky-200 border-solid"
            >
              <div className="flex z-10 gap-5 justify-between w-full max-w-[379px]">
                <div className="flex flex-col self-start mt-3 pl-[10px]">
                  <div className="text-sm font-medium text-stone-900">
                    {row.bonus_category}
                  </div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto">
                    {" "}
                    {row.approval_required ? "Yes" : "No"}
                  </div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto">
                    {moment(row.award_date).format("DD/MM/YYYY, h:mm:ss A")}
                  </div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto">
                    {" "}
                    {row?.award_amount?.toFixed(2)}
                  </div>
                </div>
              </div>
              <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
              <div className="flex">
                <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
                  <div className="flex flex-col my-auto">
                    <div className="flex gap-1">
                      <div className="font-medium text-neutral-500">
                        {langData?.['turnover']}:
                      </div>
                      <div className="text-black">
                        {(row.turnover_met ? row.turnover_met : "-") +
                          "/" +
                          (row.turnover_required ? row.turnover_required : "-")}
                      </div>
                    </div>
                  </div>
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                </div>

                <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
                  <div className="flex flex-col my-auto">
                    <div className="flex gap-1">
                      <div className="font-medium text-neutral-500">
                        {langData?.['installments']}:
                      </div>
                      <div className="text-black">
                        {(row.installments_given
                          ? row.installments_given
                          : "-") +
                          "/" +
                          (row.installments ? row.installments : "-")}
                      </div>
                    </div>
                    <div className="flex  my-auto">
                      <div className="flex gap-1">
                        <div className="font-medium text-neutral-500">
                          {langData?.['redeemed_amount']}. :
                        </div>
                        <div className="text-black">
                          {row.last_redeem_date
                            ? moment(row.last_redeem_date).format(
                                "DD/MM/YYYY, h:mm:ss A"
                              )
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 justify-between w-full text-[14px] max-w-[310px]">
                  <div className="flex flex-col my-auto">
                    <div className="flex gap-1">
                      <div className="font-medium text-neutral-500">
                        {langData?.['status']}:
                      </div>
                      <div className="text-black">{row.bonus_status}</div>
                    </div>
                    <div className="flex  my-auto">
                      <div className="flex gap-1">
                        <div className="font-medium text-neutral-500">
                          {langData?.['last_date']}:
                        </div>
                        <div className="text-black">
                          {row.last_redeem_date
                            ? moment(row.last_redeem_date).format(
                                "DD/MM/YYYY, h:mm:ss A"
                              )
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
              </div>
            </div>
          ))}
          {records && records.length === 0 && (
            <div className=" text-center mt-5">{langData?.['records_not_found_txt']}</div>
          )}
        </div>
      </div>
      {/* <div className="overflow-x-scroll text-[14px]">
      <table className="w-full border-collapse mt-3">
        <thead>
          <tr className="bg-neutral-200 text-neutral-500">
            {headings.map((heading, index) => (
              <th
                key={index}
                className={`px-2 py-1 border-r ${
                  index < headings.length - 1 ? "border-[#757575]" : ""
                }`}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((row, index) => (
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.bonus_category}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.approval_required ? "Yes" : "No"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {moment(row.award_date).format("DD/MM/YYYY, h:mm:ss A")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.award_amount?.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {(row.turnover_met ? row.turnover_met : "-") +
                  "/" +
                  (row.turnover_required ? row.turnover_required : "-")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {(row.installments_given ? row.installments_given : "-") +
                  "/" +
                  (row.installments ? row.installments : "-")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.redeemed_amount?.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.bonus_status}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"></td>
              {row.last_redeem_date
                ? moment(row.last_redeem_date).format("DD/MM/YYYY, h:mm:ss A")
                : "-"}
            </tr>
          ))}
        </tbody>
      </table>
      {records && records.length === 0 && (
        <div className=" text-center">No bonus</div>
      )}
      </div> */}
    </>
  );
};

export default BonusStatementTable;
