/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import BettingTable from "./ProfitLossTable";

import purple from "../../../../assets/purple_bg.png";
import moment from "moment";
import {
  defaultFilters,
  Filters,
} from "../../../../store/reports/reportSelectors";
import { fetchPLStatement } from "../../../../store/reports/reportsThunk";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { useAppSelector } from "../../../../store/storeHooks";

interface BettingProfitLossProps {
  plStatement: any;
  fetchPLStatement?: Function;
  handleDateChange: Function;
  filters: Filters;
  setFilters: Function;
  nextPage: Function;
  prevPage: Function;
  pageToken: string;
}

const BettingProfitLoss: React.FC<BettingProfitLossProps> = (props) => {
  const {
    plStatement,
    pageToken,
    nextPage,
    prevPage,
    setFilters,
    handleDateChange,
    filters,
  } = props;
  // const [filters, setFilters] = useState<Filters>(defaultFilters);
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex flex-col bg-zinc-100">
      <div className="w-full max-md:max-w-full ">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-full">
            <div className="flex flex-col py-px w-full text-[14px] font-medium bg-white rounded-md border border-solid border-neutral-200 max-md:mt-6">
              <div className="flex flex-col justify-center items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
                <div className="flex ">
                  <div className=" bg-primary w-full py-2 pr-4 pl-2 text-[14px] text-white">
                    {langData?.['betting_profit_and_loss']}
                  </div>
                  <div className=" skew-x-[-25deg] bg-primary -translate-x-[8px] w-5 h-full"></div>
                  <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
                  <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
                </div>
              </div>
              <DatePicker
                onDateChange={handleDateChange}
                startDate={filters.startDate}
                endDate={filters.endDate}
              />
              <BettingTable
                setFilters={setFilters}
                plStatement={plStatement}
                filters={filters}
              />
              <div className=" flex justify-center">
                {filters?.pageToken.length > 0 && (
                  <button
                    className=" p-2 w-14 leading-[16.2px] text-white bg-primary m-2"
                    onClick={(e) => prevPage()}
                  >
                    {langData?.['prev']}({filters.pageToken.length})
                  </button>
                )}
                {pageToken ? (
                  <button
                    className=" p-2 w-14 leading-[16.2px] text-white bg-primary m-2"
                    onClick={(e) => nextPage()}
                  >
                    {langData?.['next']}({filters?.pageToken.length + 2})
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BettingProfitLoss;
