import React, { useState } from 'react';
import sports from '../../../assets/mobile/mob_sports.svg';
import exchange from '../../../assets/mobile/mob_exchange.svg';
import inplay from '../../../assets/mobile/mob_inplay.svg';
import casino from '../../../assets/mobile/mob_casino.svg';
import settings from '../../../assets/mobile/mob_settings.svg';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/storeHooks';
import { setCompetition, setEventType } from '../../../store/catalog';
import { SPORTS_MAP } from '../../../constants/ExchangeEventTypes';

const BottomTabNav = () => {
    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState(''); // State to track active tab
    const { langData } = useAppSelector((state) => state.cms);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='z-[99] w-full flex rounded-t-xl h-[64px] bg-red-400 text-center fixed bottom-0 overflow-hidden text-white'>
            <div
                className={`bg-no-repeat bg-contain w-full flex justify-center items-center flex-col h-full hover:bg-primary ${activeTab === 'sports' ? 'bg-primary' : 'bg-[#303030]'}`}
                onClick={() => handleTabClick('sports')}
            >
                <NavLink to="/exchange_sports/cricket" onClick={() => {
                    dispatch(setEventType(SPORTS_MAP.get("Cricket")));
                    dispatch(setCompetition({ id: '', name: '', slug: '' }));
                }}>
                    <img className=' h-7' src={sports} alt="" />
                    <div className=' w-full text-center text-[12px]' >{langData?.['sports']}</div>
                </NavLink>
            </div>
            <div
                className={`bg-no-repeat bg-contain w-full flex justify-center items-center flex-col h-full hover:bg-primary ${activeTab === 'exchange' ? 'bg-primary' : 'bg-[#303030]'}`}
                onClick={() => handleTabClick('exchange')}
            >
                <NavLink to="/exchange_sports/inplay">
                    <img className=' h-7 mx-auto' src={exchange} alt="" />
                    <div className=' w-full text-center text-[12px]' >{langData?.['exchange']}</div>
                </NavLink>
            </div>
            <div
                className={`bg-no-repeat bg-contain w-full flex justify-center items-center flex-col h-full hover:bg-primary ${activeTab === 'inplay' ? 'bg-primary' : 'bg-[#303030]'}`}
                onClick={() => handleTabClick('inplay')}
            >
                <NavLink to="/exchange_sports/inplay">
                    <img className=' h-7 mx-auto' src={inplay} alt="" />
                    <div className=' w-full text-center text-[12px]' >{langData?.['inplay']}</div>
                </NavLink>
            </div>
            <div
                className={`bg-no-repeat bg-contain w-full flex justify-center items-center flex-col h-full hover:bg-primary ${activeTab === 'casino' ? 'bg-primary' : 'bg-[#303030]'}`}
                onClick={() => handleTabClick('casino')}
            >
                <NavLink to="/live-casino">
                    <img className=' h-7 mx-auto' src={casino} alt="" />
                    <div className=' w-full text-center text-[12px]' >{langData?.['casino']}</div>
                </NavLink>
            </div>
            <div
                className={`bg-no-repeat bg-contain w-full flex justify-center items-center flex-col h-full hover:bg-primary ${activeTab === 'settings' ? 'bg-primary' : 'bg-[#303030]'}`}
                onClick={() => handleTabClick('settings')}
            >
                <NavLink to="/setting">
                    <img className=' h-7 mx-auto' src={settings} alt="" />
                    <div className=' w-full text-center text-[12px]' >{langData?.['settings']}</div>
                </NavLink>
            </div>
        </div>
    );
};

export default BottomTabNav;
