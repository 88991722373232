/**
 * This code was generated by Builder.io.
 */
import React from "react";

import terms from '../../../assets/terms.svg'
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import { logout } from "../../../store/auth/authThunk";
import { useSelector } from "react-redux";

const SecurityLogout: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector(state => state.auth)
  const { langData } = useAppSelector((state) => state.cms);

  const handleLogout = async () => {
    const resultAction = await dispatch(logout());
    if (logout.fulfilled.match(resultAction)) {
      navigate('/', { replace: true });
    } else {
      // Handle the error if needed
      console.error(resultAction.payload);
    }
  };

  return (
    <>
      <div className="mt-6 text-[11px] font-semibold tracking-widest text-neutral-500">
        {langData?.['security_and_logout']}
      </div>
      <NavLink to="/terms">
        <div className="flex font-[600] cursor-pointer items-center gap-3.5 mt-3 text-[14px]">
          <img src={terms} alt="" className="w-5 h-5" />
          <span>{langData?.['terms_and_policy']}</span>
        </div>
      </NavLink>
      {
        loggedIn ?
          <div onClick={() => handleLogout()}>
            <div className="flex font-[600] cursor-pointer items-center gap-3.5 mt-3 text-[14px]">
              <img src={terms} alt="" className="w-5 h-5" />
              <span>{langData?.['logout']}</span>
            </div>
          </div>
          : null
      }

    </>
  );
};

export default SecurityLogout;
