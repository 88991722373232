/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import { EventDTO } from "../../../models/common/EventDTO";
import { SelectedObj } from "../../../models/SportsState";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { disconnectToWS, subscribeWsForEventOdds, unsubscribeAllWsforEvents } from "../../../webSocket/websocket";
import { connect } from "react-redux";
import { fetchEventsByCompetition } from "../../../store/catalog/catalogThunk";
import { setCompetition, setExchEvent } from "../../../store/catalog";
import { fetchEventsBySport } from "../../../api/catalog";
import { getExchangeEvents } from "../../../store/catalog/catalogSelectors";
import { RootState } from "../../../store";
import { formatTime } from "../../../utils/commonUtils";
import { isMobile } from "react-device-detect";
import { useAppSelector } from "../../../store/storeHooks";

interface ExchRaceTableProps {
    compitationsList: any;
    selectedEventType: SelectedObj;
    value: string;
}

const ExchRaceTable: React.FC<ExchRaceTableProps> = (props) => {
    const {
        compitationsList,
        selectedEventType,
        value
    } = props;

    const navigate = useNavigate();
    const { langData } = useAppSelector((state) => state.cms);

    const pathParams = useParams<any>();
    const [wsChannels, setWsChannels] = useState<string[]>([]);
    const [eventData, setEventData] = useState<EventDTO[]>([]);
    const [matchOddsBaseUrl, setMatchOddsBaseUrl] = useState<string>('');
    const [matchOddsTopic, setMatchOddsTopic] = useState<string>('');

    // useEffect(() => {
    //     if (!pathParams['competition']) {
    //         setCompetition({ id: '', name: '', slug: '' });
    //     }
    // }, [pathParams]);

    // const updateMatchOddsTopic = (currentTopic: string, currentBaseUrl: string) => {
    //     if (matchOddsTopic !== currentTopic || matchOddsBaseUrl !== currentBaseUrl) {
    //         disconnectToWS();
    //         setMatchOddsTopic(currentTopic);
    //         setMatchOddsBaseUrl(currentBaseUrl);
    //     }
    // }

    // const updateEvents = () => {
    //     if (!pathParams['competition']) {
    //         if (
    //             selectedEventType.id &&
    //             events &&
    //             selectedEventType.id === events[0]?.sportId
    //         )
    //             fetchEventsBySport(selectedEventType.id, events);
    //     } else {
    //         if (
    //             selectedEventType.id &&
    //             events &&
    //             selectedEventType.id === events[0]?.sportId
    //         ) {
    //             fetchEventsByCompetition(
    //                 selectedEventType.id,
    //                 selectedCompetition.id,
    //                 events
    //             );
    //         }
    //     }
    // };

    // useEffect(() => {
    //     if (!pathParams['competition']) {
    //         fetchEventsBySport(selectedEventType.id, events);
    //     }
    // }, [selectedEventType]);

    // useEffect(() => {
    //     if (pathParams['competition'] && !events) {
    //         fetchEventsByCompetition(
    //             selectedEventType.id,
    //             selectedCompetition.id,
    //             events
    //         );
    //     }
    // }, [selectedCompetition]);

    // useEffect(() => {
    //     updateEvents();
    // }, [loggedIn]);

    // useEffect(() => {
    //     let refreshInterval = setInterval(() => {
    //         updateEvents();
    //     }, 60000);
    //     return () => {
    //         clearInterval(refreshInterval);
    //     };
    // }, [selectedEventType]);

    // // Unsubscribe Web socket messages
    // useEffect(() => {
    //     unsubscribeAllWsforEvents();
    //     setWsChannels([]);
    // }, [selectedEventType]);

    // useEffect(() => {
    //     if (pathParams['competition']) {
    //         unsubscribeAllWsforEvents();
    //         setWsChannels([]);
    //     }
    // }, [selectedCompetition, pathParams]);

    // useEffect(() => {
    //     if (loggedIn && topicUrls?.matchOddsTopic) {
    //         if (selectedEventType.id === '4' && events) {
    //             let subs = [...wsChannels];
    //             for (let event of events) {
    //                 if (event.status === 'IN_PLAY' && !wsChannels.includes(event.eventId)) {
    //                     subs.push(event.eventId);
    //                     updateMatchOddsTopic(topicUrls?.matchOddsTopic, topicUrls?.matchOddsBaseUrl)
    //                     subscribeWsForEventOdds(
    //                         topicUrls?.matchOddsTopic,
    //                         event.sportId,
    //                         event.competitionId,
    //                         event.eventId,
    //                         event.matchOdds?.marketId,
    //                         event.providerName
    //                     );
    //                 }
    //             }
    //             setWsChannels(subs);
    //         }
    //     }
    // }, [betFairWSConnected, events, selectedEventType, loggedIn]);

    // useEffect(() => {
    //     if (events) {
    //         setEventData([...events]);
    //         // setCompitations(events);
    //     }
    // }, [events]);

    const getCompetitionSlug = (competitionName: string) => {
        return competitionName
            .toLocaleLowerCase()
            .replace(/[^a-z0-9]/g, ' ')
            .replace(/ +/g, ' ')
            .trim()
            .split(' ')
            .join('-');
    };

    const handleEventChange = (event: EventDTO, mr: any) => {
        setCompetition({
            id: event.competitionId,
            name: event.competitionName,
            slug: getCompetitionSlug(event.competitionName),
        });
        setExchEvent({
            id: event.eventId,
            name: event.eventName,
            slug: event.eventSlug,
        });
        navigate(`/exchange_sports/${selectedEventType.slug
            }/${getCompetitionSlug(
                event.competitionName
            )}/${event.eventSlug}/${btoa(
                `${event.sportId}:${event.competitionId}:${event.eventId}:${mr.marketTime}`
            )}`)
    };

    const handleEventData = (data) => {
        return data.sort((a, b) => {
            if (a.inPlay && !b.inPlay) return -1;
            if (!a.inPlay && b.inPlay) return 1;
        });
    };

    const isLive = (date: Date) => {
        let duration = moment.duration(moment(date).diff(moment()));
        return duration?.asMinutes() < 10 ? true : false;
    };

    // const setCompitations = (events: EventDTO[]) => {
    //     let compitations: { [key: number]: EventDTO[] } = {};
    //     const unique = [
    //         ...new Set(events.map((item) => item?.competitionId?.split('_')[0])),
    //     ];
    //     for (let key of unique) {
    //         compitations[key] =
    //             events?.filter((item) => item?.competitionId?.startsWith(key + '_')) ??
    //             [];
    //     }
    //     const sorted = Object.keys(compitations)
    //         .sort()
    //         .reduce((accumulator, key) => {
    //             accumulator[key] = compitations[key];
    //             return accumulator;
    //         }, {});

    //     setCompitationsList(sorted);
    //     value === '' && setValue(unique[0]);
    // };

    return (
        <div className=" w-full px-2  ">
            {compitationsList[value]?.length > 0 ? (
                handleEventData(compitationsList[value])?.map((sEvent, idx) => (
                    <React.Fragment key={idx}>
                        {sEvent.sportId === selectedEventType.id &&
                            sEvent.raceMarkets?.length > 0 ? (
                            <div className="flex pt-4 py-2 gap-5 justify-between items-center w-full ">
                                <div className="flex gap-5 justify-between text-stone-900">
                                    <div className="text-[14px] tracking-wide leading-3 text-center">
                                        <span className={` ${isMobile ? 'text-[12px]' : ''} font-medium tracking-wide text-neutral-500 m-1`}>
                                            {sEvent.eventName}
                                        </span>
                                        <br />
                                        {/* <span className="font-medium">{sEvent.eventName}</span> */}
                                    </div>
                                    {/* <div className="my-auto text-[14px] font-medium">{sEvent.team}</div> */}
                                </div>
                                <div className="flex gap-1 pr-3 text-[14px] font-medium text-center text-white whitespace-nowrap">
                                    {sEvent.raceMarkets.map((mr, idx) => (
                                        <>
                                            {idx < 8 ? (
                                                <div
                                                    key={idx}
                                                    onClick={() => handleEventChange(sEvent, mr)}
                                                    className={`cursor-pointer ${isMobile ? 'w-[34px] pr-1' : 'w-[60px]'} h-[24px] leading-[24px] rounded
                                                    ${isLive(mr.marketTime)
                                                        ? ' bg-amber-400 text-stone-900 '
                                                        : ' bg-neutral-500 '} `}
                                                >
                                                    {formatTime(mr.marketTime)}
                                                </div>
                                            ) : null
                                            }
                                        </>
                                    ))}
                                </div>
                            </div>
                        ) : null
                        }
                        {idx < compitationsList[value]?.length - 1 && (
                            <div className="shrink-0 mt-2 max-w-full h-px border border-solid bg-neutral-200 border-neutral-200 w-full " />
                        )}
                    </React.Fragment>
                ))) : null}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const eventType = state.catalog.selectedEventType;
    const competition = state.catalog.selectedCompetition;
    return {
        events: getExchangeEvents(
            state.catalog.events,
            eventType.id,
            competition.id
        ),
        selectedEventType: eventType,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        fetchEventsByCompetition: (
            sportId: string,
            competitionId: string,
            events: EventDTO[]
        ) => dispatch(fetchEventsByCompetition(sportId, competitionId, events)),
        setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
        setCompetition: (competition: SelectedObj) =>
            dispatch(setCompetition(competition)),
        fetchEventsBySport: (sportId: string) =>
            dispatch(fetchEventsBySport(sportId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchRaceTable);