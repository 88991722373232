import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import LiveCasinoDesktop from "../components/Desktop/LiveCasino/LiveCasino";
import LiveCasinoMobile from "../components/Mobile/LiveCasino/WinBuzzGaming";
import { connect } from "react-redux";
import { getDcGames, getSelectedCategoryGames } from "../store/catalog/catalogThunk";
import { RootState } from "../store";
import { categories } from "../store/catalog/catalogSelectors";
import {
  getCurrencyTypeFromToken,
  getFieldFromToken,
  JwtToken,
} from "../utils/commonUtils";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal/Modal";
import { useAppSelector } from "../store/storeHooks";

interface LiveCasinoViewProps {
  games: any;
  initGames: any;
  casinoGames: any;
  providerList: any;
  categoryList: any;
  getDcGames: Function;
  loggedIn: boolean;
  getSelectedCategoryGames: Function;
}

const LiveCasinoView: React.FC<LiveCasinoViewProps> = (props) => {
  const {
    games,
    getDcGames,
    initGames,
    casinoGames,
    categoryList,
    providerList,
    getSelectedCategoryGames,
    loggedIn,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("All");
  const [selectedGameType, setSelectedGameType] = useState("All");
  const [gamesDisplay, setGamesDisplay] = useState([]);
  const [dialogShow, setDialogShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const { langData } = useAppSelector((state) => state.cms);

  const handleGameClick = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider?: string
  ) => {
    if (
      getCurrencyTypeFromToken() === 0 &&
      !(
        provider?.toLocaleLowerCase() === "ezugi" ||
        subProvider === "BetGames.TV" ||
        subProvider === "Pragmatic Play" ||
        subProvider === "Onetouch Live" ||
        subProvider === "OneTouch" ||
        provider === "RG"
      )
    ) {
      getGameUrl(gameId, gameName, gameCode, provider, subProvider);
    } else {
      getGameUrl(gameId, gameName, gameCode, provider, subProvider);
    }
  };

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedIn) {
      const loggedInUserStatus = getFieldFromToken(JwtToken.STATUS);
      if (loggedInUserStatus === 0 || loggedInUserStatus === 3) {
        navigate(`/home`);
      }
      if (provider === "Indian Casino") {
        navigate(`/casino/indian/${gameCode}`);
      } else {
        navigate(
          `/dc/gamev1.1/${gameName?.toLowerCase().replace(/\s+/g, "-")}-${btoa(
            gameId?.toString()
          )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
        );
      }
    } else {
      setDialogShow(true);
    }
  };

  useEffect(() => {
    getSelectedCategoryGames({selectedGameType, selectedProvider, searchTerm, setGamesDisplay, games});
  }, [selectedGameType, selectedProvider, searchTerm]);

  useEffect(() => {
    setGamesDisplay(games);
  }, [games]);

  useEffect(() => {
    getDcGames();
  }, []);

  return (
    <>
      {isMobile ? (
        <LiveCasinoMobile
          title={langData?.['live_casino']}
          games={gamesDisplay}
          setDialogShow={setDialogShow}
          dialogShow={dialogShow}
          providerList={providerList}
          categoryList={categoryList}
          selectedProvider={selectedProvider}
          selectedGameType={selectedGameType}
          setSelectedGameType={setSelectedGameType}
          setSelectedProvider={setSelectedProvider}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleGameClick={handleGameClick}
        />
      ) : (
        <LiveCasinoDesktop
          title={langData?.['live_casino']}
          games={gamesDisplay}
          setDialogShow={setDialogShow}
          dialogShow={dialogShow}
          providerList={providerList}
          categoryList={categoryList}
          selectedProvider={selectedProvider}
          selectedGameType={selectedGameType}
          setSelectedGameType={setSelectedGameType}
          setSelectedProvider={setSelectedProvider}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleGameClick={handleGameClick}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    games: state.catalog.games,
    initGames: state.catalog.initGames,
    casinoGames: state.catalog.casinoGames,
    providerList: state.catalog.providerList,
    categoryList: state.catalog.categoryList,
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getDcGames: () => dispatch(getDcGames()),
    getSelectedCategoryGames: (event: boolean) => dispatch(getSelectedCategoryGames(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveCasinoView);
