import React, { useState } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import login_bg from "../../../assets/login_bg.png";
import { Navigate, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import CommonBackImg from "../ForgotPassword/ForGotPasswordImg/CommonBackImgAuthScreens"
import { useAppSelector } from "../../../store/storeHooks";

interface SignUpFormProps {
  otpSent: boolean;
  handleSubmit: Function;
  currentStep: number;
  phoneNumber: string;
  otp: number;
  setOtp: Function;
  agreedToTerms: boolean;
  handlePhoneChange: Function;
  handleTermsChange: Function;
  username: string;
  password: string;
  handleUsernameChange: Function;
  handlePasswordChange: Function;
  redirectToSignIn: Function;
  loader: boolean;
  showPasswordClickHandler: Function;
  showPassword: boolean;
  phoneNumberExists: boolean;
}

const SignUpForm: React.FC<SignUpFormProps> = (props) => {
  const {
    phoneNumberExists,
    loader,
    otpSent,
    showPasswordClickHandler,
    showPassword,
    handlePasswordChange,
    handleUsernameChange,
    password,
    username,
    handleSubmit,
    currentStep,
    phoneNumber,
    otp,
    setOtp,
    agreedToTerms,
    handlePhoneChange,
    handleTermsChange,
    redirectToSignIn,
  } = props;

  const navigate = useNavigate();
  const { langData } = useAppSelector((state) => state.cms);
  return (
    <main className="flex items-center flex-col w-[100%]  h-[100vh]">
  <CommonBackImg redirectToHome={redirectToSignIn} tagline="Bet smarter, Bet with confidence, Win bigger: your ultimate bet experience"/>
  <div className="relative w-full flex items-center justify-center">
    <section className="relative w-[90%] mt-[15px] mb-[25px] flex z-10 flex-col self-center px-4  text-sm font-medium bg-white rounded-2xl border-4 border-solid border-neutral-200 text-neutral-500">
      <header className="flex flex-col items-center text-center w-full">
        <h1 className="text-3xl font-semibold text-stone-900">
          {langData?.['sign_up']}
        </h1>
        <p className="text-base text-neutral-500">
          {langData?.['create_your_account_txt']}
        </p>
      </header>
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="flex flex-col w-full mt-5 text-sm font-medium rounded-none"
      >
        <div className="flex w-full text-[14px] text-center h-[24px] rounded-md border border-solid border-[#3FA48F]">
          <div
            className={`flex items-center justify-center w-[50%] px-4 py-1 rounded-md min-h-[24px] ${currentStep === 1 ? "bg-[#3FA48F] text-white" : "text-stone-900"}`}
          >
            {langData?.['step_1']}
          </div>
          <div
            className={`flex items-center justify-center w-[50%] px-4 py-1 rounded-md min-h-[24px] ${currentStep === 2 ? "bg-[#3FA48F] text-white" : "text-stone-900"}`}
          >
            {langData?.['step_2']}
          </div>
        </div>
        {currentStep === 1 ? (
          <StepOne
            otpSent={otpSent}
            otp={otp}
            phoneNumber={phoneNumber}
            agreedToTerms={agreedToTerms}
            setOtp={setOtp}
            onPhoneChange={(e) => handlePhoneChange(e)}
            onTermsChange={(e) => handleTermsChange(e)}
          />
        ) : (
          <StepTwo
            username={username}
            password={password}
            onUsernameChange={(e) => handleUsernameChange(e)}
            onPasswordChange={(e) => handlePasswordChange(e)}
            showPasswordClickHandler={showPasswordClickHandler}
            showPassword={showPassword}
          />
        )}
        <button
          type="submit"
          disabled={
            (currentStep === 1 && (!phoneNumber || !agreedToTerms)) ||
            (currentStep === 2 && (!username || !password)) ||
            loader || phoneNumberExists
          }
          className={`flex items-center justify-center w-full px-4 py-3 mt-5 text-base font-semibold text-center text-white rounded-lg min-h-[44px] shadow-sm ${(
            (currentStep === 1 && (!phoneNumber || !agreedToTerms)) ||
            (currentStep === 2 && (!username || !password))
          ) ? "bg-gray-400 cursor-not-allowed" : "text-[#4da8f1] hover:text-[#4da8f1]"}`}
          // onClick={() => {
          //   if (currentStep !== 1) {
          //     navigate('/login')
          //   }
          // }}
        >
          {loader
            ? "Loader"
            : currentStep === 1
              ? otpSent
                ? "Validate OTP"
                : "Send OTP"
              : "Sign Up"}
        </button>
        <div className="self-center mt-6 text-sm text-center text-stone-900">
          {langData?.['have_account_txt']}{" "}
          <a
            href="#"
            className="font-semibold text-[#4da8f1] underline"
            onClick={() => redirectToSignIn()}
          >
            {langData?.['sign_in']}
          </a>
        </div>
      </form>
    </section>
  </div>
</main>

  );
};

export default SignUpForm;
