/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import { setEventTypeThunk } from "../../../store/catalog/catalogThunk";
import { SPORTS_MAP } from "../../../constants/ExchangeEventTypes";
import { removeExchangeBet } from "../../../store/betSlip/betSlipSlice";
import { setCompetition } from "../../../store/catalog";
import { menuItems } from "../../../utils/commonUtils";

interface MenuListProps {
  searchTerm: string;
  setSelectedTab: (tab: string) => void;
}

const MenuList: React.FC<MenuListProps> = ({ searchTerm, setSelectedTab }) => {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { langData } = useAppSelector((state) => state.cms);

  const toggleExpand = (text: string) => {
    setExpandedItem(expandedItem === text ? null : text);
  };

  const filteredItems = menuItems.filter(
    (item) =>
      item.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subTabs?.some((subTab) =>
        subTab.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  return (
    <>
      {filteredItems.map((item, index) => (
        <React.Fragment key={index}>
          <NavLink to={item.path}>
            <div
              className={`flex font-[600] items-center gap-3.5 cursor-pointer mt-1.5 text-[14px]
              }`}
              onClick={() => {
                dispatch(removeExchangeBet(0));
                toggleExpand(item.text);
                setSelectedTab(item.text);
                dispatch(setEventTypeThunk(SPORTS_MAP.get(item.text)));
                dispatch(setCompetition({ id: '', name: '', slug: '' }));
              }}
            >
              <img src={item.icon} alt="" className={"w-10 h-10"} />
              <span>{langData?.[item.langKey]}</span>
            </div>
          </NavLink>
          {/* {expandedItem === item.text && item.subTabs && (
            <div className="ml-8 mt-1">
              {item.subTabs.map((subTab, subIndex) => (
                <div key={subIndex} className="text-[12px] text-[#757575] font-normal py-1" onClick={() => setSelectedTab(subTab)}>
                  {subTab}
                </div>
              ))}
            </div>
          )} */}
          {index < filteredItems.length - 1 && (
            <div className="mt-1.5 h-px bg-[#f2f2f2]" />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default MenuList;
