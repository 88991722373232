/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { FaSquarePlus } from "react-icons/fa6";
import { FaSquareMinus } from "react-icons/fa6";
import { useAppSelector } from "../../../store/storeHooks";

interface StakeSettingRowProps {
  idx: number;
  label: string;
  value: number;
  updateButtonLabel: Function;
  addOrRemove: Function;
  updateButtonAmount: Function;
}

const StakeSettingRow: React.FC<StakeSettingRowProps> = (props) => {
  const {
    updateButtonLabel,
    updateButtonAmount,
    idx,
    label,
    value,
    addOrRemove,
  } = props;
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex flex-col w-[32%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow justify-center font-medium text-black max-md:mt-6">
        <div className="flex flex-col p-4 w-full rounded-xl bg-zinc-100">
          <div className="flex gap-5 justify-between">
            <div className="my-auto text-base font-[500] text-[16px] leading-[21.6px] flex-1">
              {langData?.['button_label']}
            </div>
            <div className="px-3  py-2.5 flex-1 text-sm whitespace-nowrap bg-white rounded max-md:px-5">
              <input
                type="text"
                className=" text-center font-[500] text-[14px] leading-[18.9px]"
                value={label}
                onChange={(e) => updateButtonLabel(idx, e.target.value)}
              />
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className="my-auto text-base font-[500] text-[16px] leading-[21.6px] flex-1">
              {langData?.['input_value']}
            </div>
            <div className="flex flex-col flex-1 py-2 justify-center text-sm text-center whitespace-nowrap bg-white rounded">
              <div className="flex justify-between">
              <FaSquarePlus
                  onClick={() => addOrRemove(idx, value, "+")}
                  className=" w-8 h-8 text-[#757575] rounded"
                />
                
                <div className="my-auto font-[500] text-[14px] leading-[18.9px]">
                  <input
                    type="text"
                    className="text-center w-[142px] sm:w-[127px] font-[500] text-[14px] leading-[18.9px]"
                    value={value}
                    onChange={(e) =>
                      updateButtonAmount(idx, parseFloat(e.target.value))
                    }
                  />
                </div>
                <FaSquareMinus
                  onClick={() => addOrRemove(idx, value, "-")}
                  className=" w-8 h-8 text-[#757575] rounded "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeSettingRow;
