/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import MyTransactionTable from "./MyTransactionTable";

import moment from "moment";
import { connect } from "react-redux";
import purple from "../../../assets/purple_bg.png";
import { RootState } from "../../../store";
import {
  defaultFilters,
  Filters,
} from "../../../store/reports/reportSelectors";
import {
  fetchTransactionRequest
} from "../../../store/reports/reportsThunk";
import { useAppSelector } from "../../../store/storeHooks";

interface MyTransactionProps {
  filters:any;
  setFilters:any;
  records: any;
  fetchTransactionRequest: Function;
  handleDateChange:Function
  TransactionTypes: any;
}

const MyTransaction: React.FC<MyTransactionProps> = (props) => {
  const { records, TransactionTypes, fetchTransactionRequest,handleDateChange,filters,setFilters } = props;
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex flex-col bg-zinc-100">
      <div className="w-full max-md:max-w-full ">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-full">
            <div className="flex flex-col py-px w-full text-[14px] font-medium bg-white rounded-lg overflow-hidden border border-solid border-neutral-200 max-md:mt-6">
            <div  className="flex bg-stone-900 w-full">
                  <div className=" bg-primary py-2 pr-4 pl-2 text-[14px] text-white font-medium">
                    {langData?.['my_transactions']}
                  </div>
                  <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
                  <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
                  <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
              </div>
              <DatePicker
                TransactionTypes={TransactionTypes}
                onDateChange={handleDateChange}
                filters={filters}
                setFilters={setFilters}
              />
              <MyTransactionTable records={records} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyTransaction;
// const mapStateToProps = (state: RootState) => {
//   return {
//     records: state.reports.records,
//   };
// };

// const mapDispatchToProps = (dispatch: Function) => {
//   return {
//     fetchTransactionRequest: (event: any) =>
//       dispatch(fetchTransactionRequest(event)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(MyTransaction);
