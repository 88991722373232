import React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAppSelector } from "../../../store/storeHooks";

interface StepTwoProps {
  username: string;
  password: string;
  onUsernameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showPasswordClickHandler: Function;
  showPassword: boolean;
}

const StepTwo: React.FC<StepTwoProps> = ({
  username,
  password,
  onUsernameChange,
  onPasswordChange,
  showPasswordClickHandler,
  showPassword,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <>
      <div className="mt-6">
        <label htmlFor="username" className="self-start text-neutral-500">
          {langData?.['username']}
        </label>
        <input
          type="text"
          id="username"
          className="px-3 py-4 mt-3 h-[44px] w-full bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60"
          placeholder={langData?.['enter_username_txt']}
          value={username}
          onChange={onUsernameChange}
        />
      </div>
      <div className="mt-6">
        <label htmlFor="password" className="self-start text-neutral-500">
          {langData?.['password']}
        </label>
        <div className="flex gap-5 justify-between px-3.5 py-3 mt-3 bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            className="w-full bg-transparent border-none"
            placeholder={langData?.['enter_password']}
            value={password}
            onChange={onPasswordChange}
          />
          <div
            className="cursor-pointer"
            onClick={() => showPasswordClickHandler()}
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </div>
        </div>
      </div>
    </>
  );
};

// "w-full h-[44px] px-3 py-4  bg-white rounded-lg
export default StepTwo;
