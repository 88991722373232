import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleApiError } from "../../utils/helpers/handleApiError.helpers";
import { setLangData } from "./cmsSlice";
import { langData } from "../../constants/defaultlang";
import LANG_API from "../../api/api-services/lang-api";

export const getLangData = createAsyncThunk(
  "cms/lang.json",
  async (langCode: string, thunkAPI) => {
    try {
      const response = await LANG_API.get(
        `/languages/${langCode}/lang.json`
      );
      if (response.data) thunkAPI.dispatch(setLangData(response.data));
      else thunkAPI.dispatch(setLangData(langData));
    } catch (error) {
      thunkAPI.dispatch(setLangData(langData));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);