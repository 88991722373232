/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React, { useState } from "react";
import { MarketTypeMap, TransactionTypeMap } from "../../../../store/reports/reportSelectors";
import AccountDetails from "../AccountDetails/AccountDetails";
import { useAppSelector } from "../../../../store/storeHooks";

interface AccountStatementTableProps {
  records: any;
}

const AccountStatementTable: React.FC<AccountStatementTableProps> = (props) => {
  const { records } = props;
  const [active, setActive] = useState(false);
  const [selectedRecord,setSelectedRecord]=useState<any>();
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <>
      <div className="flex flex-col justify-center px-3 py-px mt-1.5 w-full text-[14px] font-medium rounded-md bg-neutral-200 text-neutral-500">
        <div className="overflow-x-scroll text-[14px]">
          <div className="flex gap-5 justify-between w-full">
            <div className="my-auto">{langData?.['date']}</div>
            <div className="flex gap-4 whitespace-nowrap">
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
                <div className="my-auto">{langData?.['transaction']}</div>
              </div>
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
                <div className="my-auto">{langData?.['event']}</div>
              </div>
              <div className="flex gap-2">
                <div className="shrink-0 w-px border border-solid border-neutral-500 h-[30px]" />
                <div className="my-auto">{langData?.['market_type']}</div>
              </div>
            </div>
          </div>
          {records?.map((row, index) => (
            <div
              key={index}
              className="flex px-[3px] flex-col items-center py-0.5 mt-1.5 w-full bg-white border-2 border-sky-200 border-solid"
            >
              <div className="flex justify-between items-center w-full max-w-[379px]">
                <div className="flex flex-col self-start mt-3 ">
                  <div className="text-sm font-medium text-stone-900">
                    {moment(row.transactionTime).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto text-wrap"> {TransactionTypeMap[row.transactionType]}</div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto text-wrap">{row.eventName}</div>
                </div>
                <div className="flex gap-2 text-[14px] font-medium text-black whitespace-nowrap">
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                  <div className="my-auto text-wrap"> {MarketTypeMap[row.marketType]}</div>
                </div>
              </div>
              <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
              <div className="flex w-full">
                <div className="flex justify-between w-full text-[12px] max-w-[310px]">
                  <div className="flex flex-col my-auto">
                    <div className="flex gap-1">
                      <div className="font-medium text-neutral-500">{langData?.['credit_debit']}:</div>
                      <div className="text-black">{row.amount}</div>
                    </div>
                    <div className="flex gap-3 items-center">
                      <div className="font-medium text-neutral-500">{langData?.['close_balance']}:</div>
                      <div className="text-black"> {row.balanceAfter}</div>
                    </div>
                  </div>
                  <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                </div>

                <div className="flex gap-5 justify-between w-full text-[12px] max-w-[310px]">
                  <div className="flex flex-col my-auto">
                    
                    <div className="flex  my-auto">
                      <div className="flex gap-1">
                        <div className="font-medium text-neutral-500">{langData?.['transaction_id']}:</div>
                        <div className="text-black">{row.transactionId}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shrink-0 w-px border border-solid border-neutral-200 h-[46px]" />
                <div className="flex items-center justify-center px-3 text-[12px]">
                  <div className="flex gap-1" onClick={()=>{
                                                setActive(true);
                                                setSelectedRecord(row)
                    }}>
                    <div className="font-medium items-center text-neutral-500">{'>'}</div>
                  </div>
                </div>
              </div>

            </div>
          ))}
          {records && records.length === 0 && (
            <div className=" text-center">{langData?.['records_not_found_txt']}</div>
          )}
          {active && <div className={`${active ? 'bottom-0 z-[10]' : 'top-[-1000px]'} fixed  right-[2px] h-[50%]  bg-white w-full `}>
            <AccountDetails setActive={setActive} details={selectedRecord} />
          </div>}
        </div>
      </div>
    </>
  );
};

export default AccountStatementTable;
