/**
 * This code was generated by Builder.io.
 */
import React from "react";
import TransactionFilter from "./TransactionFilter";
import TransactionList from "./TransactionList";
import purple from "../../../../assets/purple_bg.png";
import BallIcon from "../../../../assets/icons/BallIcon.webp";
import { useAppSelector } from "../../../../store/storeHooks";
interface ProfitLossProps {
  plStatement: any;
  fetchPLStatement?: Function;
  handleDateChange: Function;
  filters: any;
  setFilters: any;
  nextPage: Function;
  prevPage: Function;
  pageToken: string;
}

const ProfitLoss: React.FC<ProfitLossProps> = (props) => {
  const {
    plStatement,
    pageToken,
    nextPage,
    prevPage,
    fetchPLStatement,
    handleDateChange,
    filters,
    setFilters,
  } = props;
  const records = [{}];
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex overflow-hidden flex-col mx-auto w-full bg-zinc-100 max-w-[480px] transition ease-in-out duration-[2000ms]">
      
      <main className="flex flex-col pt-px mt-3 w-full bg-white ">
      <div className="flex flex-col justify-center items-start text-[14px] font-medium text-white rounded-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
      <div className=" bg-stone-900 flex w-full h-8 text-white text-[14px] font-semibold rounded-t-md">
        <div className="flex flex-col justify-center w-[70%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
          <div className="flex min-w-[170px]">
            <div className=" bg-primary w-full py-[0.4rem] pr-4 pl-2">
              {langData?.['betting_profit_and_loss']}
            </div>
            <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
          </div>
        </div>

      </div>
      </div>
        <TransactionFilter
          startDate={filters.startDate}
          filters={filters}
          endDate={filters.endDate}
          handleDateChange={handleDateChange}
        />
        <TransactionList plStatement={plStatement} filters={filters} setFilters={setFilters}/>
        <div className=" flex justify-center">
          {filters?.pageToken.length > 0 && (
            <button
              className=" p-2 w-16 leading-[16.2px] text-white bg-primary m-2"
              onClick={(e) => prevPage()}
            >
              {langData?.['prev']}({filters.pageToken.length})
            </button>
          )}
          {pageToken ? (
            <button
              className=" p-2 w-16 leading-[16.2px] text-white bg-primary m-2"
              onClick={(e) => nextPage()}
            >
              {langData?.['next']}({filters?.pageToken.length + 2})
            </button>
          ) : null}
        </div>
      </main>
    </div>
  );
};

export default ProfitLoss;
