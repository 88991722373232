/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React, { useState } from "react";
import Modal from "../../../Modal/Modal";
import PLStatementMktLvl from "./PLStatementMktLvl";
import {
  Filters,
  PLStatement,
} from "../../../../store/reports/reportSelectors";
import { useAppSelector } from "../../../../store/storeHooks";

interface ReportingTableProps {
  plStatement: any;
  filters: Filters;
  setFilters: Function;
}

const ReportingTable: React.FC<ReportingTableProps> = (props) => {
  const { plStatement, filters, setFilters } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { langData } = useAppSelector((state) => state.cms);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [selectedRecord, setSelectedRecord] = useState<PLStatement>();
  const headings = [
    "market_name",
    "start_time",
    "settled_time",
    "communication",
    "net_win",
  ];

  return (
    <>
      <table className="w-full border-collapse mt-3">
        <thead>
          <tr className="bg-neutral-200 text-neutral-500 h-8 leading-8">
            {headings.map((heading, index) => (
              <th
                key={index}
                className=" border-r border-[#757575]  font-[500] text-[14px] leading-[16.2px] "
              >
                {langData?.[heading]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {plStatement?.map((row) => (
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {row.eventName ? row.eventName : row.gameType} -{" "}
                {row.marketName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {moment(row?.betPlacedTime).format("D/M/YYYY HH:mm:ss")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {moment(row?.payOutDate).format("D/M/YYYY HH:mm:ss")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {!row.commission ? (
                  <div>{Number(0).toFixed(2)}</div>
                ) : row.commission > 0 ? (
                  <div className="profit">
                    {Number(row.commission).toFixed(2)}
                  </div>
                ) : row.commission < 0 ? (
                  <div className="loss">
                    {Number(row.commission).toFixed(2)}
                  </div>
                ) : (
                  <>{Number(row.commission).toFixed(2) || 0}</>
                )}
              </td>
              <td className={"px-6 py-4 whitespace-nowrap text-sm"}>
                {row.profit > 0 ? (
                  <div className="text-green-600">
                    {Number(row.profit + row.commission).toFixed(2)}
                  </div>
                ) : row.profit < 0 ? (
                  <div className="text-red-600">
                    {Number(row.profit + row.commission).toFixed(2)}
                  </div>
                ) : (
                  <> {Number(row.profit + row.commission).toFixed(2)}</>
                )}
              </td>
              <td
                onClick={() => {
                  setFilters({...filters, selectedMarket: row})
                  setSelectedRecord(row);
                  setIsModalOpen(true);
                }}
                className="px-6 py-4 cursor-pointer whitespace-nowrap text-sm"
              >
                {">"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {plStatement && plStatement.length === 0 && (
        <div className=" h-12 flex justify-center items-center">{langData?.['bet_not_found_txt']}</div>
      )}
      <Modal
        isOpen={isModalOpen}
        title={langData?.['profit_loss_details']}
        onClose={closeModal}
      >
        <PLStatementMktLvl selectedMarket={selectedRecord} filters={filters} />
      </Modal>
    </>
  );
};

export default ReportingTable;
