/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import FilterSection from "./FilterSection";
import GameTabs from "./GameTabs";
import BetList from "./BetList";

type StoreProps = {
    bets: any;
    fetchData: Function;
    nextPage:Function;
    prevPage:Function;
    handleDateChange:Function;
    filters:any;
    setFilters:any;
    nextPageToken:any;
    setNextPageToken:any;
    sports: any;
    games: any;
    betStatus: any;
};

const BettingApp: React.FC<StoreProps> = (props) => {
  const { bets, games, sports, betStatus, fetchData,nextPage,prevPage,handleDateChange,filters,setFilters,nextPageToken,setNextPageToken } = props;
  return (
    <div className="flex flex-col mx-auto w-full bg-zinc-100 max-w-[480px] overflow-scroll	">
      <div className="flex flex-col pt-px  mt-[12px] w-full bg-white border border-solid border-neutral-200">
      <div className="flex flex-col bg-zinc-100">
          <div className="w-full max-md:max-w-full ">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-full">
                <div className="flex flex-col py-px w-full text-[14px] font-medium bg-white rounded-md border border-solid border-neutral-200">
                  <div className="flex flex-col justify-center items-start text-[14px] h-9 text-white rounded-md bg-stone-900 max-md:pr-5">
                    <div className="flex ">
                      <div className=" bg-primary w-full h-9 py-[0.4rem] pr-4 pl-2 text-[14px] text-white">
                        My Bet
                      </div>
                      <div className=" skew-x-[-25deg] bg-primary h-9 -translate-x-[8px] w-6"></div>
                      <div className=" skew-x-[-25deg] bg-primary w-1 h-9 -translate-x-1"></div>
                      <div className=" skew-x-[-25deg] bg-primary w-1 h-9"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FilterSection
          onDateChange={handleDateChange}
          filters={filters}
          setFilters={setFilters}
          sports={sports}
          games={games}
          betStatus={betStatus}
        />
        <GameTabs filters={filters} setFilters={setFilters} sports={sports}/>
        <BetList bets={bets} />
      </div>
    </div>
  );
};

export default BettingApp;
