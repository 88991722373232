import { useState } from "react";
import Button from "./Button";
import InputField from "./InputField";
import ToggleButton from "./ToggleButton";
import SideScreen from "./ForgotPasswordDesktop/SideImageDesktop";
import { useAppSelector } from "../../../../store/storeHooks";

interface ForgotPasswordProps {
  redirectToSignUp: Function;
  method: string;
  handleMethodChange: Function;
  handleSubmit: Function;
  username: string;
  handleUsernameChange: Function;
  countryCode: string;
  handleCountryCodeChange: Function;
  mobile: string;
  handleMobileChange: Function;
  otpSuccess: boolean;
  otp: number;
  setOtp: Function;
  newPassword: string;
  setNewPassword: Function;
  confirmPassword: string;
  setConfirmPassword: Function;
  resetPassword: Function;
  showPasswordClickHandler: Function;
  showPassword: boolean;
  redirectToSignIn: Function;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const {
    redirectToSignUp,
    method,
    otpSuccess,
    mobile,
    handleMobileChange,
    username,
    countryCode,
    handleCountryCodeChange,
    handleUsernameChange,
    handleMethodChange,
    handleSubmit,
    otp,
    setOtp,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    resetPassword,
    showPasswordClickHandler,
    showPassword,
    redirectToSignIn
  } = props;
  const [userName, setUserName] = useState<boolean>(true);
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <main className="flex mx-auto h-[100vh] w-[100%] bg-white items-center">
      <SideScreen tagline="Bet smarter,Bet with confidence, Win bigger: your ultimate bet experience" />
      <section className="w-[390px] p-[25px] -translate-x-40 relative right-[40px]  flex flex-col px-4 py-6  text-sm font-medium bg-white rounded-2xl border-4 border-solid border-neutral-200">
        <h1 className="self-center text-3xl font-semibold text-center text-stone-900">
          {langData?.['forgot_password']}
        </h1>
        {!otpSuccess ? (
          <>
            <p className="self-center mt-2.5 text-base text-center text-neutral-500 w-[254px]">
              {langData?.['forgot_password_send_otp_txt']}
            </p>
            <ToggleButton setUserName={setUserName} method={method} handleMethodChange={handleMethodChange} />
            <div className="mt-[24px]">
            {userName ? (
              <InputField
                label={langData?.['username']}
                value={username}
                onChange={(e) => handleUsernameChange(e)}
                placeholder={langData?.['enter_username_txt']}
              />
            ) : (
              <InputField
                label={langData?.['mobile']}
                value={mobile}
                onChange={(e) => handleMobileChange(e)}
                placeholder={langData?.['enter_mobile_no']}
              />
            )}
            </div>
            <Button onClick={() => handleSubmit()}>{langData?.['send_otp']}</Button>
          </>
        ) : (
          <>
            <InputField
              label={langData?.['otp']}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder={langData?.['enter_otp_txt']}
            />
            <InputField
              label={langData?.['new_password']}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder={langData?.['enter_new_password_txt']}
            />
            <InputField
              label={langData?.['confirm_password']}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={langData?.['enter_confirm_password_txt']}
            />
            <Button onClick={() => resetPassword()}>{langData?.['reset_password_txt']}</Button>
          </>
        )}
        <p className="self-center  mt-4 text-sm text-center text-stone-900">
          {langData?.['account_not_found_txt']}{" "}
          <button
            onClick={() => redirectToSignUp()}
            className="font-semibold text-[#4da8f1] underline"
          >
            {langData?.['sign_up']}
          </button>
        </p>
      </section>
    </main>
  //    <div className="min-h-screen flex items-center justify-center bg-gray-100">
  //    <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
  //      <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>
  //      {!otpSuccess ? (
  //        <>
  //          <p className="mb-6">
  //            We’ll send OTP on your registered number or username.
  //          </p>
  //          <div className="mb-4 flex">
  //            <button
  //              className={`flex-1 py-2 ${
  //                method === "Username"
  //                  ? "bg-primarytext-white"
  //                  : "bg-gray-200"
  //              }`}
  //              onClick={() => handleMethodChange("Username")}
  //            >
  //              Username
  //            </button>
  //            <button
  //              className={`flex-1 py-2 ${
  //                method === "mobile"
  //                  ? "bg-primarytext-white"
  //                  : "bg-gray-200"
  //              }`}
  //              onClick={() => handleMethodChange("mobile")}
  //            >
  //              Mobile Number
  //            </button>
  //          </div>
  //          <form onSubmit={(e) => handleSubmit(e)}>
  //            {method === "Username" ? (
  //              <div className="mb-4">
  //                <label className="block text-gray-700">Username</label>
  //                <input
  //                  type="text"
  //                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //                  value={username}
  //                  onChange={(e) => handleUsernameChange(e)}
  //                  placeholder="Enter username"
  //                />
  //              </div>
  //            ) : (
  //              <div className="mb-4">
  //                <label className="block text-gray-700">Mobile Number</label>
  //                <div className="flex">
  //                  <select
  //                    className="mt-1 block w-20 px-3 py-2 bg-white border border-gray-300 rounded-l-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //                    value={countryCode}
  //                    onChange={(e) => handleCountryCodeChange(e)}
  //                  >
  //                    <option value="+1">+1</option>
  //                    <option value="+91">+91</option>
  //                    <option value="+44">+44</option>
  //                    <option value="+61">+61</option>
  //                    {/* Add more country codes as needed */}
  //                  </select>
  //                  <input
  //                    type="text"
  //                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-r-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //                    value={mobile}
  //                    onChange={(e) => handleMobileChange(e)}
  //                    placeholder="Enter mobile number"
  //                  />
  //                </div>
  //              </div>
  //            )}
  //            <button
  //              type="submit"
  //              className="w-full bg-purple-600 text-white py-2 rounded-md hover:bg-purple-700 focus:outline-none focus:bg-purple-700"
  //            >
  //               Send OTP
  //            </button>
  //          </form>
  //        </>
  //      ) : (
  //        <>
  //          <div className="mb-4">
  //            <label className="block text-gray-700">OTP</label>
  //            <input
  //              type="number"
  //              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //              value={otp}
  //              onChange={(e) => setOtp(e.target.value)}
  //              placeholder="Enter OTP"
  //            />
  //          </div>
  //          <div className="mb-4">
  //            <label className="block text-gray-700">New passsword</label>
  //            <input
  //              type="text"
  //              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //              value={newPassword}
  //              onChange={(e) => setNewPassword(e.target.value)}
  //              placeholder="Enter username"
  //            />
  //          </div>
  //          <div className="mb-4">
  //            <label className="block text-gray-700">Confirm password</label>
  //            <input
  //              type="text"
  //              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  //              value={confirmPassword}
  //              onChange={(e) => setConfirmPassword(e.target.value)}
  //              placeholder="Enter username"
  //            />
  //          </div>
  //          <button
  //            type="submit"
  //            className="w-full bg-purple-600 text-white py-2 rounded-md hover:bg-purple-700 focus:outline-none focus:bg-purple-700"
  //            onClick={() => resetPassword()}
  //          >
  //            Reset Password
  //          </button>
  //        </>
  //      )}
  //      <p className="mt-4 text-center">
  //        Don't have an account?{" "}
  //        <a href="#"
  //        onClick={() => redirectToSignUp()}
  //         className="text-purple-600 hover:underline">
  //          Sign Up
  //        </a>
  //      </p>
  //    </div>
  //  </div>
  );
};

export default ForgotPassword;
