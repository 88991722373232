/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import StakeSettingRow from "./StakeSettingRow";
import { connect, useDispatch } from "react-redux";
import {
  fetchButtonVariables,
  updateButtonVariables,
} from "../../../store/betSlip/betSlipThunk";
import { RootState } from "../../../store";
import { ButtonVariable } from "../../../models/ButtonVariables";
import { showToast } from "../../../store/common/commonSlice";
import purple from "../../../assets/purple_bg.png";
import { useAppSelector } from "../../../store/storeHooks";

interface StakeSettingsProps {
  buttonVariables: [];
  fetchButtonVariables: Function;
  updateButtonVariables: Function;
}

const StakeSettingsContent: React.FC<StakeSettingsProps> = (props) => {
  const { fetchButtonVariables, updateButtonVariables, buttonVariables } =
    props;
  const { langData } = useAppSelector((state) => state.cms);

  const [updateVariables, setUpdateVariables] =
    useState<ButtonVariable[]>(buttonVariables);
  const dispatch = useDispatch();

  useEffect(() => {
    buttonVariables.length && setUpdateVariables(buttonVariables);
  }, [buttonVariables]);

  const updateButtonLabel = (index: number, label: string) => {
    const updateBtnVars = [...updateVariables];
    updateBtnVars[index] = { 
      ...updateBtnVars[index], 
      label 
    };
    setUpdateVariables(updateBtnVars);
  };

  const updateButtonAmount = (index: number, amt: number) => {
    const updateBtnVars = [...updateVariables];
    updateBtnVars[index] = {
      ...updateBtnVars[index],
      stake: amt,
    };
    updateBtnVars.length && setUpdateVariables(updateBtnVars);
  };

  const addOrRemove = (index: number, stake: number, operation: string) => {
    if (stake === undefined || stake === null) {
      dispatch(
        showToast({
          type: "error",
          message: langData?.['enter_min_value_txt'],
        })
      );
      return;
    }
    const updateBtnVars = [...updateVariables];
    const targetVar = { ...updateBtnVars[index] };
    if (operation === "+") {
      targetVar.stake += targetVar.stake;
    } else {
      targetVar.stake -= Math.floor(Number(targetVar.stake) / 2);
      if (targetVar.stake <= 0) {
        targetVar.stake = 0;
      }
    }
    updateBtnVars[index] = targetVar;
    if (updateBtnVars.length) {
      setUpdateVariables(updateBtnVars);
    }
  };

  useEffect(() => {
    fetchButtonVariables();
  }, []);

  return (
    <>
      <div className="flex flex-col mt-2 justify-center items-start text-[14px] font-medium text-white rounded-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
      <div className=" bg-stone-900 flex w-full h-8 text-white text-[14px] font-semibold rounded-t-md">
        <div className="flex flex-col justify-center w-[70%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
          <div className="flex min-w-[170px]">
            <div className=" bg-primary w-full py-[0.4rem] pr-4 pl-2">
              {langData?.['stake_settings']}
            </div>
            <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
          </div>
        </div>

      </div>
      </div>
      <div className="flex flex-col px-3.5 h-[100%] max-md:max-w-full bg-white">
        <h2 className="text-sm font-[500] mt-2 text-[14px] leading-[18.9px] text-stone-900 max-md:max-w-full">
          {langData?.['change_input_label_settings_txt']}:
        </h2>
        <div className="mt-4 max-md:max-w-full">
          <div className="flex flex-wrap gap-5 max-md:flex-col">
            {updateVariables?.map((setting: ButtonVariable, index) => (
              <StakeSettingRow
                idx={index}
                label={setting.label}
                value={setting.stake}
                updateButtonLabel={updateButtonLabel}
                addOrRemove={addOrRemove}
                updateButtonAmount={updateButtonAmount}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    buttonVariables: state.betSlip.buttonVariables,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchButtonVariables: () => dispatch(fetchButtonVariables()),
    updateButtonVariables: (event: any) =>
      dispatch(updateButtonVariables(event)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StakeSettingsContent);
