import React, { useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";


const RulesPopUp = () => {
  const [isOpen1, setisOpen1] = useState(false);
  const [isOpen2, setisOpen2] = useState(false);
  const [isOpen3, setisOpen3] = useState(false);
  const [isOpen4, setisOpen4] = useState(false);
  const [isOpen5, setisOpen5] = useState(false);
  const [isOpen6, setisOpen6] = useState(false);
  const [isOpen7, setisOpen7] = useState(false);

  return (
    <div className="h-[70%] overflow-y-scroll scrollbar-hide ">
      <div
        className="transition-all  mb-2 ease-in-out duration-200 cursor-pointer"
        onClick={() => setisOpen1(!isOpen1)}
      >
        
        <span className="flex items-center gap-2 text-xl cursor-pointer border-b mb-3 pb-2">{
            isOpen1?<IoIosArrowDropdown/>:<IoIosArrowDropup/>
        }Match Odds</span>
      </div>

      <div
        
      >
        {isOpen1 && (
          <div>
            {/* <div>➢ Match Odds</div> */}
            <p>
            ➢ Match Odds & Book Maker Market Will Suspend If Play Stop On Rain Or Any Other Technical Reasons..
            </p>
            <p>
            ➢ At the Moment of the Third Umpire Decision We Can't Accept Bets in Any Fancy and Match Odds
            </p>
          
           
          </div>
        )}
      </div>
      <div
        className="transition-all  mb-2 ease-in-out duration-200 cursor-pointer"
        onClick={() => setisOpen2(!isOpen2)}
      >
        
        <span className="flex items-center gap-2 text-xl cursor-pointer border-b mb-3 pb-2">{
            isOpen2?<IoIosArrowDropdown/>:<IoIosArrowDropup/>
        }Fancy</span>
      </div>

      <div
        
      >
        {isOpen2 && (
          <div>
            {/* <div>➢ Match Odds</div> */}
            <p>
            ➢ All fancy bets will be validated when match has been tied.
            </p>
            <p>
            ➢ All advance fancy will be suspended before toss or weather condition.
            </p>
            <p>
            ➢ In case technical error or any circumstances any fancy is suspended and does not resume result will be given all previous bets will be valid.
            </p>

            {/* <div>➢ Test matches</div> */}
            <p>
            ➢ If any case wrong rate and wrong commentary has been given in fancy that particular bets will be cancelled.
            </p>

            {/* <div>➢ Limited Over matches</div> */}
            <p>
            ➢ In any circumstances management decision will be final related to all exchange items.
            </p>

            {/* <div>➢ Super Over rule</div> */}
            <p>
            ➢ In case customer make bets in wrong fancy we are not liable to delete, no changes will be made and bets will be consider as confirm bet.
            </p>
            <p>
            ➢ Due to any technical error market is open and result has come all bets after result will be deleted.
            </p>
            <p>
            ➢ Four, sixes, wide, wicket, extra run, total run, highest over and top batsman, maiden over, caught-out, no-ball, run-out, fifty and century are valid only match has been completed in case due to rain over has been reduced all other fancy will be deleted. All complete events are valid E.x 1 over run team A etc.. .
            </p>
            <p>
            ➢ Penalty runs will not be counted in any fancy.
            </p>
            <p>
            ➢ Due to mistakes in official scorecard our scorecard and results will be valid.
              </p>
              <p>
              ➢ Our exchange is not responsible for misuse of client id,master account and super master account.
              </p>
              <p>
              ➢ Minimum number of legal over has to be bowled by the bowler mentioned in the event, else the same will not be considered as valid.
              </p>
          </div>
        )}
      </div>
      <div
        className="transition-all  mb-2 ease-in-out duration-200 cursor-pointer"
        onClick={() => setisOpen3(!isOpen3)}
      >
        
        <span className="flex items-center gap-2 text-xl cursor-pointer border-b mb-3 pb-2">{
            isOpen3?<IoIosArrowDropdown/>:<IoIosArrowDropup/>
        }Player Bookmaker</span>
      </div>

      <div
        
      >
        {isOpen3 && (
          <div>
            {/* <div>➢ Match Odds</div> */}
            <p>
            ➢ Suppose Either No1 Or No2 Player Score Compare To Run But Which Even IS Hire Score IS Win
            </p>
            <p>
            ➢ Explanation Of Player Bookmaker: Compare Both Player Runs At Wicket ..if V kohli Runs 22 and R.Sharma Run 20 At The Moment Of WICKET Then V KOHLI will BE Winner.


            </p>
            <p>
            ➢ If Both Player Score Is Level It's Means Virat Kohli & Rohit Sharma Both Are Either Out Match Over Is Complete, It's Mean Game Is Tie

            </p>

            {/* <div>➢ Test matches</div> */}
            <p>
            ➢ If Match Are Interpreted Due To Bed Weather Or Any Thing Else At That Time Low Score Player Run Are Not Count And Match Are Declared To ABANDONED
            </p>
          </div>
        )}
      </div>
      <div
        className="transition-all  mb-2 ease-in-out duration-200 cursor-pointer"
        onClick={() => setisOpen4(!isOpen4)}
      >
        
        <span className="flex items-center gap-2 text-xl cursor-pointer border-b mb-3 pb-2">{
            isOpen4?<IoIosArrowDropdown/>:<IoIosArrowDropup/>
        }Other fancy</span>
      </div>

      <div
        
      >
        {isOpen4 && (
          <div>
            {/* <div>➢ Match Odds</div> */}
            <p>
            ➢ T-20 ,one day and test match in case current innings player and partnership are running in between match has been called off or abandoned that situation all current player and partnership results are valid.
            </p>
            <p>
            ➢ 1st wicket lost to any team balls meaning that any team 1st wicket fall down in how many balls that particular fancy at least minimum one ball have to be played otherwise bets will be deleted.
            </p>
            <p>
            ➢ 1st wicket lost to any team fancy valid both innings.
            </p>

            {/* <div>➢ Test matches</div> */}
            <p>
            ➢ How many balls for 50 runs any team meaning that any team achieved 50 runs in how many balls that particular fancy at least one ball have to be played otherwise that fancy bets will be deleted
            </p>

            {/* <div>➢ Limited Over matches</div> */}
            <p>
            ➢ How many balls for 50 runs fancy any team only first inning valid.
            </p>

            {/* <div>➢ Super Over rule</div> */}
            <p>
            ➢ How many balls face any batsman meaning that any batsman how many balls he/she played that particular fancy at least one ball have to be played otherwise that fancy bets will be deleted.
            </p>
            <p>
            ➢ How many balls face by any batsman both innings valid.
            </p>
          </div>
        )}
      </div>
      <div
        className="transition-all  mb-2 ease-in-out duration-200 cursor-pointer"
        onClick={() => setisOpen5(!isOpen5)}
      >
        
        <span className="flex items-center gap-2 text-xl cursor-pointer border-b mb-3 pb-2">{
            isOpen5?<IoIosArrowDropdown/>:<IoIosArrowDropup/>
        }T20/ODI Rules</span>
      </div>

      <div
        
      >
        {isOpen5 && (
          <div>
            <div >
              <div className="font-bold">1. Session</div>
            <p>
            ➢ Match 1st over run advance fancy only 1st innings run will be counted.
            </p>
            <p>
            ➢ For example:- 35 over run team a is playing any case team A is all-out in 33 over team a has made 150 run the session result is validated on particular run.
            </p>
            <p>
            ➢ Advance session & All Fancy is valid in only 1st innings
            </p>
            </div>
            <div >
              <div className="font-bold">2 50 over runs</div>
            <p>
            ➢ In case 50 over is not completed all bet will be deleted due to weather or any condition.
            </p>
            <p>
            ➢ Advance 50 over runs is valid in only 1st innings.
            </p>
            </div>
            <div >
              <div className="font-bold">3 Odi / T20 batsman runs</div>
            <p>
            ➢ In case batsman is injured he/she is made 34 runs the result will be given 34 runs.
            </p>
            <p>
            ➢ In next men out fancy if player is injured particular fancy will be deleted.
            </p>
            <p>
            ➢ In advance fancy opening batsmen is only valid if same batsmen came in opening the fancy will be valid in case one batsmen is changed that particular player fancy will be deleted.
            </p>
            </div>
            <div >
              <div className="font-bold">4 T20 over runs</div>
            <p>
            ➢ Advance 20 over run is valid only in 1st innings. 20 over run will not be considered as valid if 20 overs is not completed due to any situation.
            </p>
            </div>
            <div >
              <div className="font-bold">5 T20 / ODI batsman runs</div>
            <p>
            ➢ In case batsman is injured he/she is made 34 runs the result will be given 34 runs.
            </p>
            <p>
            ➢ In next men out fancy if player is injured particular fancy will be deleted.
            </p>
            <p>
            ➢ In advance fancy opening batsmen is only valid if same batsmen came in opening the fancy will be valid in case one batsmen is changed that particular player fancy will be deleted.
            </p>
            </div>
            <div >
              <div className="font-bold">6 At the Moment of the Third Umpire Decision We Can't Accept Bets in Any Fancy and Match Odds.</div>
            </div>

           


           
          </div>
        )}
      </div>
      
      <div
        className="transition-all  mb-2 ease-in-out duration-200 cursor-pointer"
        onClick={() => setisOpen6(!isOpen6)}
      >
        
        <span className="flex items-center gap-2 text-xl cursor-pointer border-b mb-3 pb-2">{
            isOpen6?<IoIosArrowDropdown/>:<IoIosArrowDropup/>
        }Test Match</span>
      </div>

      <div
        
      >
        {isOpen6 && (
          <div>
            <div className="font-bold"> 1 Session</div>
            <p>
            ➢ Complete session valid in test.
            </p>
            <p>
            ➢ Session is not completed for ex:- India 60 over run session Ind is running in case India team declares or all-out at 55 over next 5 over session will be continue in England inning.
            </p>
            <p>
            ➢ 1st day 1st session run minimum 25 over will be played then result is given otherwise 1st day 1st session will be deleted.
            </p>
            <p>
            ➢ 1st day 2nd session run minimum 25 over will be played then result is given otherwise 1st day 2nd session will be deleted
              </p>
              <p>
              ➢ 1st day total run minimum 80 over will be played then result is given otherwise 1st day total run will be deleted
              </p>
              <p>
              ➢ Test match both advance session is valid.
              </p>

            <div className="font-bold">2 Test lambi/ Inning run
            </div>
            <p>
            ➢ Mandatory 70 over played in test lambi paari/ Innings run. If any team all-out or declaration lambi paari/ innings run is valid
            </p>

            {/* <div>➢ Limited Over matches</div> */}
            <p>
            ➢ In case due to weather situation match has been stopped all lambi trades will be deleted.
            </p>

            {/* <div>➢ Super Over rule</div> */}
            <p>
            ➢ In test both lambi paari / inning run is valid in advance fancy.
            </p>
            <div className="font-bold">3 Test batsman</div>
            <p>➢ In case batsmen is injured he/she is made 34 runs the result will be given 34 runs.</p>
            <p>➢ Batsman 50/100 run if batsman is injured or declaration the result will be given on particular run.</p>
            <p>➢ In next men out fancy if player is injured particular fancy will be deleted.</p>
            <p>➢ In advance fancy opening batsmen is only valid if same batsmen came in opening the fancy will be valid in case one batsmen is changed that particular player fancy will be deleted.</p>
            <p>➢ Test match both advance fancy batsmen run is valid.</p>
            <div className="font-bold">4 Other fancy advance</div>
            <p>➢ Four, sixes, wide, wicket, extra run, total run, highest over and top batsmen is valid only if 300 overs has been played or the match has been won by any team otherwise all these fancy will be deleted.</p>
            <div className="font-bold">5 Concussion in Test</div>
            <p>➢ All bets of one over session will be deleted in test scenario, in case session is incomplete. For example innings declared or match suspended to bad light or any other conditions.</p>
            <div className="font-bold">6 Total Match- Events (Test)</div>
            <p> ➢ Minimum of 300 overs to be bowled in the entire test match, otherwise all bets related to the particular event will get void. For example, Total match caught outs will be valid only if 300 overs been bowled in the particular test match</p>
          </div>
        )}
      </div>
      <div
        className="transition-all  mb-2 ease-in-out duration-200 cursor-pointer"
        onClick={() => setisOpen7(!isOpen7)}
      >
        
        <span className="flex items-center gap-2 text-xl cursor-pointer border-b mb-3 pb-2">{
            isOpen7?<IoIosArrowDropdown/>:<IoIosArrowDropup/>
        }Cheating</span>
      </div>

      <div
        
      >
        {isOpen7 && (
          <div className="">
            {/* <div>➢ Match Odds</div> */}
            <p>
            ➢ Cheating Of Any Kind Is Not Allowed And Customers Bets Who Are Deemed To Be Cheating Are Liable To Have Bets Made Void.
            </p>
            <p>
            Cheating Includes But Is Not Limited To; Market Price Manipulation, Court Siding, Sniping And Commission Abuse.
            </p>
            <p>
            ➢ The Site Reserves The Right To Void Any Bets That Are Under Review As Part Of Any Integrity Investigation.
            </p>

            {/* <div>➢ Test matches</div> */}
            <p>
            ➢ The Site May Void Certain Bets In A Market Or Void A Whole Market In Its Entirety As A Result Of Any Integrity Investigation.
            </p>

            {/* <div>➢ Limited Over matches</div> */}
            <p>
            ➢ Arbitrage betting not allowed on our platform - Arbitrage bet in sports betting is an activity where you simultaneously place bets on all possible outcomes (back/lay) of an event at odds that guarantee a profit, whatever the result of the event will be.
            </p>

            {/* <div>➢ Super Over rule</div> */}
            <p>
            ➢ Users involved in one sided trades, advantage trades for commission will be suspended.
            </p>
            <p>
            If there's repetition in IP, bets would be voided and site decision is final.
            </p>
            <p>
            ➢ Clients who are placing bets only on 20over session adv and placing minimal bets on other fancy markets , trying to take advantage of site commission policies will be strictly monitored.
            </p>
            <p>Any winning bets involved in such practices will be voided !!.</p>
            <p>➢ The Site's Decision In Such Integrity Cases Shall Be ﬁnal And Binding Upon The Customer.</p>
            <p>➢ Customers Using VPN Applications To Mask Location Or Proxy Servers To Mask Device Are Liable To Having Bets Invalidated.</p>
            <p>Customers Appearing From Multiple IP Locations Are Also Liable To Having Bets Invalidated.</p>
          </div>
        )}
      </div>
      
    
     


  
    </div>
  );
};

export default RulesPopUp;
