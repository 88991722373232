import React, { useEffect, useState } from "react";
import Carousel from "../../components/Desktop/MainContent/Carousel";
import MatchList from "../../components/Desktop/Tables/MatchList";
import { EventDTO } from "../../models/common/EventDTO";
import { disconnectToWS, subscribeWsForEventOdds } from "../../webSocket/websocket";
import LiveVirtualFilter from "../../common/LiveVirtualFilter/LiveVirtualFilter";

type InplayEventsObj = {
  sportId: string;
  sportName: string;
  sportSlug: string;
  events: EventDTO[];
};
interface FilterState {
  isLive: boolean;
  isVirtual: boolean;
}
interface FiltersState {
  [index: number]: FilterState;
}

type InplayEventProps = {
  inplayEvents: InplayEventsObj[];
  loggedIn: boolean;
  topicUrls: any;
  betFairWSConnected: boolean;
  getBannerGameUrl: Function;
  games: any;
};

const InplayPage: React.FC<InplayEventProps> = (props) => {
  const {
    inplayEvents,
    getBannerGameUrl,
    games,
    topicUrls,
    loggedIn,
    betFairWSConnected,
  } = props;
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [showLive, setShowLive] = useState(false);
  const [wsChannels, setWsChannels] = useState<string[]>([]);
  const [matchOddsBaseUrl, setMatchOddsBaseUrl] = useState<string>("");
  const [matchOddsTopic, setMatchOddsTopic] = useState<string>("");
  const [inPlayLiveVirtualFilters, setInPlayLiveVirtualFilters] =useState<FiltersState>({});

  const updateMatchOddsTopic = (
    currentTopic: string,
    currentBaseUrl: string
  ) => {
    if (
      matchOddsTopic !== currentTopic ||
      matchOddsBaseUrl !== currentBaseUrl
    ) {
      disconnectToWS();
      setMatchOddsTopic(currentTopic);
      setMatchOddsBaseUrl(currentBaseUrl);
    }
  };
  const handleInPlayFilterChange = (
    index: number,
    newIsLive: boolean,
    newIsVirtual: boolean
  ) => {
    setInPlayLiveVirtualFilters((prevFilters) => ({
      ...prevFilters,
      [index]: {
        isLive: newIsLive,
        isVirtual: newIsVirtual,
      },
    }));
  };

  useEffect(() => {
    if (loggedIn && inplayEvents.length > 0 && topicUrls?.matchOddsTopic) {
      for (let iEvent of inplayEvents) {
        const subs = [...wsChannels];
        updateMatchOddsTopic(
          topicUrls?.matchOddsTopic,
          topicUrls?.matchOddsBaseUrl
        );
        for (let sEvent of iEvent.events) {
          if (!subs.includes(sEvent.eventId) && sEvent?.enableMatchOdds) {
            subs.push(sEvent.eventId);
            subscribeWsForEventOdds(
              topicUrls?.matchOddsTopic,
              sEvent.sportId,
              sEvent.competitionId,
              sEvent.eventId,
              sEvent.matchOdds?.marketId
            );
          }
        }
        setWsChannels(subs);
      }
    }
  }, [betFairWSConnected, inplayEvents, loggedIn]);

  useEffect(() => {
    return () => {
      disconnectToWS();
    }
  }, []);

  return (
    <>
      <Carousel getBannerGameUrl={getBannerGameUrl} games={games} />
      {/* <DateSelector
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                showLive={showLive}
                setShowLive={setShowLive} /> */}
      {inplayEvents?.length > 0 &&
  inplayEvents?.map((iEvent, idx) => {
    const { isLive, isVirtual } = inPlayLiveVirtualFilters[idx] || {
      isLive: false,
      isVirtual: false,
    };
    return (
      <>
        <LiveVirtualFilter
         isLive={isLive}
         isVirtual={isVirtual}
         onFilterChange={(
           newIsLive: boolean,
           newIsVirtual: boolean
         ) => {
           handleInPlayFilterChange(idx, newIsLive, newIsVirtual);
         }}
        />
        <MatchList
          selectedDate={selectedDate}
          showLive={showLive}
          iEvent={iEvent}
        isLive={isLive}
          isVirtual={isVirtual}
        />
      </>
    );
  })
}

    </>
  );
};

export default InplayPage;
