/**
 * This code was generated by Builder.io.
 */
import React from "react";
import PaymentOptions from "./PaymentOptions";
import WithdrawForm from "./WithdrawForm";
import purple from "../../../assets/purple_bg.png";
import PaymentOptionTabs from "./PaymentOptionTabs";
import { WithdrawComponentProps } from "./utils/types";
import AddAccountForm from "./AddAccountForm";
import { useAppSelector } from "../../../store/storeHooks";

const WithdrawDesktop: React.FC<WithdrawComponentProps> = ({
  currentSelectedAccount,
  addAccountFormData,
  handleAddAccountFormChange,
  handleWithdrawFormChange,
  isWithdrawSaveBtnDisabled,
  onAccountDeleteClick,
  onAddAccountSaveBtnClick,
  onAddNewAccountPress,
  onSavedAccountCardClick,
  onWithdrawFormSave,
  paymentMethodList,
  resetForms,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  showAddAccountForm,
  withdrawFormData,
  isAddAccountSaveBtnDisabled,
  addUpiAccountFormData,
  handleAddUpiAccountFormChange,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex flex-col bg-zinc-100 max-w-[900px]">
      <div className="w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
            <main className="flex flex-col  pt-px pb-5 mt-3.5 w-full bg-white rounded-md border border-solid border-neutral-200 max-md:mt-6 max-md:max-w-full">
              <h1 className="flex flex-col justify-center items-start text-[14px] font-medium text-white whitespace-nowrap rounded-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
              <div className="flex flex-col justify-center items-start text-[14px] h-9 text-white rounded-md bg-stone-900 max-md:pr-5">
                    <div className="flex ">
                      <div className=" bg-primary w-full h-9 py-2 pr-4 pl-2 text-[14px] text-white">
                        {langData?.['withdraw']}
                      </div>
                      <div className=" skew-x-[-25deg] bg-primary h-9 -translate-x-[8px] w-6"></div>
                      <div className=" skew-x-[-25deg] bg-primary w-1 h-9 -translate-x-1"></div>
                      <div className=" skew-x-[-25deg] bg-primary w-1 h-9"></div>
                    </div>
                  </div>
              </h1>

              <PaymentOptionTabs
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />

              <div className="pl-2">
                <PaymentOptions
                  onAccountDeleteClick={onAccountDeleteClick}
                  onAddNewAccountPress={onAddNewAccountPress}
                  onSavedAccountCardClick={onSavedAccountCardClick}
                  paymentMethodList={paymentMethodList}
                />

                {showAddAccountForm ? (
                  <AddAccountForm
                    addUpiAccountFormData={addUpiAccountFormData}
                    handleAddUpiAccountFormChange={
                      handleAddUpiAccountFormChange
                    }
                    selectedPaymentMethod={selectedPaymentMethod}
                    addAccountFormData={addAccountFormData}
                    handleAddAccountFormChange={handleAddAccountFormChange}
                    isSaveBtnDisabled={isAddAccountSaveBtnDisabled}
                    onSave={onAddAccountSaveBtnClick}
                    resetForms={resetForms}
                  />
                ) : (
                  <>
                    {currentSelectedAccount && (
                      <WithdrawForm
                        withdrawFormData={withdrawFormData}
                        handleWithdrawFormChange={handleWithdrawFormChange}
                        resetForms={resetForms}
                        isSaveBtnDisabled={isWithdrawSaveBtnDisabled}
                        onSave={onWithdrawFormSave}
                      />
                    )}
                  </>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawDesktop;
