import { RootState } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
// import { setSelectedCompetitionView } from "../../../store/live-virtual-filters/liveVirtualFiltersSlice";
// import { competitionViewMenuOptions } from "../../../utils/constants/header.constants";
import FilterIconWhite from "../../../assets/icons/filter-icon-white.svg";
// import MenuComponent from "./MenuComponent";
import { useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
// import { IN_PLAY_ROUTE } from "../routes/routes";

interface LiveVirtualFilterProps {
    //   pageTitle?: string;
    //   hasViewByButton?: boolean;
    isLive?: boolean;
    isVirtual?: boolean;
    onFilterChange: any;
}

const LiveVirtualFilter: React.FC<LiveVirtualFilterProps> = ({
    //   pageTitle,
    //   hasViewByButton = true,
    isLive,
    isVirtual,
    onFilterChange,
}) => {
    const pathName = useLocation().pathname;
    //   const { selectedCompetitionView } = useAppSelector(
    //     (state: RootState) => state.liveVirtualFilter
    //   );

    const dispatch = useAppDispatch();
    const { langData } = useAppSelector((state) => state.cms);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    //   const isInplayRoute = pathName === `${IN_PLAY_ROUTE}`;

    const handleLiveChange = () => {
        onFilterChange(!isLive, isVirtual);
    };

    const handleVirtualChange = () => {
        onFilterChange(isLive, !isVirtual);
    };

    return (
        <>

            {
                <div className="flex items-center py-1 gap-2">
                    <div className="flex items-center gap-2">
                        <p
                            className={`flex gap-1 text-[10px] items-center justify-center border rounded-full px-2 py-1 cursor-pointer ${isLive
                                    ? "bg-green-500 text-white border-transparent"
                                    : "bg-transparent text-black border-black"
                                }`}
                            onClick={handleLiveChange}
                        >
                            <span>{isLive ? "+" : "-"}</span>
                            <span>{langData?.['live']}</span>
                        </p>
                        <p
                            className={`flex gap-1 text-[10px] items-center justify-center border rounded-full px-2 py-1 cursor-pointer ${isVirtual
                                    ? "bg-green-500 text-white border-transparent" 
                                    : "bg-transparent text-black border-black"
                                }`}
                            onClick={handleVirtualChange}
                        >
                            <span>{isVirtual ? "+" : "-"}</span>
                            <span>{langData?.['virtual']}</span>
                        </p>
                    </div>
                </div>

            }
            {/* </div> */}
        </>
    );
};

export default LiveVirtualFilter;
