/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import PersonalInfo from "./ProfileTabs/PersonalInfo";
import ChangePassword from "./ProfileTabs/ChangePassword";
import WithdrawComponent from "../../../views/WithdrawView";
import Referral from "./ProfileTabs/Referral";
import MyBets from "./ProfileTabs/MyBets";
import MyWallet from "./ProfileTabs/MyWallet";

import personal_info from "../../../assets/personal_info.svg";
import change_password from "../../../assets/change_password.svg";
import deposit from "../../../assets/deposit.svg";
import withdraw from "../../../assets/withdraw.svg";
import referral from "../../../assets/referral.svg";
import DepositView from "../../../views/DepositView/DepositView";
import { FaUserCircle } from "react-icons/fa";
import { useAppSelector } from "../../../store/storeHooks";

interface ProfileSectionProps {
  username: string;
  profileDetails: any;
  balances: any;
  setProfileDetails: Function;
  updateUserDetails: Function;
  resetDetails: Function;
  updateNewPassword: Function;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  setOldPassword: Function;
  setNewPassword: Function;
  setConfirmPassword: Function;
  resetPassword: Function;
}

const ProfileSection: React.FC<ProfileSectionProps> = (props) => {
  const {
    profileDetails,
    resetDetails,
    balances,
    updateUserDetails,
    setProfileDetails,
    updateNewPassword,
    username,
    oldPassword,
    newPassword,
    confirmPassword,
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
    resetPassword
  } = props;
  const [activeTab, setActiveTab] = useState("Referral");
  const { langData } = useAppSelector((state) => state.cms);

  const menuItems = [
    { icon: personal_info, label: "Personal Info", langKey: 'personal_info' },
    { icon: change_password, label: "Change Password", langKey: 'change_password_txt' },
    { icon: deposit, label: "Deposit", langKey: 'deposit' },
    { icon: withdraw, label: "Withdraw", langKey: 'withdraw' },
    { icon: referral, label: "Referral", langKey: 'referral' },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "Personal Info":
        return (
          <PersonalInfo
            resetDetails={resetDetails}
            updateUserDetails={updateUserDetails}
            setProfileDetails={setProfileDetails}
            profileDetails={profileDetails}
          />
        );
      case "Change Password":
        return (
          <ChangePassword
            oldPassword={oldPassword}
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            updateNewPassword={updateNewPassword}
            setOldPassword={setOldPassword}
            setNewPassword={setNewPassword}
            setConfirmPassword={setConfirmPassword}
            resetPassword={resetPassword}
          />
        );
      case "Deposit":
        return <DepositView />;
      case "Withdraw":
        return <WithdrawComponent />;
      case "Referral":
        return <Referral />;
      default:
        return null;
    }
  };

  return (
    <div className="flex max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-3 py-4 mx-auto w-[30%] text-sm font-medium bg-white border border-solid border-neutral-200 text-stone-900 max-md:mt-2.5 min-w-[167px]">
        <div className="flex gap-3">
          <FaUserCircle className=" aspect-square" size={32} />
          <div className="my-auto">{username}</div>
        </div>
        <div className="flex flex-col justify-center text-center px-3 py-2 mt-6 rounded-md border border-solid border-neutral-200 max-md:px-5">
          <div className="self-center">
            {balances.exposure + " " + balances.currencyType}
          </div>
          <div className="mt-1">{langData?.['exposure_credited']}</div>
        </div>
        <div className="flex flex-col justify-center text-center px-3 py-2 mt-2 rounded-md border border-solid border-neutral-200 max-md:px-5">
          <div className="self-center">{balances.balance}</div>
          <div className="mt-1">{langData?.['available_balance']}</div>
        </div>
        <div className="flex flex-col justify-center text-center px-3 py-2 mt-2 rounded-md border border-solid border-neutral-200 max-md:px-5">
          <div className="self-center">{balances.bonus}</div>
          <div className="mt-1">{langData?.['bonus_rewarded']}</div>
        </div>
        {menuItems.map((item, index) => (
          <div
            key={index}
            className={`flex gap-3.5 py-3.5 p-2 mt-2 rounded-md border border-solid ${
              activeTab === item.label
                ? "text-white bg-neutral-500"
                : "bg-zinc-100 border-neutral-200"
            } cursor-pointer`}
            onClick={() => setActiveTab(item.label)}
          >
            <img
              loading="lazy"
              src={item.icon}
              className="shrink-0 w-4 aspect-square"
              alt=""
            />
            <div className="flex-auto">{langData?.[item.langKey]}</div>
          </div>
        ))}
      </div>
      <div className="flex flex-col ml-3 w-full  max-md:ml-0 max-md:w-full">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default ProfileSection;
