/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import { useAppSelector } from "../../../../../store/storeHooks";
// import purple from '../../../../assets/purple_bg.png'

interface ChangePasswordProps {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  updateNewPassword: Function;
  setOldPassword: Function;
  setNewPassword: Function;
  setConfirmPassword: Function;
  resetPassword: Function;
}

const ChangePasswordMobile: React.FC<ChangePasswordProps> = (props) => {
  const {
    oldPassword,
    newPassword,
    confirmPassword,
    updateNewPassword,
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
    resetPassword,
  } = props;
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <div className="flex flex-col pt-px pb-20 mt-3.5 w-full bg-white rounded-md  border-neutral-200 max-md:mt-6 max-md:max-w-full">
      {/* <div className="flex flex-col justify-center items-start text-[14px] font-medium text-white rounded-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
        <div style={{backgroundImage:`url(${purple})`}} className="flex bg-no-repeat overflow-hidden relative flex-col px-3 py-2 max-w-full aspect-[9.1] w-[282px] max-md:pr-5">
          Change Password
        </div>
      </div> */}
      <div className="mx-7 mt-6 text-base font-semibold text-center text-stone-900 tracking-[3.52px] max-md:mr-2.5 max-md:max-w-full">
        {langData?.['change_password_txt']}
      </div>
      <form className="flex flex-col w-full">
        <div className="flex w-full gap-3 mt-3.5 text-stone-900 max-md:flex-wrap">
          <div className="flex w-full flex-col px-5 py-0.5 max-md:max-w-full">
            <label htmlFor="firstName" className="max-md:max-w-full">
              {langData?.['old_password']}
            </label>
            <input
              id="firstName"
              type="text"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="px-3 py-2 mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pr-5 max-md:max-w-full"
              placeholder={langData?.['enter_old_password_txt']}
            />
          </div>
          <div className="flex w-full flex-col px-5 py-0.5 max-md:max-w-full">
            <label htmlFor="lastName" className="max-md:max-w-full">
              {langData?.['new_password']}
            </label>
            <input
              id="lastName"
              type="text"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="px-3 py-2 mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pr-5 max-md:max-w-full"
              placeholder={langData?.['enter_new_password_txt']}
            />
          </div>
        </div>
        <div className="flex w-full gap-3 mt-5 text-stone-900 max-md:flex-wrap">
          <div className="flex w-full flex-col px-5 py-0.5 max-md:max-w-full">
            <label htmlFor="confirmPassword" className="max-md:max-w-full">
              {langData?.['confirm_password']}
            </label>
            <input
              id="confirmPassword"
              type="text"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="px-3 py-2 mt-3 whitespace-nowrap rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pr-5 max-md:max-w-full"
              placeholder={langData?.['enter_confirm_password_txt']}
            />
          </div>
        </div>
        <div className="flex gap-3.5 px-5 justify-center mt-3 w-full text-[14px] font-medium whitespace-nowrap">
          <button
            type="reset"
            onClick={() => resetPassword()}
            className="flex-1 px-16 py-3 text-black rounded-md border border-primary border-solid"
          >
            {langData?.['reset']}
          </button>
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              updateNewPassword();
            }}
            className="flex-1 px-16 py-3 text-white bg-primary rounded-md"
          >
            {langData?.['save']}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordMobile;
