import moment from "moment";
import React from "react";
import {
  cFactor,
  MarketTypeMap,
  OutcomeDescMap,
} from "../../../store/reports/reportSelectors";
import { useAppSelector } from "../../../store/storeHooks";

interface CustomTableProps {
  row: any;
}

const CustomTable: React.FC<CustomTableProps> = (props) => {
  const { row } = props;
  const { langData } = useAppSelector((state) => state.cms);
  const headings = [
    "placed_date",
    "market_type",
    "bet_on",
    "odds",
    "stake",
    "returns",
    "status",
  ];

  return (
    <>
      <table className="w-full border-collapse mt-3">
        <thead>
          <tr className="bg-neutral-200 text-neutral-500">
            {headings.map((heading, index) => (
              <th
                key={index}
                className={`px-2 py-1 border-r ${
                  index < headings.length - 1 ? "border-[#757575]" : ""
                }`}
              >
                {langData?.[heading]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {row &&
          <tr>
          <td className=" text-center px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {moment(row.transactionTime).format("DD/MM/YYYY")}
          </td>
          <td className=" text-center px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {row?.marketType && MarketTypeMap[row.marketType]
              ? MarketTypeMap[row.marketType]
              : "-"}
          </td>
          <td className=" text-center px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {row.marketType === 2
              ? row.marketName +
                " @ " +
                Number(row.oddValue * 100 - 100).toFixed(0)
              : row.marketType === 5
              ? row?.outcomeName + " @ " + Number(row.oddValue).toFixed(0)
              : row?.outcomeName}
          </td>
          <td className=" text-center px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {row.oddValue && row.oddValue !== -1.0
              ? row.marketType === 2
                ? row.outcomeName.split(" ")[1]
                : row.marketType === 1
                ? (row.oddValue * 100 - 100).toFixed(2)
                : row.marketType === 5
                ? (2).toFixed(2)
                : row.oddValue.toFixed(2)
              : "-"}
          </td>
          <td className=" text-center px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {row.stakeAmount ? row.stakeAmount / cFactor : '-'}
          </td>
          <td className=" text-center px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {row.profitAmount ? (row.profitAmount / cFactor).toFixed(2) : '-'}
          </td>
          <td className=" text-center px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {OutcomeDescMap[row.betResult]}
          </td>
        </tr>}
        </tbody>
      </table>
      {row && row.length === 0 && (
        <div className=" text-center">{langData?.['records_not_found_txt']}</div>
      )}
    </>
  );
};

export default CustomTable;
