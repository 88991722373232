/**
 * This code was generated by Builder.io.
 */
import React from "react";

import purple from '../../../assets/purple_bg.png'
import { useAppSelector } from "../../../store/storeHooks";

const GameRules: React.FC = () => {
  const { langData } = useAppSelector((state) => state.cms);
  return (
    <>
    
       <div className="flex flex-col justify-center items-start text-[14px] font-medium text-white rounded-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
      <div className=" bg-stone-900 flex w-full h-8 text-white text-[14px] font-semibold rounded-t-md">
        <div className="flex flex-col justify-center w-[50%] items-start text-[14px] text-white rounded-md bg-stone-900 max-md:pr-5">
          <div className="flex min-w-[170px]">
            <div className=" bg-primary w-full py-[0.4rem] pr-4 pl-2">
              {langData?.['game_rules']}
            </div>
            <div className=" skew-x-[-25deg] bg-primary -translate-x-[7px] w-4 h-full"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full -translate-x-1"></div>
            <div className=" skew-x-[-25deg] bg-primary w-1 h-full"></div>
          </div>
        </div>
      </div>
      </div>
    <article className="flex flex-col pt-px pb-8 mt-3 w-full bg-white rounded-md border border-solid border-neutral-200 max-md:mt-6 max-md:max-w-full">
      {/* <header style={{backgroundImage:`url(${purple})`}} className=" font-[500] text-[14px] leading-[16.2px] flex h-8 text-white pl-2 bg-no-repeat flex-col justify-center items-start rounded-t-md bg-stone-900 max-md:pr-5 max-md:max-w-full">
        {langData?.['game_rules']}
      </header> */}
      <div className="flex font-[500] text-[14px] leading-[16.2px] gap-5 justify-between px-3 py-1.5 text-[14px] bg-neutral-200 text-stone-900 ">
        <h2 className="my-auto">{langData?.['football_fancy']}</h2>
      </div>
      <div className="mt-3.5 text-[14px] font-[400] text-black max-md:max-w-full">
        <p className=" font-[400] text-[14px] leading-[18.68px] text-stone-900 px-3 pb-3">
          The following terms and conditions apply to your use of this website
          (the "website") and its related or connected services (collectively,
          the "service"). You should carefully review these terms as they
          contain important information concerning your rights and obligations
          relating to your use of the website, whether as a guest or registered
          user with an account (an "account"). By accessing this website and
          using the service you agree to be bound by these terms together with
          any amendments which may be published from time to time by us. If you
          do not accept these terms, you must not access this website or use any
          part of it.
        </p>
        <div className="flex font-[500] text-[14px] leading-[16.2px] gap-5 justify-between px-3 py-1.5 text-[14px] bg-neutral-200 text-stone-900 ">
        <h2 className="my-auto font-medium">{langData?.['bookmaker']}</h2>
        </div>
        <p className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-4 px-3">
          We reserve the right to revise and amend these terms of service at any
          time. You should visit this page periodically to review the terms and
          conditions. Any such changes will be binding and effective immediately
          upon publication on this website, unless you object to any such
          changes, in which case you must stop using our services. Your
          continued use of our website following such publication will indicate
          your agreement to be bound by the terms as amended. Any bets not
          settled prior to such changes taking effect will be subject to the
          pre-existing terms.
        </p>
        <div className="flex font-[500] text-[14px] leading-[16.2px] gap-5 justify-between px-3 py-1.5 text-[14px] bg-neutral-200 text-stone-900 mt-3">
          <h2 className="my-auto">{langData?.['premium_market']}</h2>
        </div>
        <div className=" px-3 pt-3">
        <span className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-4 ">
          You Acknowledge That At All Times When Accessing The Website And Using
          The Service:
        </span>
        <ul className="list-decimal pl-5 mt-2">
          <li className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-2">
            You must be 18 years or older, or at least the legal age of majority
            in the jurisdiction where you live, to participate in any of our
            games. We reserve the right to ask for proof of age documents at any
            time.
          </li>
          <li className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-2">
            You Are Of Legal Capacity And Can Enter Into A Binding Legal
            Agreement With Us. You Must Not Access The Website Or Utilize The
            Service If You Are Not Of Legal Capacity.
          </li>
          <li className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-2">
            You Are A Resident In A Jurisdiction That Allows Gambling. You Are
            Not A Resident Of Any Country In Which Access To Online Gambling To
            Its Residents Or To Any Person Within Such Country Is Prohibited. It
            Is Your Sole Responsibility To Ensure That Your Use Of The Service
            Is Legal.
          </li>
          <li className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-2">
            You May Not Use A VPN, Proxy Or
            Similar Services Or Devices That Mask Or Manipulate The
            Identification Of Your Real Location.
          </li>
          <li className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-2">
            You Are The Authorized User Of The Payment Method You Use.
          </li>
          <li className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-2">
            You Must Make All Payments To Us In Good Faith And Not Attempt To
            Reverse A Payment Made Or Take Any Action Which Will Cause Such
            Payment To Be Reversed By A Third Party.
          </li>
          <li className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-2">
            When Placing Bets You May Lose Some Or All Of Your Money Deposited
            To The Service In Accordance With These Terms And You Will Be Fully
            Responsible For That Loss.
          </li>
          <li className="text-sm font-[400] text-[14px] leading-[18.68px] text-stone-900 mt-2">
            When Placing Bets You Must Not Use Any Information Obtained In
            Breach Of Any Legislation In Force In The Country In Which You Were
            When Family Members, Are Not Registered As An Affiliate In Our
            Affiliate Program.
          </li>
        </ul>
        </div>
      </div>
    </article>
    </>
  );
};

export default GameRules;
