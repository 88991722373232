import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import MyTransaction from "../components/Desktop/MyTransaction/MyTransaction";
import { RootState } from "../store";
import MyTransactionMobile from "../components/Mobile/MyTransaction/AccountStatement/MyTranaction";
import { fetchTransactionRequest } from "../store/reports/reportsThunk";
import { connect } from "react-redux";
import { defaultFilters, Filters } from "../store/reports/reportSelectors";
import moment from "moment";
import { useAppSelector } from "../store/storeHooks";
interface MyTransactionViewProps {
  records: any;
  fetchTransactionRequest: Function;
}
const MyTransactionView: React.FC<MyTransactionViewProps> = (props) => {
  const { records, fetchTransactionRequest } = props;
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const { langData } = useAppSelector((state) => state.cms);

  const TransactionTypes = [
    { value: "ALL", label: langData?.['all'] },
    { value: "DEPOSIT", label: langData?.['deposit'] },
    { value: "WITHDRAW", label: langData?.['withdraw'] },
  ];

  useEffect(() => {
    fetchTransactionRequest(filters);
  }, [filters]);

  const handleDateChange = (newFromDate: Date, newToDate: Date) => {
    setFilters({
      ...filters,
      startDate: moment(newFromDate),
      endDate: moment(newToDate),
    });
  };

  return (
    <>
      {isMobile ? (
        <MyTransactionMobile
          TransactionTypes={TransactionTypes}
          records={records}
          filters={filters}
          setFilters={setFilters}
          handleDateChange={handleDateChange}
          fetchTransactionRequest={fetchTransactionRequest}
        />
      ) : (
        <>
          <MyTransaction
            TransactionTypes={TransactionTypes}
            records={records}
            filters={filters}
            setFilters={setFilters}
            handleDateChange={handleDateChange}
            fetchTransactionRequest={fetchTransactionRequest}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    records: state.reports.records,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchTransactionRequest: (event: any) =>
      dispatch(fetchTransactionRequest(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTransactionView);
