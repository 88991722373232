/**
 * This code was generated by Builder.io.
 */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { navItems, subHeaders } from "../../../utils/commonUtils";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import { setCompetition, setEventType } from "../../../store/catalog";
import { SPORTS_MAP } from "../../../constants/ExchangeEventTypes";

interface NavigationProps {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const Navigation: React.FC<NavigationProps> = ({
  selectedTab,
  setSelectedTab,
}) => {
  const dispatch = useAppDispatch();
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <nav className="flex scrollbar-hide w-full h-9 overflow-x-scroll text-[14px] font-semibold tracking-wider text-center text-white uppercase bg-stone-900 ">
      {subHeaders('web').map((item, index) => (
          <div
            onClick={() => {
              dispatch(setCompetition({ id: '', name: '', slug: '' }));
              dispatch(setEventType(SPORTS_MAP.get(item.text)))
            }}
            className={`  my-auto min-w-fit h-8 leading-8 px-3 border-r   ${
              window.location.pathname === item.path
                ? "text-amber-400 text-opacity-90"
                : " "
            }`}
          >
            <Link key={item.text} to={item.path}>
              {langData?.[item.langKey]}
            </Link>
          </div>
      ))}
    </nav>
  );
};

export default Navigation;
