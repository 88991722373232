/**
 * This code was generated by Builder.io.
 */
import { useAppSelector } from "../../../store/storeHooks";
import { WithdrawFormType } from "./utils/types";
import { withdrawFormArr } from "./utils/Withdraw.utils";

interface WithdrawFormProps {
  withdrawFormData: WithdrawFormType;
  handleWithdrawFormChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  resetForms: () => void;
  isSaveBtnDisabled: boolean;
  onSave: (e: React.FormEvent<HTMLFormElement>) => void;
}

const WithdrawForm: React.FC<WithdrawFormProps> = ({
  withdrawFormData,
  handleWithdrawFormChange,
  resetForms,
  isSaveBtnDisabled,
  onSave,
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <form onSubmit={onSave}>
      <h2 className="mt-4 text-[14px] font-medium capitalize text-neutral-500 max-md:max-w-full">
        {langData?.['enter_payment_details']}:
      </h2>
      <div className="flex flex-col mt-3 text-[14px] font-medium text-stone-900 max-md:max-w-full">
        <div className="flex gap-3 max-md:flex-wrap">
          {withdrawFormArr.map((field) => {
            const { key, label, langKey, placeholder, type } = field;
            return (
              <div className="flex flex-col py-0.5 max-md:max-w-full">
                <label htmlFor={key} className="max-md:max-w-full">
                  {langData?.[langKey]}
                </label>
                <input
                  value={withdrawFormData[key]}
                  type={type}
                  id={key}
                  className="px-3 py-2 mt-3 rounded-md border border-solid bg-zinc-100 border-neutral-200 max-md:pr-5 max-md:max-w-full"
                  placeholder={langData?.[placeholder]}
                  onChange={handleWithdrawFormChange}
                />
              </div>
            );
          })}
        </div>
        <div className="shrink-0 mt-5 h-px border border-solid bg-neutral-200 border-neutral-200 max-md:max-w-full" />
      </div>
      <div className="flex gap-3 self-end mt-5 max-w-full text-sm font-medium text-center whitespace-nowrap w-[405px] max-md:mr-1.5">
        <button
          onClick={resetForms}
          type="reset"
          className="px-16 py-2.5 text-black rounded-md border border-white border-solid max-md:px-5"
        >
          {langData?.['reset']}
        </button>
        <button
          disabled={isSaveBtnDisabled}
          type="submit"
          className={`px-16 py-2.5 text-white ${
            isSaveBtnDisabled ? "bg-fuchsia-400" : "bg-primary"
          } rounded-md max-md:px-5`}
        >
          {langData?.['save']}
        </button>
      </div>
    </form>
  );
};

export default WithdrawForm;
