/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import cut_btn from "../../../../assets/cross.png";
import moment from "moment";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { fetchPLByMarket } from "../../../../store/reports/reportsThunk";
import { cFactor, Filters } from "../../../../store/reports/reportSelectors";
import { useAppSelector } from "../../../../store/storeHooks";

interface TransactionList {
  details: any;
  setActive: any;
  filters: Filters;
  items: any;
  backTotal: Number;
  layTotal: Number;
  marketTotal: Number;
  netMarket: Number;
  fetchPLByMarket: Function;
}
const AccountDetails: React.FC<TransactionList> = (props) => {
  const {
    details,
    filters,
    setActive,
    items,
    backTotal,
    layTotal,
    marketTotal,
    netMarket,
    fetchPLByMarket,
  } = props;
  const { langData } = useAppSelector((state) => state.cms);

  const tableFields = [
    {
      key: "betPlacedTime",
      Label: "Placed",
      className: "placed-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "selection",
      Label: "Selection",
      className: "selection-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "betId",
      Label: "Bet ID",
      className: "betid-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "betType",
      Label: "Type",
      className: "type-cell-head text-uppercase",
      align: "left",
    },
  ];

  useEffect(() => {
    fetchPLByMarket(filters);
  }, []);

  return (
    <>
      <div className="rounded-md ">
        <div className="w-[100%] flex px-[8px] justify-between items-center pt-[2px]">
          <div className="font-semibold">{langData?.['pl_details_txt']}</div>
          <div onClick={() => setActive(false)}>
            <img src={cut_btn} />
          </div>
        </div>
        <div className=" p-1">
            <div>
              <table className=" w-full text-[14px] ">
                <thead className=" w-full ">
                  <tr className=" bg-[#e0e0e0] h-8">
                    {tableFields.map((tF, index) => (
                      <th
                        key={"key_" + Math.random().toString(36).substr(2, 9)}
                        align={tF.align === "left" ? "left" : "center"}
                        className=" border-r border-[#757575] pl-4 text-[#757575]"
                      >
                        {tF.Label}
                      </th>
                    ))}
                  </tr>
                </thead>
                {items && items.length > 0 ? (
                  <>
                    <tbody className=" overflow-x-scroll">
                      {items.map((row) => (
                        <tr className=" border h-[30px]"
                          key={"key_" + Math.random().toString(36).substr(2, 9)}
                        >
                          <td className=" pl-2 text-center border font-medium">
                            {moment(row.betPlacedTime).format(
                              "D/M/YYYY HH:mm:ss"
                            )}
                          </td>
                          <td className=" pl-2 text-center border font-medium">
                            <span>
                              {row.marketType === "FANCY"
                                ? row.marketName +
                                  " @ " +
                                  Number(row.oddValue * 100 - 100).toFixed(0)
                                : row.marketType === "BINARY"
                                ? row.outcomeDesc +
                                  " @ " +
                                  Number(row.sessionRuns).toFixed(0)
                                : row.outcomeDesc}
                            </span>
                          </td>
                          <td className=" pl-2 text-center border font-medium">
                            <span>{row.id}</span>
                          </td>
                          <td className=" pl-2 text-center border font-medium">
                            <span>
                              {row.betType == "BACK" ? langData?.['back'] : langData?.['lay']}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={8}>{langData?.['records_not_found_txt']}</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        <div className="w-[100%] flex justify-center mt-1.5">
          <div className="px-3 w-[93%] bg-[#F2F2F2] mx-[3px]">
            <div className="flex justify-between ">
              <div className="px-3 py-1">{langData?.['back_subtotal']}:</div>
              <div className="px-3 py-1">{Number(backTotal).toFixed(2)}</div>
            </div>
            <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
            <div className="flex justify-between ">
              <div className="px-3 py-1">{langData?.['lay_subtotal']}:</div>
              <div className="px-3 py-1">{Number(layTotal).toFixed(2)}</div>
            </div>
            <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
            <div className="flex justify-between">
              <div className="px-3 py-1">{langData?.['market_subtotal']}:</div>
              <div className="px-3 py-1">{Number(marketTotal).toFixed(2)}</div>
            </div>
            <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
            <div className="flex justify-between">
              <div className="px-3 py-1">{langData?.['commission']}</div>
              <div className={`${details.commission > 0 ? "text-green-500" : details.commission < 0 ? "text-red-500" : ""} px-3 py-1`}>{Number(details.commission ?? 0).toFixed(2)}</div>
            </div>
            <div className="self-stretch w-full border border-solid border-neutral-200 min-h-[1px]" />
            <div className="flex justify-between">
              <div className="px-3 py-1 font-semibold">{langData?.['net_market_total']}:</div>
              <div className="px-3 py-1 font-semibold">{Number(netMarket ?? 0).toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
      {details && details.length === 0 && (
        <div className=" text-center">{langData?.['records_not_found_txt']}</div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.reports.items,
    backTotal: state.reports.backTotal,
    layTotal: state.reports.layTotal,
    marketTotal: state.reports.marketTotal,
    netMarket: state.reports.netMarket,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchPLByMarket: (event: any) => dispatch(fetchPLByMarket(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
