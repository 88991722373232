import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchBettingCurrency, fetchContentConfig } from './commonThunk';

import { CONFIG_PERMISSIONS } from '../../constants/ConfigPermission';
import { AlertDTO } from '../../models/Alert';
import { DcGameNew } from '../../models/dc/DcGame';
import { initialCampaignDetails } from '../../models/AffiliateState';
import { Currency } from '../../models/Currency';
import { CommonState } from '../../models/CommonState';
import { DomainConfig } from '../../models/DomainConfig';
import { toast } from 'react-toastify';

const initialState: CommonState = {
  isAdminReportsUrl: false,
  isAdminRiskMgmtUrl: false,
  isHouseUrl: false,
  prefersDark: localStorage.getItem('userTheme') ? localStorage.getItem('userTheme') : 'darkgreen',
  bettingCurrency: Currency.Pts,
  currenciesAllowed: null,
  selectedCasinoGame: null,
  tvGamesEnabled: false,
  dcGameUrl: '',
  streamUrl: '',
  playStream: false,
  allowedConfig: CONFIG_PERMISSIONS.sports + CONFIG_PERMISSIONS.casino,
  commissionEnabled: false,
  balanceChanged: null,
  notificationUpdated: null,
  casinoGames: [],
  domainConfig: {
    demoUser: false,
    signup: false,
    whatsapp: false,
    telegram: false,
    payments: false,
    bonus: false,
    affiliate: false,
    languages: []
  },
  contentConfig: null,
  trendingGames: null,
  campaignInfo: initialCampaignDetails,
  alert: {
    type: "",
    message: ""
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    activateReportsTab: (state, action: PayloadAction<boolean>) => {
      state.isAdminReportsUrl = action.payload;
    },
    activateRiskMgmtTab: (state, action: PayloadAction<boolean>) => {
      state.isAdminRiskMgmtUrl = action.payload;
    },
    activateHouseTab: (state, action: PayloadAction<boolean>) => {
      state.isHouseUrl = action.payload;
    },
    toggleDarkMode: (state, action: PayloadAction<string>) => {
      state.prefersDark = action.payload;
    },
    setPlayStream: (state, action: PayloadAction<boolean>) => {
      state.playStream = action.payload;
    },
    setCampaignInfo: (state, action: PayloadAction<any>) => {
      state.campaignInfo = action.payload;
    },
    setAlertMsg: (state, action: PayloadAction<AlertDTO>) => {
      state.alert = action.payload;
    },
    setCasinoGames: (state, action: PayloadAction<DcGameNew[]>) => {
      state.casinoGames = action.payload;
    },
    casinoGameSelected: (state, action: PayloadAction<{ id: string; name: string }>) => {
      state.selectedCasinoGame = action.payload;
    },
    setDcGameUrl: (state, action: PayloadAction<string>) => {
      state.dcGameUrl = action.payload;
    },
    setLivestreamUrl: (state, action: PayloadAction<string>) => {
      state.streamUrl = action.payload;
    },
    setAllowedConfig: (state, action: PayloadAction<number>) => {
      state.allowedConfig = action.payload;
    },
    setDomainConfig: (state, action: PayloadAction<DomainConfig>) => {
      state.domainConfig = action.payload;
    },
    enableCommission: (state, action: PayloadAction<boolean>) => {
      state.commissionEnabled = action.payload;
    },
    triggerFetchBalance: (state, action: PayloadAction<number>) => {
      state.balanceChanged = action.payload;
    },
    updateNotifications: (state, action: PayloadAction<number>) => {
      state.notificationUpdated = action.payload;
    },
    fetchContentConfigSuccess: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.contentConfig = action.payload;
      }
    },
    setTrendingGames: (state, action: PayloadAction<any>) => {
      state.trendingGames = action.payload;
    },
    showToast: (state, action) => {
      toast(action.payload.message, action.payload.type);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBettingCurrency.pending, (state) => {
      state.bettingCurrency = Currency.Pts; // Add appropriate state change if necessary
    });
    builder.addCase(fetchBettingCurrency.fulfilled, (state, action) => {
      state.bettingCurrency = action.payload.bettingCurrency;
      state.currenciesAllowed = action.payload.currenciesAllowed;
    });
    builder.addCase(fetchBettingCurrency.rejected, (state) => {
      state.bettingCurrency = null;
    });
    builder.addCase(fetchContentConfig.fulfilled, (state, action) => {
      state.contentConfig = action.payload;
    });
  },
});

export const {
  activateReportsTab,
  activateRiskMgmtTab,
  activateHouseTab,
  toggleDarkMode,
  setPlayStream,
  setCampaignInfo,
  setAlertMsg,
  setCasinoGames,
  casinoGameSelected,
  setDcGameUrl,
  setLivestreamUrl,
  setAllowedConfig,
  setDomainConfig,
  enableCommission,
  triggerFetchBalance,
  fetchContentConfigSuccess,
  setTrendingGames,
  updateNotifications,
  showToast
} = commonSlice.actions;

export default commonSlice.reducer;
