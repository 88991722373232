/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useRef, useState } from "react";
import star from "../../../assets/star.png";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { fetchNotifications } from "../../../store/auth/authThunk";
import Modal from "../../Modal/Modal";
import Notifications from "./Notifications";
import { useAppSelector } from "../../../store/storeHooks";

interface NotificationsProps {
  fetchNotifications: Function;
  notifications: [];
}

const TopBanner: React.FC<NotificationsProps> = (props) => {
  const { notifications, fetchNotifications } = props;
  const bannerRef = useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { langData } = useAppSelector((state) => state.cms);

  useEffect(() => {
    const banner = bannerRef.current;
    if (banner) {
      banner.style.animation = "moveLeft 30s linear infinite";
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      <div className=" overflow-hidden h-[31px] bg-stone-900 text-white text-[14px] tracking-wide py-1">
        <div ref={bannerRef} className=" w-full whitespace-nowrap inline-block">
          <div className="marquee">
            <div className="marquee-content" onClick={() => setOpenModal(true)}>
              {notifications.map((item: any, index) => (
                <React.Fragment key={index}>
                  <span className="mx-4">{item.message}</span>
                  <img
                    src={star}
                    alt=""
                    className="inline-block w-5 h-5 mx-2"
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        title={langData?.['notifications']}
        onClose={() => setOpenModal(false)}
      >
        <Notifications />
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    notifications: state.auth.notifications,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchNotifications: () => dispatch(fetchNotifications()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBanner);
