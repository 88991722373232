import React, { useEffect, useState } from 'react';
// import './FancyBookView.scss';
import { connect } from 'react-redux';
import { UserBet } from '../../../models/UserBet';
import { CURRENCY_TYPE_FACTOR } from '../../../constants/CurrencyTypeFactor';
import { getCurrencyTypeFromToken } from '../../../utils/commonUtils';
import { RootState } from '../../../store';
import { useAppSelector } from '../../../store/storeHooks';

type PropsType = {
  fancyBookOutcomeId: string;
  openBets: UserBet[];
  exposureMap?: any;
};

const FancyBookView: React.FC<PropsType> = (props) => {
  const { fancyBookOutcomeId, openBets, exposureMap } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<UserBet[]>();
  const [runsRiskMap, setRunsRiskMap] = useState({});
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const { langData } = useAppSelector((state) => state.cms);

  const getRiskValue = () => {
    let probableRunsList = [];
    if (exposureMap) {
      probableRunsList = exposureMap.sort((a, b) => Number(b) - Number(a));
      let runsMap = {};
      for (let pr of probableRunsList) {
        runsMap[pr['runnerId']] = pr;
      }
      setRunsRiskMap(runsMap);
    }
  };

  useEffect(() => {
    setLoading(true);
    getRiskValue();
    setTableData(
      openBets.filter(
        (bet) => bet.marketType === 2 && bet.outcomeId === fancyBookOutcomeId
      )
    );
    setLoading(false);
  }, [openBets]);

  return (
    <div className="fancy-book-table-ctn">
      {loading ? null : (
        <>
          {tableData ? (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr className="bg-gray-100 border-b">
                    <th className="text-left text-[14px] px-3 py-2 font-medium text-gray-700">{langData?.['runner_name']}</th>
                    <th className="text-left text-[14px] px-3 py-2 font-medium text-gray-700">{langData?.['profit_loss']}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(runsRiskMap).sort((a, b) => Number(a) - Number(b)).map((run, idx) => (
                    <tr key={idx} className="border-b">
                      <td className="px-3 py-2">
                        {idx === 0
                          ? run + ' or Less'
                          : idx === Object.keys(runsRiskMap).length - 1
                            ? run + ' or More'
                            : run}
                      </td>
                      <td
                        className={`px-3 py-2 ${
                          runsRiskMap[run]['userRisk'] > 0
                            ? 'text-green-600'
                            : runsRiskMap[run]['userRisk'] < 0
                            ? 'text-red-600'
                            : ''
                        }`}
                      >
                        {runsRiskMap[run]['userRisk'] > 0
                          ? '+' + Number(runsRiskMap[run]['userRisk']).toFixed(2)
                          : Number(runsRiskMap[run]['userRisk']).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center py-4">{langData?.['no_data_to_display']}</div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    openBets: state.betSlip.openBets,
  };
};

export default connect(mapStateToProps)(FancyBookView);
