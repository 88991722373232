/**
 * This code was generated by Builder.io.
 */
import moment from "moment";
import React from "react";
import { useAppSelector } from "../../../store/storeHooks";

interface BonusStatementTableProps {
  records: any;
}

const BonusStatementTable: React.FC<BonusStatementTableProps> = (props) => {
  const { records } = props;
  const { langData } = useAppSelector((state) => state.cms);
  const headings = [
    "bonus_type",
    "approval_required_txt",
    "awarded_date",
    "awarded_amount_txt",
    "turnover",
    "installments",
    "redeemed_amount",
    "status",
    "last_date",
  ];

  return (
    <>
      <table className="w-full border-collapse mt-3">
        <thead>
          <tr className="bg-neutral-200 text-neutral-500">
            {headings.map((heading, index) => (
              <th
                key={index}
                className={`px-2 py-1 border-r font-[500] text-[14px] leading-[16.2px] ${
                  index < headings.length - 1 ? "border-[#757575]" : ""
                }`}
              >
                {langData?.[heading]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((row, index) => (
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.bonus_category}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.approval_required ? "Yes" : "No"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {moment(row.award_date).format("DD/MM/YYYY, h:mm:ss A")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.award_amount?.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {(row.turnover_met ? row.turnover_met : "-") +
                  "/" +
                  (row.turnover_required ? row.turnover_required : "-")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {(row.installments_given ? row.installments_given : "-") +
                  "/" +
                  (row.installments ? row.installments : "-")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row?.redeemed_amount?.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.bonus_status}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"></td>
              {row.last_redeem_date
                ? moment(row.last_redeem_date).format("DD/MM/YYYY, h:mm:ss A")
                : "-"}
            </tr>
          ))}
        </tbody>
      </table>
      {records && records.length === 0 && (
        <div className="  h-12 flex justify-center items-center">{langData?.['records_not_found_txt']}</div>
      )}
    </>
  );
};

export default BonusStatementTable;
