/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Filters } from "../../../../store/reports/reportSelectors";
import DropDown from "../../DropDown";
import ReactDatePicker from "../../ReactDatePicker";
import { useAppSelector } from "../../../../store/storeHooks";

interface FilterSectionProps {
  onDateChange: Function;
  filters: Filters;
  setFilters: Function;
  sports: any;
  games: any;
  betStatus: any;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  onDateChange,
  filters,
  setFilters,
  sports,
  games,
  betStatus
}) => {
  const { langData } = useAppSelector((state) => state.cms);

  const handleFromDateChange = (date) => {
    onDateChange(date, filters.endDate);
  };

  const handleToDateChange = (date) => {
    onDateChange(filters.startDate, date);
  };

  const handleBetStatus = (event) => {
    setFilters({ ...filters, status: event.value });
  };

  const handleSport = (event) => {
    setFilters({ ...filters, selectedGame: event.value });
  };

  return (
    <section className="flex flex-col mt-3 p-[12px]">
      <div className="flex gap-3 self-center mt-3 w-full text-[14px] font-medium max-w-[406px]">
        <div className="flex flex-col flex-1 ">
          <label htmlFor="betStatus" className="text-neutral-500">
            {langData?.['bet_status']}
          </label>
          <DropDown options={betStatus} selectedOption={filters?.status === "Open" ? { value: "Open", label: "Open" } : filters?.status} onChange={handleBetStatus} />
        </div>
        <div className="flex flex-col flex-1 ">
          <label htmlFor="betStatus" className="text-neutral-500">
            {langData?.['select_games']}
          </label>
          <DropDown options={games} selectedOption={filters?.selectedGame === "SPORTS" ? { value: "SPORTS", label: "Sports" } : filters?.selectedGame} onChange={handleSport} />
        </div>
      </div>
      <div className="flex gap-3 self-center mt-[12px] w-full text-[14px] font-medium whitespace-nowrap max-w-[406px]">
        <div className="flex flex-col flex-1 ">
          <label htmlFor="fromDate" className="text-neutral-500">
            {langData?.['from']}
          </label>
            <ReactDatePicker date={filters.startDate} setDate={handleFromDateChange} />
        </div>
        <div className="flex flex-col flex-1  ">
          <label htmlFor="toDate" className="text-neutral-500">
            {langData?.['to']}
          </label>
            <ReactDatePicker date={filters.endDate} setDate={handleToDateChange} />
        </div>
      </div>
    </section>
  );
};

export default FilterSection;
