/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { subHeaders } from "../../../utils/commonUtils";
import { useDispatch } from "react-redux";
import { removeExchangeBet } from "../../../store/betSlip/betSlipSlice";
import { setCompetition, setEventType } from "../../../store/catalog";
import { SPORTS_MAP } from "../../../constants/ExchangeEventTypes";
import { useAppSelector } from "../../../store/storeHooks";

const CategoryNavigation: React.FC = () => {
  const dispatch = useDispatch();
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <nav className="flex scrollbar-hide w-full overflow-x-scroll text-[14px] font-semibold tracking-wider text-center text-white uppercase bg-stone-900 ">
      {subHeaders('mobile').map((category, index) => (
        <div
          className={` my-auto min-w-fit h-8 leading-8 px-3 border-r   ${
            window.location.pathname === category.path
              ? "text-amber-400 text-opacity-90"
              : "  "
          }`}
          onClick={() => {
            dispatch(removeExchangeBet(0));
                dispatch(setEventType(SPORTS_MAP.get(category.text)));
                dispatch(setCompetition({ id: '', name: '', slug: '' }));
          }}
        >
          <Link key={category.text} to={category.path}>
            {langData?.[category.langKey]}
          </Link>
        </div>
      ))}
    </nav>
  );
};

export default CategoryNavigation;
