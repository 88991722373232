import { useState } from "react";
import Button from "./Button";
import InputField from "./InputField";
import ToggleButton from "./ToggleButton";
import CommonBackImgAuthScreens from "./ForGotPasswordImg/CommonBackImgAuthScreens";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useAppSelector } from "../../../store/storeHooks";

interface ForgotPasswordProps {
  redirectToSignUp: Function;
  method: string;
  handleMethodChange: Function;
  handleSubmit: Function;
  username: string;
  handleUsernameChange: Function;
  countryCode: string;
  handleCountryCodeChange: Function;
  mobile: string;
  handleMobileChange: Function;
  otpSuccess: boolean;
  otp: number;
  setOtp: Function;
  newPassword: string;
  setNewPassword: Function;
  confirmPassword: string;
  setConfirmPassword: Function;
  resetPassword: Function;
  showPasswordClickHandler: Function;
  showPassword: boolean;
  redirectToSignIn: Function;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const {
    redirectToSignUp,
    method,
    otpSuccess,
    mobile,
    handleMobileChange,
    username,
    countryCode,
    handleCountryCodeChange,
    handleUsernameChange,
    handleMethodChange,
    handleSubmit,
    otp,
    setOtp,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    resetPassword,
    showPasswordClickHandler,
    showPassword,
    redirectToSignIn
  } = props;
  const [userName, setUserName] = useState<boolean>(true);
  const { langData } = useAppSelector((state) => state.cms);

  const { domainConfig } = useAppSelector(state => state.common);
  return (
    <main className="flex items-center flex-col w-[100%]  h-[100vh]">
      <CommonBackImgAuthScreens redirectToHome={redirectToSignIn} tagline="Bet smarter,Bet with confidence, Win bigger: your ultimate bet experience" />
      <section className="relative w-[90%] mt-[15px] mb-[25px] flex z-10 flex-col self-center px-4  text-sm font-medium bg-white rounded-2xl border-4 border-solid border-neutral-200 text-neutral-500">
        {/* <button
          onClick={() => redirectToSignIn()}
          className="flex items-center gap-1.5 text-sm font-medium whitespace-nowrap text-stone-900 w-[61px]"
        >
          <FaArrowLeftLong />
          <span className="my-auto">Back</span>
        </button> */}
        <h1 className="self-center text-3xl font-semibold text-center text-stone-900">
          {langData?.['forgot_password']}
        </h1>
        {!otpSuccess ? (
          <>
            <p className="self-center mt-2.5 text-base text-center text-neutral-500 w-[254px]">
              {langData?.['forgot_password_send_otp_txt']}
            </p>
            <ToggleButton
              setUserName={setUserName}
              method={method}
              handleMethodChange={handleMethodChange}
            />
            {userName ? (
              <InputField
                label={langData?.['username']}
                value={username}
                onChange={(e) => handleUsernameChange(e)}
                placeholder={langData?.['enter_username_txt']}
              />
            ) : (
              <InputField
                label={langData?.['mobile']}
                value={mobile}
                onChange={(e) => handleMobileChange(e)}
                placeholder={langData?.['enter_mobile_no']}
              />
            )}
            <Button onClick={() => handleSubmit()}>{langData?.['send_otp']}</Button>
          </>
        ) : (
          <>
            <InputField
              label={langData?.['otp']}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder={langData?.['enter_otp_txt']}
            />
            <div className="mt-6">
              <label htmlFor="password" className="self-start text-neutral-500">
                {langData?.['password']}
              </label>
              <div className="flex gap-5 justify-between px-3.5 py-3 mt-3 bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60">
                <input
                  type={"text"}
                  id="password"
                  className="w-full bg-transparent border-none"
                  placeholder={langData?.['enter_password']}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="password" className="self-start text-neutral-500">
                {langData?.['confirm_password']}
              </label>
              <div className="flex gap-5 justify-between px-3.5 py-3 mt-3 bg-white rounded-lg border border-solid border-neutral-200 text-neutral-500 text-opacity-60">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="w-full bg-transparent border-none"
                  placeholder={langData?.['enter_confirm_password_txt']}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div
                  className="cursor-pointer"
                  onClick={() => showPasswordClickHandler()}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
            </div>
            <Button onClick={() => resetPassword()}>{langData?.['reset_password_txt']}</Button>
          </>
        )}
       {domainConfig?.signup && <p className="self-center mt-4 text-sm text-center text-stone-900">
          {langData?.['no_account']}?{" "}
          <button
            onClick={() => redirectToSignUp()}
            className="font-semibold text-[#4da8f1] underline"
          >
            {langData?.['sign_up']}
          </button>
        </p>}
      </section>
    </main>
  );
};

export default ForgotPassword;
