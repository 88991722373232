import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { fetchPLByMarket } from "../../../../store/reports/reportsThunk";
import {
  cFactor,
  Filters,
  PLStatement,
} from "../../../../store/reports/reportSelectors";
import { useAppSelector } from "../../../../store/storeHooks";

type PLProps = {
  selectedMarket: PLStatement;
  filters: Filters;
  items: any;
  backTotal: Number;
  layTotal: Number;
  marketTotal: Number;
  netMarket: Number;
  fetchPLByMarket: Function;
};

const PLStatementMktLvl: React.FC<PLProps> = (props) => {
  const {
    selectedMarket,
    filters,
    items,
    backTotal,
    layTotal,
    marketTotal,
    netMarket,
    fetchPLByMarket,
  } = props;
  const { langData } = useAppSelector((state) => state.cms);

  const tableFields = [
    {
      key: "betPlacedTime",
      Label: "Placed",
      langKey: "placed",
      className: "placed-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "selection",
      Label: "Selection",
      langKey: "selection",
      className: "selection-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "betId",
      Label: "Bet ID",
      langKey: "bet_id",
      className: "betid-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "betType",
      Label: "Type",
      langKey: "type",
      className: "type-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "odds",
      Label: "Odds",
      langKey: "odds",
      className: "odds-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "stake",
      Label: "Amount",
      langKey: "amount",
      className: "stake-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "profitLoss",
      Label: "Winnings",
      langKey: "winnings",
      className: "ploss-cell-head text-uppercase",
      align: "left",
    },
    {
      key: "status",
      Label: "Result",
      langKey: "result",
      className: "status-cell-head text-uppercase",
      align: "left",
    },
  ];

  useEffect(() => {
    fetchPLByMarket(filters);
  }, []);

  return (
    <div className="modal-pl">
      {selectedMarket ? (
        <>
          <div className=" p-1">
            <div>
              <table className=" w-full text-[14px] ">
                <thead className=" w-full ">
                  <tr className=" bg-[#e0e0e0] h-8">
                    {tableFields.map((tF, index) => (
                      <th
                        key={"key_" + Math.random().toString(36).substr(2, 9)}
                        align={tF.align === "left" ? "left" : "center"}
                        className=" border-r border-[#757575] pl-4 text-[#757575]"
                      >
                        {langData?.[tF.langKey]}
                      </th>
                    ))}
                  </tr>
                </thead>
                {items && items.length > 0 ? (
                  <>
                    <tbody className=" ">
                      {items.map((row) => (
                        <tr className=" border h-[30px]"
                          key={"key_" + Math.random().toString(36).substr(2, 9)}
                        >
                          <td className=" pl-2 text-center border font-medium">
                            {moment(row.betPlacedTime).format(
                              "D/M/YYYY HH:mm:ss"
                            )}
                          </td>
                          <td className=" pl-2 text-center border font-medium">
                            <span>
                              {row.marketType === "FANCY"
                                ? row.marketName +
                                  " @ " +
                                  Number(row.oddValue * 100 - 100).toFixed(0)
                                : row.marketType === "BINARY"
                                ? row.outcomeDesc +
                                  " @ " +
                                  Number(row.sessionRuns).toFixed(0)
                                : row.outcomeDesc}
                            </span>
                          </td>
                          <td className=" pl-2 text-center border font-medium">
                            <span>{row.id}</span>
                          </td>
                          <td className=" pl-2 text-center border font-medium">
                            <span>
                              {row.betType == "BACK" ? "Back" : "Lay"}
                            </span>
                          </td>
                          <td className=" pl-2 text-center border font-medium">
                            <span>
                              {row.oddValue === -1
                                ? "-"
                                : row.oddValue.toFixed(2)}
                            </span>
                          </td>
                          <td className=" pl-2 text-center border font-medium">
                            <span>
                              {(row.stakeAmount / cFactor).toFixed(2)}
                            </span>
                          </td>
                          <td className=" pl-2 text-center border font-medium">
                            <span>
                              <div
                                className={
                                  row.outcomeResult == "Won"
                                    ? "text-green-600"
                                    : "text-red-600"
                                }
                              >
                                {Number(row.payOutAmount / cFactor).toFixed(2)}
                              </div>
                            </span>
                          </td>

                          <td className=" pl-2 text-center border font-medium">
                            <span>
                              <div>
                                {row.outcomeResult == "Won" ||
                                row.payOutAmount > 0
                                  ? "Win"
                                  : "Loss"}
                              </div>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={8}>{langData?.['records_not_found_txt']}</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>

          <div className="">
            <div className=" flex justify-between p-1 font-medium">
              <div className=""> {langData?.['back_subtotal']}:</div>
              <div className="">
                {Number(backTotal).toFixed(2)}
              </div>
            </div>
            <div className=" flex justify-between border p-1 bg-[#e0e0e0] font-medium">
              <div className=""> {langData?.['lay_subtotal']}:</div>
              <div className="">
                {Number(layTotal).toFixed(2)}
              </div>
            </div>
            <div className=" flex justify-between border p-1 bg-[#e0e0e0] font-medium">
              <div className=""> {langData?.['market_subtotal']}: </div>
              <div className="">
                {Number(marketTotal).toFixed(2)}
              </div>
            </div>
            <div className=" flex justify-between border p-1 bg-[#e0e0e0] font-medium">
              <div className=""> {langData?.['commission']}: </div>
              <div className={selectedMarket.commission > 0 ? "text-green-500" : selectedMarket.commission < 0 ? "text-red-500" : ""}>
                {Number(selectedMarket.commission ?? 0).toFixed(2)}
              </div>
            </div>
            <div className=" flex justify-between border p-1 bg-[#e0e0e0] font-medium">
              <div className=" font-bold">{langData?.['net_market_total']}:</div>
              <div className=" ">
                {Number(netMarket ?? 0).toFixed(2)}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.reports.items,
    backTotal: state.reports.backTotal,
    layTotal: state.reports.layTotal,
    marketTotal: state.reports.marketTotal,
    netMarket: state.reports.netMarket,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchPLByMarket: (event: any) => dispatch(fetchPLByMarket(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PLStatementMktLvl);
