import { createInitialDataObjFromFormArr } from "../../../Withdraw/utils/Withdraw.utils";
import {
  API_PAYMENT_METHODS,
  BankDepositInitialFormType,
  DEPOSIT_PAYMENT_TYPES,
  PAYMENT_OPTION_TYPES,
} from "./types";

export const bankDepositInputFields = [
  {
    key: "amount",
    label: "Amount",
    langKey: 'amount',
    type: "number",
    placeholder: "enter_amount",
  },
  {
    key: "mobileNumber",
    label: "Mobile Number",
    langKey: 'mobile',
    type: "number",
    placeholder: "enter_mobile_no",
  },
];

export const PaymentOptionTypes = Object.keys(
  PAYMENT_OPTION_TYPES
) as PAYMENT_OPTION_TYPES[];

export const API_PAYMENT_METHODS_MAP = {
  [DEPOSIT_PAYMENT_TYPES.UPI]: API_PAYMENT_METHODS.UPI_TRANSFER,
  [DEPOSIT_PAYMENT_TYPES.BANK]: API_PAYMENT_METHODS.BANK_TRANSFER,
};

export const getBankDepositFormInitialState = (): BankDepositInitialFormType =>
  createInitialDataObjFromFormArr(
    bankDepositInputFields
  ) as BankDepositInitialFormType;
