/**
 * This code was generated by Builder.io.
 */
import React, { useEffect } from "react";
import InputField from "./InputField";
import { bankDepositInputFields } from "../utils/deposit.utils";
import { BankDepositInitialFormType } from "../utils/types";
import { useAppSelector } from "../../../../../store/storeHooks";

interface InputFormProps {
  bankDepositFormData: BankDepositInitialFormType;
  handleBankAccountFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDepositAmountClick: (e: React.FormEvent<HTMLFormElement>) => void;
  isDepositBtnDisabled: boolean;
  isBankDepositFormDisabled: boolean;
}

const InputForm: React.FC<InputFormProps> = ({
  bankDepositFormData,
  handleBankAccountFormChange,
  handleDepositAmountClick,
  isDepositBtnDisabled,
  isBankDepositFormDisabled,
}) => {
  const { langData } = useAppSelector((state) => state.cms);
  return (
    <form
      onSubmit={handleDepositAmountClick}
      className="flex flex-wrap gap-3 items-center mt-4"
    >
      {bankDepositInputFields.map((field) => {
        return (
          <React.Fragment key={field.key}>
            <div className="flex flex-col self-stretch my-auto rounded-md min-w-[240px] w-full lg:w-[48%] max-md:max-w-full flex-wrap">
            <label
              htmlFor={field.key}
              className="self-start text-[14px] font-medium text-stone-900"
            >
              {langData?.[field.langKey]}
            </label>
            <input
              disabled={isBankDepositFormDisabled}
              id={field.key}
              placeholder={langData?.[field.placeholder]}
              className={`gap-2.5 self-stretch px-3 py-3.5 mt-2 rounded-md border border-solid bg-zinc-100 border-neutral-200 min-h-[44px] text-[14px] ${
                isBankDepositFormDisabled && "text-gray-400"
              }`}
              aria-label={field.label}
              value={bankDepositFormData[field.key]}
              type={field.type}
              onChange={handleBankAccountFormChange}
              onInput={(e) => {
                e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, '');
              }}
            />
          </div>
          </React.Fragment>
        );
      })}

      <div className="mt-6 w-full">
        <button
          type="submit"
          disabled={isDepositBtnDisabled}
          className={`px-4 py-2 ${
            isDepositBtnDisabled ? "bg-green-300" : "bg-green-600"
          } text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
        >
          {langData?.['next']}
        </button>
      </div>
    </form>
  );
};

export default InputForm;
