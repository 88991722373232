import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchContentConfigSuccess, setDomainConfig, updateNotifications } from './commonSlice'; // Adjust the path as needed
import moment from 'moment';
// import { getAccountPathFromToken, getSportsBookFromToken };
import SVLS_API from '../../api/api-services/svls-api';
import { logout } from '../auth/authThunk';
import { getAccountPathFromToken, getSportsBookFromToken } from '../../utils/commonUtils';
import { BRAND_DOMAIN } from '../../constants/Branding';
import { DomainConfig } from '../../models/DomainConfig';

export const fetchBettingCurrency = createAsyncThunk(
  'common/fetchBettingCurrency',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await SVLS_API.get('/user/betting-currency', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        const token = sessionStorage.getItem('jwt_token');
        if (token) {
          dispatch(logout());
        }
      }
      return rejectWithValue(err.message);
    }
  }
);

export const fetchContentConfig = createAsyncThunk(
  'common/fetchContentConfig',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await SVLS_API.get(`/account/v2/books/cache/${BRAND_DOMAIN}/content-config`);
      if (!response?.data?.default_template) {
        dispatch(fetchContentConfigSuccess(response?.data));
      }
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const getDomainConfig = createAsyncThunk(
  'common/getDomainConfig',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await SVLS_API.get(`/account/v2/books/${BRAND_DOMAIN}`);
      if (response.status === 200) {
        const dConfig: DomainConfig = {
          demoUser: response.data.demo_user_enabled,
          signup: response.data.signup_enabled,
          whatsapp: response.data.whatsapp_support,
          telegram: response.data.telegram_support,
          payments: response.data.payments_enabled,
          bonus: response.data.bonus_enabled,
          affiliate: response.data.affiliate_enabled,
          languages: response.data.languages
        };
        console.log("kjhfd", dConfig);
        dispatch(setDomainConfig(dConfig));
      }
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const triggerFetchNotifications = createAsyncThunk(
  'common/triggerFetchNotifications',
  async (data: any, { dispatch }) => {
    var adminAccountPath = data.adminAccountPath.concat('/');
    if (
      getAccountPathFromToken().includes(adminAccountPath) &&
      (data.sportsBooks.includes('all') || data.sportsBooks.includes(getSportsBookFromToken()))
    ) {
      dispatch(updateNotifications(moment.now()));
    }
  }
);

export const isAccountPath = (limitKey: string) => {
  return !limitKey.includes('/CT/SPORTS');
};