/**
 * This code was generated by Builder.io.
 */
import React from "react";

import gameRules from '../../../assets/game_rules.svg'
import favourites from '../../../assets/favourites.svg'
import my_bets from '../../../assets/my_bets.svg'
import my_wallet from '../../../assets/my_wallet.svg'
import profit_loss from '../../../assets/profit_loss.svg'
import turnover_history from '../../../assets/turnover_history.svg'
import acc_statement from '../../../assets/acc_statement.svg'
import bonus_statement from '../../../assets/bonus_statement.svg'
import my_transaction from '../../../assets/my_transaction.svg'
import stake_settings from '../../../assets/stake_settings.svg'
import my_profile from '../../../assets/my_profile.svg'
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/storeHooks";


interface MenuItem {
  icon: string;
  text: string;
  langKey: string;
  path: string;
  requiresAuth?: boolean;
}

const menuItems: MenuItem[] = [
  { icon: gameRules, text: "Game Rules", langKey: "game_rules", path: "/game-rules" },
  { icon: favourites, text: "Favourites", langKey: "favourites", path: "/favourites", requiresAuth: true },
  { icon: my_bets, text: "My Bets", langKey: "my_bets", path: "/my-bets", requiresAuth: true },
  { icon: my_wallet, text: "My Wallet", langKey: "my_wallet", path: "/my-wallet", requiresAuth: true },
  { icon: profit_loss, text: "Betting Profit & Loss", langKey: "betting_profit_and_loss", path: "/profit-loss", requiresAuth: true },
  { icon: turnover_history, text: "Turnover History", langKey: "turnover_history", path: "/turnover-history", requiresAuth: true },
  { icon: turnover_history, text: "Commission Report", langKey: "commission_report", path: "/commission_report", requiresAuth: true },
  { icon: acc_statement, text: "Account Statement", langKey: "account_statement", path: "/account-statement", requiresAuth: true },
  { icon: bonus_statement, text: "Bonus Statement", langKey: "bonus_statement", path: "/bonus-statement", requiresAuth: true },
  { icon: my_transaction, text: "My Transaction", langKey: "my_transactions", path: "/my-transaction", requiresAuth: true },
  { icon: stake_settings, text: "Stake Setting", langKey: "stake_setting", path: "/stake-setting", requiresAuth: true },
  { icon: my_profile, text: "My Profile", langKey: "my_profile", path: "/my-profile", requiresAuth: true },
];

type OtherMenuProps = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const OtherMenu: React.FC<OtherMenuProps> = ({ selectedTab, setSelectedTab }) => {
  const { loggedIn } = useAppSelector(state => state.auth);
  const { langData } = useAppSelector((state) => state.cms);

  return (
    <>
      <div className="mt-6 text-[11px] font-semibold tracking-widest text-neutral-500">
        {langData?.['other_menu']}
      </div>
      {menuItems.map((item, index) => {
        if (item.requiresAuth && !loggedIn) {
          return null;
        }
        return (
          <React.Fragment key={index}>
            <Link to={item.path} onClick={() => setSelectedTab(item.path)}>
              <div className={`flex font-[600] items-center gap-3.5 mt-3 text-[14px]`} onClick={() => setSelectedTab(item.text)}>
                <img src={item.icon} alt="" className="w-5 h-5" />
                <span>{langData?.[item.langKey]}</span>
              </div>
            </Link>
            {index < menuItems.length - 1 && (
              <div className="mt-1.5 h-px bg-zinc-100" />
            )}
          </React.Fragment>
        )
      }
      )}
    </>
  );
};

export default OtherMenu;
