import moment from "moment";
import { connect } from "react-redux";
import { fetchAdminNotifications } from "../../../store/auth/authThunk";
import { RootState } from "../../../store";
import { useEffect } from "react";
import { useAppSelector } from "../../../store/storeHooks";

interface NotificationProps {
  notifications: [];
  fetchNotifications: Function;
}

const Notifications: React.FC<NotificationProps> = (props) => {
  const { notifications, fetchNotifications } = props;
  const { langData } = useAppSelector((state) => state.cms);

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      {notifications.map((notification: any) => (
        <div className="notifi-title-time">
          <div>
            <span className=" font-medium">{langData?.['time']}: </span>
            {moment(notification.startTime).format("DD MMM")},
            {moment(notification.startTime).format("HH:mm")}
          </div>
          <div>
            <span className=" font-medium">{langData?.['message']}: </span>
            {notification.title}
          </div>
        </div>
      ))}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    notifications: state.auth.notifications,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchNotifications: () => dispatch(fetchAdminNotifications()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
