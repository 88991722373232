/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../store/storeHooks";

interface MenuItemProps {
  icon: string;
  text: string;
  langKey: string;
  path:string;
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, text, langKey, path }) => {
  const { langData } = useAppSelector((state) => state.cms);
  return (
    <div className="flex flex-col mt-[1px] w-full">
      <div className="flex flex-col justify-center items-start px-2 py-1.5 w-full bg-white border border-solid border-neutral-200">
        <NavLink to={path}>
        <div className="flex gap-3 justify-center items-center">
          <img
            loading="lazy"
            src={icon}
            alt=""
            className="object-contain shrink-0 self-stretch my-auto w-8 rounded-none aspect-square"
          />
          <div className="self-stretch my-auto">{langData?.[langKey]}</div>
        </div>
        </NavLink>
      </div>
    </div>
  );
};

export default MenuItem;
